// Copyright 2018 Descript, Inc

import * as ApiClient from './ApiClient';
import { Product } from './ProductClient';
import { AsyncContext } from '@descript/analytics';
import { SignedUrlJson } from './SignedUrlJson';
import { OwnershipJson, ProjectJson, User, UserGroup, UserJson } from './Project';
import { Errors } from '@descript/errors';

export async function markAuthorized(ctx: AsyncContext, isSignIn: boolean): Promise<void> {
    try {
        await ApiClient.request({
            ctx,
            method: ApiClient.RequestType.PUT,
            path: '/users/authorized',
            query: {
                is_sign_in: isSignIn,
            },
            // Do not reauthenticate, do not retry
            retryCount: 0,
        });
    } catch (e) {
        const error = e as Error;
        // Eat unauthorized and rate limit errors
        if (
            Errors.isUnauthorizedError(error) ||
            Errors.isForbiddenError(error) ||
            Errors.isRateLimitError(error)
        ) {
            return;
        }
        throw e;
    }
}

export async function fetchUser(
    ctx: AsyncContext,
    userId: string = 'me',
    delegateAuth?: string,
): Promise<User> {
    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/${userId}`,
        delegateAuth,
    });
    return User.fromJson(json as UserJson);
}

export async function fetchCard(
    ctx: AsyncContext,
    userId: string = 'me',
): Promise<CreditCard | undefined> {
    try {
        const json = (await ApiClient.request({
            ctx,
            method: ApiClient.RequestType.GET,
            path: `/users/${userId}/card`,
        })) as CreditCard;
        if (!json.brand || !json.last4) {
            // Not a credit card - don't show in UI
            return;
        }
        return json;
    } catch (err) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((err as any).statusCode === 404) {
            return await Promise.resolve(undefined);
        }
        throw err;
    }
}

export async function lookupUser(
    ctx: AsyncContext,
    email: string,
): Promise<LookupUserJson | undefined> {
    try {
        return (await ApiClient.request({
            ctx,
            method: ApiClient.RequestType.GET,
            path: '/users/lookup',
            query: {
                email: email.trim(),
            },
        })) as LookupUserJson;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((error as any).statusCode === 404) {
            return await Promise.resolve(undefined);
        }

        throw error;
    }
}

export async function updateUser(
    ctx: AsyncContext,
    userId: string = 'me',
    userUpdates: UpdateUserJson,
): Promise<User> {
    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.PUT,
        path: `/users/${userId}`,
        data: userUpdates,
    });
    return User.fromJson(json as UserJson);
}

export async function deleteUser(ctx: AsyncContext, userId: string): Promise<boolean> {
    await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.DELETE,
        path: `/users/${userId}`,
    });
    return true;
}

export async function linkAccounts(
    ctx: AsyncContext,
    {
        secondaryToken,
    }: {
        secondaryToken: string;
    },
): Promise<void> {
    await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: '/auth0/link_accounts',
        data: {
            secondaryToken,
        },
    });
}

export async function setCreditCard(
    ctx: AsyncContext,
    userId: string = 'me',
    token: StripeTokenJson,
): Promise<CreditCard | undefined> {
    return await ApiClient.request<CreditCard | undefined>({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/users/${userId}/card`,
        data: token,
    });
}

export async function fetchDataConsentInfo(
    ctx: AsyncContext,
    userId: string = 'me',
): Promise<DataConsent | undefined> {
    try {
        const json = (await ApiClient.request({
            ctx,
            method: ApiClient.RequestType.GET,
            path: `/users/${userId}/data_consent`,
        })) as DataConsentJson;
        return {
            isOptedIn: json.is_opted_in || false,
            hasResponded: json.has_responded || false,
            canRetry: json.can_retry || false,
        };
    } catch (e) {
        const err = e as Error;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((err as any).statusCode === 404) {
            return await Promise.resolve(undefined);
        }
        throw err;
    }
}

export async function setDataConsent(
    ctx: AsyncContext,
    userId: string = 'me',
    optIn: boolean,
): Promise<DataConsent> {
    const json = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/users/${userId}/data_consent`,
        data: { is_opted_in: optIn },
    })) as DataConsentJson;
    return {
        isOptedIn: json.is_opted_in || false,
        hasResponded: json.has_responded || true,
        canRetry: false,
    };
}

export async function getDiagnosticWriteCredentials(
    ctx: AsyncContext,
    userId: string = 'me',
): Promise<DiagnosticBucketCredentials> {
    return (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/${userId}/diagnostic_write_credentials`,
    })) as DiagnosticBucketCredentials;
}

export async function requestChangePasswordEmail(
    ctx: AsyncContext,
    userId: string = 'me',
): Promise<void> {
    await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/users/${userId}/request_change_password_email`,
    });
}

export async function createStripeCustomerPortalSession(ctx: AsyncContext): Promise<string> {
    const json = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/create_customer_portal_session`,
    })) as { url: string };
    return json?.url;
}

export async function fetchAuthIdentitiesSummary(
    ctx: AsyncContext,
    userId: string = 'me',
): Promise<AuthIdentitiesSummary> {
    const authIdentitiesSummaryJson = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/${userId}/auth_identities`,
    })) as AuthIdentitiesSummaryJson;
    return {
        canChangeEmail: authIdentitiesSummaryJson.canChangeEmail,
        changeEmailHint: authIdentitiesSummaryJson.changeEmailHint,
        canAddUsernamePassword: authIdentitiesSummaryJson.canAddUsernamePassword,
        identities: authIdentitiesSummaryJson.identities.map((authIdentityJson) => ({
            id: authIdentityJson.id,
            displayName: authIdentityJson.displayName,
            canChangePassword: authIdentityJson.canChangePassword,
            canRemove: authIdentityJson.canRemove,
        })),
    };
}

export async function deleteAuthIdentity(
    ctx: AsyncContext,
    {
        userId,
        authIdentityId,
    }: {
        userId: string;
        authIdentityId: string;
    },
): Promise<{ authIdentityId: string }> {
    const responseJson = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.DELETE,
        path: `/users/${userId}/auth_identities/${authIdentityId}`,
    })) as { id: string };
    const resp = { authIdentityId: responseJson.id };
    return resp;
}

export async function fetchAuthorizedClients(
    ctx: AsyncContext,
    userId: string = 'me',
): Promise<AuthorizedClient[]> {
    const authorizedClientsJson = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/${userId}/authorized_clients`,
    })) as AuthorizedClientJson[];
    return authorizedClientsJson.map((clientJson) => ({
        id: clientJson.client_id,
        name: clientJson.client_name,
    }));
}

/** Delete (and deauthorize) one of the authorized clients
 *
 * @returns ID of deleted client
 */
export async function deleteAuthorizedClient(
    ctx: AsyncContext,
    userId: string,
    clientId: string,
): Promise<{ clientId: string }> {
    const responseJson = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.DELETE,
        path: `/users/${userId}/authorized_clients/${clientId}`,
    })) as { client_id: string };
    const resp = { clientId: responseJson.client_id };
    return resp;
}

export async function getSignedFileUploadUrl(
    ctx: AsyncContext,
    userId: string,
    contentType: string,
    filename?: string,
): Promise<SignedUrlJson> {
    const query = {
        content_type: contentType,
        filename: filename,
    };
    const urls = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/${userId}/upload_file`,
        query,
    })) as SignedUrlJson;

    return urls;
}

export async function fetchZendeskChatJWT(ctx: AsyncContext): Promise<string> {
    const { jwt } = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/users/me/zendesk_chat_token`,
    })) as { jwt: string };
    return jwt;
}

export interface SubscriptionGroupDefinition {
    key: string;
    description: string;
}

export async function fetchEmailSubscriptionGroupsList(
    ctx: AsyncContext,
): Promise<SubscriptionGroupDefinition[]> {
    return await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/email_subscriptions/groups`,
    });
}

export async function fetchEmailSubscriptionGroups(
    ctx: AsyncContext,
    email: string,
): Promise<string[]> {
    const { subscription_groups: subscriptionGroups } = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/email_subscriptions/${encodeURIComponent(email)}`,
    })) as { subscription_groups: string[] };
    return subscriptionGroups;
}

export async function upsertEmailSubscriptionGroups(
    ctx: AsyncContext,
    {
        email,
        subscriptionGroups,
    }: {
        email: string;
        subscriptionGroups: string[];
    },
): Promise<string[]> {
    const { subscription_groups: updatedGroups } = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.PUT,
        path: `/email_subscriptions/${encodeURIComponent(email)}`,
        query: {},
        data: { subscription_groups: subscriptionGroups },
    })) as { subscription_groups: string[] };
    return updatedGroups;
}

export async function fetchEmailSubscriptionGroupsWithToken(
    ctx: AsyncContext,
    email: string,
    token: string,
): Promise<string[]> {
    const { subscription_groups: subscriptionGroups } = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/email_subscriptions/${encodeURIComponent(email)}/no_session`,
        query: { token },
    })) as { subscription_groups: string[] };
    return subscriptionGroups;
}

export async function upsertEmailSubscriptionGroupsWithToken(
    ctx: AsyncContext,
    {
        email,
        subscriptionGroups,
        token,
    }: {
        email: string;
        subscriptionGroups: string[];
        token: string;
    },
): Promise<string[]> {
    const { subscription_groups: updatedGroups } = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.PUT,
        path: `/email_subscriptions/${encodeURIComponent(email)}/no_session`,
        query: {},
        data: { subscription_groups: subscriptionGroups, token },
    })) as { subscription_groups: string[] };
    return updatedGroups;
}

export const UnknownUserJson: UserJson = {
    id: 'no-id',
    email: 'anonymous@descript.com',
};

export type UpdateUserJson = {
    first_name?: string;
    last_name?: string;
    email?: string;
    password?: string;
    old_password?: string;
    referral_target_id?: string;
    user_group?: UserGroup;
    app_use_cases?: string;
    affiliate_code?: string;
    profile_image_url?: string | null; // null to delete
    partnerstack_xid?: string;
    partnerstack_partner_key?: string;
};

export type OwnershipsJson = {
    ownerships?: OwnershipJson[];
    projects?: ProjectJson[];
};

export type LookupUserJson = {
    id: string;
    email: string;
};

export type DataConsentJson = {
    is_opted_in?: boolean;
    has_responded?: boolean;
    can_retry?: boolean;
};

export type DataConsent = {
    isOptedIn: boolean;
    hasResponded: boolean;
    canRetry: boolean;
};

export type StripeTokenJson = {
    stripe_token?: string;
};

export type CreditCard = {
    brand?: string;
    last4?: string;
};

export type AuthIdentityJson = {
    id: string;
    displayName: string;
    canChangePassword: boolean;
    canRemove: boolean;
};

export type AuthIdentity = {
    id: string;
    displayName: string;
    canChangePassword: boolean;
    canRemove: boolean;
};

export type AuthIdentitiesSummaryJson = {
    canChangeEmail: boolean;
    changeEmailHint?: string;
    canAddUsernamePassword: string;
    identities: AuthIdentityJson[];
};

export type AuthIdentitiesSummary = {
    canChangeEmail: boolean;
    changeEmailHint?: string;
    canAddUsernamePassword: string;
    identities: AuthIdentityJson[];
};

export type AuthorizedClientJson = {
    client_id: string;
    client_name: string;
};

export type AuthorizedClient = {
    id: string;
    name: string;
};

export type DiagnosticBucketCredentials = {
    access_key_id: string;
    secret_access_key: string;
    expiration: string;
    session_token: string;
};

export type UserAndProducts = {
    user: User;
    activeProduct: Product;
    products: Product[];
};
