// Copyright 2024 Descript, Inc

import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { checkRecoveryFiles } from '@descript/recorder-shared';
import { trackError } from '@lib/trackError';
import { ErrorCategory } from '@descript/errors';
import * as UserClient from '@descript/client/src/Api/UserClient';
import * as ApiClient from '@descript/client/src/Api/ApiClient';
import { errorCategoryContext } from '@descript/analytics';
import { RecoveryState, RecoveryStatus } from '@descript/recorder-base';
import { DescriptLoading } from '@components/App/DescriptLoading';
import { Stack, StackItem } from '@daily/shared/src/components/Stack';
import { Text } from '@daily/shared/src/components/Text';
import { UploadingModalContent } from '@features/recording/components/UploadingModalContent';
import { useRecordingUploadProgress } from '@features/recording/hooks/useUploadProgress';
import { useUploadingState } from '@features/recording/state/uploadProgress';
import { useAnalytics } from '@contexts/AnalyticsProvider';
import { usePreviousValue } from '@hooks/usePreviousValue';
import { useTracing } from '@contexts/TracingProvider';

const defaultCtx = errorCategoryContext(ErrorCategory.Recording);

export function Recovery() {
    const [pageReady, setPageReady] = useState(false);
    const { push } = useRouter();
    const [userId, setUserId] = useState(undefined);
    const [recoveryStatus, setRecoveryStatus] = useState<RecoveryStatus | undefined>();

    const { incrementLastSegmentUploaded, incrementTotalSegmentCount, onArtifactCommitted } =
        useRecordingUploadProgress();

    const [uploadState, setUploadState] = useUploadingState();
    const { trackEvent } = useAnalytics();
    const { setTracerDelegateToken } = useTracing();

    useEffect(() => {
        const url = new URL(window.location.href);
        const split = url.pathname.split('/');
        const room = split[split.length - 2] === 'room' ? split[split.length - 1] : '';
        if (room) {
            const params = url.searchParams.toString();
            const newUrl = `/${room}${params ? `?${params}` : ''}`;
            push(newUrl).catch((err) => {
                console.error(err);
            });
        } else {
            setPageReady(true);
        }
    }, [push]);

    useEffect(() => {
        if (pageReady) {
            if (ApiClient.isLoggedIn()) {
                UserClient.fetchUser(defaultCtx())
                    .then((user) => {
                        setUserId(user.id);
                    })
                    .catch((err) => trackError(err, 'fetch-logged-in-user'));
            } else {
                setUserId('0');
            }
            trackEvent('rooms-recovery-page-loaded');
        }
    }, [pageReady, trackEvent]);

    useEffect(() => {
        if (!userId) {
            return;
        }
        checkRecoveryFiles({
            recoveryReason: 'app_load',
            userId,
            onStatusChanged: setRecoveryStatus,
            incrementTotalSegmentCount,
            incrementLastSegmentUploaded,
            onArtifactCommitted,
        }).catch((err) => {
            trackError(err, 'recording-recovery-check-files', {
                category: ErrorCategory.Recording,
            });
        });
    }, [userId, incrementLastSegmentUploaded, incrementTotalSegmentCount, onArtifactCommitted]);

    useEffect(() => {
        if (
            recoveryStatus?.state !== RecoveryState.RECOVERING ||
            !recoveryStatus?.currentDelegateToken
        ) {
            return;
        }

        ApiClient.request({
            ctx: defaultCtx(),
            method: ApiClient.RequestType.POST,
            path: `/recordings/collaborative/guest/${recoveryStatus.currentProject}/overall-progress`,
            data: {
                participantId: recoveryStatus.currentParticipantId,
                participantName: recoveryStatus.currentParticipantName,
                estRemaining: uploadState.estRemaining,
                progress: uploadState.status === 'complete' ? 100 : uploadState.percent,
                forceMessage: uploadState.status === 'complete',
            },
            delegateAuth: recoveryStatus.currentDelegateToken,
        }).catch((e) => {
            trackError(
                e,
                `Failed to send upload progress for participant: ${recoveryStatus.currentParticipantName}: ${recoveryStatus.currentParticipantId}`,
                {
                    category: ErrorCategory.Recording,
                },
            );
        });
    }, [uploadState, recoveryStatus]);

    useEffect(() => {
        if (!recoveryStatus) {
            return;
        }

        setUploadState((currentUploadState) => ({
            ...currentUploadState,
            ...(recoveryStatus.currentParticipantId
                ? { id: recoveryStatus.currentParticipantId }
                : {}),
            ...(recoveryStatus.currentParticipantName
                ? { name: recoveryStatus.currentParticipantName }
                : {}),
        }));
    }, [setUploadState, recoveryStatus]);

    const prevRecoveryState = usePreviousValue(recoveryStatus?.state);
    useEffect(() => {
        // Set delegate token in analytics provider to track guest spans during recovery
        if (recoveryStatus?.state === RecoveryState.RECOVERING) {
            setTracerDelegateToken(recoveryStatus.currentDelegateToken);
        }
        // Track recovery page completion
        if (
            recoveryStatus?.state === RecoveryState.COMPLETED &&
            prevRecoveryState !== RecoveryState.COMPLETED
        ) {
            trackEvent('rooms-recovery-page-completed', {
                total: recoveryStatus.total,
                completed: recoveryStatus.completed,
            });
        }
    }, [recoveryStatus, setTracerDelegateToken, prevRecoveryState, trackEvent]);

    const statusForDisplay = useCallback(() => {
        switch (recoveryStatus.state) {
            case RecoveryState.CHECKING:
                return 'Checking for recovery files';
            case RecoveryState.NO_RECOVERIES:
                return 'No recovery files found';
            case RecoveryState.RECOVERING:
                return `Found ${recoveryStatus.total} recovery session${
                    recoveryStatus.total > 1 ? 's' : ''
                }, attempting recovery`;
            case RecoveryState.COMPLETED:
                return `Recovered ${recoveryStatus.completed} of ${recoveryStatus.total} successfully`;
            default:
                return 'Unknown recovery status';
        }
    }, [recoveryStatus]);

    /**
     * Prevent users from accidentally navigating away from recovery when upload hasn't finished
     */
    const handleOnUnload = useCallback((event) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        if (!pageReady) {
            return;
        }

        if (
            [RecoveryState.NO_RECOVERIES, RecoveryState.COMPLETED].includes(
                recoveryStatus?.state,
            )
        ) {
            return;
        }

        window.addEventListener('beforeunload', handleOnUnload);

        return () => {
            window.removeEventListener('beforeunload', handleOnUnload);
        };
    }, [handleOnUnload, pageReady, recoveryStatus?.state]);

    return (
        <div className="container">
            <div className="message-container">
                <Stack>
                    {pageReady && recoveryStatus && (
                        <Text variant="largestrong"> {statusForDisplay()}</Text>
                    )}

                    {pageReady &&
                    [RecoveryState.RECOVERING, RecoveryState.COMPLETED].includes(
                        recoveryStatus?.state,
                    ) ? (
                        <StackItem style={{ marginTop: 0, width: '400px' }}>
                            <UploadingModalContent />
                        </StackItem>
                    ) : (
                        recoveryStatus?.state !== RecoveryState.NO_RECOVERIES && (
                            <DescriptLoading />
                        )
                    )}
                </Stack>
            </div>

            <style jsx>{`
                .container {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    height: 100%;
                    overflow: auto;
                    width: 100%;
                    padding: 8px;
                    height: 100%;
                }
                .container :global(.card-content) {
                    padding: 32px;
                }
                .container :global(.message-container) {
                    padding: 16px;
                    border-radius: 12px;
                    background: var(--body-bg-accent);
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    flex-grow: 1;
                    height: 100%;
                }
            `}</style>
        </div>
    );
}
