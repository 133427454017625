// Copyright 2024 Descript, Inc

export function base64Encode(bytes: Uint8Array): string {
    let binaryString = '';
    const len = bytes.length;
    for (let i = 0; i < len; i++) {
        binaryString += String.fromCharCode(bytes[i]!);
    }

    return btoa(binaryString);
}

export function base64Decode(base64String: string): Uint8Array {
    const binaryString = atob(base64String);
    const bytes = new Uint8Array(binaryString.length);

    for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
}
