// Copyright 2024 Descript, Inc
import {
    ArtifactRecoveryInfo,
    RecorderContext,
    RecorderMetadata,
    RecordingAnalyticsEvents,
    RecordingTarget,
    RecoveryResults,
    RecoveryState,
    RunRecoveryProps,
    SaveRecoverySegmentInput,
    WebRecorderSegmentMetadata,
    WebRecordingSession,
    WebRecoveryMetadata,
} from '@descript/recorder-base';
import { invariant, ErrorCategory, DescriptError, Errors } from '@descript/errors';
import {
    createWebRecordingSession,
    getWebRecordingSession,
    updateWebRecordingSession,
    updateWebRecordingSessionRecorderMetadata,
} from '../Engine/webRecorderSessions';
import {
    checkRecorderRecovery,
    debugDump,
    downloadSessionRecoveries,
    getSegmentData,
    recoveryArtifactComplete,
    saveRecoverySegment,
} from '@descript/web-recorder-recovery';
import { trackError } from '@descript/client/src/Utilities/ErrorTracker';
import {
    AsyncContext,
    errorCategoryContext,
    recordSpanError,
    SpanTag,
    withSpanAsync,
} from '@descript/analytics';
import {
    CurrentRecorderContext,
    droppedSpanTracker,
    endWebRecordingTrace,
    getSessionCtx,
    startWebRecordingTrace,
    trackDroppedSpan,
} from '../Span/webRecorderSpanHandlers';
import { WebRecorder } from '@descript/web-recorder';
import {
    commitFinalArtifact,
    createAssetAndArtifactIfNeeded,
    createWebRecorderSegmentFromBlob,
    reifyPlaceholderIfNeeded,
    signalTrackIfNeeded,
    webRecorderMainQueue,
    webRecorderUploadQueue,
} from '../Engine/webRecorderEngine';
import {
    queryCollaborativeRecordingWorkflow,
    queryRecordingWorkflow,
    RecordingWorkflowStatus,
    signalWorkflowCollabRecordingStopped,
    signalWorkflowWebRecordingStopped,
    startCollaborativeRecordingWorkflow,
} from '@descript/client';
import { SpanNames } from '@descript/client/src/Utilities/Tracing';
import { startWebWorkflow } from '../Workflow/workflow';
import { createScopedLogger } from '@descript/descript-core';
import { trackRecordingAnalyticsEvent } from '../Analytics/analytics';
import { projectIsDeleted } from '@descript/client/src/Api/ProjectClient';

const defaultCtx = errorCategoryContext(ErrorCategory.Recording);
const log = createScopedLogger({
    name: 'RECORDER/recovery',
    color: 'orange',
    // Temporarily enabled logging for all rooms environments
    alwaysEnabled: CurrentRecorderContext === RecorderContext.ROOMS,
});

async function recoverSession(
    ctx: AsyncContext,
    recoveryInfo: ArtifactRecoveryInfo[],
    incrementTotalSegmentCount?: (recorderId: string, count?: number) => void, // used for rooms upload status on recovery page
    incrementLastSegmentUploaded?: (
        metadata: WebRecorderSegmentMetadata,
        delegateToken?: string,
    ) => void, // used for rooms upload status on recovery page
    onArtifactCommitted?: (recorderId: string) => void, // used for rooms upload status on recovery page
): Promise<RecoveryResults | undefined> {
    let workflowId: string | undefined,
        session: WebRecordingSession | undefined,
        isRecording = false,
        info: ArtifactRecoveryInfo['info'] | undefined;
    try {
        if (!recoveryInfo[0]) {
            return;
        }
        const recorders = WebRecorder.getAllRecorders();
        const activeRecorders = recorders.reduce((acc, recorder) => {
            if (recorder.isRecording) {
                acc.add(recorder.id);
            }
            return acc;
        }, new Set());

        isRecording = recoveryInfo.some((recovery) => {
            return activeRecorders.has(recovery.info.recorderId);
        });
        log.debug(`Is the recorder still recording? ${isRecording}`);
        if (activeRecorders.size && !isRecording) {
            return;
        }

        recoveryInfo.sort((a, b) => {
            return b.info.savedAt - a.info.savedAt;
        });

        const { recordersObj, ...sessionData } = recoveryInfo[0].session;
        let recoveredWebSession = {
            ...sessionData,
            recorders: new Map(Object.entries(recordersObj)),
        } as WebRecordingSession;
        if (recoveredWebSession.backendWorkflowStartTime) {
            recoveredWebSession.backendWorkflowStartTime = new Date(
                recoveredWebSession.backendWorkflowStartTime,
            );
        }
        if (recoveredWebSession.localStartTime) {
            recoveredWebSession.localStartTime = new Date(recoveredWebSession.localStartTime);
        }
        session = recoveredWebSession;

        log.debug('Got session', { session: JSON.stringify(session) });

        startWebRecordingTrace(
            {
                sessionId: session?.sessionId,
                projectId: session?.projectId,
            },
            ctx,
        );

        info = recoveryInfo[0].info;
        const delegateToken = info.delegateToken;
        log.debug('Loaded recovery info', {
            delegateToken,
            info: JSON.stringify(info),
        });

        // create or update the session in the session map so the engine methods can access it
        const sessionInSessionMap = getWebRecordingSession(session.sessionId);
        if (!sessionInSessionMap) {
            createWebRecordingSession(recoveredWebSession.sessionId, recoveredWebSession);
        } else {
            updateWebRecordingSession(recoveredWebSession.sessionId, recoveredWebSession);
        }

        if (!WebRecorder.onRecorderEvent) {
            WebRecorder.onRecorderEvent = () => undefined;
        }

        for (const recovery of recoveryInfo) {
            await webRecorderMainQueue.add(async () => {
                log.debug('Creating asset and artifact');
                await createAssetAndArtifactIfNeeded(
                    ctx,
                    getWebRecordingSession(recoveredWebSession.sessionId)!,
                    recovery.info.recorderId,
                    delegateToken,
                );
            });
        }

        const { projectId } = recoveredWebSession;
        workflowId = recoveredWebSession.backendWorkflowId;
        log.debug(`Querying workflow ${workflowId}`);
        let workflowStatus: RecordingWorkflowStatus | undefined;
        if (workflowId) {
            if (info.recorderContext === RecorderContext.ROOMS) {
                const response = await queryCollaborativeRecordingWorkflow(
                    defaultCtx(),
                    workflowId,
                    delegateToken,
                ).catch((e) => {
                    console.warn('Error querying workflow', e);
                    return { status: RecordingWorkflowStatus.Unknown };
                });
                workflowStatus = response.status;
                log.debug(`Got workflow status ${workflowStatus}`);
            } else {
                const response = await queryRecordingWorkflow(defaultCtx(), workflowId).catch(
                    (e) => {
                        console.warn('Error querying workflow', e);
                        return { status: RecordingWorkflowStatus.Unknown };
                    },
                );
                workflowStatus = response.status;
                log.debug(`Got workflow status ${workflowStatus}`);
            }
        }

        if (workflowStatus !== RecordingWorkflowStatus.Running) {
            if (info.recorderContext === RecorderContext.ROOMS) {
                // if the project is deleted just download what we have and bail
                const isDeleted = await projectIsDeleted(ctx, projectId, delegateToken);
                if (isDeleted) {
                    endWebRecordingTrace(session.sessionId);
                    log.debug('Downloading recoveries due to an error');
                    await downloadRecoveriesWithSpan(
                        ctx,
                        session.sessionId,
                        info?.recorderContext,
                    );
                    return { workflowId, session };
                }

                for (const recovery of recoveryInfo) {
                    await webRecorderMainQueue.add(async () => {
                        log.debug('Creating asset and artifact');
                        await createAssetAndArtifactIfNeeded(
                            ctx,
                            recoveredWebSession,
                            recovery.info.recorderId,
                            delegateToken,
                            true,
                        );
                    });
                    updateWebRecordingSessionRecorderMetadata(
                        recoveredWebSession.sessionId,
                        recovery.info.recorderId,
                        {
                            track_created: false,
                        },
                    );
                }
            } else {
                log.debug('Starting web workflow');
                workflowId = await startWebWorkflow(
                    ctx,
                    projectId,
                    recoveredWebSession.sessionId,
                    false,
                    0,
                    true,
                );
                updateWebRecordingSession(recoveredWebSession.sessionId, {
                    backendWorkflowId: workflowId,
                });
            }
        }

        recoveredWebSession = getWebRecordingSession(recoveredWebSession.sessionId)!;
        for (const recovery of recoveryInfo) {
            const recorderMetadata = recoveredWebSession.recorders.get(
                recovery.info.recorderId,
            );
            if (recorderMetadata) {
                recorderMetadata.segmentCount =
                    workflowStatus === RecordingWorkflowStatus.Running
                        ? recovery.info.segmentCount
                        : recovery.segments.length;
            }

            if (incrementTotalSegmentCount && recovery.segments.length) {
                incrementTotalSegmentCount(recovery.info.recorderId, recovery.segments.length);
            }

            log.debug(`Processing segments: ${recovery.segments.length}`);
            let segmentCount = 0;
            for (const segment of recovery.segments) {
                log.debug(`Adding segment to queue: ${segment.chunkNumber}`);
                await webRecorderUploadQueue.add(async () => {
                    await webRecorderMainQueue.onIdle();
                    const fileExtension = recorderMetadata?.fileExtension ?? 'mp4';
                    const buffer = await getSegmentData(
                        recoveredWebSession.recorders.get(recovery.info.recorderId)
                            ?.artifactGuid ?? '',
                        `${segment.chunkNumber}.${fileExtension}`,
                    );
                    log.debug('Creating segment from blob');
                    if (workflowStatus !== RecordingWorkflowStatus.Running) {
                        segment.chunkNumber = segmentCount;
                    }
                    segmentCount++;
                    await createWebRecorderSegmentFromBlob(
                        ctx,
                        recoveredWebSession,
                        recovery.info.recorderId,
                        new Blob([buffer]),
                        segment,
                    );
                    log.debug(`Done with segment: ${segment.chunkNumber}`);

                    if (incrementLastSegmentUploaded) {
                        incrementLastSegmentUploaded(segment, delegateToken);
                    }
                });
            }

            let newWorkflowId: string | undefined;
            if (!isRecording) {
                await webRecorderMainQueue.add(() =>
                    reifyPlaceholderIfNeeded(recoveredWebSession, recovery.info.recorderId),
                );
                log.debug('Reifying placeholder');
                await Promise.all([
                    webRecorderMainQueue.onIdle(),
                    webRecorderUploadQueue.onIdle(),
                ]);

                // Create workflow if not already running
                if (workflowStatus !== RecordingWorkflowStatus.Running) {
                    if (!newWorkflowId) {
                        log.debug('Starting collaborative recording workflow');
                        const startWorkflow = await startCollaborativeRecordingWorkflow(
                            ctx,
                            {
                                projectId,
                                tracks: [],
                                runTranscription: false,
                                runStudioSound: false,
                                recovery: true,
                                recordingStartTime: recoveredWebSession.originalStartTime,
                                recordingFolderName: recoveredWebSession.recordingFolderName,
                            },
                            delegateToken,
                        );
                        workflowId = startWorkflow.sessionId;
                        if (!workflowId) {
                            throw new DescriptError(
                                'Could not start recovery workflow',
                                ErrorCategory.Recording,
                            );
                        }
                        log.debug(`Started workflow: ${workflowId}`, {
                            response: JSON.stringify(startWorkflow),
                        });
                        updateWebRecordingSession(recoveredWebSession.sessionId, {
                            backendWorkflowId: workflowId,
                        });
                    }

                    log.debug('Signalling track');
                    await signalTrackIfNeeded(
                        ctx,
                        recoveredWebSession.sessionId,
                        recovery.info.recorderId,
                        recovery.info.trackSkew,
                        delegateToken,
                    ).catch((error) => {
                        trackError(error, 'recovery-signal-track-failed', {
                            category: ErrorCategory.Recording,
                        });
                    });
                    log.debug('Signalled track');
                }

                if (workflowId) {
                    log.debug('Signalling stopped');
                    await signalStopped(workflowId, info, delegateToken);
                }
                log.debug('Done with all async steps, committing final artifact');
                await commitFinalArtifact(recoveredWebSession, recovery.info.recorderId, true);
                log.debug('Committed final artifact');

                trackRecordingAnalyticsEvent(
                    recoveredWebSession,
                    RecordingAnalyticsEvents.recorder_completed,
                    { recovery: true },
                    recovery.info.recorderId,
                );

                if (onArtifactCommitted) {
                    onArtifactCommitted(recovery.info.recorderId);
                }
            }
        }
        endWebRecordingTrace(recoveredWebSession.sessionId);
    } catch (e) {
        const error = e as Error;
        trackError(error, 'recording-recovery-session', {
            category: ErrorCategory.Recording,
            extra: {
                context: info?.recorderContext,
                workflowId,
            },
            attachment: {
                filename: 'recovery-debug.txt',
                data: await debugDump(),
                contentType: 'text/plain',
            },
        });
        // Avoid downloading recoveries if we lost connection, downloading recovery will succeed
        // but will make further recovery attempts fail. It could also leave the artifact un-reified
        // and stall the workflow. If a user lost connection, they'll want to try to recover again
        // when they reconnect.
        if (session && !isRecording && !Errors.isNetworkError(error)) {
            endWebRecordingTrace(session.sessionId, error);
            log.debug(`Downloading recoveries due to an error`);
            await downloadRecoveriesWithSpan(
                ctx,
                session.sessionId,
                info?.recorderContext,
            ).catch(async () => {
                if (info?.artifactId) {
                    await recoveryArtifactComplete(info.artifactId).catch(
                        async (completeError) => {
                            if (completeError.name === 'NotFoundError') {
                                return;
                            }
                            trackError(completeError, 'recovery-artifact-complete-failed', {
                                category: ErrorCategory.Recording,
                                attachment: {
                                    filename: 'recovery-debug.txt',
                                    data: await debugDump(),
                                    contentType: 'text/plain',
                                },
                            });
                        },
                    );
                }
                throw error;
            });
        }
        throw error;
    }
    return { workflowId, session };
}

async function signalStopped(
    workflowId: string,
    info: WebRecoveryMetadata['info'],
    delegateToken?: string,
) {
    try {
        switch (info.recorderContext) {
            case RecorderContext.ROOMS:
                if (!delegateToken) {
                    await signalWorkflowCollabRecordingStopped(defaultCtx(), workflowId, {
                        documentCommitIndex: 0,
                    });
                }
                break;
            case RecorderContext.WEB:
                await signalWorkflowWebRecordingStopped(defaultCtx(), workflowId, {
                    documentCommitIndex: 0,
                    ...(info.recoveryTarget ? { recordingTarget: info.recoveryTarget } : {}),
                });
                break;
            default:
                break;
        }
    } catch (e) {
        // The stop is optional, so we'll track the error but not rethrow it
        trackError(e as Error, 'stop-recording-failed', {
            category: ErrorCategory.Recording,
        });
    }
}

export async function autoRecoveryWithSpan(
    ctx: AsyncContext,
    projectId: string,
    recoveryInfo: ArtifactRecoveryInfo[],
    recoveryType: string,
    incrementTotalSegmentCount?: (recorderId: string) => void, // used for rooms upload status on recovery page
    incrementLastSegmentUploaded?: (
        metadata: WebRecorderSegmentMetadata,
        delegateToken?: string,
    ) => void, // used for rooms upload status on recovery page
    onArtifactCommitted?: (recorderId: string) => void, // used for rooms upload status on recovery page
): Promise<RecoveryResults | undefined> {
    return await withSpanAsync(SpanNames.WebRecorder_AutoRecovery, { ctx }, async (newCtx) => {
        try {
            newCtx.span.setAttributes({
                [SpanTag.metricName]: SpanNames.WebRecorder_AutoRecovery,
                [SpanTag.appProjectId]: projectId,
                [SpanTag.appRecorderContext]:
                    recoveryInfo[0]!.info.recorderContext ?? CurrentRecorderContext,
                [SpanTag.appRecorderRecoveryType]: recoveryType,
                [SpanTag.appRecorderRecoverySuccess]: 1,
            });
            return await recoverSession(
                newCtx,
                recoveryInfo,
                incrementTotalSegmentCount,
                incrementLastSegmentUploaded,
                onArtifactCommitted,
            );
        } catch (e) {
            const error = e as Error;
            trackError(error, 'recording-recovery-automatic', {
                category: ErrorCategory.Recording,
                extra: {
                    rooms: true,
                    projectId,
                },
                attachment: {
                    filename: 'recovery-debug.txt',
                    data: await debugDump(),
                    contentType: 'text/plain',
                },
            });
            newCtx.span.setAttributes({
                [SpanTag.appRecorderRecoverySuccess]: 0,
                [SpanTag.appRecorderRecoveryMessage]: error.message,
            });
            recordSpanError(newCtx.span, error);
            return;
        }
    });
}

export async function downloadRecoveriesWithSpan(
    ctx: AsyncContext,
    sessionId: string,
    recorderContext: RecorderContext = CurrentRecorderContext,
) {
    await withSpanAsync(SpanNames.WebRecorder_RecoveryDownload, { ctx }, async (newCtx) => {
        try {
            newCtx.span.setAttributes({
                [SpanTag.metricName]: SpanNames.WebRecorder_RecoveryDownload,
                [SpanTag.appRecorderContext]: recorderContext,
                [SpanTag.appRecorderRecoveryType]: 'download',
                [SpanTag.appRecorderSessionId]: sessionId,
                [SpanTag.appRecorderRecoverySuccess]: 1,
            });
            await downloadSessionRecoveries(sessionId);
        } catch (e) {
            const error = e as Error;
            trackError(error, 'recording-recovery-download', {
                category: ErrorCategory.Recording,
                extra: {
                    rooms: true,
                    sessionId,
                },
                attachment: {
                    filename: 'recovery-debug.txt',
                    data: await debugDump(),
                    contentType: 'text/plain',
                },
            });
            newCtx.span.setAttributes({
                [SpanTag.appRecorderRecoverySuccess]: 0,
                [SpanTag.appRecorderRecoveryMessage]: error.message,
            });
            recordSpanError(newCtx.span, error);
        }
    });
}

export async function checkRecoveryFiles({
    recoveryReason,
    userId,
    onStatusChanged, // will get called with the recovery status every time it changes
    incrementTotalSegmentCount, // used for rooms upload status on recovery page
    incrementLastSegmentUploaded, // used for rooms upload status on recovery page
    onArtifactCommitted, // used for rooms upload status on recovery page
    singleSessionId, // If provided, skip the segmentCount check and recover this one session
}: RunRecoveryProps) {
    const status = onStatusChanged || (() => undefined);
    log.debug('Checking recovery files...');
    status({ state: RecoveryState.CHECKING });
    const recoveries = await checkRecorderRecovery();
    if (!recoveries.length) {
        log.debug('No recoveries found');
        status({ state: RecoveryState.NO_RECOVERIES });
        return [];
    }

    const recoveriesBySession: Map<string, ArtifactRecoveryInfo[]> = new Map();
    for (const recovery of recoveries) {
        if (recovery.info.segmentCount === 1) {
            log.debug('Purging recovery with no data');
            await recoveryArtifactComplete(recovery.info.artifactId);
            continue;
        }
        if (recovery.info.userId && recovery.info.userId !== userId) {
            log.debug(`Skipping recovery for userId ${recovery.info.userId}`);
            continue;
        }
        const sessionId = recovery.session.sessionId;
        if (!recoveriesBySession.has(sessionId)) {
            recoveriesBySession.set(sessionId, []);
        }
        recoveriesBySession.get(sessionId)?.push(recovery);
    }
    log.debug(`Found recoveries: ${recoveriesBySession.size}`);

    if (WebRecorder.isAnyRecorderActive()) {
        log.debug('Recoveries are not ready because the recorder is still recording');
        status({ state: RecoveryState.NO_RECOVERIES });
        return [];
    }

    const readyRecoveries: Map<string, ArtifactRecoveryInfo[]> = new Map();
    if (singleSessionId && recoveriesBySession.has(singleSessionId)) {
        const recoveryArray = recoveriesBySession.get(singleSessionId);
        if (recoveryArray) {
            readyRecoveries.set(singleSessionId, recoveryArray);
        } else {
            status({ state: RecoveryState.NO_RECOVERIES });
            return [];
        }
    } else {
        log.debug('Waiting to see if the files are still being recorded...');
        // Wait 10s and check recovery files again.  If the segmentCount is changing, it's not ready for recovery yet
        await new Promise((resolve) => setTimeout(resolve, 10000));

        const updatedRecoveries = await checkRecorderRecovery();
        for (const recovery of updatedRecoveries) {
            const sessionId = recovery.session.sessionId;
            const initialRecovery = recoveriesBySession.get(sessionId);
            let isChanging = false;
            if (initialRecovery) {
                const recoveryInfo = initialRecovery.find(
                    (r) => r.info.recorderId === recovery.info.recorderId,
                );
                if (recoveryInfo?.info.segmentCount !== recovery.info.segmentCount) {
                    isChanging = true;
                }
            }
            if (initialRecovery && !isChanging) {
                if (!readyRecoveries.has(sessionId)) {
                    readyRecoveries.set(sessionId, []);
                }
                readyRecoveries.get(sessionId)?.push(recovery);
            }
        }
        log.debug(`Recoveries ready: ${readyRecoveries.size}`);

        if (!readyRecoveries.size) {
            status({ state: RecoveryState.NO_RECOVERIES });
            return [];
        }
    }

    status({ state: RecoveryState.RECOVERING, total: readyRecoveries.size, completed: 0 });

    const finishedRecoveries: Array<RecoveryResults> = [];
    for (const [, recoveryInfo] of readyRecoveries) {
        log.debug('Starting recovery', { recovery: JSON.stringify(recoveryInfo[0]) });
        status({
            state: RecoveryState.RECOVERING,
            total: recoveriesBySession.size,
            completed: finishedRecoveries.length,
            currentProject: recoveryInfo[0]?.session?.projectId,
            currentDelegateToken: recoveryInfo[0]?.info?.delegateToken,
            currentParticipantId: recoveryInfo[0]?.info?.participantId,
            currentParticipantName: recoveryInfo[0]?.info?.participantName || 'Guest',
        });

        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        const sessionCtx = getSessionCtx(recoveryInfo[0]?.session?.sessionId!);
        const result = await autoRecoveryWithSpan(
            sessionCtx ?? defaultCtx(),
            // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
            recoveryInfo[0]?.session?.projectId!,
            recoveryInfo,
            recoveryReason,
            incrementTotalSegmentCount,
            incrementLastSegmentUploaded,
            onArtifactCommitted,
        );
        log.debug('Finished recovery', { result: JSON.stringify(result) });
        if (result) {
            finishedRecoveries.push(result);
        }
        status({
            state: RecoveryState.RECOVERING,
            total: recoveriesBySession.size,
            completed: finishedRecoveries.length,
            currentProject: recoveryInfo[0]?.session?.projectId,
            currentDelegateToken: recoveryInfo[0]?.info?.delegateToken,
            currentParticipantId: recoveryInfo[0]?.info?.participantId,
            currentParticipantName: recoveryInfo[0]?.info?.participantName || 'Guest',
        });
    }
    log.debug('Finished all recoveries', { recoveries: JSON.stringify(finishedRecoveries) });

    // Use a 0 timeout to ensure the status update on line 567 is not overwritten by this one
    await new Promise((resolve) =>
        setTimeout(
            () =>
                resolve(
                    status({
                        state: RecoveryState.COMPLETED,
                        total: recoveriesBySession.size,
                        completed: finishedRecoveries.length,
                    }),
                ),
            0,
        ),
    );

    return finishedRecoveries;
}

export const exportedForTesting = {
    runRecovery: autoRecoveryWithSpan,
};

export async function handleOnDataAvailableRecovery(
    blob: Blob,
    metadata: WebRecorderSegmentMetadata,
    trackSkew?: number,
    userId: string = '0',
    delegateToken?: string,
    participantId?: string,
    participantName?: string,
) {
    let webRecordingSession: WebRecordingSession | undefined;
    try {
        log.debug(`Got new segment: ${metadata.chunkNumber}`, {
            metadata: JSON.stringify(metadata),
        });
        // Sanity check, don't save recovery segments after 4500 (~5 hours)
        if (metadata.chunkNumber > 4500) {
            trackError(
                new DescriptError(
                    'Possible runaway recording, rejecting recovery segment',
                    ErrorCategory.Recording,
                ),
                'recording-recovery-too-many-segments',
                {
                    category: ErrorCategory.Recording,
                    extra: { projectId: webRecordingSession?.projectId, userId },
                },
            );
            return;
        }
        if (WebRecorder.getAllRecorders().find((recorder) => recorder.terminated)) {
            return;
        }
        const recordingSessionId = metadata.recordingSessionId;
        invariant(
            recordingSessionId,
            'Handle On Data Available: No recording session id found',
            ErrorCategory.Recording,
        );
        webRecordingSession = getWebRecordingSession(recordingSessionId);
        invariant(
            webRecordingSession?.projectId,
            'Handle On Data Available: No project id found',
            ErrorCategory.Recording,
        );

        const recoveryTarget = webRecordingSession.target as RecordingTarget;

        const { recorders, ...recoverySession } = webRecordingSession;
        const recordersObj = Array.from(recorders.entries()).reduce(
            (acc, [recorderId, recorder]) => {
                acc[recorderId] = recorder;
                return acc;
            },
            {} as Record<string, RecorderMetadata>,
        );

        const artifactId = recordersObj[metadata.recorderId]?.artifactGuid ?? '';
        const session = { ...recoverySession, recordersObj };

        const info = {
            artifactId,
            savedAt: Date.now(),
            recorderId: metadata.recorderId,
            userId,
            recorderContext: CurrentRecorderContext,
            ...(recoveryTarget ? { recoveryTarget } : {}),
            ...(delegateToken ? { delegateToken } : {}),
            ...(trackSkew ? { trackSkew } : {}),
            ...(participantId ? { participantId } : {}),
            ...(participantName ? { participantName } : {}),
        };

        const recoveryInput: SaveRecoverySegmentInput = {
            session,
            info,
            buf: await blob.arrayBuffer(),
            segmentMetadata: metadata,
        };

        log.debug('Saving recovery segment');
        await saveRecoverySegment(recoveryInput);
        log.debug('Saved recovery segment');
    } catch (e) {
        const error = e as Error;
        trackError(error, 'recording-recovery-save-segment', {
            category: ErrorCategory.Recording,
            extra: { projectId: webRecordingSession?.projectId, userId },
            attachment: {
                filename: 'recovery-debug.txt',
                data: await debugDump(),
                contentType: 'text/plain',
            },
        });
    }
}

export async function droppedSpanHandler(userId: string) {
    try {
        const inProgressSpans = await droppedSpanTracker.getInProgressSpans();
        const userSpans = inProgressSpans?.filter(
            (span) =>
                span.spanInfo.context === CurrentRecorderContext &&
                (span.spanInfo?.userId === userId ||
                    span.spanInfo?.userId === 'unknown' ||
                    !span.spanInfo?.userId),
        );
        if (userSpans?.length) {
            for (const { spanInfo, spanId } of inProgressSpans) {
                trackDroppedSpan(spanInfo.spanType, spanId);
            }
        }
    } catch (e) {
        const error = e as Error;
        trackError(error, 'dropped-span-handler-failed', {
            category: ErrorCategory.Recording,
        });
    }
}
