// Copyright 2018 Descript, Inc

import { ComparisonResult, sortAlphabetical } from '@descript/descript-core';
import { ProjectBrowserTableColumn, ProjectFilter } from './ProjectFilters';

export type SortFn<T> = (a: T, b: T) => number;

// Create a sort that will go execute the provided sorts in order until a difference is found. Used to set fallback sort(s) to break ties, ex: primary sort and secondary sort
export function layerSortFns<T>(...sortFns: SortFn<T>[]): SortFn<T> {
    return function (a: T, b: T) {
        for (const sortFn of sortFns) {
            const candidate = sortFn(a, b);
            if (candidate !== ComparisonResult.OrderedSame) {
                return candidate;
            }
        }
        return ComparisonResult.OrderedSame;
    };
}

// Create a sort function that is the inverse of the provided sort function. ex: ASC -> DESC
export function reverseSortFn<T>(sortFn: SortFn<T>): SortFn<T> {
    return function (a: T, b: T) {
        return -1 * sortFn(a, b);
    };
}

export function sortOrReverseFn<T>(sortFn: SortFn<T>, sortAscending: boolean): SortFn<T> {
    return sortAscending ? sortFn : reverseSortFn(sortFn);
}

export interface INameSortable {
    name: string;
}

export const sortByName = (a: INameSortable, b: INameSortable): number => {
    return sortAlphabetical(a.name, b.name);
};

export const sortByDrive = (a: { driveId: string }, b: { driveId: string }): number => {
    // TODO: (COR-3376) fix drive sorting to be alphabetical by name, not driveId
    return sortAlphabetical(a.driveId, b.driveId);
};

interface ICreatedAtSortable {
    createdAt: Date;
}

export const sortByOptionalDate = (a?: Date | null, b?: Date | null): number => {
    if (a && b) {
        return a.getTime() - b.getTime();
    } else if (a) {
        return ComparisonResult.OrderedDescending;
    } else if (b) {
        return ComparisonResult.OrderedAscending;
    }
    return ComparisonResult.OrderedSame;
};

export const sortByCreatedAtDateAsc = (
    a: ICreatedAtSortable,
    b: ICreatedAtSortable,
): number => {
    return a.createdAt.getTime() - b.createdAt.getTime();
};

export const sortByCreatedAtDateDesc = (
    a: ICreatedAtSortable,
    b: ICreatedAtSortable,
): number => {
    return b.createdAt.getTime() - a.createdAt.getTime();
};

interface IUpdatedAtSortable {
    updatedAt: Date;
}

export const sortByUpdatedAt = (a: IUpdatedAtSortable, b: IUpdatedAtSortable): number => {
    return a.updatedAt.getTime() - b.updatedAt.getTime();
};

interface IIDSortable {
    id: string;
}

export const sortByID = (a: IIDSortable, b: IIDSortable): number => {
    const aID = a.id;
    const bID = b.id;

    if (aID < bID) {
        return ComparisonResult.OrderedAscending;
    } else if (aID > bID) {
        return ComparisonResult.OrderedDescending;
    } else {
        return ComparisonResult.OrderedSame;
    }
};

/**
 * Following the basic principles of autocomplete listed here:
 * https://jeremymikkola.com/posts/2019_03_19_rules_for_autocomplete.html
 */
export function filterAndPartitionForAutocomplete<
    T extends INameSortable & {
        category?: string;

        /**
         * text that is displayed before the name that we should
         * match as higher-relevance than the `category` but lower-relevance
         * than the `name`
         */
        prefix?: string[];
    },
>(
    items: readonly T[],
    term: string,
    sortComparator: ((a: T, b: T) => number) | false = sortByName,
): {
    exact: readonly T[];
    namePrefixes: readonly T[];
    nameSubstrings: readonly T[];
    prefixes: readonly T[];
    categorized: readonly T[];
} {
    const t = term.toLocaleLowerCase().trim();

    const exact: T[] = [];
    const namePrefixes: T[] = [];
    const nameSubstrings: T[] = [];
    const prefixes: T[] = [];
    const categorized: T[] = [];

    for (const item of items) {
        const lowerName = item.name.toLocaleLowerCase().trim();
        const lowerPrefix =
            item.prefix !== undefined ? item.prefix[0]!.toLowerCase() : undefined;

        const lowerAltPrefixes =
            item.prefix !== undefined && item.prefix.length > 0
                ? item.prefix.slice(1).join(' ').toLowerCase()
                : undefined;
        // assuming that there's a space between prefix and name
        const combined = lowerPrefix !== undefined ? `${lowerPrefix} ${lowerName}` : lowerName;

        const hasEntirePrefix = lowerPrefix !== undefined ? t.startsWith(lowerPrefix) : false;

        if (lowerName === t || combined === t) {
            exact.push(item);
        } else if (
            lowerName.startsWith(t) ||
            (hasEntirePrefix && combined.startsWith(t)) ||
            (hasEntirePrefix && lowerPrefix && lowerName.includes(t.slice(lowerPrefix.length)))
        ) {
            // matches the start of any word in name
            namePrefixes.push(item);
        } else if (lowerName.indexOf(t) >= 0) {
            nameSubstrings.push(item);
        } else if (
            (lowerPrefix && lowerPrefix.trim().includes(t)) ||
            (lowerAltPrefixes && lowerAltPrefixes.trim().includes(t))
        ) {
            prefixes.push(item);
        } else if (item.category && item.category.toLowerCase().trim().startsWith(t)) {
            categorized.push(item);
        }
    }
    if (sortComparator) {
        exact.sort(sortComparator);
        namePrefixes.sort(sortComparator);
        nameSubstrings.sort(sortComparator);
        prefixes.sort(sortComparator);
        categorized.sort(sortComparator);
    }
    return {
        exact,
        namePrefixes,
        nameSubstrings,
        prefixes,
        categorized,
    };
}

export function isInvalidSortState(
    currentSort: ProjectBrowserTableColumn,
    currentFilter: ProjectFilter,
): boolean {
    return (
        currentSort === ProjectBrowserTableColumn.Drive &&
        currentFilter !== ProjectFilter.SharedWithMe
    );
}
