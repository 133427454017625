// Copyright 2020 Descript, Inc

import { MessageResponseOf } from './WorkerClient';
import { ErrorCategory } from '@descript/errors';

export type MessageNewRequest<T> = {
    type: 'new';
    id: string;
    req: T;
    hasCallback: boolean;
    hasAbortController: boolean;
};
export type MessageAbortRequest = {
    type: 'abort';
    id: string;
};

export type MessageRequest<T> = MessageNewRequest<T> | MessageAbortRequest;

export type MessageResolved<T> = {
    id: string;
    value: T;
};
export type MessageCallback<T> = {
    id: string;
    callback: T;
};
export type MessageRejected = {
    id: string;
    err: Error;
};
export type MessageResponse<T, K> = MessageResolved<T> | MessageRejected | MessageCallback<K>;

// eslint-disable-next-line @typescript-eslint/naming-convention
export type WorkerRequest<Req, _Res> = { type: string; payload: Req; sync: false };
// eslint-disable-next-line @typescript-eslint/naming-convention
export type SyncWorkerRequest<Req, _Res> = { type: string; payload: Req; sync: true };
export type WorkerRequestOptions<CallbackRes> = {
    callback?: OnResponseCallbackFn<CallbackRes>;
    abortController?: AbortController;
};
export type WorkerRequestCreator<Req, Res, CallbackRes> = ((
    payload: Req,
    options?: WorkerRequestOptions<CallbackRes>,
) => WorkerRequest<Req, Res>) & { type: string; sync: false };

export type WorkerSyncRequestCreator<Req, Res, CallbackRes> = ((
    payload: Req,
    options?: WorkerRequestOptions<CallbackRes>,
) => SyncWorkerRequest<Req, Res>) & { type: string; sync: true };

export function workerRequestCreatorFactory<Req = void, Res = void, CallbackRes = void>(
    type: string,
): WorkerRequestCreator<Req, Res, CallbackRes> {
    const creator = (payload: Req) => ({
        type,
        payload,
        sync: false as const,
    });
    creator.type = type;
    creator.sync = false as const;
    return creator;
}

export function syncWorkerRequestCreatorFactory<Req = void, Res = void, CallbackRes = void>(
    type: string,
): WorkerSyncRequestCreator<Req, Res, CallbackRes> {
    const creator = (payload: Req) => ({
        type,
        payload,
        sync: true as const,
    });
    creator.type = type;
    creator.sync = true as const;
    return creator;
}

export type OnResponseCallbackFn<CallbackRes> = (res: CallbackRes) => void;

export type ResponseOf<WRC> =
    WRC extends WorkerRequestCreator<infer _Req, infer Res, infer _CallbackRes> ? Res : never;
export type RequestOf<WRC> =
    WRC extends WorkerRequestCreator<infer Req, infer _Res, infer _CallbackRes> ? Req : never;
export type CallbackResponseOf<WRC> =
    WRC extends WorkerRequestCreator<infer _Req, infer _Res, infer CallbackRes>
        ? CallbackRes
        : never;
export type OnResponseCallbackFnOf<WRC> =
    WRC extends WorkerRequestCreator<infer _Req, infer _Res, infer CallbackRes>
        ? OnResponseCallbackFn<CallbackRes>
        : never;
export type WorkerRequestOf<WRC> =
    WRC extends WorkerRequestCreator<infer Req, infer Res, infer _CallbackRes>
        ? WorkerRequest<Req, Res>
        : never;

export class WorkerAbortedError extends Error {
    category = ErrorCategory.VideoMediaEngine;

    constructor(message: string) {
        super(message);
        this.name = 'WorkerAbortedError';
    }
}

/**
 * Interface for a client that makes requests of a WorkerServer via a WorkerClient.
 * Should be subclassed to implement the methods required for requesting (postMessage)
 * and receiving messages (onMessage).
 */
export interface WorkerRequester<Creators> {
    onMessage(handler: (data: MessageResponseOf<Creators>) => void): void;
    postMessage(
        message: MessageRequest<WorkerRequestOf<Creators>>,
        transferable?: Transferable[],
    ): void;
}

/**
 * Interface for an object that processes messages in a WorkerServer.
 * Should be subclassed to implement the methods required for responding to (postMessage)
 * and receiving requests (onMessage).
 */
export interface WorkerResponder<Creators> {
    onMessage(handler: (data: MessageRequest<WorkerRequestOf<Creators>>) => void): void;
    postMessage(message: MessageResponseOf<Creators>, transferable?: Transferable[]): void;
}
