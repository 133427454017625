// Copyright 2019 Descript, Inc

import {
    CachedAssetsMetadata,
    ContentAssetJson,
    IEncodedDocument,
    RevisionAssetsJson,
} from '@descript/descript-model';
import { UserJson } from './Project';

/**
 * This is what gets serialized into the `revisions.contents` column in the local SQLite
 * database and the server-side PostgreSQL database.
 */
export type RevisionContentsJson = {
    /**
     * In newer revisions, redundant with {@link content.url} (so prefer that).
     * In older revisions, `content` is not present.
     * @deprecated
     */
    content_url: string;
    /** Metadata for the serialized {@link IEncodedDocument} file. Only present in newer
     * revisions. */
    content?: ContentAssetJson;
    /** Map of asset keys to their metadata */
    assets: RevisionAssetsJson;
    /**
     * @deprecated
     */
    encoded_assets?: RevisionAssetsJson;
    /**
     * @deprecated
     */
    json: Record<string, unknown>;
    /**
     * @deprecated
     */
    cdn_content_url?: string;
    /**
     * @deprecated
     */
    cdn_assets?: RevisionAssetsJson;
};

export type CompositionSummary = {
    id: string;
    name: string;
    duration?: number;
    media_type?: 'audio' | 'video' | string;
};

export function compositionSummaryIsEqual(a: CompositionSummary, b: CompositionSummary) {
    return (
        a.id === b.id &&
        a.name === b.name &&
        a.duration === b.duration &&
        a.media_type === b.media_type
    );
}

// Local only summary of a revision to minimize data transfer between processes
// For use in the Project Browser so that we're not transferring large amounts
// of data via IPC which can be very slow. This removes the majority of the
// 57 MB we used to move for Descript Drive when calling the Data Manager's
// fetchOwnerships fn which acts as a locally cached fn.
export type RevisionSummary = {
    revisionId: string;
    compositionSummaries: CompositionSummary[] | undefined;
    size: number | undefined;
    createdAt: string;
};

// This needs to be IPC-serializable
export type Revision = Readonly<{
    projectId: string;
    revisionId: string;
    baseRevisionId: string | undefined;
    revisionNumber: number;
    message: string;
    bundleVersion: string;
    createdBy: UserJson;
    createdAt: string;
    contents: RevisionContentsJson;
    isSignificant: boolean;
    isNew: boolean;
    forceSave: boolean;
    compositionSummaries: CompositionSummary[] | undefined;
}>;

export type RevisionJson = {
    id: string;
    revision_number: number;
    message: string;
    bundle_version: string;
    created_by: UserJson;
    created_at: string;
    contents: RevisionContentsJson;
    is_significant: boolean;
    composition_summaries?: CompositionSummary[];
    /**
     * @deprecated
     */
    default_scene_analysis: Record<string, unknown>;
};

export type CreateRevision = Pick<
    Revision,
    | 'revisionId'
    | 'baseRevisionId'
    | 'bundleVersion'
    | 'message'
    | 'contents'
    | 'forceSave'
    | 'compositionSummaries'
> & {
    /** revision posted after project was migrated to live collab, only needed for api request */
    isLateRevision?: boolean;
};

export type CreateRevisionJson = Pick<
    RevisionJson,
    'id' | 'message' | 'bundle_version' | 'contents' | 'composition_summaries'
> & {
    /**
     * @deprecated
     */
    default_scene_analysis: Record<string, unknown>;
    base_revision_id?: string;
    force_save?: boolean;
    /**
     * if the revision was posted after migrated to live collab
     * the server will only accept revisions from LC projects if this flag is set to true
     * else the request will be rejected and the client is forced to upgrade to a migration-aware version
     */
    is_late_revision?: boolean;
};

export type ContentsAndCachedAssetsMetadata = {
    content: IEncodedDocument;
    cachedAssetsMetadata: CachedAssetsMetadata;
    revision: Revision;
};

export function getRestoreRevisionMessage(revision: Revision): string {
    return `Restoring version ${revision.revisionNumber}`;
}
