// Copyright 2022 Descript, Inc

import { isObjectEmpty } from '@descript/descript-core';

// Artifact info

import {
    ArtifactStatus,
    CardId,
    CompositionId,
    Dimensions,
    LayoutType,
} from '@descript/descript-model';
import {
    isBetweenProjectPermissionLevels,
    ProjectPermissionLevel,
    ProjectPermissions,
} from './ProjectPermissions';
import { DescriptError, ErrorCategory } from '@descript/errors';
import { Entries } from 'type-fest';

type TemplatePreviewArtifactInfo = {
    assetId: string;
    artifactId: string;
    readUrl?: string;
    status?: ArtifactStatus;
};

type TemplatePreviewArtifactInfoJSON = {
    asset_id: string;
    artifact_id: string;
    read_url?: string;
    status?: ArtifactStatus;
};

// Card info
// user-facing term is  "scene", but "scene" is already used interchangeably with composition in code base

export type TemplateCardInfo = {
    compositionId: CompositionId;
    cardId: CardId;
    name?: string;
    type?: LayoutType;
    dimensions?: Dimensions;
    description?: string;
    previewImageTimestampMs?: number;
    previewImage?: TemplatePreviewArtifactInfo;
    previewVideo?: TemplatePreviewArtifactInfo;
};

type TemplateCardInfoJSON = {
    composition_id: CompositionId;
    card_id: string;
    name?: string;
    type?: LayoutType;
    dimensions?: Dimensions;
    description?: string;
    preview_image_timestamp_ms?: number;
    preview_image?: TemplatePreviewArtifactInfoJSON;
    preview_video?: TemplatePreviewArtifactInfoJSON;
};

// Contents

export type TemplateContents = {
    description?: string;
    author?: string;
    cards: TemplateCardInfo[];
    templatePreviewVideo?: TemplatePreviewArtifactInfo;
    documentVersion?: string;
};

type TemplateContentsJSON = {
    description?: string;
    author?: string;
    cards: TemplateCardInfoJSON[];
    template_preview_video?: TemplatePreviewArtifactInfoJSON;
    document_version?: string;
};

// Project Template Info

export type ProjectPublishedTemplateInfo = {
    contents: TemplateContents;
    /**
     * If true, this template is available to _all_ users in their public template gallery
     */
    isPublic: boolean;
    /**
     * Revision of the project to use for contents
     */
    versionId: string;

    /**
     * Gallery order for gallery templates
     */
    galleryOrder?: number;

    /**
     * Whether the backend should strip emojis when rendering template previews.
     */
    stripEmojisFromPreviews?: boolean;
    /**
     * If true, existing template layers are not removed when this template is applied.
     */
    isElement?: boolean;
};

// Empty object
export type ProjectUnpublishedTemplateInfo = Record<string, never>;

export type ProjectTemplateInfo = ProjectPublishedTemplateInfo | ProjectUnpublishedTemplateInfo;

export type ProjectTemplateInfoJSON =
    | ProjectUnpublishedTemplateInfo
    | {
          contents: Partial<TemplateContentsJSON> | undefined;
          is_public: boolean;
          version_id: string | undefined;
          gallery_order: number | undefined;
          is_element: boolean | undefined;
      };

export type ProjectTemplatePublishInfoJSON =
    | ProjectUnpublishedTemplateInfo
    | (Omit<ProjectTemplateInfoJSON, 'is_public'> & { strip_emojis: boolean | undefined });

// Read URLs for preview assets
export type TemplateCardPreviewInfo = Pick<TemplateCardInfo, 'previewImage' | 'previewVideo'>;
export interface TemplateCompositionPreviewReadUrls {
    [cardId: string]: TemplateCardPreviewInfo;
}
export interface TemplatePreviewReadUrls {
    [compositionId: string]: TemplateCompositionPreviewReadUrls;
}

export type TemplateCardPreviewInfoJSON = Pick<
    TemplateCardInfoJSON,
    'preview_image' | 'preview_video'
>;
export interface TemplateCompositionPreviewReadUrlsJSON {
    [card_id: string]: TemplateCardPreviewInfoJSON;
}
export interface TemplatePreviewReadUrlsJSON {
    [composition_id: string]: TemplateCompositionPreviewReadUrlsJSON;
}

function templatePreviewArtifactInfoFromJson(
    json: TemplatePreviewArtifactInfoJSON,
): TemplatePreviewArtifactInfo {
    return {
        assetId: json.asset_id,
        artifactId: json.artifact_id,
        readUrl: json.read_url,
        status: json.status,
    };
}

function templatePreviewArtifactInfoToJson(
    previewArtifact: TemplatePreviewArtifactInfo,
): TemplatePreviewArtifactInfoJSON {
    return {
        asset_id: previewArtifact.assetId,
        artifact_id: previewArtifact.artifactId,
        read_url: previewArtifact.readUrl,
        status: previewArtifact.status,
    };
}

export function templatePreviewReadUrlsFromJSON(
    json: TemplatePreviewReadUrlsJSON,
): TemplatePreviewReadUrls {
    const templatePreviewUrls: TemplatePreviewReadUrls = {};
    Object.keys(json).forEach((compositionId) => {
        templatePreviewUrls[compositionId] = {};
        Object.keys(json[compositionId]!).forEach((cardId) => {
            const { preview_image, preview_video } = json[compositionId]![cardId as string]!;
            templatePreviewUrls[compositionId]![cardId] = {
                previewImage: preview_image
                    ? templatePreviewArtifactInfoFromJson(preview_image)
                    : undefined,
                previewVideo: preview_video
                    ? templatePreviewArtifactInfoFromJson(preview_video)
                    : undefined,
            };
        });
    });
    return templatePreviewUrls;
}

function templateCardInfoFromJson(json: TemplateCardInfoJSON): TemplateCardInfo {
    return {
        compositionId: json.composition_id,
        cardId: json.card_id as CardId,
        name: json.name,
        type: json.type,
        dimensions: json.dimensions,
        description: json.description,
        previewImage: json.preview_image
            ? templatePreviewArtifactInfoFromJson(json.preview_image)
            : undefined,
        previewVideo: json.preview_video
            ? templatePreviewArtifactInfoFromJson(json.preview_video)
            : undefined,
    };
}

function templateCardInfoToJson(card: TemplateCardInfo): TemplateCardInfoJSON {
    return {
        composition_id: card.compositionId,
        card_id: card.cardId,
        name: card.name,
        type: card.type,
        dimensions: card.dimensions,
        description: card.description,
        preview_image_timestamp_ms: card.previewImageTimestampMs,
        preview_image: card.previewImage
            ? templatePreviewArtifactInfoToJson(card.previewImage)
            : undefined,
        preview_video: card.previewVideo
            ? templatePreviewArtifactInfoToJson(card.previewVideo)
            : undefined,
    };
}

function templateContentsFromJSON(
    json: Partial<TemplateContentsJSON>,
): TemplateContents | undefined {
    if (json.cards === undefined) {
        return undefined;
    }

    return {
        description: json.description,
        author: json.author,
        cards: json.cards.map(templateCardInfoFromJson),
        templatePreviewVideo: json.template_preview_video
            ? templatePreviewArtifactInfoFromJson(json.template_preview_video)
            : undefined,
        documentVersion: json.document_version,
    };
}

function templateContentsToJSON(
    contents: Partial<TemplateContents>,
): TemplateContentsJSON | undefined {
    if (contents.cards === undefined) {
        return undefined;
    }

    return {
        description: contents.description,
        author: contents.author,
        cards: contents.cards.map(templateCardInfoToJson),
        template_preview_video: contents.templatePreviewVideo
            ? templatePreviewArtifactInfoToJson(contents.templatePreviewVideo)
            : undefined,
        document_version: contents.documentVersion,
    };
}

export function projectTemplateInfoFromJSON(
    json: Partial<ProjectTemplateInfoJSON>,
): ProjectTemplateInfo {
    const contents = json.contents ? templateContentsFromJSON(json.contents) : undefined;
    if (
        contents === undefined ||
        json.version_id === undefined ||
        json.is_public === undefined
    ) {
        // an empty template object means the project is a template but isn't published
        return {};
    }

    return {
        contents,
        versionId: json.version_id,
        isPublic: json.is_public,
        galleryOrder: json.gallery_order,
        isElement: json.is_element,
    };
}

export function projectTemplateInfoToJSON(
    template: Partial<ProjectTemplateInfo>,
): ProjectTemplateInfoJSON {
    const contents = template.contents ? templateContentsToJSON(template.contents) : undefined;
    if (contents === undefined || template.versionId === undefined) {
        // an empty template object means the project is a template but isn't published
        return {};
    }

    return {
        contents,
        version_id: template.versionId,
        is_public: template.isPublic ?? false,
        gallery_order: template.galleryOrder,
        is_element: template.isElement,
    };
}

export function projectTemplatePublishInfoToJSON(
    template: Partial<ProjectTemplateInfo>,
): ProjectTemplatePublishInfoJSON {
    const templateInfoJSON = projectTemplateInfoToJSON(template);
    if (isObjectEmpty(templateInfoJSON)) {
        return {};
    }

    return {
        contents: templateInfoJSON.contents,
        version_id: templateInfoJSON.version_id,
        is_element: templateInfoJSON.is_element,
        gallery_order: template.galleryOrder,
        strip_emojis: template.stripEmojisFromPreviews,
    };
}

function templateCardInfoIsEqual(
    a: Partial<TemplateCardInfo>,
    b: Partial<TemplateCardInfo>,
): boolean {
    return (
        a.compositionId === b.compositionId &&
        a.cardId === b.cardId &&
        a.name === b.name &&
        a.type === b.type &&
        a.dimensions?.width === b.dimensions?.width &&
        a.dimensions?.height === b.dimensions?.height &&
        a.description === b.description &&
        a.previewImage?.artifactId === b.previewImage?.artifactId &&
        a.previewImage?.assetId === b.previewImage?.assetId &&
        a.previewVideo?.artifactId === b.previewVideo?.artifactId &&
        a.previewVideo?.assetId === b.previewVideo?.assetId
    );
}

export function templateContentsIsEqual(
    a: Partial<TemplateContents> | undefined,
    b: Partial<TemplateContents> | undefined,
): boolean {
    if (a === undefined || b === undefined) {
        return a === undefined && b === undefined;
    }
    if (a.cards?.length !== b.cards?.length) {
        return false;
    }
    if (a.cards && b.cards) {
        // different order for the same cards also counts as unequal
        for (let i = 0; i < a.cards.length; i++) {
            if (
                b.cards[i] === undefined ||
                !templateCardInfoIsEqual(a.cards[i]!, b.cards[i]!)
            ) {
                return false;
            }
        }
    }

    return (
        a.description === b.description &&
        a.author === b.author &&
        a.templatePreviewVideo?.artifactId === b.templatePreviewVideo?.artifactId &&
        a.templatePreviewVideo?.assetId === b.templatePreviewVideo?.assetId &&
        a?.documentVersion === b?.documentVersion
    );
}

/**
 * Describes how combinations of project permissions fall into different template access options
 */
export interface TemplateAccess {
    name: string;
    minPublicAccess?: ProjectPermissionLevel;
    maxPublicAccess?: ProjectPermissionLevel;
    minDriveAccess?: ProjectPermissionLevel;
    maxDriveAccess?: ProjectPermissionLevel;
}

const privateAccessOption: TemplateAccess = {
    name: 'Private',
    maxPublicAccess: ProjectPermissionLevel.None,
    maxDriveAccess: ProjectPermissionLevel.None,
};

const driveAccessOption: TemplateAccess = {
    name: 'Share with drive',
    maxPublicAccess: ProjectPermissionLevel.None,
    minDriveAccess: ProjectPermissionLevel.Comment,
};

const publicAccessOption: TemplateAccess = {
    name: 'Share publicly',
    minPublicAccess: ProjectPermissionLevel.View,
    minDriveAccess: ProjectPermissionLevel.Comment,
};

export type TemplateAccessType = 'Private' | 'Drive' | 'Public';

export const templateAccessOptions: Record<TemplateAccessType, TemplateAccess> = {
    Private: privateAccessOption,
    Drive: driveAccessOption,
    Public: publicAccessOption,
};

export function getTemplateAccessForProjectPermissions(
    permissions: ProjectPermissions,
): TemplateAccess {
    const accessType = getTemplateAccessTypeForProjectPermissions(permissions);
    return templateAccessOptions[accessType];
}

export function getTemplateAccessTypeForProjectPermissions(
    permissions: ProjectPermissions,
): TemplateAccessType {
    const templateAccessEntries = Object.entries(templateAccessOptions) as Entries<
        typeof templateAccessOptions
    >;
    const [accessType] =
        templateAccessEntries.find(([_, option]) => {
            return (
                isBetweenProjectPermissionLevels(
                    permissions.publicAccess,
                    option.minPublicAccess,
                    option.maxPublicAccess,
                ) &&
                isBetweenProjectPermissionLevels(
                    permissions.driveAccess,
                    option.minDriveAccess,
                    option.maxDriveAccess,
                )
            );
        }) || [];

    if (!accessType) {
        throw new DescriptError(
            'Unable to map project permissions to template access type',
            ErrorCategory.Templates,
        );
    }

    return accessType;
}

export function isTemplateAccessType(type: string): type is TemplateAccessType {
    return Object.keys(templateAccessOptions).includes(type);
}
