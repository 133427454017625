// Copyright 2024 Descript, Inc

import React, { useEffect, useMemo, useState } from 'react';
import { Stack } from '@daily/shared/src/components/Stack';
import { Text } from '@daily/shared/src/components/Text';

import { UploadingParticipantRow } from './UploadingParticipantRow';
import { useAllUploadingStates } from '../state/uploadProgress';

type Props = {
    onUploadingStateChange?: (state: 'active' | 'stalled' | 'complete') => void;
};

export function UploadingModalContent({ onUploadingStateChange }: Props) {
    const participantStates = useAllUploadingStates();
    const [showUploadState, setShowUploadState] = useState(false);

    const isIncomplete = useMemo(
        () => (showUploadState ? participantStates.some((p) => p.status !== 'complete') : true),
        [participantStates, showUploadState],
    );

    const hasStalled = useMemo(
        () => participantStates.some((p) => p.status === 'stalled'),
        [participantStates],
    );

    const [debouncedIsIncomplete, setDebouncedIncomplete] = useState(true);
    useEffect(() => {
        const to = setTimeout(() => {
            setDebouncedIncomplete(isIncomplete);
        }, 750);

        return () => clearTimeout(to);
    }, [setDebouncedIncomplete, isIncomplete]);

    const uploadingState = useMemo(() => {
        if (!debouncedIsIncomplete) {
            return 'complete';
        }
        if (hasStalled) {
            return 'stalled';
        }
        return 'active';
    }, [debouncedIsIncomplete, hasStalled]);

    useEffect(() => {
        if (onUploadingStateChange) {
            onUploadingStateChange(uploadingState);
        }
    }, [onUploadingStateChange, uploadingState]);

    /**
     * Delaying showing the upload percent for a litte because we
     * get the last chunks just after hitting stop which results
     * in often seeing the initial percent drop. This is just UI fluff.
     */
    useEffect(() => {
        const to = setTimeout(() => setShowUploadState(true), 750);
        return () => clearTimeout(to);
    }, [setShowUploadState]);

    return (
        <Stack gap={16}>
            <Text
                variant="base"
                style={{
                    fontSize: '12px',
                    marginTop: '16px',
                }}
            >
                {debouncedIsIncomplete
                    ? 'The recording has stopped - your files are uploading...'
                    : 'Thanks for recording in Descript Rooms!'}
            </Text>
            <Stack
                style={{
                    maxHeight: '50vh',
                    overflowY: 'auto',
                }}
            >
                {participantStates.map((state, index) => (
                    <UploadingParticipantRow
                        key={`participant_${index}`}
                        uploadState={state}
                        showUploadState={showUploadState}
                    />
                ))}
            </Stack>
        </Stack>
    );
}
