import { Delta } from 'jsondiffpatch';
import type { Commit } from 'trimerge-sync';
import {
    DEPRECATED_FORMAT_NOOP,
    FORMAT_GZIPPED_JSON_DIFF_PATCH_1,
    FORMAT_JSON_DIFF_PATCH_1,
} from './constants';
import {
    DeltaApplicator,
    makeComparingDeltaApplicator,
    MappingDeltaApplicator,
} from './DeltaApplicator';
import {
    DeltaDecoder,
    GzipJsonDeltaDecoder,
    JdpImmerDeltaApplicator,
    JdpPojoDeltaApplicator,
    JsonDeltaDecoder,
    MappingDeltaDecoder,
    NoopDeltaApplicator,
    NoopDeltaDecoder,
    parseDelta,
    serializeDelta,
} from './DeltaCodecs';
import { DeltaFormat } from './DeltaFormat';
import { getJsonDiffPatch, getJsonDiffPatch_naiveStringDiff } from './jsonDiffPatch';
import type { CommitMetadata, DeltaType } from './types';
import { DescriptError, ErrorCategory } from '@descript/errors';

export function sortCommits(
    commitA: Commit<CommitMetadata, DeltaType>,
    commitB: Commit<CommitMetadata, DeltaType>,
): number {
    if (commitA.metadata.server && commitB.metadata.server) {
        return commitA.metadata.server.index - commitB.metadata.server.index;
    }

    if (!commitA.metadata.server && !commitB.metadata.server) {
        if (commitA.metadata.client?.index && commitB.metadata.client?.index) {
            return commitA.metadata.client.index - commitB.metadata.client.index;
        } else if (commitA.metadata.client?.index) {
            return -1;
        } else if (commitB.metadata.client?.index) {
            return 1;
        } else {
            return commitA.ref < commitB.ref ? -1 : 1;
        }
    } else if (commitA.metadata.server) {
        return -1;
    } else {
        return 1;
    }
}

export function patch<T>(
    decoder: DeltaDecoder,
    deltaApplicator: DeltaApplicator,
    base: T | undefined,
    delta: DeltaType | undefined,
): T {
    if (delta === undefined) {
        if (base === undefined) {
            throw new DescriptError(
                'base and delta are both undefined',
                ErrorCategory.LiveCollab,
            );
        }
        return base;
    }

    const { serializationFormat, deltaFormat, deltaBody } = parseDelta(delta);

    // deserialize the delta
    const decodedDelta = decoder.decode(serializationFormat, deltaBody);
    if (!decodedDelta.success) {
        throw new DescriptError(
            `Failed to decode delta: ${serializationFormat}`,
            ErrorCategory.LiveCollab,
        );
    }

    // apply the delta
    const patchedResult = deltaApplicator.apply(base, deltaFormat, decodedDelta.result);
    if (!patchedResult.success) {
        throw new DescriptError(
            `Failed to apply delta: ${deltaFormat}`,
            ErrorCategory.LiveCollab,
        );
    }

    return patchedResult.result;
}

/** Used for transforming a serialized delta to some in-memory representation of the delta (e.g. json-diff-patch Delta) */
export const COMMIT_DELTA_DECODER = new MappingDeltaDecoder(
    new Map([
        [FORMAT_JSON_DIFF_PATCH_1, JsonDeltaDecoder],
        [FORMAT_GZIPPED_JSON_DIFF_PATCH_1, GzipJsonDeltaDecoder],
        [DEPRECATED_FORMAT_NOOP, NoopDeltaDecoder],
    ]),
);

type Patcher<T> = (base: T | undefined, delta: DeltaType | undefined) => T;

export function getPojoPatcher<T>(): Patcher<T> {
    return patch.bind(
        undefined,
        COMMIT_DELTA_DECODER,
        new MappingDeltaApplicator(
            new Map([
                [DeltaFormat.JsonDiffPatch, JdpPojoDeltaApplicator],
                [DeltaFormat.NoopDelta, NoopDeltaApplicator],
            ]),
        ),
    ) as Patcher<T>;
}

export function getImmerPatcher<T>(): Patcher<T> {
    return patch.bind(
        undefined,
        COMMIT_DELTA_DECODER,
        new MappingDeltaApplicator(
            new Map([
                [DeltaFormat.JsonDiffPatch, JdpImmerDeltaApplicator],
                [DeltaFormat.NoopDelta, NoopDeltaApplicator],
            ]),
        ),
    ) as Patcher<T>;
}

export function getPojoValidityCheckPatcher<T>(): Patcher<T> {
    return patch.bind(
        undefined,
        COMMIT_DELTA_DECODER,
        new MappingDeltaApplicator(
            new Map([
                [
                    DeltaFormat.JsonDiffPatch,
                    makeComparingDeltaApplicator([
                        JdpPojoDeltaApplicator,
                        JdpImmerDeltaApplicator,
                    ]),
                ],
                [DeltaFormat.NoopDelta, NoopDeltaApplicator],
            ]),
        ),
    ) as Patcher<T>;
}

export function diff<T>(left: T, right: T): DeltaType | undefined {
    let delta: Delta | undefined;
    try {
        delta = getJsonDiffPatch().diff(left, right);
    } catch (e) {
        if (e instanceof URIError) {
            delta = getJsonDiffPatch_naiveStringDiff().diff(left, right);
        } else {
            throw e;
        }
    }
    return serializeDelta(delta);
}
