// Copyright 2024 Descript, Inc
export const getVideoBitRate = (cohort?: string) => {
    switch (cohort) {
        case 'experiment-treatment-1':
            return {
                '4k': 20_000_000,
                '1440p': 10_000_000,
                '1080p': 7_500_000,
                '720p': 3_500_000,
            };
        case 'experiment-treatment-2':
            return {
                '4k': 15_000_000,
                '1440p': 7_500_000,
                '1080p': 5_000_000,
                '720p': 2_500_000,
            };
        default:
            return {
                '4k': 30_000_000,
                '1440p': 15_000_000,
                '1080p': 10_000_000,
                '720p': 5_000_000,
            };
    }
};
