// Copyright 2023 Descript, Inc
import { TimeInterval } from '@descript/descript-model';
import { acceptedMimeTypes } from './SupportedFileTypes';
import UAParser from 'ua-parser-js';
import { PlatformHelpers } from '@descript/descript-core';

export { supportedFileTypes } from './SupportedFileTypes';
export const electronAppProtocol = 'descriptbc:';
export const systemPrefProtocolMac = 'x-apple.systempreferences:';
export const systemPrefProtocolWin = 'ms-settings:';
export const electronAppScheme = `${electronAppProtocol}//`;
export const electronAppSchemeRoute = `${electronAppScheme}route`;

export const standaloneRecorderAppName = 'Descript Recorder';
export const trayIcon = 'tray-icon.png';

export const convertPathToElectron = (path: string) => {
    return PlatformHelpers.isWindows() || PlatformHelpers.isMac()
        ? `${electronAppSchemeRoute}${path}`
        : 'https://www.descript.com';
};

export const desktop2AppProtocol = 'descript:';
export const desktop2AppScheme = `${desktop2AppProtocol}//`;
export const desktop2AppSchemeRoute = `${desktop2AppScheme}route`;

export const descriptRecorderProtocol = 'descript-recorder:';
export const descriptRecorderAppScheme = `${descriptRecorderProtocol}//`;
export const descriptRecorderAppSchemeRoute = `${descriptRecorderAppScheme}route`;
export const descriptRecordingDownloadPage = 'https://www.descript.com/download/recorder';
export const spotAuditionSeconds = 0.75;
export const spotHighlightsLength = 10;
export const minPlaybackRate = 0.5;
export const maxPlaybackRate = 3;
export const seekAmount = 5;
// imagePinDuration moved to MediaReferences.DEFAULT_INSERT_DURATION
export const audioChunkSizeSeconds: TimeInterval = 30;
export const videoChunkSizeSeconds: TimeInterval = 10;
export const mediaEnginePrefetchSeconds: TimeInterval = 10;
export const alterPlaybackRateAmount = 0.1;
export const defaultExportFilename = 'Untitled Descript Export';
export const newProjectRevisionName = 'New project save';
export const defaultWindowWidth = 1300;
export const defaultWindowHeight = 800;
export const minWindowWidth = 790;
export const minWindowHeight = 775;
// Keep in sync with headerbar-height
export const headerBarHeight = 52;
export const quickEditWindowWidth = minWindowWidth;
export const quickEditWindowHeight = minWindowHeight;
export const learningRailWidth = 300;
export const learningRailBorder = 1;
export const learningRailVideoWidthDefault = 430;
export const insertDecorationId = 'insert-decoration-trigger';
export const projectFilesTree = 'project-files-tree';

export const trafficLightX = 17;
export const trafficLightY = 19;
export const trafficLightYRedesign = 15.5;
export const projectSidebarDefaultSize = 312;
export const projectPageSidebarSizeMin = 170;
export const projectPageSidebarCollapsedSize = 50;
export const actualThumbSize = 300;
export const minCardRailSize = 96;
export const maxCardRailSize = 180;
export const minSidebarTranscriptionWidth = 312;
export const minMediaLibraryInspectorSize = 132;
export const maxMediaLibraryInspectorSize = 400;
export const minVideoPaneSize = 180;
export const rightSidebarWidth = 260;
export const historyPaneSize = 300;
export const maxTeamLogoBytes = 1_048_576; // 1 MB
export const maxProfileImageBytes = 1_048_576; // 1 MB
export const maxMetadataArtworkBytes = 512_000; // 500 KB
export const mediaEngineTimeout = 60_000; // milliseconds
export const revisionBundleVersion = '1.0.0';
export const insertGapDuration: TimeInterval = 1.0;
export const recordingLevelMonitorInterval = 16; // milliseconds. 60Hz equals 16.7ms intervals
export const cachedEnvelopeHz = 10;
export const roughMaxImputationWords = 30;
export const maxImputationCharacters = 250;
export const maxTTSCharacters = 1000;
export const maxTTSCharactersUvm = 1800;
export const speakerDetectionMinDurationAutomatic = 10;
export const speakerDetectionMinDuration = 30;
export const speakerDetectionMaxDurationAutomatic = 5 * 60 * 60; // 5 hours
export const speakerDetectionMaxDuration = 3 * 60 * 60; // 3 hours
export const maxDriveNameLength = 64;
export const maxProjectTitleLength = 255; // match backend limit
export const headlinerAPIKey = 'Bj6Cw6UU6qNut3g3XkGVmhaZk';
export const googleAppsAPIKey = 'AIzaSyDNKIIGHMI308n9xxKcOIzjytF3L4iBDQg';
export const giphyAPIKey = 'bSB9vdQmTUNBbLn7JWMFtXm3yVet21Nj';
// transcription glossary limits based on rev.ai custom vocabulary rules
export const maxWordsPerTranscriptionGlossaryPhrase = 12;
export const maxCharactersPerWordInTranscriptionGlossaryPhrase = 34;
export const maxPhrasesInTranscriptionGlossary = 6000;
export const minimumMinutesForLiveTranscription = 60;
export const numRecentFonts = 6;
export const numRecentColors = 7;

// this is a wild guess that seems reasonable -- if 25% of the words in a sequence are overlapping,
// there might be mic bleed in the sources
export const minOverlappingWordRatioToShowMicBleedToast = 0.25;

export const acceptedMediaDropFormats = [
    'audio/*',
    'video/*',
    'image/*',
    // Note - Safari wasn't accepting .m4a and .aac files when just "audio/*" was specified
    // Listing the types separately fixes that
    ...acceptedMimeTypes,
];
export const acceptedMediaDropFormatsString = acceptedMediaDropFormats.join(', ');

export const desktop2Version = globalThis.DescriptDesktopAPI?.desktop
    ? (globalThis.DescriptDesktopAPI.buildVersion ?? '?')
    : undefined;

export const prettyVersion = `${process.env.REACT_APP_VERSION || '?'} (${
    process.env.REACT_APP_BUILD_NUMBER || process.env.GITHUB_SHA || '?'
})`;

export const detailedVersion = `${process.env.REACT_APP_VERSION || '?'} (${
    process.env.REACT_APP_BUILD_NUMBER || '?'
}) (${process.env.GITHUB_SHA || '?'})${desktop2Version ? ` (Desktop ${desktop2Version})` : ''}`;

export const screenRecorderMacVersion = '1.0.159';

export const screenRecorderWinVersion = '7.0.0.1203';

export const screenRecorderCameraPreviewSizePercentage = 30;
export const screenRecorderCountdownLength = 3;

export const templateThumbnailHeight = '120px';

export const diagnosticDataS3BucketName = 'descript-diagnostic';

export const cloudLibraryAudioPreviewHeight = 42;
export const cloudLibraryImagePreviewHeight = 150;

export const templateReplaceThumbnailHeight = 120;

export const sbrmSidebarWidth = 290;
// These values are calculated for SBRM drawer variant, not popover
export const cloudLibraryImagePreviewHeightSbrm = 139.5;
export const cloudLibraryImagePreviewHeightMiniSbrm = 68.63;
export const cloudLibraryAudioPreviewHeightSbrm = 72;

// first N cloud media a user can import for each cloud library search
export const freeCloudMediaLimit = 5;
export const legacyCreatorCloudMediaLimit = 12;

export * as AWS from './Aws';
export * as Audio from './Audio';
export * as Auth from './Auth';
export * as ProjectBrowser from './ProjectBrowser';
export * as Publish from './Publish';
export * as Recording from './Recording';
export * as QuickRecording from './QuickRecording';
export * as LearningRailAppCommands from './LearningRailAppCommands';
export * as LearningRailCookies from './LearningRailCookies';
export * as Dimensions from './Dimensions';
export * as DragTypes from './DragAndDrop';
export * as Timeline from './Timeline';
export * as MarketingCookies from './MarketingCookies';
export * as PaymentMethodDisplayErrors from './PaymentMethodDisplayErrors';

export const supportsOffscreenWebgl = Boolean(
    typeof HTMLCanvasElement !== 'undefined' &&
        // @ts-expect-error Different browsers have different support
        HTMLCanvasElement.prototype.transferControlToOffscreen &&
        new OffscreenCanvas(10, 10).getContext('webgl'),
);
export const supportsVideoDecoder = typeof VideoDecoder !== 'undefined';
/**
 * Whether the browser is fully supported by Descript.
 * Note: this check will not work in web workers (limitation of ua-parser-js)
 */
export const browserIsFullySupported =
    supportsVideoDecoder &&
    supportsOffscreenWebgl &&
    // There are issues with OffscreenCanvas on Safari.
    // So for now, explicitly enable only on Chromium-based browsers
    new UAParser().getEngine()?.name === 'Blink';

export const activeEditorLimit = 100;
