// Copyright 2023 Descript, Inc

import { PlatformHelpers, getAppVersion, JSONObject } from '@descript/descript-core';

type Desktop2GlobalThis = {
    DescriptDesktopAPI?: {
        buildVersion: string;
    };
};

const version = getAppVersion();
const build = process.env.REACT_APP_BUILD_NUMBER || process.env.GITHUB_SHA || 'web';
export const appGenericContext: Readonly<JSONObject> = {
    app: {
        name: 'Descript',
        // for Amplitude's "Version" property, for their "Releases" concept
        version,
        build,
    },
};

export function getGenericAppAnalyticsProperties() {
    // #Desktop2BuildAnalytics
    // To determine if we're on Desktop 2, we check for the presence of the
    // global `DescriptDesktopAPI`, injected by the Desktop 2 Electron
    // renderer. We can't use `isDesktop2` inside pkg-js/analytics because
    // that's only exposed to pkg-js/client.
    const descriptDesktopAPI = (globalThis as unknown as Desktop2GlobalThis).DescriptDesktopAPI;
    return {
        platform: PlatformHelpers.platform(),
        build:
            (descriptDesktopAPI !== undefined ? 'desktop_v2' : process.env.PRODUCT) ||
            'unknown',
        desktop2_build_version: descriptDesktopAPI?.buildVersion || 'unknown',
        build_type: process.env.REACT_APP_BUILD_TYPE || 'unknown',
        version,
        build_number: build,
        bundle_id: PlatformHelpers.clientID(),
        os_version: PlatformHelpers.getOS(),
    };
}
