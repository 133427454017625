// Copyright 2024 Descript, Inc
import classnames from 'classnames';
import { forwardRef } from 'react';
import * as React from 'react';

import { useTheme } from '../../contexts/Theme';
import { hexToRgba } from '../../lib/colors';
import { ExternalLinkIcon } from '../Icons/ExternalLinkIcon';
import { LoadingSpinner } from '../LoadingSpinner';
import { ReactNull } from '@descript/react-utils';

interface BaseProps {
    disabled?: boolean;
    focusable?: boolean;
    fullWidth?: boolean;
    iconAfter?: JSX.Element;
    iconBefore?: JSX.Element;
    loading?: boolean;
    noIcon?: boolean;
    size?: 'medium' | 'small';
    variant?: 'primary' | 'secondary' | 'warning' | 'danger' | 'ghost';
}

interface ButtonPropsWithHref extends React.AnchorHTMLAttributes<HTMLAnchorElement>, BaseProps {
    href: string;
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, BaseProps {
    href?: string;
    type?: 'button' | 'reset' | 'submit';
}

type Props = ButtonProps | ButtonPropsWithHref;

export const Button = forwardRef<HTMLAnchorElement | HTMLButtonElement, Props>(function Button(
    {
        children,
        className,
        disabled = false,
        fullWidth,
        href,
        iconAfter = ReactNull,
        iconBefore = ReactNull,
        loading = false,
        noIcon = false,
        size = 'medium',
        type = 'button',
        variant = 'primary',
        focusable = variant === 'ghost',
        ...props
    },
    ref,
) {
    const { colors, fontFamilies, fontSizes, lineHeights } = useTheme();
    const classNames = classnames('button', className, size, variant, {
        disabled,
        focusable,
        fullWidth,
        loading,
    });

    const isExternalHref = href && href.match(/^(https?:)?\/\//);
    const linkIcon = href && isExternalHref && !iconAfter && !noIcon && (
        <ExternalLinkIcon size={16} />
    );

    const content = (
        <>
            {iconBefore && <span className="iconBefore">{iconBefore}</span>}
            {children}
            {(iconAfter || linkIcon) && (
                <span className="iconAfter">
                    {iconAfter}
                    {linkIcon}
                </span>
            )}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                .iconAfter {
                    margin: 0 0 -2px 4px;
                }
                .iconBefore {
                    margin: 0 4px -2px 0;
                }
            `}</style>
        </>
    );

    const linkProps: ButtonPropsWithHref = {
        className: classNames,
        href: disabled ? ReactNull : href,
        rel: isExternalHref ? 'noopener' : ReactNull,
        target: isExternalHref ? '_blank' : ReactNull,
        ...(props as ButtonPropsWithHref),
    };

    return (
        <>
            {href ? (
                <a ref={ref as React.MutableRefObject<HTMLAnchorElement>} {...linkProps}>
                    {content}
                    {loading && (
                        <span className="loadingSpinner">
                            <LoadingSpinner size={16} />
                        </span>
                    )}
                </a>
            ) : (
                <button
                    ref={ref as React.MutableRefObject<HTMLButtonElement>}
                    className={classNames}
                    disabled={disabled || loading}
                    // eslint-disable-next-line react/button-has-type
                    type={(type as ButtonProps['type']) || 'button'}
                    {...(props as ButtonProps)}
                >
                    {content}
                    {loading && (
                        <span className="loadingSpinner">
                            <LoadingSpinner size={16} />
                        </span>
                    )}
                </button>
            )}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx>{`
                .button {
                    align-items: center;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    box-shadow: 0 0 0 0 transparent;
                    color: var(--button-color);
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    font-family: ${fontFamilies.medium};
                    font-size: ${fontSizes.base};
                    line-height: ${lineHeights.base};
                    margin: 0;
                    pointer-events: all;
                    position: relative;
                    text-decoration: none;
                    transition:
                        background 200ms ease,
                        border 200ms ease,
                        box-shadow 200ms ease;
                    white-space: nowrap;
                }
                a.button {
                    display: inline-flex;
                }
                .button:focus {
                    outline: none;
                }
                .button::-moz-focus-inner {
                    border: 0;
                }
                .primary {
                    background-color: rgb(0, 98, 255);
                    color: white;
                    box-shadow: 0 1px 2px var(--color-black-20pct);
                }
                .primary:hover {
                    background-color: rgb(0, 78, 204);
                }
                .primary:not(.loading):focus-visible {
                    box-shadow: 0 0 0 2px ${hexToRgba(colors.accent, 0.8)};
                }
                .primary:not(.loading):hover {
                    // box-shadow: 0 0 0 2px ${hexToRgba(colors.accent, 0.8)};
                }
                .warning:not(.loading):focus-visible,
                .warning:not(.loading):hover {
                    box-shadow: 0 0 0 2px ${hexToRgba(colors.system.orange, 0.6)};
                }
                .danger:not(.loading):focus-visible,
                .danger:not(.loading):hover {
                    box-shadow: 0 0 0 2px ${hexToRgba(colors.system.red, 0.6)};
                }
                .warning {
                    background-color: ${colors.system.orange};
                    border-color: ${colors.system.orange};
                }
                .secondary {
                    box-shadow: 0 1px 2px var(--color-black-20pct);
                    background-color: var(--grey-0);
                    color: var(--color-standard-text);
                    border: 1px solid var(--color-black-10pct);
                }

                .secondary:not(.loading):not([disabled]):focus-visible,
                .secondary:not(.loading):not([disabled]):hover {
                    box-shadow: 0 0 0 2px var(--focus-shadow);
                    border-color: var(--focus-border);
                    background-color: rgb(230, 230, 230);
                }
                .danger {
                    background-color: ${colors.system.red};
                    border-color: ${colors.system.red};
                    color: ${colors.system.white};
                }
                .medium {
                    padding: 8px 16px;
                }
                .small {
                    padding: 2px 8px;
                }
                .fullWidth {
                    justify-content: center;
                    width: 100%;
                    white-space: normal;
                }
                .ghost {
                    background-color: transparent;
                    border: none;
                    border-radius: 0;
                    color: var(--button-ghost-color);
                    padding: 0;
                }
                .ghost.focusable {
                    border-radius: 2px;
                }
                // .focusable:not(.loading):not([disabled]):hover
                .focusable:not(.loading):not([disabled]):focus-visible {
                    border-radius: 2px;
                    box-shadow: 0 0 0 2px ${hexToRgba(colors.accent, 0.6)};
                }
                .disabled:not(.loading),
                [disabled]:not(.loading) {
                    color: ${colors.supportiveText};
                    cursor: default;
                }
                .disabled.primary:not(.loading),
                [disabled].primary:not(.loading),
                .disabled.warning:not(.loading),
                [disabled].warning:not(.loading),
                .disabled.danger:not(.loading),
                [disabled].danger:not(.loading) {
                    background-color: var(--button-disabled-bg);
                    border-color: var(--button-disabled-border);
                    box-shadow: none;
                    color: var(--button-disabled-color);
                    transform: none;
                }
                .disabled.secondary:not(.loading),
                [disabled].secondary:not(.loading) {
                    background-color: var(--button-secondary-disabled-bg);
                    border-color: var(--button-secondary-disabled-border);
                    color: var(--button-secondary-disabled-color);
                }
                .loading {
                    cursor: default;
                    opacity: 0.9;
                }
                .loadingSpinner {
                    align-items: center;
                    background-color: inherit;
                    border-radius: inherit;
                    bottom: 0;
                    display: flex;
                    justify-content: center;
                    left: 0;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
                .button[type='submit'] {
                    font-weight: 400;
                }
            `}</style>
        </>
    );
});
