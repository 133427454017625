// Copyright 2022 Descript, Inc

export enum ProjectPermissionLevel {
    None = 'none',
    View = 'view',
    Comment = 'comment',
    Edit = 'edit',
}

const COMPARABLE_PERMISSION_LEVELS: Record<ProjectPermissionLevel, number> = {
    [ProjectPermissionLevel.None]: 0,
    [ProjectPermissionLevel.View]: 1,
    [ProjectPermissionLevel.Comment]: 2,
    [ProjectPermissionLevel.Edit]: 3,
};

export function atLeastProjectPermissionLevel(
    level: ProjectPermissionLevel,
    targetLevel: ProjectPermissionLevel,
): boolean {
    return COMPARABLE_PERMISSION_LEVELS[level] >= COMPARABLE_PERMISSION_LEVELS[targetLevel];
}

export type ProjectPermissions = {
    publicAccess: ProjectPermissionLevel;
    driveAccess: ProjectPermissionLevel;
};

export const privateWorkspacePermissions = {
    publicAccess: ProjectPermissionLevel.None,
    driveAccess: ProjectPermissionLevel.None,
} as ProjectPermissions;

export function isNonPrivateWorkspacePermissions(permissions: ProjectPermissions | undefined) {
    if (!permissions) {
        return false;
    }
    return (
        permissions.driveAccess !== privateWorkspacePermissions.driveAccess ||
        permissions.publicAccess !== privateWorkspacePermissions.publicAccess
    );
}

/*
 * Check if a given permission level is between an inclusive range from a provided upper and/or lower bound permission level
 */
export function isBetweenProjectPermissionLevels(
    level: ProjectPermissionLevel,
    lowerBound?: ProjectPermissionLevel,
    upperBound?: ProjectPermissionLevel,
): boolean {
    if (lowerBound && !atLeastProjectPermissionLevel(level, lowerBound)) {
        return false;
    }
    if (upperBound && !atLeastProjectPermissionLevel(upperBound, level)) {
        return false;
    }

    return true;
}

/**
 * Fits a given permission level to an inclusive range from a provided upper and/or lower bound permission level
 */
export function fitToProjectPermissionLevels(
    level: ProjectPermissionLevel,
    lowerBound?: ProjectPermissionLevel,
    upperBound?: ProjectPermissionLevel,
): ProjectPermissionLevel {
    if (lowerBound && !atLeastProjectPermissionLevel(level, lowerBound)) {
        level = lowerBound;
    }
    if (upperBound && !atLeastProjectPermissionLevel(upperBound, level)) {
        level = upperBound;
    }

    return level;
}

export type ProjectPermissionsJson = {
    public_access?: ProjectPermissionLevel;
    member_access?: ProjectPermissionLevel;
};
