// Copyright 2018 Descript, Inc

import { IEncodedBackendVoiceVersionStatus } from '@descript/descript-model';

export const SPEECH_TRANSCRIPTION_UPDATED = 'speechTranscriptionUpdated';
export const ANNOTATIONS_UPDATED = 'annotationsUpdated';
export const REVISION_CREATED = 'revisionCreated';
export const AUDIO_SESSION_CONVERSION_TASK_COMPLETED = 'audioSessionConversionTaskCompleted';
export const ASSET_COPY_COMPLETED = 'assetCopyCompleted';
export const ASSET_COPY_FAILED = 'assetCopyFailed';
export const DRIVE_SUBSCRIPTION_STATUS_UPDATED = 'driveSubscriptionStatusUpdated';
export const DRIVE_TRANSCRIPTION_MINUTES_UPDATED = 'driveTranscriptionMinutesUpdated';
export const PAYMENT_INFORMATION_UPDATED = 'paymentInformationChanged';
export const PAID_FAILED_INVOICES = 'paidFailedInvoices';
export const FOLDER_UPDATED = 'folderUpdated';
export const FOLDER_DELETED = 'folderDeleted';
export const PROJECT_CREATED = 'projectCreated';
export const PROJECT_UPDATED = 'projectUpdated';
export const PROJECT_DELETED = 'projectDeleted';
export const ARTIFACT_PROGRESS = 'artifactProgress';
export const CLIENT_ARTIFACT_UPLOAD_PROGRESS = 'clientArtifactUploadProgress';
export const ROOMS_PARTICIPANT_UPLOAD_PROGRESS = 'roomsParticipantUploadProgress';
export const VOICE_TRAINING_STATE_UPDATED = 'voiceTrainingStateUpdated';
export type DriveMembershipMessageType =
    | 'membershipCreated'
    | 'membershipUpdated'
    | 'membershipDeleted';
type DriveMembershipUpgradeRequestMessageType =
    | 'driveMembershipUpgradeRequestCreated'
    | 'driveMembershipUpgradeRequestHandled';
type DriveMembershipUpgradeRequestStatus = 'pending' | 'approved' | 'rejected';
type OwnershipMessageType = 'ownershipCreated' | 'ownershipUpdated' | 'ownershipDeleted';

export const PUBLISH_STARTED = 'publish-started';
export const PUBLISH_SUCCESS = 'publish-success';
export const PUBLISH_FAILED = 'publish-failed';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PublishMessageTypes = [PUBLISH_STARTED, PUBLISH_SUCCESS, PUBLISH_FAILED] as const;
export type PublishMessageType = (typeof PublishMessageTypes)[number];

export const LIVE_COLLAB_MIGRATED = 'migratedToLiveCollab';
export const LIVE_COLLAB_REVERTED = 'revertedToClassic';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const LiveCollabMigrationMessageTypes = [LIVE_COLLAB_MIGRATED, LIVE_COLLAB_MIGRATED] as const;
export type LiveCollabMigrationMessageType = (typeof LiveCollabMigrationMessageTypes)[number];

export const PAYWALL_LEDGER_UPDATED = 'paywall-ledger-updated';

export type IPubnubMessage =
    | {
          type: typeof REVISION_CREATED;
          message: {
              revisionId: string;
              revisionNumber: number;
          };
      }
    | {
          type: typeof ANNOTATIONS_UPDATED;
      }
    | {
          type: typeof SPEECH_TRANSCRIPTION_UPDATED;
          receiptId: string;
          state: 'progress' | 'completed';
          progress?: number;
      }
    | {
          type: typeof AUDIO_SESSION_CONVERSION_TASK_COMPLETED;
          taskId: string;
      }
    | {
          type: typeof ASSET_COPY_COMPLETED | typeof ASSET_COPY_FAILED;
          sourceUrl: string;
          destinationUrl: string;
      }
    | { type: typeof FOLDER_UPDATED | typeof FOLDER_DELETED; folderId: string }
    | {
          type:
              | typeof PROJECT_CREATED
              | typeof PROJECT_UPDATED
              | typeof PROJECT_DELETED
              | LiveCollabMigrationMessageType;
          projectId: string;
      }
    | {
          type: typeof VOICE_TRAINING_STATE_UPDATED;
          voiceId: string;
          voiceVersionId: string;
          trainingState: IEncodedBackendVoiceVersionStatus;
      }
    | {
          type: DriveMembershipMessageType;
          membershipId: string;
      }
    | {
          type: DriveMembershipUpgradeRequestMessageType;
          driveId: string;
          membershipId: string;
          upgradeRequestId: string;
          status: DriveMembershipUpgradeRequestStatus;
      }
    | {
          type: 'userMembershipCreated';
          driveId: string;
          membershipId: string;
      }
    | {
          type: OwnershipMessageType;
          userId: string;
          projectId: string;
          ownershipId: string;
      }
    | {
          type: typeof DRIVE_SUBSCRIPTION_STATUS_UPDATED;
          driveId: string;
      }
    | {
          type: PublishMessageType;
          taskId: string;
          extra?: {
              service: string;
              error: string;
          };
      }
    | {
          type: typeof ARTIFACT_PROGRESS;
          assetId: string;
          artifactId: string;
          progress: number;
      }
    | {
          type: typeof CLIENT_ARTIFACT_UPLOAD_PROGRESS;
          assetId: string;
          artifactId: string;
          progress: number;
      }
    | {
          type: typeof PAYWALL_LEDGER_UPDATED;
          driveId: string;
      };
