import {
    type LogEntry,
    LogLevel,
    type Loggable,
    type Logger,
    isLogEntryConvertible,
    toLogEntry,
} from './Logger';

/** Useful base class from which loggers can be implemented.
 *
 * Handles some of the boilerplate for filtering by emit level and converting types
 * into structured entries.
 */
export abstract class BaseLogger implements Logger {
    constructor(private readonly minLevel: LogLevel) {}

    /** Not usually emitted in release builds or production. */
    debug(loggable: Loggable): void {
        if (this.minLevel <= LogLevel.Debug) {
            this.logEntry(LogLevel.Debug, loggableToEntry(loggable), loggable);
        }
    }

    /** Normal but significant events, such as login, startup, shutdown, initialization, etc.
     *
     * Use sparingly.
     */
    info(loggable: Loggable): void {
        if (this.minLevel <= LogLevel.Info) {
            this.logEntry(LogLevel.Info, loggableToEntry(loggable), loggable);
        }
    }

    /** Something bad happened, but impact might be minimal */
    warn(loggable: Loggable): void {
        if (this.minLevel <= LogLevel.Warning) {
            this.logEntry(LogLevel.Warning, loggableToEntry(loggable), loggable);
        }
    }

    /** Something bad happened, with likely negative impact */
    error(loggable: Loggable): void {
        if (this.minLevel <= LogLevel.Error) {
            this.logEntry(LogLevel.Error, loggableToEntry(loggable), loggable);
        }
    }

    /** Something really bad happened
     *
     * On the server, this would usually be accompanied by crashing.
     */
    critical(loggable: Loggable): void {
        if (this.minLevel <= LogLevel.Critical) {
            this.logEntry(LogLevel.Critical, loggableToEntry(loggable), loggable);
        }
    }

    /** Actual implementation of logging in derived classes
     */
    protected abstract logEntry(level: LogLevel, entry: LogEntry, original: Loggable): void;
}

export function loggableToEntry(loggable: Loggable): LogEntry {
    if (isLogEntryConvertible(loggable)) {
        return loggable[toLogEntry]();
    } else if (loggable instanceof Error) {
        return {
            group: 'error',
            message: String(loggable),
            attrs: {
                'exception.type': loggable.constructor.name || loggable.name,
                'exception.message': loggable.message,
                'exception.stacktrace': loggable.stack,
                'exception.cause': loggable.cause ? String(loggable.cause) : undefined,
            },
            error: loggable,
        };
    } else {
        return loggable;
    }
}
