// Copyright 2024 Descript, Inc

export const properNouns = new Map<string, string>([
    ['descript', 'Descript'],
    ['studio sound', 'Studio Sound'],
    ['white glove', 'White Glove'],
    ['finder', 'Finder'],
    ['explorer', 'Explorer'],
    ['beamcoder', 'Beamcoder'],
    ['overdub', 'Overdub'],
    ['eye contact', 'Eye Contact'],
    ['giphy', 'GIPHY'],
    ['gif', 'GIF'],
    ['gifs', 'GIFs'],
    ['youtube', 'YouTube'],
    ['underlord', 'Underlord'],
]) as ReadonlyMap<string, string>;

// A regex pattern that will match on any of the proper nouns.
const properNounsRegex = Array.from(properNouns.keys())
    .map((phrase) => phrase.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) // Escape regex chars
    .join('|');

/**
 * A regex pattern that will match any of the proper nouns, but only if they are a full match.
 * In particular, something like 'description' should NOT match 'Descript'.
 */
export const fullWordsProperNounsRegex = new RegExp(`\\b(${properNounsRegex})\\b`, 'g');
