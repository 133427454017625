// Copyright 2023 Descript, Inc
import { sentenceCase } from '@descript/descript-core';

export type Workspace = Readonly<{
    id: string;
    name: string;
    type: WorkspaceType;
    driveId: string;
}>;

export enum WorkspaceNames {
    DriveWorkspace = 'Drive Workspace',
    PrivateWorkspace = 'Private Workspace',
    LegacyMyWorkspace = 'My Workspace',
}

export enum WorkspaceType {
    PERSONAL = 'personal',
    DRIVE = 'drive',
    RESTRICTED = 'restricted',
}

export function generateWorkspaceId(driveId: string, workspaceType: WorkspaceType) {
    return `${workspaceType.charAt(0)}-${driveId}`;
}

export function modifiedWorkspaceName<T extends WorkspaceNames | string | undefined>({
    workspaceName,
    driveName,
    isSentenceCase = false,
}: {
    workspaceName: T;
    driveName?: string;
    isSentenceCase?: boolean;
}): T | string {
    if (workspaceName === WorkspaceNames.LegacyMyWorkspace) {
        return isSentenceCase
            ? sentenceCase(WorkspaceNames.PrivateWorkspace)
            : WorkspaceNames.PrivateWorkspace;
    }
    if (workspaceName === WorkspaceNames.DriveWorkspace && driveName) {
        return isSentenceCase ? `${driveName} workspace` : `${driveName} Workspace`;
    }
    return isSentenceCase && workspaceName ? sentenceCase(workspaceName) : workspaceName;
}
