// Copyright © 2024 Descript, Inc. All rights reserved.

import { DiffContext } from 'jsondiffpatch';

/**
 * Arrays are expensive to diff using fast-meyers-diff if they don't have many items that are comparable.
 * fast-myers-diff is O(min(n,m)*d) where n,m are the lengths of the arrays and d is the number of differences.
 *
 * This filter should be run _before_ an array diff filter. If the overlap in comparable items is small,
 * this filter returns the whole array as an atomic change.
 */
export function expensiveDiffArrayFilter(context: DiffContext) {
    if (!Array.isArray(context.left) || !Array.isArray(context.right)) {
        return;
    }

    if (!context.options?.objectHash) {
        // eslint-disable-next-line no-console
        console.error('expensiveDiffArrayFilter requires objectHash');
        return;
    }

    const objectHash = context.options.objectHash;

    const shorterArray =
        context.left.length <= context.right.length ? context.left : context.right;
    const longerArray = shorterArray === context.left ? context.right : context.left;
    const shorterLen = shorterArray.length;
    const longerLen = longerArray.length;

    if (shorterLen === 0) {
        context.setResult([context.left, context.right]).exit();
        return;
    }

    // goal: >= 50% of the items are present in left and right
    let neededOverlaps = Math.ceil(shorterLen / 2);

    const longerIds = new Set<unknown>();
    for (let index = 0; index < longerLen; index++) {
        const item = longerArray[index];
        longerIds.add(typeof item === 'object' ? objectHash(item, index) : item);
    }

    for (let index = 0; index < shorterLen; index++) {
        if (neededOverlaps <= 0) {
            // we've found enough overlaps; continue trying to diff these arrays with other filters in the pipeline
            return;
        }
        const item = shorterArray[index];
        if (longerIds.has(typeof item === 'object' ? objectHash(item, index) : item)) {
            neededOverlaps--;
        }
    }

    // not worth diffing; mark the entire array as changed
    context.setResult([context.left, context.right]).exit();
}
expensiveDiffArrayFilter.filterName = 'expensiveArrayDiff';
