// Copyright 2024 Descript, Inc

import '@descript/client/src/css';
import '@descript/design-tokens/icons.css';

import { GlobalCSS } from '@daily/shared/src/components/GlobalCSS';
import * as ApiClient from '@descript/client/src/Api/ApiClient';
import { AppSettings } from '@descript/client/src/App/AppSettings';
import { webApiTargetUpdater } from '@descript/client/src/App/WebApiTargetUpdater';
import WebAppCookieSettings from '@descript/client/src/App/WebAppCookieSettings';
import WebAppLocalStorageSettings from '@descript/client/src/App/WebAppLocalStorageSettings';
import WebLogoutCallback from '@descript/client/src/App/WebLogoutCallback';
import getConfig from 'next/config';
import {
    installApiTargetUpdater,
    updateApiTargetFromUserSetting,
} from '@descript/client/src/Api/ApiTargetUpdater';
import { useEffect } from 'react';
import * as NUserSettings from '@descript/client/src/App/UserSettings';
import { setupAppAnalyticsLogging } from '@descript/client/src/Utilities/setupAppAnalyticsLogging';
import { setAnalyticsProperties } from '@descript/analytics';
import * as ApiTarget from '@descript/client/src/Api/ApiTarget';
import { DebugSettingsWeb } from '@descript/web-debug-settings';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // don't force data refresh when navigating back to tab
        },
    },
});

function PrebuiltApp({ Component, pageProps }) {
    const { assetPrefix } = getConfig().publicRuntimeConfig;
    useEffect(() => {
        const webAppLocalStorageSettings = new WebAppLocalStorageSettings();
        AppSettings.install(webAppLocalStorageSettings, WebAppCookieSettings); // Auth uses cookies to jump between web and web share, and because cookies are marginally safer than localstorage
        ApiTarget.loadUserSetting();
        ApiClient.installLogoutCallback(WebLogoutCallback);
        if (DescriptFeatures.DEV_TOOLS) {
            installApiTargetUpdater(webApiTargetUpdater);
            updateApiTargetFromUserSetting();
        }

        import('@lib/segment').catch((err) => {
            console.error('Failed to import segment', err);
        });
        setupAppAnalyticsLogging();
        setAnalyticsProperties({
            installId: NUserSettings.Application.installId.get(),
        });
        DebugSettingsWeb.initialize().catch((e) => {
            console.warn('Failed to initialize debug settings', e);
        });
    }, []);

    return (
        <>
            <GlobalCSS background="var(--body-bg)" fontsBasePath={`${assetPrefix}/fonts`} />
            <QueryClientProvider client={queryClient}>
                <Component {...pageProps} />
            </QueryClientProvider>
        </>
    );
}

export default PrebuiltApp;
