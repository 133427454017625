// Copyright 2024 Descript, Inc

import i18next from 'i18next';
import backend from 'i18next-http-backend';
import getConfig from 'next/config';
import { initReactI18next } from 'react-i18next';
import { ReactNull } from '@descript/react-utils';

import enTranslations from '../../public/locales/en/translation.json';

const { assetPrefix } = getConfig().publicRuntimeConfig;

i18next
    .use(backend)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        backend: {
            addPath: ReactNull,
            crossDomain: true,
            loadPath: `${assetPrefix}/locales/{{lng}}/{{ns}}.json`,
        },
        supportedLngs: [
            'de',
            'en',
            'es',
            'fi',
            'fr',
            'it',
            'jp',
            'ka',
            'nl',
            'no',
            'pl',
            'pt',
            'pt-BR',
            'ru',
            'sv',
            'tr',
        ],
        lng: 'en',
        fallbackLng: 'en', // use en if detected lng is not available

        saveMissing: false,

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
        react: {
            useSuspense: true,
        },
    })
    .catch((err) => {
        console.error('i18n error', err);
    });

i18next.addResourceBundle('en', 'translation', enTranslations);

i18next.services.formatter.add('lowercase', (value: string) => {
    return value.toLowerCase();
});

export default i18next;
