import { ConsoleFormatter, ConsoleLogger } from './ConsoleLogger';
import { Attributes, LogLevel } from './Logger';

// TODO: Consolidate this with the getDefaultLogger() in std-srv once we can take in attrs there
export function getDefaultConsoleLogger(attrs: Attributes = {}): ConsoleLogger {
    const minLevel = getLogLevel(process.env.LOG_LEVEL);

    let style: ConsoleFormatter = 'gcp';

    if (process.env.LOG_STYLE) {
        style = process.env.LOG_STYLE as ConsoleFormatter;
    } else if (process.env.CI) {
        style = 'full';
    } else if (process.stdout?.isTTY || process.env.NODE_ENV !== 'production') {
        style = 'minimal';
    }

    return new ConsoleLogger({ style, minLevel, attrs });
}

function getLogLevel(level: string | undefined): LogLevel {
    if (level === undefined) {
        return LogLevel.Error;
    }
    switch (level.toLowerCase()) {
        case 'debug':
            return LogLevel.Debug;
        case 'info':
            return LogLevel.Info;
        case 'warn':
        case 'warning':
            return LogLevel.Warning;
        case 'error':
            return LogLevel.Error;
        case 'fatal':
        case 'critical':
            return LogLevel.Critical;
        default:
            return LogLevel.Error;
    }
}
