// Copyright 2024 Descript, Inc

import { fastDeepEqual } from '@descript/fast-deep-equal';
import { LiveCollabUnsupportedDeltaError } from './errors';
import { Result } from './types';
import { DescriptError, ErrorCategory } from '@descript/errors';

export type PatchFn<T> = (base: T | undefined, deltaBody: string) => T;
export type DeltaApplicator = {
    name: string;
    apply: <DFmt extends string, T>(
        base: T | undefined,
        deltaFormat: DFmt,
        delta: unknown,
    ) => Result<T>;
};

export class MappingDeltaApplicator implements DeltaApplicator {
    public name: string;
    /**
     * @param decoderMap - map from delta format to an applicator for that format
     */
    constructor(private readonly applicators: Map<string, DeltaApplicator>) {
        this.name = `CombinedDeltaApplicator[${[...applicators.values()]
            .map((a) => a.name)
            .join(',')}]`;
    }

    apply<DFmt extends string, T>(
        base: T | undefined,
        deltaFormat: DFmt,
        delta: unknown,
    ): Result<T> {
        const applicator = this.applicators.get(deltaFormat);
        if (applicator === undefined) {
            throw new LiveCollabUnsupportedDeltaError(
                `Unsupported delta format: ${deltaFormat}`,
            );
        }
        return applicator.apply(base, deltaFormat, delta);
    }
}

/**
 * Creates a delta applicator that ensures that multiple applicators are returning an identical result
 */
export function makeComparingDeltaApplicator(applicators: DeltaApplicator[]): DeltaApplicator {
    if (applicators.length === 0) {
        throw new DescriptError(
            'makeComparingDeltaApplicator requires at least one applicator',
            ErrorCategory.LiveCollab,
        );
    }
    return {
        name: `ComparingDeltaApplicator[${applicators.map((a) => a.name).join(',')}]`,
        apply: <DFmt extends string, T>(
            base: T | undefined,
            deltaFormat: DFmt,
            delta: unknown,
        ) => {
            const results = applicators.map((a) => a.apply(base, deltaFormat, delta));
            const success = results[0]!.success;
            if (!results.every((r) => r.success === success)) {
                throw new DescriptError(
                    'ComparingDeltaApplicator: some applicators succeeded while others failed',
                    ErrorCategory.LiveCollab,
                );
            }
            const firstResult = results[0]!;
            for (let i = 1; i < results.length; i++) {
                if (!fastDeepEqual(firstResult, results[i])) {
                    throw new DescriptError(
                        'ComparingDeltaApplicator: applicators returned different results',
                        ErrorCategory.LiveCollab,
                    );
                }
            }
            return firstResult;
        },
    };
}
