// Copyright 2024 Descript, Inc
import { atom, useRecoilState } from 'recoil';
import { ReactNull } from '@descript/react-utils';

/**
 * Modal visibility states.
 * There are a couple of single-purpose modals whose visibility is controlled
 * via shared UI state.
 */
export type SettingsView = 'general' | 'audio' | 'video'; // | 'devices' | 'effects' | 'version';
const settingsViewState = atom<SettingsView | null>({
    key: 'settings-view',
    default: ReactNull,
});
/**
 * Used to control visibility and tabs for SettingsModal.
 */
export const useSettingsView = () => useRecoilState(settingsViewState);

const autoplayFailedModalState = atom<boolean>({
    key: 'autoplay-failed-modal',
    default: false,
});
/**
 * Used to control visibility of AutoPlayFailedModal.
 */
export const useAutoplayFailedModal = () => useRecoilState(autoplayFailedModalState);

const deviceInUseModalState = atom<boolean>({
    key: 'device-in-use-modal',
    default: false,
});
/**
 * Used to control visibility of DeviceInUseModal.
 */
export const useDeviceInUseModal = () => useRecoilState(deviceInUseModalState);

const deviceNotFoundModalState = atom<boolean>({
    key: 'device-not-found-modal',
    default: false,
});
/**
 * Used to control visibility of DeviceNotFoundModal.
 */
export const useDeviceNotFoundModal = () => useRecoilState(deviceNotFoundModalState);

const nameModalState = atom<boolean>({
    key: 'name-modal-state',
    default: false,
});
/**
 * Used to control visibility of NameModal.
 */
export const useNameModal = () => useRecoilState(nameModalState);

const startScreenshareModalState = atom<boolean>({
    key: 'start-screenshare-modal-state',
    default: false,
});
/**
 * Used to control visibility of StartScreenshareModal.
 */
export const useStartScreenshareModal = () => useRecoilState(startScreenshareModalState);

type ScreenshareError = 'blocked-by-os' | 'blocked-by-browser';

const screenshareErrorModalState = atom<ScreenshareError | null>({
    key: 'screenshare-error-modal-state',
    default: ReactNull,
});
/**
 * Used to control visibility of ScreenshareErrorModal.
 */
export const useScreenshareErrorModal = () => useRecoilState(screenshareErrorModalState);

const unblockPermissionsModalState = atom<boolean>({
    key: 'unblock-modal-state',
    default: false,
});
/**
 * Used to control visibility of UnblockPermissionsModal.
 */
export const useUnblockPermissionsModal = () => useRecoilState(unblockPermissionsModalState);

const muteAllModalState = atom<boolean>({
    key: 'mute-all-modal-state',
    default: false,
});
/**
 * Used to control visibility of MuteAllModal.
 */
export const useMuteAllModal = () => useRecoilState(muteAllModalState);

const appsModalState = atom<boolean>({
    key: 'apps-modal-state',
    default: false,
});
/**
 * Used to control visibility of AppsModal.
 */
export const useAppsModal = () => useRecoilState(appsModalState);

const uploadingModalState = atom<boolean>({
    key: 'uploading-modal-state',
    default: false,
});
/**
 * Used to control visibility of UploadingModal.
 */
export const useUploadingModal = () => useRecoilState(uploadingModalState);
