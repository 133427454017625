// Copyright 2024 Descript, Inc

import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import PubNub from 'pubnub';
import { CallSessionData, useCallState } from '@contexts/CallProvider';
import { useMeetingSessionData } from '@features/breakouts/hooks/useMeetingSessionData';
import { ReactNull } from '@descript/react-utils';

interface ContextValue {
    isReady: boolean;
    initPubNub(
        userId: string,
        publishKey: string,
        subscribeKey: string,
        isOwner: boolean,
    ): void;
    addListeners(listeners: PubNub.ListenerParameters): void;
    removeListeners(listeners: PubNub.ListenerParameters): void;
}

const PubNubContext = createContext<ContextValue>({
    isReady: false,
    initPubNub: () => void 0,
    addListeners: () => void 0,
    removeListeners: () => void 0,
});

export function PubNubProvider({ children }: React.PropsWithChildren<unknown>) {
    const [isOwner, setIsOwner] = useState(false);
    const { sessionData } = useMeetingSessionData<CallSessionData>();
    const [isReady, setIsReady] = useState(false);
    const [pubNubInstance, setPubNubInstance] = useState<PubNub | null>(ReactNull);
    const { driveId } = useCallState();

    const initPubNub = useCallback(
        (userId: string, publishKey: string, subscribeKey: string, owner: boolean) => {
            setIsOwner(owner);

            setPubNubInstance((prevInstance) => {
                if (prevInstance) {
                    prevInstance.stop();
                }
                const pubNub = new PubNub({
                    publishKey,
                    subscribeKey,
                    uuid: userId,
                });

                return pubNub;
            });
            setIsReady(true);
        },
        [],
    );

    const addListeners = useCallback(
        (listeners: PubNub.ListenerParameters) => {
            if (pubNubInstance) {
                pubNubInstance.addListener(listeners);
            }
        },
        [pubNubInstance],
    );

    const removeListeners = useCallback(
        (listeners: PubNub.ListenerParameters) => {
            if (pubNubInstance) {
                pubNubInstance.removeListener(listeners);
            }
        },
        [pubNubInstance],
    );

    useEffect(() => {
        if (pubNubInstance) {
            pubNubInstance.unsubscribeAll();
        }

        if (isOwner && pubNubInstance) {
            const projectId = sessionData?.cs?.projectId;
            const driveChannel = `drive.${driveId}`;

            const channels = [driveChannel];

            // project id is only available after recording has begun
            if (projectId) {
                const projectChannel = `project.${projectId}`;
                channels.push(projectChannel);
            }

            pubNubInstance.subscribe({ channels });
        }
    }, [driveId, isOwner, pubNubInstance, sessionData?.cs?.projectId]);

    useEffect(() => () => pubNubInstance?.unsubscribeAll(), [pubNubInstance]);

    return (
        <PubNubContext.Provider
            value={{
                isReady,
                initPubNub,
                addListeners,
                removeListeners,
            }}
        >
            {children}
        </PubNubContext.Provider>
    );
}

export const usePubNub = () => useContext(PubNubContext);
