// Copyright 2024 Descript, Inc

import React, { useEffect, useMemo, useCallback, useRef, useState } from 'react';
import { Text } from '@daily/shared/src/components/Text';

import { useStyleVariants } from '@hooks/useStyleVariants';
import { robotsClassName } from '@lib/robots';
import { Button } from '@daily/shared/src/components/Button';
import { Tooltip } from '@daily/shared/src/components/Tooltip';
import { useResizeObserver } from '@daily/shared/src/hooks/useResizeObserver';
import { useTheme } from '@daily/shared/src/contexts/Theme';
import classNames from 'classnames';
import { ReactNull } from '@descript/react-utils';
import { Avatar } from '@features/chat/components/Avatar';
import { AlertTriangleIcon, CheckIcon } from '@descript/design-tokens';
import { ParticipantUploadState } from '../types/uploadProgress';

interface Props {
    uploadState: ParticipantUploadState;
    showUploadState?: boolean;
}

export function UploadingParticipantRow({ showUploadState = true, uploadState }: Props) {
    const { textVariant } = useStyleVariants();
    const [isTruncated, setIsTruncated] = useState(false);
    const nameRef = useRef<HTMLDivElement>(ReactNull);
    const { colors } = useTheme();
    const [buttonText, setButtonText] = useState('Copy recovery link');

    // const timeRemaining = useMemo(() => {
    //     const seconds = Math.floor((uploadState.estRemaining ?? 0) / 1000);
    //     const minutes = Math.floor(seconds / 60);
    //     const remainingSeconds = seconds % 60;

    //     let timeString = '';
    //     if (minutes > 0) {
    //         timeString += `${minutes}m `;
    //     }
    //     if (remainingSeconds > 0) {
    //         timeString += `${remainingSeconds}s`;
    //     }

    //     return timeString.trim();
    // }, [uploadState.estRemaining]);

    // Normalize the percent to 0-99 if status isnt 'complete'
    // since we dont count something as '100% done' until the artifact is committed
    const percent = useMemo(() => {
        if (!showUploadState) {
            return 0;
        }

        if (uploadState.status === 'complete') {
            return 100;
        }

        return Math.floor(((uploadState.percent ?? 0) / 100) * 99);
    }, [uploadState, showUploadState]);

    useResizeObserver(
        nameRef,
        useCallback(() => {
            if (!nameRef.current) {
                return;
            }
            const p = nameRef.current.querySelector('p');
            // eslint-disable-next-line @descript-eslint/no-force-reflow
            setIsTruncated(p?.scrollWidth > p?.clientWidth);
        }, [setIsTruncated]),
    );

    const handleCopyRecoveryLink = useCallback(() => {
        // copy the current url to the clipboard
        navigator.clipboard.writeText(window.location.origin + '/room').catch((e) => {
            console.error(e);
        });
        setButtonText('Copied!');
    }, [setButtonText]);

    useEffect(() => {
        let to: NodeJS.Timeout;
        if (buttonText === 'Copied!') {
            to = setTimeout(() => setButtonText('Copy recovery link'), 2000);
        }

        return () => clearTimeout(to);
    }, [buttonText]);

    return (
        <div className="uploading-participant-row">
            <div style={{ width: '100%', display: 'flex', gap: 8, alignItems: 'center' }}>
                <Avatar name={uploadState.name} userId={uploadState.id} />
                <Tooltip
                    disabled={!isTruncated}
                    id={`tt-${uploadState.id}`}
                    title={uploadState.name}
                    toggleStyle={{ overflow: 'auto' }}
                >
                    <div ref={nameRef} className={classNames('participant-name-container')}>
                        <Text
                            className={classNames(robotsClassName('display-name'))}
                            truncate
                            variant={textVariant}
                            color="muted"
                        >
                            {uploadState.name}
                        </Text>
                    </div>
                </Tooltip>
                <div
                    className="actions"
                    style={{ display: 'flex', gap: '4px', color: 'var(--text-muted)' }}
                >
                    {showUploadState && uploadState.status === 'complete' ? (
                        <CheckIcon color="var(--green-500)" />
                    ) : showUploadState && uploadState.status === 'stalled' ? (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8,
                            }}
                        >
                            <AlertTriangleIcon color="var(--red-800)" />
                            <Text style={{ color: 'var(--red-800)' }}>
                                High quality upload stalled
                            </Text>
                        </div>
                    ) : showUploadState ? (
                        `${percent}%`
                    ) : (
                        ReactNull
                    )}
                </div>
            </div>
            {uploadState.status === 'stalled' && (
                <Text color="muted">
                    Share this recovery link with your guest, and have them open it to resume
                    uploading their high quality files (valid for 7 days). A backup recording
                    has been added to your files
                </Text>
            )}
            {uploadState.status === 'stalled' && (
                <Button
                    variant="primary"
                    style={{
                        backgroundColor: colors.custom.descriptGrey800,
                        color: colors.custom.descriptGrey0,
                        width: '100%',
                        display: 'block',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: '4px',
                    }}
                    onClick={handleCopyRecoveryLink}
                >
                    {buttonText}
                </Button>
            )}
            <div className="loading-bar-background" />
            <div className="loading-bar" style={{ width: `${percent}%` }} />

            <style jsx>{`
                .participant-name-container {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .uploading-participant-row {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    background: var(--body-bg);
                    padding: 8px;
                    border-radius: 8px;
                    border: 1px solid
                        ${uploadState.status === 'stalled'
                            ? 'var(--red-200)'
                            : colors.custom.descriptGrey100};
                    min-height: 56px;
                    width: ${uploadState.status === 'stalled' ? 'calc(100% - 4px)' : '100%'};
                    position: relative;
                    overflow: hidden;
                    ${uploadState.status === 'stalled'
                        ? `box-shadow: 0 0 0 2px rgba(255,0,0,0.25);
                        margin: 2px;`
                        : ''}
                }
                .actions {
                    flex: none;
                    margin-left: auto;
                }
                .loading-bar-background {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 4px;
                    background-color: ${colors.custom.descriptGrey5};
                    width: 100%;
                }
                .loading-bar {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 4px;
                    background-color: ${uploadState.status === 'complete'
                        ? 'var(--green-500)'
                        : '#cccccc'};
                    width: 0;
                    transition: width 1s ease-in-out;
                    opacity: ${uploadState.status === 'complete' ? '0.22' : '1'};
                }
            `}</style>
        </div>
    );
}
