// Copyright 2024 Descript, Inc

import { FeatureFlagName, FeatureFlagValue } from './types';

/**
 * Feature flag values for e2e test account as of 2024-03-01
 * Retrieved by logging into the e2e test account and running
 * JSON.parse(localStorage.getItem('Application.featureFlags'))[JSON.parse(localStorage.getItem('Application.currentUserId'))]
 *
 * Then:
 * - deleted some that were not used in the codebase (linter shows these)
 * - added some which are set as `undefined`, (linter shows these)
 *
 * Some of these are referring to project ids, which are definitely incorrect.
 */
export const E2E_FEATURE_FLAG_VALUES = Object.freeze<Record<FeatureFlagName, FeatureFlagValue>>(
    {
        'web-app-target-picker': false,
        'asset-upload-networking': 'fetch',
        'automatic-project-backups': true,
        'web-automatic-project-backups': false,
        'conductor-sidebar-name': 'Conductor',
        'conductor-sidebar-name-override': '',
        'sidebar-template-pane': true,
        braze: true,
        'braze-content-cards': true,
        'braze-in-app-messaging': true,
        'child-process-canary-interval-ms': 600000,
        'child-process-canary-timeout-ms': 15000,
        'classic-speakers-submenu': true,
        'client-event-logging': true,
        'client-new-revert-logic': true,
        'cloud-provider-giphy': true,
        'correction-wizard': true,
        'draftjs-global-dom-selection': true,
        'duplicate-qem-projects-to-lc': true,
        'edu-nfp-plan-available': false,
        'edit-for-clarity-ai-speech-param': false,
        'edit-for-clarity-with-overdub': false,
        'edit-for-clarity-intensities': undefined,
        'ai-actions-new-edit-review-state': false,
        'global-asset-sync': 'new-assets-single-write',
        'json-upload-cache': 'off',
        'l10n-beta-tags-transcription': 'el,hi',
        'l10n-languages-transcription':
            'ar,bg,ca,cmn,cs,da,de,el,es,fi,fr,hi,hr,hu,it,ja,ko,lv,lt,ms,no,nl,pl,pt,ro,ru,sk,sl,sv,tr',
        'learning-rail': true,
        'live-collab-commit-poll-period-seconds': 30,
        'live-collab-copy-local-commits-on-duplicate': false,
        'live-collab-cursor-in-doc': false,
        'live-collab-doc-hashing-algorithm': 'NONE',
        'live-collab-in-new-storyboard-project': true,
        'live-collab-only-main-commits': false,
        'live-collab-restore-history': true,
        'live-collab-throttle-period-ms': 100,
        'live-collab-sparse-mode': false,
        'live-collab-worker': false,
        'live-collab-disallow-lower-version-doc-update': false,
        'live-collab-sync-doc-update': false,
        'live-collab-use-redux-state-for-base-ref': false,
        'live-collab-echoing-commits-branch-fix': false,
        'remove-drive-creation-from-bearer-function': false,
        'remove-drive-creation-from-bearer-function-invite-link-only': false,
        'manage-auth-identities': true,
        'manual-proxy-generation-video': true,
        'media-lib-load-doc-autosave': true,
        'monthly-proration-on-immediate-upgrades': true,
        'multitrack-script': false,
        multiwindow: true,
        'offline-storage-location': true,
        'otel-redux-trace': true,
        'onboarding-checklist': true,
        'overdub-promote-voice-model': '7f8bc1a6-33fa-48e5-99b9-bb48f7b70daf|Overdub 3.0',
        'overdub-training-project-uses-live-collab': true,
        'overdub-regenerate-prefix-suffix-duration': 2,
        'properties-panel-slip': true,
        'publish-captivate-fm-integration': true,
        'publish-castos-integration': true,
        'publish-ewebinar-integration': true,
        'publish-hello-audio-integration': true,
        'publish-podbean-integration': true,
        'publish-blubrry-integration': true,
        'publish-podcast-co-integration': true,
        'publish-restream-integration': true,
        'publish-transistor-integration': true,
        'publish-video-ask-integration': true,
        'publish-wistia-integration': true,
        'quick-recorder-record-and-edit': true,
        'quick-recorder-version': 2,
        'redesign-drive-view-storyboard': true,
        'redux-connect-impl': 'deep-equality',
        'redux-actions-batching-type': 'tick',
        'redux-actions-throttle': false,
        'replace-script-track': true,
        'reverse-trials': true,
        'translation-bulk-generate': false,
        'translation-bulk-export': true,
        'automatic-reverse-trials-for-new-free-drives': false,
        'allow-reverse-trials-for-existing-free-drives': true,
        'storyboard-new-project-checkbox': true,
        'storyboard-demo-project-id': '53d4ce72-cac8-4e52-818d-51607903c758',
        'stripe-customer-portal': true,
        'subscription-modal-default-to-annual': false,
        'voice-id-browser-recorder': 'none',
        'zendesk-chat': false,
        'zendesk-live-chat-access-level': 'descript_creator',
        'zendesk-live-chat-feature-tier': 1,
        'edit-in-descript': true,
        'tts-release-channel': 'prod',
        'legacy-browser-new-template-button': true,
        'cloud-library-safe-search': true,
        'scene-background-color': true,
        'transcription-window-minutes': true,
        'transcription-window-minutes-short': false,
        'demo-to-pip-url-slug': {
            '2b7ffc1d-0668-4a30-9f3f-79dfce3b2180': 'LQ1jnsrPLza',
            'b593f9e3-8c9f-4fa1-aa4b-727dfa789843': '9UOzkez84KT',
            '89ac0f9c-fb1c-40d7-9b4d-65d85eaddad0': 'G7fxio6RML5',
            '6f2db40f-d498-4f06-8695-ecfc5c443770': 'L35bGnlaRWR',
            'ce8f0f65-ae2a-4813-86c5-78a4a542f3a9': 'xzxS4GVKxXT',
            'b5e986fd-f924-46d8-8d53-ed1ad4c74113': '7L8NL449SNg',
            'bdb99911-589b-4432-8ff9-9d010135b6b0': 'C1AQLxfdcb9',
            'f80b4e4c-ed9f-4e19-9a0a-ab2e8b0c33f2': 'VIqQB2Nv4MK',
            'fd48b040-db31-48b1-b500-e8bf547f626c': 'HZUEu2NtDna',
            '2209abd5-b61c-4d25-bdc3-0b2778bb965b': 'iW1nDXni4X4',
            '7de0f9bb-22b3-4602-ae6e-88cefaaae1d0': 'LQ1jnsrPLza',
            '1bdcba34-d212-424d-a628-c066833b4ac3': '0qbMxlNHw69',
            '1828e453-aaf1-47f7-b5a7-be562e18e754': '5ECXOF9743G',
            '3ba101e4-94ae-4168-9759-bcd3326ad928': 'ro3R9cKjSRA',
            '098f9330-2ec4-4c7d-beca-6905730f490a': 'p7Z4rBL0aMu',
            'eace1c49-f37a-4b2e-9c40-a42c9aac3092': 'XMa08ofEpUa',
            '1fbdffd3-b859-4e5a-ae1a-a2bf7d42c69c': 'LQ1jnsrPLza',
            '70fb9021-96bd-46e5-b128-ebf2ad0386cb': 'IzxdHRW19zo',
            '2b1fda94-b073-4424-bf43-1f7132fd7a45': 'VIqQB2Nv4MK',
            '07c2674b-75d6-4048-9617-b4461eddf921': 'zQvkduwdkMI',
            'fe176e54-2f85-4d48-aa3e-f4570d9cf360': 'XMa08ofEpUa',
            '335be2cb-a59a-4c4e-807d-50d263953079': 'IzxdHRW19zo',
            'ffd61be0-9d57-41ee-9e17-e84bd41cd167': 'RZE2fDxvMvU',
            '97998828-9651-4a59-88c4-6c5e7f8b24c2': 'Dres6FbaxLf',
            '1a9fd65d-008f-4fe0-a827-97b713294ce5': 'd1myNcGW20L',
            '53d4ce72-cac8-4e52-818d-51607903c758': '8ToYxGSxE5c',
            '07bcb559-9a13-48bf-802a-2eec50c5f1b0': 'D5XkizzSZ2B',
            'edf02a17-2fae-4383-baee-eed0520a304a': 'zQvkduwdkMI',
            'd9fceb06-6302-463d-9c3b-4e21deadeba2': 'Dres6FbaxLf',
            '0dbe0552-6bdd-4318-9d34-00e1d7a63241': 'd1myNcGW20L',
            '1a81ef10-0eac-4c2e-85b3-8bd877680138': '7L8NL449SNg',
            'e583e922-fc12-49d8-ad80-90a54f7fc9c0': 'fjYKd9wN5G5',
            '0e78b647-46ec-46a7-902b-d839ac4c883a': 'HZUEu2NtDna',
            '97f689ee-4522-4344-896e-bcfbfc1264a8': 'jPQJ9vji7TB',
            '82b08227-e6cd-47de-a2e7-f0a6b3a6ec76': 'ulmVgDGQr4e',
            'cb3fe25e-f032-405c-8c85-a1d2c857c986': 'CyakXC8RqQ3',
            '5fbc5164-cb08-4c4b-9adb-a99142f81174': 'fjYKd9wN5G5',
            '90fd8316-c11a-45c8-803a-cc55f1310d4a': 'fjYKd9wN5G5',
            '80fbd2cf-569b-4eff-aa3e-81be54957b89': 'L35bGnlaRWR',
            'b2d5f580-1ed6-487f-bf81-696e29352285': '9UOzkez84KT',
        },
        'pip-embed-params': {
            hideCta: 1,
            pauseAtNextMarker: 1,
            autoplay: 1,
            controlSpacer: 1,
        },
        'settings-redesign': true,
        'fast-export-honor-client-watermark-request': true,
        'failed-payment-banner': true,
        'storyboard-upgrade-banner': true,
        'storyboard-banner-message':
            "Want to try Descript's latest features? *Copy this project to the new Descript.*",
        'create-classic-project': true,
        'drive-conductor': true,
        'proxy-keyframe-interval-threshold-ms': 3000,
        'media-engine-new-fps-calculation': true,
        'word-gap-over-text-cursor': false,
        'duplicate-normal-project-as-new-template': false,
        'sequence-track-include-transcript-toggle': true,
        'timeline-tools-redesign': false,
        'email-subscription-groups': true,
        'close-qem-on-publish': true,
        'dall-e': true,
        'dall-e-variations': true,
        'use-canvas-state-machine': true,
        'eye-contact': false,
        'export-banner': true,
        'uvm-voices': true,
        'use-cloud-aligner': false,
        'smarter-drive-default': true,
        'use-msft-win-camera-api': true,
        'remove-live-transcription-visualization': true,
        'squadcast-entry-points': true,
        'timeline-zoomable-scrollbar': true,
        'wordless-media-ellipses': true,
        'wordless-media-ellipses-kill-switch': true,
        'wordless-media-allow-correct': true,
        'video-overdub-layer': true,
        'direct-to-web': false,
        'you-tube-publish-on-web': true,
        'plus-insert-button': true,
        'ia-refactor': 'control',
        'ai-speech-autogen-debounce-ms': 10000,
        'screen-capture-kit-audio': false,
        'eye-contact-in-prod': false,
        'include-at-sign-in-speaker-label': false,
        'uvm-stock-speakers': true,
        'ai-speech-controls': false,
        'ai-speech-controls-kill-switch': false,
        'bulk-actions': true,
        'bulk-improvements': true,
        'move-popover-starred-folders': true,
        'bulk-delete': false,
        'disable-white-glove': true,
        'query-param-allowlist': 'ia-refactor',
        'new-canvas-toolbars': true,
        'insert-from-the-script-default': false,
        'insert-from-the-script-global-override': '',
        'independent-rounded-corners': true,
        'multi-drive-admin': true,
        'labs-settings': true,
        'live-collab-write-commits-buffer': true,
        'timeline-redesign-default': true,
        'timeline-redesign-global-override': '',
        'export-use-ffmpeg-compositor': true,
        'web-media-local-export': true,
        'hide-card-rail-default': false,
        'bottom-card-rail-default': false,
        'bottom-card-rail-eligible': false,
        'bottom-card-rail-override': false,
        'hide-card-rail-global-override': '',
        'new-media-range-picker-default': false,
        'new-media-range-picker-global-override': '',
        'new-speaker-merge-ux': true,
        'timeline-redesign-labs-eligible': true,
        'timeline-script-layers': false,
        'new-project-privacy': true,
        'regenerate-release-channel': 'prod',
        'hide-card-rail-eligible': true,
        'new-media-range-picker-eligible': true,
        'publish-privacy-drive-settings': false,
        'automatic-speaker-detection': false,
        'ed-workflow-save-layout-flow': false,
        'windows-quick-progressive-upload': 2,
        'restore-removed-media-toolbar-button': true,
        'newline-button-row': true,
        'new-file-lifecycle-ui': false,
        'publish-to-google-drive': false,
        'transcription-in-supertoast': false,
        'recorder-allow-access-to-sckit-error-object': false,
        'file-lifecycle-media-library': false,
        'ed-workflow-speaker-focus': true,
        'template-browser-redesign': false,
        'empty-project-starter-state': 'control',
        'desktop-supertoast': false,
        'recorder-zoom-recordings-import': true,
        'prefetch-media-on-timeline-hover': 0,
        'prefetch-media-on-script-hover': 0,
        'acceleration-aware-prefetch': false,
        'hedra-avatar-prototype': false,
        'mts-ai-effects': [],
        'max-gop-file-warning': 300,
        'default-clip-video-template': '',
        'default-multicam-template': '',
        'default-template-browser': '',
        'web-enable-live-tx': false,
        'web-export-multi-scope': true,
        'shorten-word-gap-remove-filler-words-paywall': true,
        'web-recorder-rainbow-audio-bars-easter-egg': true,
        'web-max-file-upload-size-gb': 5,
        'transcription-auto-detect-exp': 'not-in-experiment',
        'client-make-web-proxy': true,
        'magic-multicam': false,
        'automatic-multicam-template-param': true,
        'window-dressing-action': false,
        'generate-visuals-action': false,
        'clips-model-experiment-variant': 'v3-gpt',
        'timeline-computation-on-worker': true,
        'collaborative-recorder': false,
        'web-flatten-tracks': false,
        'fetch-paywall-usage-debounce-time': 0,
        'dynamic-payment-methods': false,
        'exp-alternate-payment-methods': undefined,
        'exp-web-checkout': 'control-not-exp',
        'web-timeline-export': false,
        'skip-artifact-md5': false,
        'video-regenerate-default': false,
        'video-regenerate-eligible': false,
        'video-regenerate-global-override': '',
        'zoom-auto-import': false,
        'zoom-show-browse-page-card': false,
        'ai-usage-dashboard': true,
        'ai-usage-dashboard-admin-view': true,
        'web-mic-bleed-detection': false,
        'exp-show-recorder-by-descript-onboarding': 'not-in-experiment',
        'drive-view-standalone-recorder-options': 'control',
        'onboarding-routes': 'not-in-experiment',
        'exp-simplify-onboarding-3.1': 'true-control',
        'exp-start-trial-url': 'true-control',
        'start-trial-from-allow-list': 'true-control',
        'exp-onboarding-plans-page-update': 'not-in-experiment',
        'deprecate-uvm-stock-voices': false,
        'exp-studio-sound-default-intensity': 'not-in-experiment',
        'ed-smart-media-panel-defaults': false,
        'live-collab-presence': false,
        'regenerate-toolbar-and-overdub-rename-default': false,
        'regenerate-toolbar-and-overdub-rename-eligible': false,
        'regenerate-toolbar-and-overdub-rename-global-override': '',
        'workflows-save-dimensions-of-template-card': false,
        'ed-classic-deprecation-soon-dialog': false,
        'ed-classic-deprecated-now-dialog': false,
        'editor-smart-transitions': false,
        'editor-smart-transitions-eligible': false,
        'editor-smart-transitions-override': '',
        'enable-forethought-chatbot': false,
        'youtube-publish-category-field': false,
        'exp-onboarding-over-editor': 'not-in-experiment',
        'exp-workflows-layouts-2.1-release': 'excluded-from-experiment',
        'ed-replacemedia-button-on-canvas-toolbar': true,
        'rooms-host-device-controls': false,
        'desktop-2-upgrade-button': false,
        'ed-sceneboundary-click-layout-picker': false,
        'ed-new-textselection-toolbar-oct2024': false,
        'workflows-group-by-composition-name': false,
        'workflows-favorite-layouts': false,
        'desktop-2-auto-upgrade': 'off',
        'rooms-billing': false,
        'multilingual-aligner-for-tts-enabled': false,
        'ed-layout-picker-script-shortcut-key': false,
        'rooms-enable-firefox': false,
        'rooms-enable-safari': false,
        'exp-deemphasize-free-plan-in-onboarding': 'excluded-from-experiment',
        'ed-copy-paste-split-card': false,
        'erw-lossless-audio-allowed': false,
        'blur-background': false,
        'web-recorder-fps-analytics': false,
        'recorder-support-dynamic-resolution': false,
        'quick-recorder-enable-live-tx': false,
        'ed-remove-frame-snapping': false,
        'erw-enable-live-tx': false,
        'exp-preview-descript-watermark': 'excluded-from-experiment',
        'exp-hide-braze-in-product-surfaces': 'excluded-from-experiment',
        'volume-as-percentage': false,
        'exp-pricing-experiment-monthly-increase-by-25': 'excluded-from-experiment',
        'web-recorder-audio-tracking-analytics': false,
        'exp-free-drive-dropdown-upgrade-upsell': 'excluded-from-experiment',
        'web-storage-monitor': false,
        'project-prompt-modal': true,
        'exp-recorder-video-bitrate': 'excluded-from-experiment',
        'exp-rebrand-publish-tab-its-all-export': 'excluded-from-experiment',
        'ed-effects-amins-transitions-in-toolbars': false,
        'copy-paste-quality-refactor': false,
        'image-style-prompts': {},
        'exp-pricing-experiment-monthly-increase-by-equal-amounts': 'excluded-from-experiment',
        // AUTOMATED_FEATURE_FLAG_CREATION
        'file-upload-paywall-enabled': true,
        'identify-user-from-backend': false,
        'learn-descript-v2-sbrm': {},
        'exp-churn-variants': 'not-in-experiment',
        'exp-churn-key-preview-flag': false,
        'gallery-template-categories': {},
        'workflows-placeholder-layers': true,
        'web-file-manipulation': false,
        'rounded-text-bg': true,
        'underlord-recommendation-editor': false,
        'speech-enhancement-version': 'v1',
        'workflows-auto-text-bounding-box': false,
        'exp-upselling-annual-subscriptions': 'percentage_not_in_exp',
        'workflows-text-bounding-box-background': false,
        'exp-enforce-storage-limits': 'excluded-from-experiment',
        'live-collab-track-redux-trimerge-refs': false,
        'exp-trials-main': 'not-in-experiment',
        'exp-enable-alternate-payment-methods-in-web-checkout-for-onboarding': false,
        'exp-churnkey-kill-switch': false,
        'disable-tts-instead-of-gibberish': false,
        'exp-churnkey-paywall-type': 'soft',
        'exp-churnkey-check-pause-wall': false,
        'eleven-labs-multilingual-tts': true,
        'eleven-labs-for-stock-speaker-dub': true,
        'eleven-labs-for-voice-clones': true,
        'live-collab-merge-strategy-override': undefined,
        'script-tokens': false,
    },
);
