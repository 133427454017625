// Copyright 2024 Descript, Inc

import { diff_core } from 'fast-myers-diff';
import { Context, DiffContext } from 'jsondiffpatch';

function matchItems<T>(
    array1: readonly T[],
    array2: readonly T[],
    index1: number,
    index2: number,
    objectHash: (obj: unknown, index: number) => unknown,
) {
    const value1 = array1[index1];
    const value2 = array2[index2];
    if (value1 === value2) {
        return true;
    }
    if (typeof value1 !== 'object' || typeof value2 !== 'object') {
        return false;
    }
    return objectHash(value1, index1) === objectHash(value2, index2);
}

export function fastMyersDiffArrayFilter(context: DiffContext) {
    function makeDiffContext(left: unknown, right: unknown): DiffContext {
        // hack because jsondiffpatch doesn't export Context/DiffContext classes
        // https://github.com/benjamine/jsondiffpatch/issues/285
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        return new context.constructor(left, right);
    }

    if (!Array.isArray(context.left) || !Array.isArray(context.right)) {
        return;
    }

    if (!context.options?.objectHash) {
        // eslint-disable-next-line no-console
        console.error('fastMyersDiffArrayFilter requires objectHash');
        return;
    }
    if (context.options?.arrays?.detectMove) {
        // eslint-disable-next-line no-console
        console.warn('fastMyersDiffArrayFilter does not implement detectMove');
    }

    const objectHash = context.options.objectHash;

    let commonHead = 0;
    let commonTail = 0;
    let index = 0;
    let index1 = 0;
    let index2 = 0;
    const array1 = context.left;
    const array2 = context.right;
    const len1 = array1.length;
    const len2 = array2.length;

    let child: DiffContext | undefined;

    // separate common head
    while (
        commonHead < len1 &&
        commonHead < len2 &&
        matchItems(array1, array2, commonHead, commonHead, objectHash)
    ) {
        index = commonHead;
        child = makeDiffContext(context.left[index], context.right[index]);
        (context as Context).push(child, index);
        commonHead++;
    }
    // separate common tail
    while (
        commonTail + commonHead < len1 &&
        commonTail + commonHead < len2 &&
        matchItems(array1, array2, len1 - 1 - commonTail, len2 - 1 - commonTail, objectHash)
    ) {
        index1 = len1 - 1 - commonTail;
        index2 = len2 - 1 - commonTail;
        child = makeDiffContext(context.left[index1], context.right[index2]);
        context.push(child, index2);
        commonTail++;
    }
    let result: Record<string | number, unknown> | undefined;
    if (commonHead + commonTail === len1) {
        if (len1 === len2) {
            // arrays are identical
            context.setResult(undefined).exit();
            return;
        }
        // trivial case, a block (1 or more consecutive items) was added
        result = result ?? {
            _t: 'a',
        };
        for (index = commonHead; index < len2 - commonTail; index++) {
            result[index] = [array2[index]];
        }
        context.setResult(result).exit();
        return;
    }
    if (commonHead + commonTail === len2) {
        // trivial case, a block (1 or more consecutive items) was removed
        result = result ?? {
            _t: 'a',
        };
        for (index = commonHead; index < len1 - commonTail; index++) {
            result['_' + index] = [array1[index], 0, 0];
        }
        context.setResult(result).exit();
        return;
    }

    result = result ?? {
        _t: 'a',
    };

    let lastAEnd = commonHead;
    let lastBEnd = commonHead;
    for (const [aStart, aEnd, bStart, bEnd] of diff_core(
        commonHead,
        len1 - commonTail - commonHead,
        commonHead,
        len2 - commonTail - commonHead,
        (i, j) => matchItems(array1, array2, i, j, objectHash),
    )) {
        for (index = aStart; index < aEnd; index++) {
            // removed
            result['_' + index] = [array1[index], 0, 0];
        }

        for (index = bStart; index < bEnd; index++) {
            // added
            result[index] = [array2[index]];
        }

        for (index = lastAEnd; index < aStart; index++) {
            // match, do inner diff
            index1 = index;
            index2 = index - lastAEnd + lastBEnd;
            child = makeDiffContext(context.left[index1], context.right[index2]);
            context.push(child, index2);
        }

        lastAEnd = aEnd;
        lastBEnd = bEnd;
    }

    for (index = lastAEnd; index < len1 - commonTail; index++) {
        // match, do inner diff
        index1 = index;
        index2 = index - lastAEnd + lastBEnd;
        child = makeDiffContext(context.left[index1], context.right[index2]);
        context.push(child, index2);
    }

    context.setResult(result).exit();
}
fastMyersDiffArrayFilter.filterName = 'fastMyersDiff';
