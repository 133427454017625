import { create, DiffPatcher } from 'jsondiffpatch';

let jsonDiffPatch: DiffPatcher | undefined;
let jsonDiffPatch_naiveStringDiff: DiffPatcher | undefined;

// make sure that array object comparisons use ids if available.
function objectHash(obj: unknown, index: number) {
    // eslint-disable-next-line no-null/no-null
    if (typeof obj === 'object' && obj !== null && 'id' in obj) {
        return (obj as { id: string }).id;
    }
    return `$$index:${index}`;
}

/** Exported for testing. */
export function setJsonDiffPatch(newJsonDiffPatch: DiffPatcher) {
    jsonDiffPatch = newJsonDiffPatch;
}

export function getJsonDiffPatch() {
    if (!jsonDiffPatch) {
        jsonDiffPatch = create({
            textDiff: { minLength: 20 },
            objectHash,
        });
    }
    return jsonDiffPatch;
}

/** The jsonDiffPatch returned by this function does not attempt to do character-level diffing.
 *  It should only be used in the case that the above differ fails to diff two documents
 *  (e.g. in the case of a single emoji surrogate pair which causes URI encode to fail) */
export function getJsonDiffPatch_naiveStringDiff() {
    if (!jsonDiffPatch_naiveStringDiff) {
        jsonDiffPatch_naiveStringDiff = create({
            /** If we don't supply a minLength, JsonDiffPatch uses 60 by default. */
            textDiff: { minLength: Infinity },
            objectHash,
        });
    }
    return jsonDiffPatch_naiveStringDiff;
}
