// Copyright 2023 Descript, Inc

import { JSONObject } from '../json';

/** Interface for a system to log messages for debugging and operations
 *
 * In addition to conforming to the type interface, any system backing an
 * implementation of `Logger` should, if possible, index the level and group.
 * Other attributes can be indexed according to the needs of that logging system.
 */
export interface Logger {
    /** Not usually emitted in release builds or production. */
    debug(entry: Loggable): void;

    /** Normal but significant events, such as login, startup, shutdown, initialization, etc.
     *
     * Use sparingly.
     */
    info(entry: Loggable): void;

    /** Something bad happened, but impact might be minimal */
    warn(entry: Loggable): void;

    /** Something bad happened, with likely negative impact */
    error(entry: Loggable): void;

    /** Something really bad happened
     *
     * On the server, this would usually be accompanied by crashing.
     */
    critical(entry: Loggable): void;
    /** If this logger has any sort of shutdown/teardown functionality, such as flushing buffered entries */
    shutdown?(): Promise<void>;
}

/** Argument to Logger methods */
export type Loggable = Error | LogEntryConvertible | LogEntry;

/** Structured log entry */
export type LogEntry = {
    /** Grouping for log entry, like a feature name */
    group: string;
    /** Human readable message */
    message?: string;
    /** Key-value metadata */
    attrs?: Attributes;
    /** An underlying error that this log message is about */
    error?: Error;
};

/** What level of impact this log entry has.
 *
 * See corresponding method descriptions for more info on each level
 */
export enum LogLevel {
    Debug = 10,
    Info = 20,
    Warning = 30,
    Error = 40,
    Critical = 50,
}

/** Name for method in `LogEntryConvertible`
 *
 * We don't use `Symbol.for()` here, like we would in idiomatic Javascript,
 * because multiple versions of the package would then not be interoperable
 * due to `unique symbol` typing. By using a string, we can at least have
 * structural matching between library copies.
 */
export const toLogEntry = '@descript/std/logging/toLogEntry';

/**
 * An interface for providing structured information to a `Logger`
 *
 * This is useful when another custom object, such as an Error or Span subclass,
 * holds information that would be useful to extract for logging.
 */
export interface LogEntryConvertible {
    [toLogEntry](): LogEntry;
}

export type Attributes = JSONObject;

/** Runtime test for whether an object implements Loggable */
export function isLogEntryConvertible(x: unknown): x is LogEntryConvertible {
    return (
        typeof x === 'object' &&
        x !== null &&
        toLogEntry in x &&
        typeof x[toLogEntry] === 'function'
    );
}
