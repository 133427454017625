// Copyright 2024 Descript, Inc
import { useTheme } from '@daily/shared/src/contexts/Theme';
import Head from 'next/head';
import { useEffect } from 'react';

import { useCustomTrayButtons } from '@lib/state/custom';

import { Meta } from './Meta';

export function Layout({ children }: React.PropsWithChildren<unknown>) {
    const { colors } = useTheme();
    const [customTrayButtons] = useCustomTrayButtons();

    /**
     * iOS < 15 layout hack: rotating the device hides/minifies Safari's browser bars.
     * Forcing a repaint re-shows browser bars.
     */
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        const handleOrientationChange = () => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                // eslint-disable-next-line @descript-eslint/no-force-reflow
                document.documentElement.style.height = `${window.innerHeight}px`;
                timeout = setTimeout(() => {
                    document.documentElement.style.height = '';
                }, 0);
            }, 500);
        };
        window.addEventListener('orientationchange', handleOrientationChange);
        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    return (
        <div>
            <Head>
                {Object.entries(customTrayButtons).map(([id, btn]) => (
                    // Prefetches icons for customTrayButtons as soon as possible
                    <link key={id} rel="prefetch" href={btn.iconPath} />
                ))}
            </Head>
            <Meta />
            {children}

            <style global jsx>{`
                :root {
                    --zindex-sidebar: 15;
                    --zindex-tray: 10;
                    --zindex-waitingroom: 25;
                }
                html {
                    height: 100%;
                    overflow: hidden;
                    overscroll-behavior: none;
                    width: 100%;
                }
                body {
                    --snackbar-info-bg: ${colors.background};
                    --snackbar-info-color: ${colors.baseText};
                    --snackbar-success-bg: ${colors.backgroundAccent};
                    --snackbar-success-color: ${colors.baseText};

                    height: 100%;
                    overflow: hidden;
                    width: 100%;
                }
                ::-webkit-scrollbar {
                    background: ${colors.background};
                    width: 12px;
                }
                ::-webkit-scrollbar-thumb {
                    background-color: ${colors.supportiveText};
                    border: 2px solid ${colors.background};
                    border-radius: 6px;
                }
            `}</style>

            <style jsx>{`
                div {
                    bottom: 0;
                    left: 0;
                    overflow: hidden;
                    position: absolute;
                    right: 0;
                    top: 0;
                }
            `}</style>
        </div>
    );
}
