import { User } from '@descript/client/src/Api/Project';
import { useAvatarImage } from '@hooks/useAvatarImage';

interface Props {
    name: string;
    userId: string;
}

export function Avatar({ name, userId }: Props) {
    const avatarImage = useAvatarImage(userId);

    return (
        <div
            className="avatar"
            style={{
                backgroundColor: User.ColorForUserId(userId, true),
            }}
        >
            {avatarImage ? <img alt="" src={avatarImage} /> : (name?.[0] ?? '').toUpperCase()}

            <style jsx>{`
                img {
                    border-radius: 50%;
                    flex: none;
                    height: 24px;
                    margin-right: 0;
                    width: 24px;
                }
                .avatar {
                    min-width: 24px;
                    min-height: 24px;
                    max-width: 24px;
                    max-height: 24px;
                    border-radius: 50%;
                    display: flex;
                    background-color: #3498db;
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                }
            `}</style>
        </div>
    );
}
