import type { Attributes, LogEntry, Logger } from './Logger';
import { getDefaultConsoleLogger } from './DefaultConsoleLogger';

export type ConsoleLogInput = string | Error | undefined;

/**
 * A drop-in replacement for `console.log()`, so that `group` doesn't need
 * to be specified manually for `Logger`.
 */
export class GroupLogger {
    constructor(
        private readonly group: string,
        private readonly logger: Logger,
    ) {}

    debug(message: ConsoleLogInput, attrs?: Attributes): void {
        this.logger.debug(toLogEntry(this.group, message, attrs));
    }

    info(message: ConsoleLogInput, attrs?: Attributes): void {
        this.logger.info(toLogEntry(this.group, message, attrs));
    }

    warn(message: ConsoleLogInput, attrs?: Attributes): void {
        this.logger.warn(toLogEntry(this.group, message, attrs));
    }

    error(message: ConsoleLogInput, attrs?: Attributes): void {
        this.logger.error(toLogEntry(this.group, message, attrs));
    }

    critical(message: ConsoleLogInput, attrs?: Attributes): void {
        this.logger.critical(toLogEntry(this.group, message, attrs));
    }
}

export function groupLogger(group: string, attrs?: Attributes): GroupLogger {
    return new GroupLogger(group, getDefaultConsoleLogger(attrs));
}

function toLogEntry(group: string, message: ConsoleLogInput, attrs?: Attributes): LogEntry {
    const entry: LogEntry = {
        group,
    };
    if (message instanceof Error) {
        entry.message = message.message;
        entry.error = message;
    } else if (message) {
        entry.message = message;
    }
    if (attrs) {
        entry.attrs = attrs;
    }
    return entry;
}
