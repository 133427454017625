// Copyright 2022 Descript, Inc

import { useLayoutEffect, useRef, useState } from 'react';
import {
    HybridMouseEvent,
    HybridMouseEventHandlerWithItem,
    isRightClick,
} from './ReactEventHandlers';
import { useEventCallback } from './useEventCallback';
import { useModifierKeysDelayed } from './useModifierKeysDelayed';
import { Modifiers } from './useModifierKeys';
import { DomModifiers } from './KeyboardEvent';
import { useOnChange } from './useOnChange';

type EventProperties = {
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
    target: HybridMouseEvent['target'];
};

/**
 * Subset of Mouse/KeyboardEvent properties that are available with useDragging (since we synthetically create events)
 */
export type UseDraggingEvent = EventProperties &
    DomModifiers & {
        preventDefault: () => void;
        stopPropagation: () => void;
    };

export type DragDelta = { deltaX: number; deltaY: number };
export type DragPhase = 'start' | 'drag' | 'end' | 'cancel';
export type DragResult = 'keep' | 'reset';
export type HandleDragFn<T = undefined> = (
    delta: DragDelta,
    phase: DragPhase,
    event: UseDraggingEvent,
    representedItem: T,
) => DragResult;

export type UseDraggingReturn<T = undefined> = {
    /**
     * Generic T allows this to be used in the `onItemMouseDown`
     * property of `PureSelectableItem`, which lets us define a single
     * drag handler for many items at a time.
     */
    handleMouseDown: HybridMouseEventHandlerWithItem<T>;
} & DraggedItem<T>;

type DraggedItem<T> =
    | { isDragging: true; draggingItem: T }
    | { isDragging: false; draggingItem?: undefined };

function noop() {
    // do nothing, used for placeholder preventDefault and stopPropagation below
}

/**
 * Extracts the mouse position from a MouseEvent
 */
function getEventProperties({ clientX, clientY, pageX, pageY, target }: EventProperties) {
    return { clientX, clientY, pageX, pageY, target };
}

/** @deprecated Use `usePointerMove` instead. */
export function useDragging<T = undefined>({
    onHandleDrag,
    stopPropagation = true,
    enableClick = false,
    preventDefault = stopPropagation,
    // #DefaultModifierExitDelay
    modifierExitDelayMs = 150,
    deadZonePixels = 0,
}: {
    onHandleDrag: HandleDragFn<T>;
    stopPropagation?: boolean;
    enableClick?: boolean;
    preventDefault?: boolean;
    modifierExitDelayMs?: number;
    deadZonePixels?: number;
}): UseDraggingReturn<T> {
    const [draggingState, setDragging] = useState<DraggedItem<T>>({ isDragging: false });
    const lastOnHandleDrag = useRef<HandleDragFn<T>>(onHandleDrag);

    const modifiers = useModifierKeysDelayed('UseDragging', modifierExitDelayMs);

    // When modifiers change, notify the drag handler
    const onModifierKeyChange = useRef<((modifiers: Modifiers) => void) | undefined>();
    useOnChange(modifiers, onModifierKeyChange);

    useLayoutEffect(() => {
        lastOnHandleDrag.current = onHandleDrag;
    }, [onHandleDrag]);

    const handleMouseDown = useEventCallback(
        (downEvent: React.MouseEvent, representedItem: T) => {
            if (isRightClick(downEvent)) {
                return;
            }
            if (stopPropagation) {
                downEvent.stopPropagation();
            }
            if (preventDefault) {
                downEvent.preventDefault();
            }
            if (!enableClick) {
                setDragging({ isDragging: true, draggingItem: representedItem });
            }

            const startPosition = getEventProperties(downEvent);
            let lastPosition = startPosition;
            let lastModifiers = modifiers;
            let passedDeadZone = deadZonePixels <= 0;

            const deriveDragDeltas = (event: EventProperties) => {
                const subtrahend = lastDragResult === 'reset' ? lastPosition : startPosition;
                lastPosition = getEventProperties(event);
                return {
                    deltaX: event.clientX - subtrahend.clientX,
                    deltaY: event.clientY - subtrahend.clientY,
                };
            };

            const endDragging = () => {
                setDragging({ isDragging: false });
                window.removeEventListener('mousemove', handleMouseMove);
                window.removeEventListener('mouseup', handleDragFinish, true);
                window.removeEventListener('drop', handleDragFinish, true);
                onModifierKeyChange.current = undefined;
            };

            let lastDragResult = lastOnHandleDrag.current(
                { deltaX: 0, deltaY: 0 },
                'start',
                {
                    ...lastPosition,
                    ...lastModifiers,
                    preventDefault: downEvent.preventDefault.bind(downEvent),
                    stopPropagation: downEvent.stopPropagation.bind(downEvent),
                },
                representedItem,
            );

            const handleMouseMove = (moveEvent: MouseEvent) => {
                if (stopPropagation) {
                    moveEvent.stopPropagation();
                }
                if (preventDefault) {
                    moveEvent.preventDefault();
                }
                if (enableClick) {
                    setDragging({ isDragging: true, draggingItem: representedItem });
                }
                if (!passedDeadZone) {
                    const deltaX = moveEvent.clientX - startPosition.clientX;
                    const deltaY = moveEvent.clientY - startPosition.clientY;
                    if (deltaX * deltaX + deltaY * deltaY < deadZonePixels * deadZonePixels) {
                        return;
                    }
                    passedDeadZone = true;
                }
                lastDragResult = lastOnHandleDrag.current(
                    deriveDragDeltas(moveEvent),
                    'drag',
                    {
                        ...lastPosition,
                        ...lastModifiers,
                        preventDefault: moveEvent.preventDefault.bind(downEvent),
                        stopPropagation: moveEvent.stopPropagation.bind(downEvent),
                    },
                    representedItem,
                );
            };

            const handleDragFinish = (upEvent: MouseEvent) => {
                if (stopPropagation) {
                    upEvent.stopPropagation();
                }
                if (preventDefault) {
                    upEvent.preventDefault();
                }

                lastOnHandleDrag.current(
                    deriveDragDeltas(upEvent),
                    passedDeadZone ? 'end' : 'cancel',
                    {
                        ...getEventProperties(upEvent),
                        ...lastModifiers,
                        preventDefault: upEvent.preventDefault.bind(downEvent),
                        stopPropagation: upEvent.stopPropagation.bind(downEvent),
                    },
                    representedItem,
                );

                endDragging();
            };

            onModifierKeyChange.current = (newModifiers: Modifiers) => {
                lastModifiers = newModifiers;
                lastOnHandleDrag.current(
                    deriveDragDeltas(lastPosition),
                    'drag',
                    {
                        ...lastPosition,
                        ...lastModifiers,
                        preventDefault: noop,
                        stopPropagation: noop,
                    },
                    representedItem,
                );
            };

            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleDragFinish, true);
            // Need to listen to drop event because mouseup is not fired when dragging
            window.addEventListener('drop', handleDragFinish, true);
        },
    );

    return {
        handleMouseDown: handleMouseDown as HybridMouseEventHandlerWithItem<T>,
        ...draggingState,
    };
}
