// Copyright 2024 Descript, Inc
import * as Sentry from '@sentry/react';
import { ReactNull } from '@descript/react-utils';
import { configureDefaultErrorTracker } from '@descript/analytics';

if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT) {
    const sampleRate = 1.0; // Sampling everything for now
    // const sampleRate = ['development', 'vercel-preview'].includes(
    //     process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    // )
    //     ? 1.0
    //     : 0.1;
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
        environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
        tracesSampleRate: sampleRate,
        ignoreErrors: [
            'Non-Error exception captured',
            'Non-Error promise rejection captured',
            // See: https://stackoverflow.com/a/50387233
            'ResizeObserver loop limit exceeded',
            'Cancel rendering route',
        ],
        beforeBreadcrumb: (breadcrumb, hint) => {
            if (breadcrumb.category.includes('redux')) {
                return ReactNull;
            }
            /**
             * In case the clicked element is inside a button with an id,
             * update breadcrumb message to include the button id instead of the selector path.
             */
            if (
                breadcrumb.category.includes('ui.click') &&
                hint.event.target.closest('button[id]')
            ) {
                const btn = hint.event.target.closest('button[id]');
                /**
                 * Filter out jsx- classNames, as they're autogenerated anyway,
                 * but include all other classNames.
                 */
                const classList = [...btn.classList].filter((c) => !c.startsWith('jsx-'));
                breadcrumb.message = `button#${btn.id}${
                    classList.length ? `.${classList.join('.')}` : ''
                }`;
            }
            return breadcrumb;
        },
        integrations: [
            new Sentry.BrowserTracing(),
            new Sentry.Integrations.Breadcrumbs({
                console: false,
            }),
        ],
        beforeSend: (event) => {
            // To avoid eating up sentry quota, only send 1% of csp errors while continuing to send 100% of all other events
            if (event.type === 'csp') {
                if (Math.random() > 0.01) {
                    return undefined; // Discard this event
                }
            }
            return event;
        },
    });

    configureDefaultErrorTracker();
}
