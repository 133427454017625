// Copyright 2022 Descript, Inc
/**
 * Information on how to add feature flags:
 * https://www.notion.so/descript/LaunchDarkly-Feature-Flags-def47cb764f84c5dad1801e21c90d557
 */
import { JSONObject } from '@descript/descript-core';

export const allFeatureFlags = [
    'conductor-sidebar-name',
    'conductor-sidebar-name-override',
    'asset-upload-networking',
    'automatic-project-backups',
    'web-automatic-project-backups',
    'remove-drive-creation-from-bearer-function',
    'remove-drive-creation-from-bearer-function-invite-link-only',
    'braze',
    'braze-content-cards',
    'braze-in-app-messaging',
    'child-process-canary-interval-ms',
    'child-process-canary-timeout-ms',
    'classic-speakers-submenu',
    'client-event-logging',
    'client-new-revert-logic',
    'cloud-provider-giphy',
    'correction-wizard',
    'draftjs-global-dom-selection',
    'duplicate-qem-projects-to-lc',
    'edu-nfp-plan-available',
    'edit-for-clarity-ai-speech-param',
    'edit-for-clarity-with-overdub',
    'edit-for-clarity-intensities',
    'ai-actions-new-edit-review-state',
    'global-asset-sync',
    'json-upload-cache',
    'l10n-beta-tags-transcription',
    'l10n-languages-transcription',
    'learning-rail',
    'live-collab-commit-poll-period-seconds',
    'live-collab-copy-local-commits-on-duplicate',
    'live-collab-cursor-in-doc',
    'live-collab-doc-hashing-algorithm',
    'live-collab-in-new-storyboard-project',
    'live-collab-only-main-commits',
    'live-collab-restore-history',
    'live-collab-throttle-period-ms',
    'live-collab-sparse-mode',
    'live-collab-worker',
    'live-collab-disallow-lower-version-doc-update',
    'live-collab-sync-doc-update',
    'live-collab-use-redux-state-for-base-ref',
    'live-collab-echoing-commits-branch-fix',
    'live-collab-merge-strategy-override',
    'live-collab-track-redux-trimerge-refs',
    'manage-auth-identities',
    'manual-proxy-generation-video',
    'media-lib-load-doc-autosave',
    'monthly-proration-on-immediate-upgrades',
    'multitrack-script',
    'multiwindow',
    'offline-storage-location',
    'otel-redux-trace',
    'onboarding-checklist',
    'overdub-promote-voice-model',
    'overdub-training-project-uses-live-collab',
    'properties-panel-slip',
    'publish-captivate-fm-integration',
    'publish-castos-integration',
    'publish-ewebinar-integration',
    'publish-hello-audio-integration',
    'publish-podbean-integration',
    'publish-blubrry-integration',
    'publish-podcast-co-integration',
    'publish-restream-integration',
    'publish-transistor-integration',
    'publish-video-ask-integration',
    'publish-wistia-integration',
    'quick-recorder-record-and-edit',
    'quick-recorder-version',
    'redesign-drive-view-storyboard',
    'redux-connect-impl',
    'redux-actions-batching-type',
    'redux-actions-throttle',
    'replace-script-track',
    'reverse-trials',
    'automatic-reverse-trials-for-new-free-drives',
    'allow-reverse-trials-for-existing-free-drives',
    'storyboard-new-project-checkbox',
    'storyboard-demo-project-id',
    'stripe-customer-portal',
    'subscription-modal-default-to-annual',
    'voice-id-browser-recorder',
    'zendesk-chat',
    'zendesk-live-chat-access-level',
    'zendesk-live-chat-feature-tier',
    'edit-in-descript',
    'legacy-browser-new-template-button',
    'cloud-library-safe-search',
    'scene-background-color',
    'transcription-window-minutes',
    'transcription-window-minutes-short',
    'demo-to-pip-url-slug',
    'pip-embed-params',
    'settings-redesign',
    'fast-export-honor-client-watermark-request',
    'failed-payment-banner',
    'storyboard-upgrade-banner',
    'storyboard-banner-message',
    'create-classic-project',
    'drive-conductor',
    'proxy-keyframe-interval-threshold-ms',
    'media-engine-new-fps-calculation',
    'word-gap-over-text-cursor',
    'duplicate-normal-project-as-new-template',
    'sequence-track-include-transcript-toggle',
    'timeline-tools-redesign',
    'email-subscription-groups',
    'close-qem-on-publish',
    'dall-e',
    'dall-e-variations',
    'use-canvas-state-machine',
    'eye-contact',
    'export-banner',
    'uvm-voices',
    'use-cloud-aligner',
    'smarter-drive-default',
    'use-msft-win-camera-api',
    'remove-live-transcription-visualization',
    'squadcast-entry-points',
    'timeline-zoomable-scrollbar',
    'wordless-media-ellipses',
    'wordless-media-ellipses-kill-switch',
    'wordless-media-allow-correct',
    'video-overdub-layer',
    'direct-to-web',
    'you-tube-publish-on-web',
    'plus-insert-button',
    'ia-refactor',
    'ai-speech-autogen-debounce-ms',
    'screen-capture-kit-audio',
    'eye-contact-in-prod',
    'include-at-sign-in-speaker-label',
    'uvm-stock-speakers',
    'ai-speech-controls',
    'ai-speech-controls-kill-switch',
    'bulk-actions',
    'bulk-improvements',
    'bulk-delete',
    'move-popover-starred-folders',
    'disable-white-glove',
    'query-param-allowlist',
    'new-canvas-toolbars',
    'insert-from-the-script-default',
    'insert-from-the-script-global-override',
    'independent-rounded-corners',
    'multi-drive-admin',
    'labs-settings',
    'new-speaker-merge-ux',
    'live-collab-write-commits-buffer',
    'timeline-redesign-default',
    'timeline-redesign-global-override',
    'timeline-redesign-labs-eligible',
    'timeline-script-layers',
    'new-project-privacy',
    'export-use-ffmpeg-compositor',
    'web-media-local-export',
    'hide-card-rail-eligible',
    'hide-card-rail-default',
    'bottom-card-rail-eligible',
    'hide-card-rail-global-override',
    'bottom-card-rail-default',
    'bottom-card-rail-override',
    'publish-privacy-drive-settings',
    'new-media-range-picker-eligible',
    'new-media-range-picker-default',
    'new-media-range-picker-global-override',
    'automatic-speaker-detection',
    'ed-workflow-save-layout-flow',
    'windows-quick-progressive-upload',
    'restore-removed-media-toolbar-button',
    'newline-button-row',
    'new-file-lifecycle-ui',
    'tts-release-channel',
    'overdub-regenerate-prefix-suffix-duration',
    'translation-bulk-generate',
    'translation-bulk-export',
    'publish-to-google-drive',
    'recorder-allow-access-to-sckit-error-object',
    'transcription-in-supertoast',
    'file-lifecycle-media-library',
    'ed-workflow-speaker-focus',
    'template-browser-redesign',
    'empty-project-starter-state',
    'desktop-supertoast',
    'recorder-zoom-recordings-import',
    'prefetch-media-on-script-hover',
    'prefetch-media-on-timeline-hover',
    'acceleration-aware-prefetch',
    'mts-ai-effects',
    'max-gop-file-warning',
    'default-clip-video-template',
    'default-multicam-template',
    'default-template-browser',
    'web-enable-live-tx',
    'web-export-multi-scope',
    'shorten-word-gap-remove-filler-words-paywall',
    'web-recorder-rainbow-audio-bars-easter-egg',
    'web-max-file-upload-size-gb',
    'transcription-auto-detect-exp',
    'client-make-web-proxy',
    'magic-multicam',
    'automatic-multicam-template-param',
    'window-dressing-action',
    'generate-visuals-action',
    'clips-model-experiment-variant',
    'timeline-computation-on-worker',
    'collaborative-recorder',
    'web-flatten-tracks',
    'fetch-paywall-usage-debounce-time',
    'dynamic-payment-methods',
    'exp-alternate-payment-methods',
    'exp-web-checkout',
    'web-timeline-export',
    'skip-artifact-md5',
    'video-regenerate-default',
    'video-regenerate-eligible',
    'video-regenerate-global-override',
    'zoom-auto-import',
    'learn-descript-v2-sbrm',
    'zoom-show-browse-page-card',
    'ai-usage-dashboard',
    'ai-usage-dashboard-admin-view',
    'web-mic-bleed-detection',
    'exp-churn-variants',
    'exp-churn-key-preview-flag',
    'workflows-placeholder-layers',
    'exp-simplify-onboarding-3.1',
    'exp-start-trial-url',
    'start-trial-from-allow-list',
    'exp-onboarding-plans-page-update',
    'deprecate-uvm-stock-voices',
    'exp-studio-sound-default-intensity',
    'ed-smart-media-panel-defaults',
    'live-collab-presence',
    'regenerate-toolbar-and-overdub-rename-default',
    'regenerate-toolbar-and-overdub-rename-eligible',
    'regenerate-toolbar-and-overdub-rename-global-override',
    'workflows-save-dimensions-of-template-card',
    'exp-show-recorder-by-descript-onboarding',
    'drive-view-standalone-recorder-options',
    'onboarding-routes',
    'ed-classic-deprecation-soon-dialog',
    'ed-classic-deprecated-now-dialog',
    'editor-smart-transitions',
    'editor-smart-transitions-eligible',
    'editor-smart-transitions-override',
    'enable-forethought-chatbot',
    'youtube-publish-category-field',
    'exp-onboarding-over-editor',
    'exp-workflows-layouts-2.1-release',
    'ed-replacemedia-button-on-canvas-toolbar',
    'rooms-host-device-controls',
    'ed-sceneboundary-click-layout-picker',
    'ed-new-textselection-toolbar-oct2024',
    'workflows-group-by-composition-name',
    'workflows-favorite-layouts',
    'desktop-2-auto-upgrade',
    'rooms-billing',
    'multilingual-aligner-for-tts-enabled',
    'ed-layout-picker-script-shortcut-key',
    'rooms-enable-firefox',
    'rooms-enable-safari',
    'exp-deemphasize-free-plan-in-onboarding',
    'ed-copy-paste-split-card',
    'erw-lossless-audio-allowed',
    'blur-background',
    'web-recorder-fps-analytics',
    'recorder-support-dynamic-resolution',
    'quick-recorder-enable-live-tx',
    'ed-remove-frame-snapping',
    'erw-enable-live-tx',
    'exp-preview-descript-watermark',
    'hedra-avatar-prototype',
    'exp-hide-braze-in-product-surfaces',
    'volume-as-percentage',
    'disable-tts-instead-of-gibberish',
    'exp-pricing-experiment-monthly-increase-by-25',
    'web-recorder-audio-tracking-analytics',
    'exp-free-drive-dropdown-upgrade-upsell',
    'web-storage-monitor',
    'project-prompt-modal',
    'exp-recorder-video-bitrate',
    'exp-rebrand-publish-tab-its-all-export',
    'ed-effects-amins-transitions-in-toolbars',
    'copy-paste-quality-refactor',
    'image-style-prompts',
    'exp-pricing-experiment-monthly-increase-by-equal-amounts',
    // AUTOMATED_FEATURE_FLAG_CREATION
    'sidebar-template-pane',
    'file-upload-paywall-enabled',
    'identify-user-from-backend',
    'gallery-template-categories',
    'regenerate-release-channel',
    'web-app-target-picker',
    'web-file-manipulation',
    'rounded-text-bg',
    'underlord-recommendation-editor',
    'speech-enhancement-version',
    'workflows-auto-text-bounding-box',
    'exp-upselling-annual-subscriptions',
    'desktop-2-upgrade-button',
    'workflows-text-bounding-box-background',
    'exp-enforce-storage-limits',
    'exp-trials-main',
    'exp-enable-alternate-payment-methods-in-web-checkout-for-onboarding',
    'exp-churnkey-kill-switch',
    'exp-churnkey-paywall-type',
    'exp-churnkey-check-pause-wall',
    'eleven-labs-multilingual-tts',
    'eleven-labs-for-stock-speaker-dub',
    'eleven-labs-for-voice-clones',
    'script-tokens',
] as const; // ensures allFeatureFlags is not string[]

export type FeatureFlagName = (typeof allFeatureFlags)[number];
export type FeatureFlagValue =
    | boolean
    | string
    | number
    | JSONObject
    | Array<string>
    | undefined;
export type FeatureFlagSettings = Partial<Record<FeatureFlagName, FeatureFlagValue>>;
