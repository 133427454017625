// Copyright 2023 Descript, Inc

export interface Segment {
    data: Blob;
    duration: number;
    startTimecode: number;
    isInit: boolean;
    number: number;
    tracks?: Map<number, TrackInfo>;
    frameCount?: number;
}

export interface Block {
    trackId: number;
    timecode: number;
    timecodeBase: number;
    keyframe: boolean;
    data: ArrayBuffer;
}

export interface DemuxResult {
    headerInfo: {
        tracks: Map<number, TrackInfo>;
    };
    blocks: Block[];
}

export interface TrackInfo {
    type: TrackType;
    codec: string; // https://www.matroska.org/technical/codec_specs.html
    sampleRate?: number;
    channels?: number;
    width?: number;
    height?: number;
    bitDepth?: number;
}

export const enum TrackType {
    UNKNOWN = 0,
    VIDEO = 1,
    AUDIO = 2,
    COMPLEX = 3,
    LOGO = 16,
    SUBTITLE = 17,
    BUTTONS = 18,
    CONTROL = 32,
    METADATA = 33,
}
