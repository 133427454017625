// Copyright 2025 Descript, Inc
import { useRecording, useAppMessage } from '@daily-co/daily-react';
import { useState, useEffect } from 'react';
import { DailyEventObjectAppMessage } from '@daily-co/daily-js';
import { useUploadingModal } from '../../../lib/state/modals';

export interface RecordingAppMessageData {
    event: 'recording-starting' | 'recording-stopped';
    recordingId?: string;
}

/**
 * Hook to synchronize recording state between Daily's isRecording and host recording events. This is only needed because
 * Daily's isRecording state is not always reliable in the case a guest joins after the recording has started.
 *
 * @returns {boolean} synchronized recording state
 */
export const useSyncedIsRecording = () => {
    const { isRecording, recordingId } = useRecording();
    const [recordingStoppedByHost, setRecordingStoppedByHost] = useState(false);
    const [effectiveRecordingState, setEffectiveRecordingState] = useState(false);
    const [, setShowUploadingModal] = useUploadingModal();

    // Handle recording events from host
    useAppMessage<RecordingAppMessageData>({
        onAppMessage: (ev: DailyEventObjectAppMessage<RecordingAppMessageData>) => {
            switch (ev?.data?.event) {
                case 'recording-stopped':
                    // The recordingId can be undefined only on the first recording of the session so no need to
                    // update the recordingStoppedByHost state after the first stop event.
                    setRecordingStoppedByHost(true);
                    setShowUploadingModal(true);
                    break;
                default:
                    break;
            }
        },
    });

    // Sync recording states
    useEffect(() => {
        // Trust recording state if there's a recordingId, if not then trust if we saw the stop event.
        setEffectiveRecordingState(
            isRecording && (recordingId !== undefined || !recordingStoppedByHost),
        );
    }, [isRecording, recordingStoppedByHost, recordingId]);

    return effectiveRecordingState;
};
