import { JSONArray, JSONObject } from '@descript/descript-core';
import { createHash, Hash } from 'crypto';
import { DescriptError, ErrorCategory } from '@descript/errors';

type HashableJSONValue = JSONArray | JSONObject;

type CacheSlingingHasherConfig = {
    algorithm?: string;
    encoding?: BufferEncoding;
    /**
     *  This will mean that two objects with the same keys/values but in a different order
     *  will hash differently.
     *
     *  Default: false.
     */
    preserveKeyOrder?: boolean;
};

/** This is a hasher that "slings" aka uses a cache to avoid recomputing hashes for reference equal object. */
export class CacheSlingingHasher {
    private readonly cache = new WeakMap<HashableJSONValue, Buffer>();
    private readonly nullHash: Buffer;

    private readonly algorithm: string;
    private readonly encoding: BufferEncoding;
    private readonly preserveKeyOrder: boolean;
    constructor({
        algorithm = 'sha256',
        encoding = 'base64url',
        preserveKeyOrder = false,
    }: CacheSlingingHasherConfig = {}) {
        this.algorithm = algorithm;
        this.encoding = encoding;
        this.preserveKeyOrder = preserveKeyOrder;
        this.nullHash = createHash(this.algorithm).update('null').digest();
    }

    /**
     * Computes a deterministic hash of primitives and immutable objects where, during hashing, all objects are replaced
     * by their own hash result.  This optimizes for repeated and reused references and will not recompute those hashes.
     * If a previously hashed object is mutated, subsequent hashings that share any mutated subtrees will not be valid.
     *
     * Symbols, Functions, and cycles are not supported.
     *
     * @param value the immutable object or primitive to hash.
     * @return the contents of the hash according to the specified encoding.
     */
    public hash(value: Readonly<unknown>): string {
        if (typeof value === 'object') {
            return this.hashObject(value).toString(this.encoding);
        }
        const hasher = createHash(this.algorithm);
        this.addValue(hasher, value);
        return hasher.digest().toString(this.encoding);
    }

    private addValue(
        hasher: Hash,
        value: Readonly<unknown> | null,
        stack?: Set<HashableJSONValue>,
    ): void {
        switch (typeof value) {
            case 'boolean':
            case 'number':
            case 'bigint':
            case 'string':
                hasher.update(JSON.stringify(value));
                break;
            case 'object':
                // hex formatted `0x[0-9a-f]*` is used to avoid collisions with all other legal JSON values
                hasher.update('0x');
                hasher.update(this.hashObject(value, stack).toString('hex'));
                break;
            default:
                throw new DescriptError(
                    'cannot hash non-serializable values',
                    ErrorCategory.LiveCollab,
                );
        }
    }

    private hashObject(
        obj: Readonly<JSONObject> | null,
        stack?: Set<HashableJSONValue>,
    ): Buffer {
        // eslint-disable-next-line no-null/no-null
        if (obj === null) {
            return this.nullHash;
        }

        // try cached values
        const hit = this.cache.get(obj);
        if (hit) {
            return hit;
        }

        // hash the object
        if (stack === undefined) {
            stack = new Set<HashableJSONValue>();
        } else if (stack.has(obj)) {
            throw new DescriptError('cannot hash cyclic structures', ErrorCategory.LiveCollab);
        }
        stack.add(obj);

        const hasher = createHash(this.algorithm);
        let afterFirst = false;
        function comma(): void {
            if (afterFirst) {
                hasher.update(',');
            } else {
                afterFirst = true;
            }
        }
        if (Array.isArray(obj)) {
            hasher.update('[');
            for (const value of obj) {
                comma(); // always write commas for undefined values for accurate index
                if (value === undefined) {
                    continue;
                }
                this.addValue(hasher, value, stack);
            }
            hasher.update(']');
        } else {
            hasher.update('{');
            const keys = this.preserveKeyOrder ? Object.keys(obj) : Object.keys(obj).sort();
            for (const key of keys) {
                const value = obj[key];
                if (value === undefined) {
                    continue;
                }
                comma(); // only write defined keys -- no extra commas
                this.addValue(hasher, key);
                hasher.update(':');
                this.addValue(hasher, value, stack);
            }
            hasher.update('}');
        }
        const result = hasher.digest();
        this.cache.set(obj, result);
        stack.delete(obj);
        return result;
    }
}
