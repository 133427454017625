// Copyright 2018 Descript, Inc
import { MergeStrategy } from '@descript/descript-collab';
import * as ApiClient from './ApiClient';
import { Revision, RevisionSummary } from './Revision';
import {
    fromRevisionJson,
    fromRevisionJsons,
    getApproximateContentAndAssetsSize,
} from './Revisions';
import {
    Project,
    Ownership,
    User,
    UserGroup,
    UserJson,
    OwnershipJson,
    ProjectJson,
    ProjectEditorVariant,
    OwnershipRole,
} from './Project';
import { ProjectPermissions, ProjectPermissionsJson } from './ProjectPermissions';
import { basename, extname } from 'path';
import { replaceUrlWithAccelerateEndpoint, SignedUrlJson } from './SignedUrlJson';
import * as ApiTarget from './ApiTarget';
import { generateRecordingsFolderId, ParentFolderId } from './FolderClientConstants';
import { AppConstants } from '../App/Constants';
import {
    ProjectPublishedTemplateInfo,
    projectTemplatePublishInfoToJSON,
    TemplatePreviewReadUrls,
    templatePreviewReadUrlsFromJSON,
    TemplatePreviewReadUrlsJSON,
} from './ProjectTemplate';
import { JSONObject, PlatformHelpers } from '@descript/descript-core';
import { S3BucketCredentials } from './S3Types';
import { AsyncContext } from '@descript/analytics';
import { QueryKey } from '@tanstack/react-query';
import { ErrorCategory, DescriptError, Errors } from '@descript/errors';
import { trackError } from '../Utilities/ErrorTracker';
import * as NUserSettings from '../App/UserSettings';
import { CompositionId, MediaReferenceId } from '@descript/descript-model';
import { getWebUrlBase } from '../Utilities/getShareUrlBase';

/**
 * Generates a project url given a specified project id.
 * The composition id can be used to link directly to a composition within the project.
 *
 * @param projectId Project id associated with the url
 * @param compositionId Composition id associated with the project
 * @param includeHostName Determines whether the url should be prefixed with the Descript host name
 * @param redirectLocalToStaging If true, will rewrite any URL that begins with http://localhost to staging.
 * This option is only honored when includeHostName=true.
 * This is useful to avoid 'open-external-url-safe-mismatch-protocol' errors in openExternalSafe() in shellSafe.ts
 */
export function getProjectUrl({
    projectId,
    compositionId,
    includeHostName,
    redirectLocalToStaging,
}: {
    projectId: string;
    compositionId?: CompositionId | undefined;
    includeHostName: boolean;
    redirectLocalToStaging?: boolean;
}): string {
    let base = '';
    if (includeHostName) {
        base = getWebUrlBase();
        if (redirectLocalToStaging === true && base.startsWith('http://localhost')) {
            base = getWebUrlBase('staging');
        }
    }
    const compositionIdPath = compositionId
        ? `/${compositionId.substring(0, 5).toLocaleLowerCase()}`
        : '';
    return `${base}/${projectId}${compositionIdPath}`;
}

export function getProjectMediaUrl(
    projectId: string,
    mediaRefId: MediaReferenceId,
    includeHostName: boolean,
): string {
    return `${
        includeHostName ? 'https://web.descript.com' : ''
    }/${projectId}/media/${mediaRefId}`;
}

export type UploadProgressCallback = (url: string, percentage: number) => void;

type ProjectViewJson = {
    id: string;
    last_viewed_at: string;
};

type ProjectView = {
    id: string;
    lastViewedAt: Date;
};

export async function fetchProject(
    ctx: AsyncContext,
    projectId: string,
    delegateToken?: string,
): Promise<ProjectAndRevisions> {
    const json = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}`,
        delegateAuth: delegateToken,
    })) as ProjectJson;
    return {
        project: Project.fromJson(json),
        revisions: (json.revisions || []).map((revision) =>
            fromRevisionJson(projectId, revision),
        ),
    };
}

/**
 * Whether a project is deleted on the server
 *
 * We query `projectIsDeleted` instead of `projectExists`, because for uncertain
 * values, we want to return undefined. If people forget to distinguish between
 * false and undefined, the consequences will likely be less bad if false
 * corresponds to a project existing.
 *
 * @returns true only if we know positively that the project is deleted.
 *    Returns false if we know the project exists. Returns undefined if we don't
 *    know either way.
 */
export async function projectIsDeleted(
    ctx: AsyncContext,
    projectId: string,
    delegateToken?: string,
): Promise<boolean | undefined> {
    try {
        await fetchProject(ctx, projectId, delegateToken);
        return false;
    } catch (err) {
        if (err instanceof Errors.RequestError) {
            if (err.statusCode === 404) {
                return true;
            } else if (err.statusCode === 403) {
                return false;
            }
        }
    }
    return undefined;
}

export async function createProject(
    ctx: AsyncContext,
    projectId: string,
    name: string,
    driveId: string,
    parentFolderId?: ParentFolderId,
    permissions?: ProjectPermissions,
    editorVariant?: ProjectEditorVariant,
    isLiveCollabEnabled?: boolean,
    isStoryboardEnabled?: boolean,
    asTemplate?: boolean,
    mergeStrategy: MergeStrategy = MergeStrategy.MultiTimeline,
): Promise<Project> {
    const payload: CreateProjectJson = {
        id: projectId,
        name:
            name.length > AppConstants.maxProjectTitleLength
                ? name.slice(0, AppConstants.maxProjectTitleLength)
                : name,
        drive_id: driveId,
        ...(editorVariant ? { editor_variant: editorVariant } : undefined),
        is_live_collab_enabled: isLiveCollabEnabled,
        is_storyboard_enabled: isStoryboardEnabled,
        merge_strategy: mergeStrategy,
    };

    // TODO [workspaces]: Remove when we add backend support for workspaces. (https://app.asana.com/0/1199653420240807/list)
    if (parentFolderId && parentFolderId === generateRecordingsFolderId(driveId)) {
        payload.in_recordings_folder = true;
    } else {
        payload.parent_id = parentFolderId;
    }

    if (permissions) {
        payload.permissions = {
            public_access: permissions.publicAccess,
            member_access: permissions.driveAccess,
        };
    }

    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects`,
        query: { as_template: asTemplate ?? false },
        data: payload,
    });
    return Project.fromJson(json as ProjectJson);
}

export async function updateProject(
    ctx: AsyncContext,
    projectId: string,
    {
        permissions,
        name,
        driveId,
        parentFolderId,
        editorVariant,
        hasThumbnail,
    }: {
        permissions?: ProjectPermissions;
        name?: string;
        driveId?: string;
        parentFolderId?: ParentFolderId;
        editorVariant?: ProjectEditorVariant;
        hasThumbnail?: boolean;
    },
): Promise<Project> {
    let payload: ProjectJson = {};
    // TODO [workspaces]: Remove when we add backend support for workspaces. (https://app.asana.com/0/1199653420240807/list)
    if (parentFolderId && driveId && parentFolderId === generateRecordingsFolderId(driveId)) {
        payload.in_recordings_folder = true;
    } else if (parentFolderId !== undefined) {
        payload.parent_id = parentFolderId;
    }
    if (permissions !== undefined) {
        const permissionsJson = {
            public_access: permissions.publicAccess,
            member_access: permissions.driveAccess,
        };
        payload = { ...payload, permissions: permissionsJson };
    }
    if (name !== undefined) {
        payload = {
            ...payload,
            name:
                name.length > AppConstants.maxProjectTitleLength
                    ? name.slice(0, AppConstants.maxProjectTitleLength)
                    : name,
        };
    }
    if (driveId !== undefined) {
        payload = { ...payload, drive_id: driveId };
    }
    if (editorVariant !== undefined) {
        payload.editor_variant = editorVariant;
    }
    if (hasThumbnail !== undefined) {
        payload.has_thumbnail = hasThumbnail;
    }

    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.PUT,
        path: `/projects/${projectId}`,
        data: payload,
    });
    return Project.fromJson(json as ProjectJson);
}

export async function deleteProject(ctx: AsyncContext, { projectId }: { projectId: string }) {
    await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.DELETE,
        path: `/projects/${projectId}`,
    });
}

export async function duplicateProject(
    ctx: AsyncContext,
    {
        projectId,
        name,
        sourceDriveId,
        driveId,
        revisionId,
        parentFolderId,
        permissions,
        copyAnnotations,
        copyFonts,
        isLiveCollabEnabled,
        duplicatePublishedTemplate,
        asTemplate,
        mergeStrategy = MergeStrategy.MultiTimeline,
    }: {
        projectId: string;
        name?: string;
        sourceDriveId?: string;
        driveId?: string;
        revisionId?: string;
        parentFolderId?: ParentFolderId;
        permissions?: ProjectPermissions;
        copyAnnotations?: boolean;
        copyFonts?: boolean;
        isLiveCollabEnabled?: boolean;
        duplicatePublishedTemplate?: boolean;
        asTemplate?: boolean;
        mergeStrategy?: MergeStrategy;
    },
): Promise<Project> {
    const query: JSONObject = {
        source_id: projectId,
        copy_annotations: copyAnnotations,
        ...((copyFonts || sourceDriveId === driveId) && { copy_fonts: true }),
        duplicate_published_template: duplicatePublishedTemplate,
    };

    if (revisionId !== undefined) {
        // creates project from a specified revision
        query.source_revision_id = revisionId;
    }

    if (asTemplate !== undefined) {
        query.as_template = asTemplate;
    }

    const payload: DuplicateProjectJson = {
        name,
        drive_id: driveId,
        merge_strategy: mergeStrategy,
    };

    // TODO [workspaces]: Remove when we add backend support for workspaces. (https://app.asana.com/0/1199653420240807/list)
    if (parentFolderId && driveId && parentFolderId === generateRecordingsFolderId(driveId)) {
        payload.in_recordings_folder = true;
    } else {
        payload.parent_id = parentFolderId;
    }

    if (permissions) {
        payload.permissions = {
            public_access: permissions.publicAccess,
            member_access: permissions.driveAccess,
        };
    }

    if (isLiveCollabEnabled) {
        payload.is_live_collab_enabled = true;
    }

    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects`,
        query,
        data: payload,
    });
    return Project.fromJson(json as ProjectJson);
}

// Ownerships

export async function updateOwnership(
    ctx: AsyncContext,
    projectId: string,
    ownershipId: string,
    role: OwnershipRole,
): Promise<[Ownership, User]> {
    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.PUT,
        path: `/projects/${projectId}/ownerships/${ownershipId}`,
        data: { role },
    });
    const responseJson = json as ProjectOwnershipJson;

    let ownerships: Ownership[] = [];
    if (responseJson.ownerships) {
        ownerships = responseJson.ownerships.map(Ownership.fromJson);
    }

    let users: User[] = [];
    if (responseJson.users) {
        users = responseJson.users.map(User.fromJson);
    }

    const ownership = ownerships[0]!;
    const user = users[0]!;
    return [ownership, user];
}

export async function deleteOwnership(
    ctx: AsyncContext,
    projectId: string,
    ownershipId: string,
) {
    await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.DELETE,
        path: `/projects/${projectId}/ownerships/${ownershipId}`,
    });
    return await Promise.resolve({});
}

export async function fetchProjectOwnerships(
    ctx: AsyncContext,
    projectId: string,
): Promise<[Ownership[], User[]]> {
    const json = await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/ownerships`,
    });
    const responseJson = json as ProjectOwnershipJson;

    let ownerships: Ownership[] = [];
    if (responseJson.ownerships) {
        ownerships = responseJson.ownerships.map(Ownership.fromJson);
    }

    let users: User[] = [];
    if (responseJson.users) {
        users = responseJson.users.map(User.fromJson);
    }
    return [ownerships, users];
}

export async function inviteUserToProject(
    ctx: AsyncContext,
    projectId: string,
    userId: string,
    role: OwnershipRole,
    notify: boolean,
): Promise<[Ownership, User]> {
    const payload = {
        role,
        user_id: userId,
        notify,
    };
    const json = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects/${projectId}/ownerships`,
        data: payload,
    })) as ProjectOwnershipJson;

    let ownerships: Ownership[] = [];
    if (json.ownerships) {
        ownerships = json.ownerships.map(Ownership.fromJson);
    }

    let users: User[] = [];
    if (json.users) {
        users = json.users.map(User.fromJson);
    }

    const ownership = ownerships[0]!;
    const user = users[0]!;
    return [ownership, user];
}

export async function transferOwner(
    ctx: AsyncContext,
    projectId: string,
    userId: string,
): Promise<Project> {
    const payload = {
        owner_id: userId,
    };
    const json = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects/${projectId}/transfer_owner`,
        data: payload,
    })) as ProjectJson;
    return Project.fromJson(json);
}

// Files

export type UploadSource = 'project' | 'publish';

export async function getSignedFileUploadUrl(
    ctx: AsyncContext,
    projectId: string,
    contentType: string,
    filename: string,
    source: UploadSource = 'project',
): Promise<SignedUrlJson> {
    const extension = extname(filename);
    return await getRawSignedFileUploadUrl(ctx, projectId, source, {
        content_type: contentType,
        extension,
        filename: basename(filename, extension),
    });
}

export async function getRawSignedFileUploadUrl(
    ctx: AsyncContext,
    projectId: string,
    source: UploadSource = 'project',
    query: { content_type: string; extension?: string; filename?: string },
): Promise<SignedUrlJson> {
    let path: string;
    switch (source) {
        case 'publish':
            path = `/published_projects/${projectId}/upload_file`;
            break;
        case 'project':
        default:
            path = `/projects/${projectId}/upload_file`;
            break;
    }
    const urls = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path,
        query,
    })) as SignedUrlJson;
    return replaceUrlWithAccelerateEndpoint(urls);
}

export async function copyAssetFromURL(
    ctx: AsyncContext,
    projectId: string,
    sourceType: 'recording' | 'project',
    sourceId: string,
    fromURL: string,
    filename: string | undefined = undefined, // the basename without extension
    fileExtension: string | undefined = undefined,
): Promise<SignedUrlJson> {
    const payload = {
        source_url: fromURL,
        source_id: sourceId,
        source_type: sourceType,
        extension: fileExtension,
        filename: filename,
    };
    const urls = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects/${projectId}/copy_asset`,
        data: payload,
    })) as SignedUrlJson;
    return urls;
}

/**
 * This grants temporary read & write credentials to the project folder on S3.
 * Rather than getting permission for the whole project folder, we should
 * instead get credentials for just the files we need.
 */
export async function getProjectWriteCredentials(
    ctx: AsyncContext,
    projectId: string,
): Promise<S3BucketCredentials> {
    const credentials = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/asset_write_credentials`,
    })) as S3BucketCredentials;
    return credentials;
}

/** Get the write URL for a projects thumbnail */
export async function getProjectThumbnailWriteUrl(
    ctx: AsyncContext,
    projectId: string,
    md5: string,
) {
    const response = await ApiClient.request<{ write_url: string }>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/thumbnail_write_url`,
        query: { md5 },
    });

    return response.write_url;
}

export function makeAWSFileURL(
    projectId: string,
    filename: string,
    bucket: string = ApiTarget.awsBucket(),
): string {
    return `https://${bucket}.s3.amazonaws.com/${projectId}/${filename}`;
}

/**
 * @returns
 *   @member containerId Project or recording ID
 *   @member filename filename in the AWS URL
 *   @member bucket The S3 bucket
 */
export function parseAWSFileURL(
    fileUrl: string,
): { containerId: string; bucket: string; filename: string } | undefined {
    const url = new URL(fileUrl);
    const hostnameMatch = url.hostname.match('([^.]+)\\.s3\\.amazonaws\\.com');
    if (!hostnameMatch) {
        return undefined;
    }
    const pathMatch = url.pathname.match('^/([^/]+)/([^/]+)');
    if (!pathMatch) {
        return undefined;
    }
    return {
        bucket: hostnameMatch[1]!,
        containerId: pathMatch[1]!,
        filename: pathMatch[2]!,
    };
}

export async function uploadData(
    url: string,
    file: File | ArrayBuffer | string,
    contentType: string,
    progressCallback?: UploadProgressCallback,
): Promise<boolean> {
    return await new Promise<boolean>((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        if (progressCallback) {
            const onProgress = (e: ProgressEvent) => {
                if (e.lengthComputable) {
                    const progress = Math.round((e.loaded * 100) / e.total);
                    progressCallback(url, progress);
                }
            };
            xhr.upload.addEventListener('progress', onProgress);
        }

        const onFailure = () => {
            const error = new DescriptError('Upload failed', ErrorCategory.ProjectLoad);
            reject(error);
        };
        xhr.upload.addEventListener('error', onFailure);
        xhr.upload.addEventListener('abort', onFailure);

        xhr.onreadystatechange = () => {
            const { readyState, status } = xhr;
            if (readyState === 4) {
                if (status === 200) {
                    resolve(true); // success
                } else {
                    onFailure();
                }
            }
        };

        xhr.onerror = (ev: ProgressEvent) => {
            // xhr.onerror fires when there is a failure on the network level
            const error = new DescriptError('Upload failed', ErrorCategory.ProjectLoad);
            reject(error);
        };

        xhr.open('PUT', url);
        xhr.setRequestHeader('Content-Type', contentType);
        xhr.setRequestHeader('Cache-Control', 'max-age=31536000');
        xhr.send(file);
    });
}

// templates
export async function fetchProjectTemplates(ctx: AsyncContext): Promise<ProjectTemplates> {
    return (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.GET,
        path: '/projects/templates',
    })) as ProjectTemplates;
}

export async function logProjectView(
    ctx: AsyncContext,
    projectId: string,
): Promise<ProjectView> {
    const { id, last_viewed_at: lastViewedAtString } = (await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/users/me/projects/${projectId}/view`,
    })) as ProjectViewJson;

    return { id, lastViewedAt: new Date(lastViewedAtString) };
}

export type CreateProjectJson = {
    id: string;
    name: string;
    drive_id?: string;
    parent_id?: ParentFolderId;
    permissions?: ProjectPermissionsJson;
    in_recordings_folder?: boolean;
    editor_variant?: ProjectEditorVariant;
    is_live_collab_enabled?: boolean;
    is_storyboard_enabled?: boolean;
    merge_strategy?: MergeStrategy;
};

export type DuplicateProjectJson = Omit<CreateProjectJson, 'id' | 'name'> & {
    name?: string;
};

export type ProjectOwnershipJson = {
    ownerships?: OwnershipJson[];
    users?: UserJson[];
};

export type ProjectAndRevisionsJson = {
    project: ProjectJson;
    revisions: Revision[];
};

export type ProjectAndRevisions = {
    project: Project;
    revisions: Revision[];
};

export type ProjectAndRevisionSummaryJson = {
    project: ProjectJson;
    revisionSummary: RevisionSummary | undefined;
};

export type ProjectAndRevisionSummary = {
    project: Project;
    revisionSummary: RevisionSummary | undefined;
};

export function revisionsToRevisionSummary(revisions: Revision[]): RevisionSummary | undefined {
    const latestRevision = Project.latestRevision(revisions);
    if (latestRevision === undefined) {
        return undefined;
    }

    return {
        revisionId: latestRevision.revisionId,
        compositionSummaries: latestRevision.compositionSummaries,
        size:
            PlatformHelpers.isElectron() && latestRevision.contents
                ? getApproximateContentAndAssetsSize(latestRevision)
                : undefined,
        createdAt: latestRevision.createdAt,
    };
}

export function projectAndRevisionstoJSON({
    project,
    revisions,
}: ProjectAndRevisions): ProjectAndRevisionsJson {
    return {
        project: Project.toJSON(project),
        revisions,
    };
}
export function projectAndRevisionsfromJson({
    project,
    revisions,
}: ProjectAndRevisionsJson): ProjectAndRevisions {
    return {
        project: Project.fromJson(project),
        revisions,
    };
}
export function projectAndRevisionsfromProjectJsons(
    projectJsons: ProjectJson[] = [],
): ProjectAndRevisions[] {
    return projectJsons.map((projectJson) => {
        const project = Project.fromJson(projectJson);
        return {
            project,
            revisions: fromRevisionJsons(project.id, projectJson.revisions),
        };
    });
}

export type CursorInfo = {
    ttl: number;
    after: string;
};
export type PaginatedDriveProjectsJson = {
    has_more: boolean;
    cursors: CursorInfo;
    projects: ProjectJson[];
    fetched_at: number;
};
export type PaginatedDriveProjects = {
    hasMore: boolean;
    cursorInfo: CursorInfo;
    projects: Project[];
    fetchedAt: number;
};
export function paginatedDriveProjectsFromJson(
    json: PaginatedDriveProjectsJson,
): PaginatedDriveProjects {
    return {
        hasMore: json.has_more,
        cursorInfo: json.cursors,
        projects: json.projects.map(Project.fromJson),
        fetchedAt: json.fetched_at,
    };
}
export function paginatedDriveProjectsToJson(
    data: PaginatedDriveProjects,
): PaginatedDriveProjectsJson {
    return {
        has_more: data.hasMore,
        cursors: data.cursorInfo,
        projects: data.projects.map(Project.toJSON),
        fetched_at: data.fetchedAt,
    };
}

/**
 * We cache just the most recently used first Drive View paginated page for a
 * faster initial boot. We don't store more than one to keep the size small
 * enough to store in localStorage
 */
type CachedPaginatedDriveProjectsJson = PaginatedDriveProjectsJson & {
    query_key: QueryKey;
};
export function bootCacheSet(queryKey: QueryKey, data: PaginatedDriveProjects) {
    try {
        const dataJson = paginatedDriveProjectsToJson(data);
        const cachedJson: CachedPaginatedDriveProjectsJson = {
            query_key: queryKey,
            ...dataJson,
        };
        const value = JSON.stringify(cachedJson);
        NUserSettings.Application.bootCachePayload.set(value);
    } catch (error) {
        trackError(error as Error, 'drive-view-paginated-cache-set', {
            category: ErrorCategory.AppBoot,
        });
    }
}
export function bootCacheGet(queryKey: QueryKey): PaginatedDriveProjects | undefined {
    try {
        const cacheValue = NUserSettings.Application.bootCachePayload.get();
        if (!cacheValue) {
            return undefined;
        }

        const json = JSON.parse(cacheValue) as CachedPaginatedDriveProjectsJson;
        if (queryKey.join(':') !== json.query_key.join(':')) {
            return undefined;
        }

        return paginatedDriveProjectsFromJson(json);
    } catch (error) {
        trackError(error as Error, 'drive-view-paginated-cache-get', {
            category: ErrorCategory.AppBoot,
        });
        return undefined;
    }
}

export type ProjectTemplateKey = UserGroup | 'overdubTraining' | 'tutorial';

// project IDs of project templates for each project template key
export type ProjectTemplates = {
    [key in ProjectTemplateKey]: string;
} & { beta?: Partial<ProjectTemplates> };

// `isPublic` is only settable by admins. We don't want to expose a way to set it in the app.
export type PublishTemplateAPIParams = Omit<ProjectPublishedTemplateInfo, 'isPublic'>;

export async function publishTemplate(
    ctx: AsyncContext,
    {
        projectId,
        templateInfo,
    }: {
        projectId: string;
        templateInfo: PublishTemplateAPIParams;
    },
) {
    const json = projectTemplatePublishInfoToJSON(templateInfo);
    return await ApiClient.request({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects/${projectId}/templates/publish`,
        data: json,
    });
}

export async function fetchPublicTemplates(ctx: AsyncContext): Promise<ProjectAndRevisions[]> {
    const jsonArray = await ApiClient.request<ProjectJson[]>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/templates/public`,
        query: {
            payload_version: 1,
        },
    });
    return projectAndRevisionsfromProjectJsons(jsonArray);
}

export async function fetchTemplatePreviews(
    ctx: AsyncContext,
    {
        projectId,
    }: {
        projectId?: string;
    },
): Promise<TemplatePreviewReadUrls> {
    const json = await ApiClient.request<TemplatePreviewReadUrlsJSON>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/templates/previews`,
    });
    return templatePreviewReadUrlsFromJSON(json);
}

export async function fetchUnauthedTemplatePreviews(
    ctx: AsyncContext,
    {
        projectId,
    }: {
        projectId?: string;
    },
): Promise<TemplatePreviewReadUrls> {
    const json = await ApiClient.publicApiRequest<TemplatePreviewReadUrlsJSON>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/templates/previews`,
    });
    return templatePreviewReadUrlsFromJSON(json);
}

/** Takes a URL for a remote file and uploads its contents into a pre-specified placeholder in the project **/
export async function addRemoteFileToProject(
    ctx: AsyncContext,
    {
        projectId,
        fileUrl,
        domainType,
        placeholderAssetId,
        placeholderArtifactId,
    }: {
        projectId: string;
        fileUrl: string;
        domainType: 'youtube' | 'direct';
        placeholderAssetId: string;
        placeholderArtifactId: string;
    },
): Promise<string> {
    const response = await ApiClient.request<{ workflowId: string }>({
        ctx,
        method: ApiClient.RequestType.POST,
        path: `/projects/${projectId}/add_file`,
        data: {
            fileUrl,
            placeholderAssetId,
            placeholderArtifactId,
            domainType,
        },
    });

    return response.workflowId;
}

export enum ImportFileWorkflowStatus {
    Running = 'running',
    Succeeded = 'succeeded',
    Failed = 'failed',
    Unknown = 'unknown',
}

export async function queryRemoteFileAddStatus(
    ctx: AsyncContext,
    addFileWorkflowId: string,
): Promise<{ status: ImportFileWorkflowStatus }> {
    return await ApiClient.request<{ status: ImportFileWorkflowStatus }>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: '/projects/add_file_status',
        query: { workflowId: addFileWorkflowId },
    });
}

/** Get the read URL for a projects scene thumbnail */
export async function getSceneThumbnailReadUrl(
    ctx: AsyncContext,
    projectId: string,
    hashedGraph: string,
) {
    const response = await ApiClient.request<{ url: string }>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/${hashedGraph}/thumbnail_read_url`,
    });

    return response;
}

/** Get the write URL for a projects scene thumbnail */
export async function getSceneThumbnailWriteUrl(
    ctx: AsyncContext,
    projectId: string,
    hashedGraph: string,
    md5: string,
) {
    const response = await ApiClient.request<{ url: string }>({
        ctx,
        method: ApiClient.RequestType.GET,
        path: `/projects/${projectId}/${hashedGraph}/thumbnail_write_url`,
        query: { md5 },
    });

    return response;
}
