// Copyright 2022 Descript, Inc

export const projectBrowser = '/';
export const drives = '/drives';
export const drive = `${drives}/:driveId`;
export const driveProjects = `${drive}/projects`;
export const driveTemplates = `${drive}/templates`;
export const recentProjects = `${drive}/recent-projects`;
export const quickRecordings = `${drive}/quick-recordings`;
export const sharedWithMe = `${drive}/shared-with-me`;
export const folders = `/folders`;
export const folder = `${folders}/:folderId`;
export const loader = '/loader';
export const invoices = '/invoices';
export const invoice = `${invoices}/invoiceId`;
export const voices = '/voices';
export const aiSpeakers = '/ai-speakers';
export const downloaded = '/downloaded';
export const learnDescript = '/learn-descript';
export const templates = '/templates';
export const voice = `${voices}/:voiceId`;
export const voiceTrainingSubmitted = (voiceId: string = ':voiceId') =>
    `${voices}/${voiceId}/submitted`;
export const workspaces = `/workspaces`;
export const workspace = `${workspaces}/:workspaceId`;
export const auth0AuthorizeCallback = '/auth0/authorize-callback';
export const auth0LogoutCallback = '/auth0/logout-callback';
export const accountLinking = '/account-linking';
export const preferences = '/preferences';
export const subscription = '/subscription';
export const plans = '/plans';
export const checkout = '/checkout';
export const checkoutConfirmation = '/checkout-confirmation';
export const updatePaymentMethod = '/update-payment-method';
export const updatePaymentMethodSuccess = '/update-payment-method-success';
export const createNewDrive = '/create-new-drive';
export const inviteDriveMemberPrefilled = '/invite-drive-member-prefill';
export const invitesForNewDrive = '/invites-for-new-drive';
export const account = '/account';
export const accountDialog = '/view/settings/account';
export const membersSearch = '?active=members';
export const members = `${accountDialog}${membersSearch}`;
export const generalSettings = `${accountDialog}?active=general`;
export const subscriptionSettings = `${accountDialog}?active=subscription`;
export const driveSubscriptionSettings = (driveId: string) =>
    `${subscriptionSettings}&driveId=${driveId}`;
export const newProjectForDrive = '/new/:drive';
export const newProjectForDefaultDrive = '/new';
export const duplicateProject = '/duplicate-project';
export const useSquadCast = '/use-squadcast';
export const embedAuth = '/embed-auth';
export const privateEmbedAuth = `/private${embedAuth}`;
export const joinDriveInviteLinkExternalEntrypoint = `${drive}/join`;
export const joinDriveInviteLink = '/join';
export const requireEmailVerificationPage = '/email-verification';
export const redirectToDesktop = '/redirect-to-desktop';
export const projectPrompt = '/project-prompt';

// Removed the `/browser/` part of the url, but keeping these around to redirect old links
export const legacyVoices = '/browser/voices';
export const legacyVoice = `${legacyVoices}/:voiceId`;
export const legacyMembers = '/members';

export const startReverseTrial = '/start-trial';
export const startTrialOffer = '/start-trial-offer';

export const zoomRecordingsOAuthCallback = '/zoom_oauth';
export const zoomRecordingsDesktopOAuthCallback = '/zoom_desktop_oauth';
export const zoomBrazeLink = '/link_zoom_account';

export const gotoRoom = '/goto-room';

// routes for the onboarding flow
export const onboarding = '/onboarding';
export const onboardingInitial = '/onboarding/initial';
export const onboardingMarketSegment = '/onboarding/market-segment';
export const onboardingEducationRole = '/onboarding/education';
export const onboardingPersonalRole = '/onboarding/personal';
export const onboardingWorkRole = '/onboarding/work';
export const onboardingRole = '/onboarding/role';
export const onboardingVerticalIntent = '/onboarding/first-project';
export const onboardingCheckout = '/onboarding/checkout';
export const onboardingPricing = '/onboarding/pricing';
export const onboardingWebCheckoutPricing = '/onboarding/web-checkout';
export const onboardingInvites = '/onboarding/invites';
export const onboardingDownload = '/onboarding/download';
export const onboardingDataConsent = '/onboarding/data-consent';
export const onboardingRecorder = '/onboarding/try-recorder';
export const onboardingProjectReadiness = '/onboarding/project-readiness';

export const AllOnboardingRoutesType = [
    onboarding,
    onboardingInitial,
    onboardingMarketSegment,
    onboardingEducationRole,
    onboardingPersonalRole,
    onboardingWorkRole,
    onboardingRole,
    onboardingVerticalIntent,
    onboardingCheckout,
    onboardingPricing,
    onboardingInvites,
    onboardingDownload,
    onboardingDataConsent,
    onboardingRecorder,
    onboardingProjectReadiness,
    onboardingWebCheckoutPricing,
] as const;
export type OnboardingRoutesType = (typeof AllOnboardingRoutesType)[number];
