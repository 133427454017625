// Copyright 2024 Descript, Inc
import {
    createContext,
    useCallback,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import * as React from 'react';

import { defaultDarkTheme } from './darkTheme';
import { defaultLightTheme } from './lightTheme';
import { getThemeCSS } from './theme';
import type { Theme, ThemeColors } from './types';
import { DescriptThemeOption } from './types';
import { useCustomTheme } from './useCustomTheme';
import { useDarkMode } from 'use-dark-mode-ts';

const { DARK, SYSTEM } = DescriptThemeOption;

/*
Web's NUserSettings.Application.colorScheme.get() fetches key "Application.colorScheme" from localStorage
Since we cannot leverage NUserSettings here, just use the key directly
*/
const COLOR_SCHEME_KEY = 'Application.colorScheme';

export const DARK_THEME_CLASS = 'dark-ok';
export const LIGHT_THEME_OVERRIDE_CLASS = 'light-override';

interface Props {
    colors?: ThemeColors;
    darkColors?: ThemeColors;
}

export interface ThemeProviderValue extends Theme {
    currentTheme: DescriptThemeOption;
    isDarkMode: boolean;
    lightColors: ThemeColors;
    darkColors: ThemeColors;
    setDarkColors(colors: ThemeColors): void;
    setLightColors(colors: ThemeColors): void;
    setTheme(theme: DescriptThemeOption): void;
}

export const ThemeContext = createContext<ThemeProviderValue>({
    ...defaultLightTheme,
    lightColors: defaultLightTheme.colors,
    darkColors: defaultDarkTheme.colors,
    currentTheme: SYSTEM,
    isDarkMode: false,
    setDarkColors: () => {
        // No-op
    },
    setLightColors: () => {
        // No-op
    },
    setTheme: () => {
        // No-op
    },
});

export function ThemeProvider({
    children,
    colors = defaultLightTheme.colors,
    darkColors = defaultDarkTheme.colors,
}: React.PropsWithChildren<Props>) {
    const [currentTheme, setCurrentTheme] = useState<DescriptThemeOption>(SYSTEM);
    const [currentLightColors, setCurrentLightColors] = useState(colors);
    const [currentDarkColors, setCurrentDarkColors] = useState(darkColors);

    useEffect(() => {
        setCurrentLightColors(colors);
    }, [colors]);
    useEffect(() => {
        setCurrentDarkColors(darkColors);
    }, [darkColors]);

    const isSystemDark = useDarkMode();

    const isDarkMode = useMemo(
        () => currentTheme === DARK || (currentTheme === SYSTEM && isSystemDark),
        [currentTheme, isSystemDark],
    );

    const customLightTheme = useCustomTheme(currentLightColors);
    const customDarkTheme = useCustomTheme(currentDarkColors);

    const lightThemeCSS = getThemeCSS(customLightTheme.colors);
    const darkThemeCSS = getThemeCSS(customDarkTheme.colors);

    useEffect(() => {
        let initialColorScheme: DescriptThemeOption;
        try {
            const localStorageColorScheme = localStorage.getItem(COLOR_SCHEME_KEY);
            if (localStorageColorScheme) {
                initialColorScheme = JSON.parse(localStorageColorScheme) as DescriptThemeOption;
            }
        } catch (err) {
            // no-op
        }

        // if nothing, just default to system options
        if (!initialColorScheme) {
            initialColorScheme = SYSTEM;
            localStorage.setItem(COLOR_SCHEME_KEY, JSON.stringify(SYSTEM));
        }

        // write to context
        setCurrentTheme(initialColorScheme);
    }, [isSystemDark]);

    // if editor changes theme in another tab, update it here too
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (!event?.key || event.key !== COLOR_SCHEME_KEY) {
                return;
            }

            try {
                const newTheme = JSON.parse(event.newValue) as DescriptThemeOption;

                if (
                    [
                        DescriptThemeOption.DARK,
                        DescriptThemeOption.LIGHT,
                        DescriptThemeOption.SYSTEM,
                    ].includes(newTheme)
                ) {
                    setCurrentTheme(newTheme);
                }
            } catch (err) {
                // no-op
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    // depending on initial theme or user input, change classes accordingly
    useLayoutEffect(() => {
        if (currentTheme === DARK || (currentTheme === SYSTEM && isSystemDark)) {
            document.documentElement.classList.add(DARK_THEME_CLASS);
        } else {
            document.documentElement.classList.remove(DARK_THEME_CLASS);
        }
    }, [currentTheme, isSystemDark]);

    const setTheme = useCallback((newTheme: DescriptThemeOption) => {
        setCurrentTheme(newTheme);
        localStorage.setItem(COLOR_SCHEME_KEY, JSON.stringify(newTheme));
    }, []);

    return (
        <ThemeContext.Provider
            value={{
                ...(isDarkMode ? customDarkTheme : customLightTheme),
                lightColors: customLightTheme.colors,
                darkColors: customDarkTheme.colors,
                currentTheme,
                isDarkMode,
                setDarkColors: setCurrentDarkColors,
                setLightColors: setCurrentLightColors,
                setTheme,
            }}
        >
            {children}
            {/* eslint-disable-next-line react/no-unknown-property */}
            <style jsx global>{`
                :root,
                .html,
                .${LIGHT_THEME_OVERRIDE_CLASS} {
                    ${lightThemeCSS}

                    color: var(--text-default);
                }
                .${DARK_THEME_CLASS} {
                    ${darkThemeCSS}

                    color: var(--text-default);
                }
            `}</style>
        </ThemeContext.Provider>
    );
}
