// Copyright 2018 Descript, Inc

import { fullWordsProperNounsRegex, properNouns } from '../constants';
import { clamp } from '../math/Clamp';

export const splitFilenameAndExtension = (input: string): [string, string] => {
    let basenameWithoutExtension: string = input;
    let extension = '';
    const components = input.split('.');
    const isHiddenFileNoExtension = components.length === 2 && components[0] === '';
    if (components.length > 1 && !isHiddenFileNoExtension) {
        extension = '.' + components.pop()!;
        basenameWithoutExtension = components.join('.');
    }
    return [basenameWithoutExtension, extension];
};

export function getBasename(input: string): string {
    return splitFilenameAndExtension(input)[0];
}

export function getExtension(input: string): string {
    return splitFilenameAndExtension(input)[1];
}

export function getExtensionWithoutDot(input: string): string {
    return splitFilenameAndExtension(input)[1].slice(1);
}

/** Capitalizes the first letter of a string. */
export function capitalize(str: string): string {
    return str.substring(0, 1).toLocaleUpperCase() + str.substring(1);
}

export function toggleCapitalization(str: string): string {
    const firstChar = str.charAt(0);
    const lowerCaseChar = firstChar.toLocaleLowerCase();
    const newFirstChar =
        firstChar === lowerCaseChar ? firstChar.toLocaleUpperCase() : lowerCaseChar;
    return newFirstChar + str.substring(1);
}

export function underscored(s: string): string {
    return s
        .trim()
        .replace(/([a-z\d])([A-Z]+)/g, '$1_$2')
        .replace(/[-\s]+/g, '_')
        .toLowerCase();
}

export function unUnderscored(
    s: string,
    options: { capitalize?: boolean; titlecase?: boolean } = {
        capitalize: false,
        titlecase: false,
    },
): string {
    const noUnderscores = s.trim().replace(/_/g, ' ');

    if (options.titlecase) {
        return noUnderscores.split(' ').map(capitalize).join(' ');
    } else if (options.capitalize) {
        return capitalize(noUnderscores);
    }

    return noUnderscores;
}

export function punctuate(s: string, punctuation: string = '.'): string {
    if (s[s.length - 1] === punctuation) {
        return s;
    }

    return s + punctuation;
}

export function trimTrailingSlash(str: string): string {
    return str.replace(/\/+$/, '');
}

export const uuidRegexWithoutAnchorsString =
    '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}';
export const uuidRegex = new RegExp(`^${uuidRegexWithoutAnchorsString}$`);
export function isUuid(str: string): boolean {
    return uuidRegex.test(str);
}

/**
 * V8 preserves the original string's memory when creating a slice/substring.
 * This function forces it to _not_ do that.
 *
 * See:
 * - Chromium: https://bugs.chromium.org/p/v8/issues/detail?id=2869
 * - Node: https://github.com/nodejs/node/issues/25846
 *
 * @param as
 */
export function forceV8Reallocate(s: string): string {
    return (' ' + s).slice(1) as string;
}

/** Determine if a piece of text is a link */
export function isLink(text?: string): boolean {
    return Boolean(text?.match(/^https?:/));
}

export function sentenceCase(input: string) {
    return capitalize(
        input
            .toLocaleLowerCase()
            .replace(fullWordsProperNounsRegex, (match) => properNouns.get(match) || match),
    );
}

const NON_WORD_CHAR_REGEX = /[\W_]/gu;

/**
 * Pre-processing function to clean the string for `fuzzyIndexOf`.
 * Removes non-word characters such as punctuation, symbols and whitespace, and converts to lowercase.
 */
export function cleanString(str: string): string {
    return (
        str
            // Remove all characters that are not word characters
            .replace(NON_WORD_CHAR_REGEX, '')
            .toLowerCase()
    );
}

export function isPlural(count: number): boolean {
    // NOTE: This will not work for other languages if we ever support non-English languages.
    return count !== 1;
}

/**
 * A version of String.indexOf that ignores differences in punctuation, capitalization, and whitespace. If `fromIndex`
 * is provided, the search will start at that index.
 */
export function fuzzyIndexOf(
    mainString: string,
    searchTerm: string,
    fromIndex?: number,
): number {
    if (fromIndex !== undefined) {
        fromIndex = clamp(fromIndex, 0, mainString.length);
        mainString = mainString.slice(fromIndex);
    }
    const cleanMainString = cleanString(mainString);
    const cleanSearchTerm = cleanString(searchTerm);

    // Find the index of the cleaned search term in the cleaned main string
    const index = cleanMainString.indexOf(cleanSearchTerm);

    if (index === -1) {
        return -1;
    }

    // If found, find the corresponding index in the original main string
    let count = 0;
    let originalIndex = 0;

    for (let i = 0; i < mainString.length; i++) {
        const char = mainString[i]!;

        // Skip over non-word characters
        if (!char.match(NON_WORD_CHAR_REGEX)) {
            if (count === index) {
                originalIndex = i;
                break;
            }
            count++;
        }
    }

    return originalIndex + (fromIndex ?? 0);
}

/**
 * Finds the start and end index of a `searchTerm` within a `mainString`, ignoring differences in punctuation,
 * capitalization, and whitespace.
 */
export function fuzzySearch(
    mainString: string,
    searchTerm: string,
    fromIndex?: number,
): { startIndex: number; endIndex: number } | undefined {
    const originalStartIndex = fuzzyIndexOf(mainString, searchTerm, fromIndex);
    if (originalStartIndex === -1) {
        return undefined;
    }

    // Find the length of the search term in the original main string
    const cleanSearchTermLength = cleanString(searchTerm).length;
    const nonWordCharsAtEndOfSearchTerm = searchTerm.match(/\W+$/)?.[0] ?? '';
    let nonWordCharsAtEndOfSearchTermIndex = 0;
    let originalEndIndex = originalStartIndex;
    let lengthCount = 0;
    for (let i = originalStartIndex; i < mainString.length; i++) {
        const char = mainString[i]!;

        // Skip over non-word characters
        if (!char.match(NON_WORD_CHAR_REGEX) && lengthCount <= cleanSearchTermLength) {
            lengthCount++;
            if (lengthCount === cleanSearchTermLength) {
                originalEndIndex = i + 1;
            }
        } else if (lengthCount >= cleanSearchTermLength) {
            // Match non-word characters at the end that are in both the search term and the main string
            const nonWordChar =
                nonWordCharsAtEndOfSearchTerm[nonWordCharsAtEndOfSearchTermIndex];
            if (nonWordChar && char === nonWordChar) {
                originalEndIndex = i + 1;
                nonWordCharsAtEndOfSearchTermIndex++;
            } else {
                break;
            }
        }
    }

    return { startIndex: originalStartIndex, endIndex: originalEndIndex };
}

export function extractAndParseJson(inputString: string): object | undefined {
    try {
        // First, try to parse the entire string as JSON
        try {
            return JSON.parse(inputString);
        } catch {
            // If that fails, try matching JSON within triple backticks (with or without the json keyword)
            const jsonFencedRegex = /```\s*(json\s*)?({[\s\S]*?})\s*```/;
            let match = inputString.match(jsonFencedRegex);

            if (match && match[2]) {
                // Parse the extracted JSON string
                return JSON.parse(match[2]);
            } else {
                // If that fails, try matching content within curly braces with escaped characters
                const embeddedJsonRegex = /{\\n\s*\\"[\s\S]*?\\n}/;
                match = inputString.match(embeddedJsonRegex);

                if (match) {
                    // Replace escape sequences and parse JSON
                    const jsonString = match[0].replace(/\\n/g, '').replace(/\\"/g, '"');
                    return JSON.parse(jsonString);
                }
            }
        }
        return undefined;
    } catch (error) {
        return undefined;
    }
}
