// Copyright 2022 Descript, Inc

import {
    CommitUploadResponse,
    CreatePlaceholderArtifactResponse,
    RemoteArtifact,
    RemoteAsset,
    RemoteAssetStore,
    Result,
} from '../AssetSync/Types';
import {
    ApiSegments,
    AssetApiResponse,
    AssetSyncApi,
    CommitUploadApiSuccessResponse,
    CreateAssetApiPayload,
    CreatePlaceholderArtifactApiRequestPayload,
    CreatePlaceholderArtifactApiResponse,
    mapApiArtifactToRemoteArtifact,
    mapApiAssetToRemoteAsset,
    mapApiPlaceholderArtifactToRemotePlaceholderArtifact,
} from '../AssetSync/api';
import * as ApiClient from './ApiClient';
import { AsyncContext } from '@descript/analytics';
import { MediaMetadata } from '@descript/descript-model';
import { DescriptError, ErrorCategory, Errors } from '@descript/errors';

let delegateToken: string | undefined;
export class RecordingAssetSyncApi extends AssetSyncApi implements RemoteAssetStore {
    isAvailable(): boolean {
        // Guests will not be fully logged in
        return ApiClient.isLoggedIn() || delegateToken !== undefined;
    }

    onlineInvariant() {
        if (!this.isAvailable()) {
            throw Errors.networkError(
                new DescriptError('We are offline right now', ErrorCategory.Recording),
            );
        }
    }

    setDelegateToken(token: string) {
        delegateToken = token;
    }

    override async createAssetRequest(
        ctx: AsyncContext,
        projectId: string,
        requestPayload: CreateAssetApiPayload,
    ): Promise<{ result: Result; asset: RemoteAsset }> {
        const prefix = this.getPrefix(projectId);
        const response = await ApiClient.request<AssetApiResponse>({
            ctx,
            method: ApiClient.RequestType.POST,
            path: `${prefix}/media_assets`,
            data: requestPayload,
            delegateAuth: delegateToken,
        });
        return {
            result: 'ok',
            asset: mapApiAssetToRemoteAsset(response.asset),
        };
    }

    override async writeUrlSegment(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        sequence: number,
        fileExtension: string,
        size: number,
        md5: string,
    ) {
        const prefix = this.getPrefix(projectId);
        const response = await ApiClient.request<{ url: string }>({
            ctx,
            method: ApiClient.RequestType.POST,
            path: `${prefix}/media_assets/${assetGuid}/artifacts/${artifactGuid}/segments/${sequence}/write_url`,
            data: {
                size,
                md5,
                file_extension: fileExtension,
            },
            delegateAuth: delegateToken,
        });
        return response.url;
    }

    override async createSegmentRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        sequence: number,
        isInit: boolean,
        fileExtension: string,
        size: number,
        md5: string,
        duration: number, // microseconds
        startTime: number, // microseconds
    ) {
        const prefix = this.getPrefix(projectId);
        const response = await ApiClient.request<{ segment: ApiSegments }>({
            ctx,
            method: ApiClient.RequestType.POST,
            path: `${prefix}/media_assets/${assetGuid}/artifacts/${artifactGuid}/segments`,
            data: {
                file_extension: fileExtension,
                size,
                md5,
                duration,
                sequence,
                is_init: isInit,
                start_time: startTime,
            },
            delegateAuth: delegateToken,
        });
        return response.segment;
    }

    override async createPlaceholderRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        requestPayload: CreatePlaceholderArtifactApiRequestPayload,
    ): Promise<CreatePlaceholderArtifactResponse> {
        const prefix = this.getPrefix(projectId);
        const response = await ApiClient.request<CreatePlaceholderArtifactApiResponse>({
            ctx,
            method: ApiClient.RequestType.POST,
            path: `${prefix}/media_assets/${assetGuid}/placeholder_artifacts`,
            data: requestPayload,
            delegateAuth: delegateToken,
        });
        return {
            result: 'ok',
            placeholderArtifact: mapApiPlaceholderArtifactToRemotePlaceholderArtifact(
                response.placeholderArtifact,
            ),
        };
    }

    override async reifySegmentedPlaceholderRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        segmentCount: number,
        mediaMetadata: MediaMetadata | undefined,
    ): Promise<RemoteArtifact> {
        const prefix = this.getPrefix(projectId);
        const response = await ApiClient.request<{ artifact: RemoteArtifact }>({
            ctx,
            method: ApiClient.RequestType.POST,
            path: `${prefix}/media_assets/${assetGuid}/artifacts/${artifactGuid}/reify_segmented`,
            data: {
                segment_count: segmentCount,
                metadata: { media: mediaMetadata },
            },
            delegateAuth: delegateToken,
        });
        return response.artifact;
    }

    override async commitUploadRequest(
        ctx: AsyncContext,
        projectId: string,
        assetGuid: string,
        artifactGuid: string,
        payload: { upload_token: string } | undefined,
    ): Promise<CommitUploadResponse> {
        const prefix = this.getPrefix(projectId);
        const response = await ApiClient.request<CommitUploadApiSuccessResponse>({
            ctx,
            method: ApiClient.RequestType.POST,
            path: `${prefix}/media_assets/${assetGuid}/artifacts/${artifactGuid}/commit`,
            data: payload,
            delegateAuth: delegateToken,
        });
        return {
            result: 'ok',
            artifact: mapApiArtifactToRemoteArtifact(response.artifact),
        };
    }

    getPrefix(projectId: string) {
        return delegateToken ? '/guest' : `/projects/${projectId}`;
    }
}
