// Copyright 2024 Descript, Inc
import { WebWorkerRequester, WorkerClient } from '@descript/web-workers';
import {
    WebRecorderRecoveryWorkerRequests,
    ListRecoveryFiles as ListRecoveryContents,
    SaveRecoveryFile,
    MoveTempDirectory,
    ReadRecoveryJson,
    DeleteRecoveryJson,
    DeleteArtifactDirectory,
    GetRecoverySegment,
    StitchSegments,
    ReadJson,
    DebugDump,
} from './WebRecorderRecoveryShared';
import { ArtifactRecoveryInfo, SaveRecoverySegmentInput } from '@descript/recorder-base';
import { RecoveryJsons } from './types';

let client: WorkerClient<WebRecorderRecoveryWorkerRequests> | undefined;

// NextJS doesn't have access to new Worker() at build time, so we need to lazy load it
function getClient() {
    if (client) {
        return client;
    }

    // #BACKEND_CJS
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore Our backend is using CJS
    const worker = new Worker(new URL('./WebRecorderRecovery.worker', import.meta.url), {
        name: 'WebRecorderRecovery',
    });

    client = new WorkerClient<WebRecorderRecoveryWorkerRequests>(
        new WebWorkerRequester(worker),
    );
    return client;
}

export async function checkRecorderRecovery() {
    const contents = await getClient().request(ListRecoveryContents, undefined);
    const recoveryInfos: ArtifactRecoveryInfo[] = [];
    for (const dir of contents.directories) {
        const json = await getClient().request(ReadRecoveryJson, dir);
        if (json) {
            if (!json.info.artifactId && !dir.includes('temp_')) {
                json.info.artifactId = dir;
            }
            for (const recorder of Object.values(json.session.recordersObj)) {
                if (!recorder.artifactGuid && !dir.includes('temp_')) {
                    recorder.artifactGuid = dir;
                }
            }
            recoveryInfos.push(json);
        }
    }
    return recoveryInfos;
}

export async function saveRecoverySegment(input: SaveRecoverySegmentInput) {
    return await getClient().request(SaveRecoveryFile, input);
}

export async function moveRecoveryTempDirectory(
    tempId: string,
    artifactId: string,
    assetId?: string,
) {
    return await getClient().request(MoveTempDirectory, { tempId, artifactId, assetId });
}

export async function recoverySegmentComplete(
    artifactId: string,
    segmentNumber: number,
): Promise<void> {
    await getClient().request(DeleteRecoveryJson, { artifactId, segmentNumber });
}

export async function recoveryArtifactComplete(artifactId: string) {
    await getClient().request(DeleteArtifactDirectory, artifactId);
}

export async function readRecoveryFile(artifactId: string, filename: RecoveryJsons) {
    return await getClient().request(ReadJson, { artifactId, filename });
}

export async function getSegmentData(artifactId: string, filename: string) {
    return await getClient().request(GetRecoverySegment, { artifactId, filename });
}

export async function stitchSegments(artifactId: string) {
    return await getClient().request(
        StitchSegments,
        { artifactId },
        {
            onCallback: (progress) => {
                // TODO: Add this to the UI/toast REC-4529 https://linear.app/descript/issue/REC-4529/[web-recorder]-convert-toasts-and-notifications-to-super-toast
            },
        },
    );
}

export async function debugDump() {
    return await getClient().request(DebugDump, undefined);
}

export async function downloadSessionRecoveries(sessionId: string) {
    const recoveries = await checkRecorderRecovery();
    const artifactIds = recoveries.reduce((acc, r) => {
        if (r.session.sessionId === sessionId) {
            acc.add(r.info.artifactId);
        }
        return acc;
    }, new Set<string>());
    for (const artifactId of artifactIds) {
        const files = await getClient().request(ListRecoveryContents, artifactId);
        const hasInitData = files.files.some(
            (f) => f.name !== '0.json' && f.name.includes('0.'),
        );
        if (!hasInitData) {
            await getClient().request(DeleteArtifactDirectory, artifactId);
            continue;
        }
        const recovery = recoveries.find((r) => r.info.artifactId === artifactId);
        const recorderMeta = recovery?.session.recordersObj[recovery?.info.recorderId];
        const filename = `${new Date().getTime()}-${recorderMeta?.mode || 'unknown'}`;
        const blob = await stitchSegments(artifactId);
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `recovered-${filename}.${blob.type.includes('audio') ? 'wav' : 'mp4'}`;
        a.click();
        void recoveryArtifactComplete(artifactId);
    }
}
