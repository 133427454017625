// Copyright 2022 Descript, Inc
/**
 * Test "fixtures" for dynamic feature flagging system
 *
 * This gives a way to statically configure default values for all dynamic feature flags during tests
 * (both local jest tests and CI), as well as a way to override them for when you want to test a
 * specific non-default configuration.
 *
 * Information about FeatureFlags: https://www.notion.so/descript/LaunchDarkly-Feature-Flags-def47cb764f84c5dad1801e21c90d557
 */

import { FeatureFlagName, FeatureFlagValue } from './types';
import { E2E_FEATURE_FLAG_VALUES } from './e2eFixtures';

const DEFAULT_VALUES = Object.freeze<Record<FeatureFlagName, FeatureFlagValue>>({
    'web-app-target-picker': false,
    'newline-button-row': false,
    'restore-removed-media-toolbar-button': false,
    'conductor-sidebar-name': undefined,
    'conductor-sidebar-name-override': '',
    'hide-card-rail-eligible': false,
    'hide-card-rail-default': false,
    'bottom-card-rail-default': false,
    'bottom-card-rail-eligible': false,
    'bottom-card-rail-override': false,
    'hide-card-rail-global-override': false,
    'new-media-range-picker-eligible': false,
    'new-media-range-picker-default': false,
    'new-media-range-picker-global-override': false,
    'labs-settings': false,
    'new-canvas-toolbars': false,
    'asset-upload-networking': 'fetch',
    'settings-redesign': false,
    'automatic-project-backups': true,
    'web-automatic-project-backups': false,
    'remove-drive-creation-from-bearer-function': false,
    'remove-drive-creation-from-bearer-function-invite-link-only': false,
    braze: false,
    'braze-content-cards': false,
    'braze-in-app-messaging': false,
    'child-process-canary-interval-ms': 0,
    'child-process-canary-timeout-ms': 0,
    'classic-speakers-submenu': false,
    'client-event-logging': false,
    'client-new-revert-logic': true,
    'correction-wizard': true,
    'draftjs-global-dom-selection': false,
    'duplicate-qem-projects-to-lc': true,
    'edu-nfp-plan-available': false,
    'edit-for-clarity-ai-speech-param': false,
    'edit-for-clarity-with-overdub': false,
    'edit-for-clarity-intensities': undefined,
    'ai-actions-new-edit-review-state': false,
    'json-upload-cache': 'write',
    'global-asset-sync': 'all-assets-read-write',
    'storyboard-new-project-checkbox': false,
    'learning-rail': false,
    'live-collab-commit-poll-period-seconds': 0,
    'live-collab-copy-local-commits-on-duplicate': false,
    'live-collab-in-new-storyboard-project': false,
    'live-collab-only-main-commits': false,
    'live-collab-restore-history': true,
    'live-collab-throttle-period-ms': 0,
    'live-collab-worker': false,
    'live-collab-doc-hashing-algorithm': false,
    'live-collab-sparse-mode': false,
    'live-collab-cursor-in-doc': false,
    'live-collab-disallow-lower-version-doc-update': false,
    'live-collab-sync-doc-update': false,
    'live-collab-use-redux-state-for-base-ref': false,
    'live-collab-echoing-commits-branch-fix': false,
    'tts-release-channel': 'prod',
    'manage-auth-identities': true,
    'manual-proxy-generation-video': false,
    'media-lib-load-doc-autosave': true,
    'monthly-proration-on-immediate-upgrades': false,
    'multitrack-script': false,
    multiwindow: true,
    'offline-storage-location': true,
    'onboarding-checklist': false,
    'exp-show-recorder-by-descript-onboarding': 'not-in-experiment',
    'drive-view-standalone-recorder-options': 'control',
    'onboarding-routes': undefined,
    'otel-redux-trace': false,
    'overdub-promote-voice-model': '',
    'overdub-training-project-uses-live-collab': false,
    'overdub-regenerate-prefix-suffix-duration': 2,
    'properties-panel-slip': false,
    'publish-ewebinar-integration': false,
    'publish-podbean-integration': false,
    'publish-blubrry-integration': false,
    'publish-podcast-co-integration': false,
    'publish-video-ask-integration': false,
    'publish-captivate-fm-integration': false,
    'publish-hello-audio-integration': false,
    'publish-castos-integration': false,
    'publish-restream-integration': false,
    'publish-wistia-integration': false,
    'publish-transistor-integration': false,
    'publish-to-google-drive': false,
    'quick-recorder-record-and-edit': false,
    'quick-recorder-version': 1,
    'translation-bulk-generate': false,
    'translation-bulk-export': false,
    'storyboard-demo-project-id': '',
    'subscription-modal-default-to-annual': true,
    'redesign-drive-view-storyboard': false,
    'redux-connect-impl': 'deep-equality',
    'redux-actions-batching-type': 'tick',
    'redux-actions-throttle': false,
    'replace-script-track': false,
    'voice-id-browser-recorder': 'none',
    'zendesk-chat': false,
    'zendesk-live-chat-access-level': 'none',
    /**
     * -1 is a special value that means the feature is disabled
     * since LaunchDarkly numeric flags can only return numbers
     * when targeting is on.
     */
    'zendesk-live-chat-feature-tier': -1,
    'l10n-languages-transcription': undefined,
    'l10n-beta-tags-transcription': undefined,
    'legacy-browser-new-template-button': false,
    'new-speaker-merge-ux': false,
    'cloud-provider-giphy': false,
    'edit-in-descript': true,
    'cloud-library-safe-search': false,
    'reverse-trials': false,
    'automatic-reverse-trials-for-new-free-drives': false,
    'allow-reverse-trials-for-existing-free-drives': false,
    'scene-background-color': false,
    'transcription-window-minutes': false,
    'transcription-window-minutes-short': false,
    'demo-to-pip-url-slug': undefined,
    'pip-embed-params': undefined,
    'fast-export-honor-client-watermark-request': false,
    'failed-payment-banner': true,
    'storyboard-upgrade-banner': false,
    'storyboard-banner-message':
        'Want to try Descript Storyboard? *Copy this project to Storyboard* and explore the new features.',
    'stripe-customer-portal': true,
    'create-classic-project': false,
    'drive-conductor': true,
    'proxy-keyframe-interval-threshold-ms': 0,
    'media-engine-new-fps-calculation': false,
    'word-gap-over-text-cursor': false,
    'duplicate-normal-project-as-new-template': false,
    'sequence-track-include-transcript-toggle': false,
    'timeline-tools-redesign': false,
    'email-subscription-groups': false,
    'close-qem-on-publish': false,
    'dall-e': false,
    'dall-e-variations': false,
    'use-canvas-state-machine': true,
    'eye-contact': false,
    'export-banner': false,
    'uvm-voices': false,
    'disable-white-glove': false,
    'hedra-avatar-prototype': false,
    'use-cloud-aligner': false,
    'smarter-drive-default': true,
    'use-msft-win-camera-api': false,
    'remove-live-transcription-visualization': false,
    'squadcast-entry-points': false,
    'timeline-zoomable-scrollbar': false,
    'wordless-media-ellipses-kill-switch': true,
    'wordless-media-ellipses': false,
    'wordless-media-allow-correct': false,
    'video-overdub-layer': false,
    'direct-to-web': false,
    'you-tube-publish-on-web': false,
    'plus-insert-button': false,
    'ia-refactor': 'control',
    'ai-speech-autogen-debounce-ms': 5000,
    'screen-capture-kit-audio': false,
    'eye-contact-in-prod': false,
    'include-at-sign-in-speaker-label': false,
    'uvm-stock-speakers': false,
    'ai-speech-controls': false,
    'ai-speech-controls-kill-switch': false,
    'bulk-actions': false,
    'bulk-improvements': false,
    'bulk-delete': false,
    'move-popover-starred-folders': false,
    'query-param-allowlist': '',
    'insert-from-the-script-default': false,
    'insert-from-the-script-global-override': '',
    'independent-rounded-corners': false,
    'multi-drive-admin': false,
    'live-collab-write-commits-buffer': false,
    'export-use-ffmpeg-compositor': true,
    'web-media-local-export': false,
    'timeline-redesign-default': false,
    'timeline-redesign-global-override': false,
    'timeline-redesign-labs-eligible': false,
    'timeline-script-layers': false,
    'new-project-privacy': true,
    'automatic-speaker-detection': false,
    'ed-workflow-save-layout-flow': false,
    'windows-quick-progressive-upload': 1,
    'publish-privacy-drive-settings': false,
    'new-file-lifecycle-ui': false,
    'transcription-in-supertoast': false,
    'recorder-allow-access-to-sckit-error-object': false,
    'file-lifecycle-media-library': false,
    'ed-workflow-speaker-focus': false,
    'template-browser-redesign': false,
    'empty-project-starter-state': 'control',
    'desktop-supertoast': false,
    'recorder-zoom-recordings-import': true,
    'prefetch-media-on-timeline-hover': 0,
    'prefetch-media-on-script-hover': 0,
    'acceleration-aware-prefetch': false,
    'mts-ai-effects': [],
    'max-gop-file-warning': 300,
    'default-clip-video-template': '',
    'default-multicam-template': '',
    'default-template-browser': '',
    'web-enable-live-tx': false,
    'web-export-multi-scope': false,
    'regenerate-release-channel': 'prod',
    'shorten-word-gap-remove-filler-words-paywall': true,
    'web-recorder-rainbow-audio-bars-easter-egg': true,
    'web-max-file-upload-size-gb': 5,
    'transcription-auto-detect-exp': 'not-in-experiment',
    'client-make-web-proxy': true,
    'magic-multicam': false,
    'automatic-multicam-template-param': false,
    'window-dressing-action': false,
    'generate-visuals-action': false,
    'clips-model-experiment-variant': 'v3-gpt',
    'timeline-computation-on-worker': false,
    'collaborative-recorder': false,
    'web-flatten-tracks': false,
    'fetch-paywall-usage-debounce-time': 0,
    'dynamic-payment-methods': false,
    'exp-alternate-payment-methods': ['card'],
    'exp-web-checkout': undefined,
    'web-timeline-export': false,
    'skip-artifact-md5': false,
    'video-regenerate-default': false,
    'video-regenerate-eligible': false,
    'video-regenerate-global-override': '',
    'zoom-auto-import': false,
    'zoom-show-browse-page-card': false,
    'ai-usage-dashboard': false,
    'ai-usage-dashboard-admin-view': false,
    'web-mic-bleed-detection': false,
    'exp-simplify-onboarding-3.1': 'true-control',
    'exp-start-trial-url': 'true-control',
    'start-trial-from-allow-list': 'true-control',
    'exp-onboarding-plans-page-update': 'not-in-experiment',
    'deprecate-uvm-stock-voices': false,
    'exp-studio-sound-default-intensity': 'not-in-experiment',
    'ed-smart-media-panel-defaults': false,
    'live-collab-presence': false,
    'regenerate-toolbar-and-overdub-rename-default': false,
    'regenerate-toolbar-and-overdub-rename-eligible': false,
    'regenerate-toolbar-and-overdub-rename-global-override': '',
    'workflows-save-dimensions-of-template-card': false,
    'ed-classic-deprecation-soon-dialog': false,
    'ed-classic-deprecated-now-dialog': false,
    'editor-smart-transitions': false,
    'editor-smart-transitions-eligible': false,
    'editor-smart-transitions-override': '',
    'enable-forethought-chatbot': false,
    'youtube-publish-category-field': false,
    'exp-onboarding-over-editor': 'not-in-experiment',
    'exp-workflows-layouts-2.1-release': 'excluded-from-experiment',
    'ed-replacemedia-button-on-canvas-toolbar': false,
    'rooms-host-device-controls': false,
    'desktop-2-upgrade-button': false,
    'ed-sceneboundary-click-layout-picker': false,
    'ed-new-textselection-toolbar-oct2024': false,
    'workflows-group-by-composition-name': false,
    'workflows-favorite-layouts': false,
    'desktop-2-auto-upgrade': 'off',
    'rooms-billing': false,
    'multilingual-aligner-for-tts-enabled': false,
    'ed-layout-picker-script-shortcut-key': false,
    'rooms-enable-firefox': false,
    'rooms-enable-safari': false,
    'exp-deemphasize-free-plan-in-onboarding': 'excluded-from-experiment',
    'ed-copy-paste-split-card': false,
    'erw-lossless-audio-allowed': false,
    'blur-background': false,
    'web-recorder-fps-analytics': false,
    'recorder-support-dynamic-resolution': false,
    'quick-recorder-enable-live-tx': false,
    'ed-remove-frame-snapping': false,
    'erw-enable-live-tx': false,
    'exp-preview-descript-watermark': 'excluded-from-experiment',
    'exp-hide-braze-in-product-surfaces': 'excluded-from-experiment',
    'volume-as-percentage': false,
    'exp-pricing-experiment-monthly-increase-by-25': 'excluded-from-experiment',
    'web-recorder-audio-tracking-analytics': false,
    'exp-free-drive-dropdown-upgrade-upsell': 'excluded-from-experiment',
    'web-storage-monitor': false,
    'project-prompt-modal': true,
    'exp-recorder-video-bitrate': 'excluded-from-experiment',
    'exp-rebrand-publish-tab-its-all-export': 'excluded-from-experiment',
    'ed-effects-amins-transitions-in-toolbars': false,
    'copy-paste-quality-refactor': false,
    'image-style-prompts': {},
    'exp-pricing-experiment-monthly-increase-by-equal-amounts': 'excluded-from-experiment',
    // AUTOMATED_FEATURE_FLAG_CREATION
    'file-upload-paywall-enabled': true,
    'sidebar-template-pane': false,
    'identify-user-from-backend': false,
    'gallery-template-categories': undefined,
    'learn-descript-v2-sbrm': {},
    'exp-churn-variants': 'not-in-experiment',
    'exp-churn-key-preview-flag': false,
    'workflows-placeholder-layers': false,
    'web-file-manipulation': false,
    'rounded-text-bg': false,
    'underlord-recommendation-editor': false,
    'speech-enhancement-version': 'v1',
    'workflows-auto-text-bounding-box': false,
    'exp-upselling-annual-subscriptions': 'percentage_not_in_exp',
    'workflows-text-bounding-box-background': false,
    'exp-enforce-storage-limits': 'excluded-from-experiment',
    'live-collab-track-redux-trimerge-refs': false,
    'exp-trials-main': 'not-in-experiment',
    'exp-enable-alternate-payment-methods-in-web-checkout-for-onboarding': false,
    'exp-churnkey-kill-switch': false,
    'disable-tts-instead-of-gibberish': false,
    'exp-churnkey-paywall-type': 'soft',
    'exp-churnkey-check-pause-wall': false,
    'eleven-labs-multilingual-tts': false,
    'eleven-labs-for-stock-speaker-dub': false,
    'eleven-labs-for-voice-clones': false,
    'live-collab-merge-strategy-override': undefined,
    'script-tokens': false,
});

let CURRENT_VALUES: Record<FeatureFlagName, FeatureFlagValue> = {
    ...(DescriptFeatures.UI_TEST ? E2E_FEATURE_FLAG_VALUES : DEFAULT_VALUES),
};

export const DynamicFlagFixtures = {
    getValue(flagName: FeatureFlagName): FeatureFlagValue {
        return CURRENT_VALUES[flagName];
    },
    setOverrides(flagValues: Partial<Record<FeatureFlagName, FeatureFlagValue>>): void {
        CURRENT_VALUES = { ...CURRENT_VALUES, ...flagValues };
    },
    clearOverrides(): void {
        CURRENT_VALUES = { ...DEFAULT_VALUES };
    },
    getAllValues(): Record<FeatureFlagName, FeatureFlagValue> {
        return CURRENT_VALUES;
    },
};
