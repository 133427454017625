// Copyright 2022 Descript, Inc
import * as FeatureFlagsClient from '../Api/FeatureFlagsClient';
import { allFeatureFlags, FeatureFlagName, FeatureFlagValue } from './types';
import * as NUserSettings from '../App/UserSettings';
import { setGlobalErrorMetadata, GlobalErrorMetadata } from '../Utilities/ErrorTracker';
import * as ApiClient from '../Api/ApiClient';
import sleep from '../Utilities/sleep';
import { DynamicFlagFixtures } from './fixtures';
import { produce } from 'immer';
import { createScopedLogger, entries, PlatformHelpers } from '@descript/descript-core';
import { setGlobalDefaultMetadata } from '../Utilities/Tracing/OpenTelemetry';
import { AssetTracingMetadata, AsyncContext, errorCategoryContext } from '@descript/analytics';
import { DescriptError, ErrorCategory, Errors } from '@descript/errors';

const defaultCtx = errorCategoryContext(ErrorCategory.AppArchitecture);

const logger = createScopedLogger({
    name: 'FEATURE_FLAGS',
});

/**
 * Loops through all feature flags and tries to sync them periodically
 *
 * This will loop forever, syncing every 5 mins. Clients have initial jitter
 * so that they don't all try to sync feature flags at the same time.
 */
export class FeatureFlagsSyncLoop {
    static syncIntervalMs = 1000 * 60 * 5;
    private static instance: FeatureFlagsSyncLoop; // Singleton

    static get(userId: string | undefined): FeatureFlagsSyncLoop {
        if (!this.instance) {
            this.instance = new FeatureFlagsSyncLoop(userId);
        }

        // If the user has changed, start a sync immediately. Since logging in
        // is done by a person, it should be naturally slightly jittered.
        if (this.instance.userId !== userId) {
            this.instance.pause();
            this.instance = new FeatureFlagsSyncLoop(userId);
        }
        return this.instance;
    }

    private constructor(private readonly userId: string | undefined) {}

    private nextSyncPromise: Promise<void> = Promise.resolve();
    private isEnabled = false;

    get nextSync(): Promise<void> {
        return this.nextSyncPromise;
    }

    start(): Promise<void> {
        if (!this.isEnabled) {
            this.isEnabled = true;
            this.nextSyncPromise = this.waitAndTrySyncAll(0);
        }
        return this.nextSyncPromise;
    }

    pause(): void {
        this.isEnabled = false;
    }

    private async waitAndTrySyncAll(
        waitTime = FeatureFlagsSyncLoop.syncIntervalMs,
    ): Promise<void> {
        if (this.isEnabled) {
            try {
                if (waitTime) {
                    await sleep(waitTime);
                }
                await this.syncNow(defaultCtx());
            } finally {
                this.nextSyncPromise = this.waitAndTrySyncAll().catch((e) =>
                    logger.warn(`${e}`),
                );
            }
        }
    }

    async syncNow(ctx: AsyncContext): Promise<void> {
        // Lock userId for duration of this sync
        const userId = this.userId;
        try {
            for (const featureFlagsChunk of chunks(allFeatureFlags, 10)) {
                await syncFeatureFlags(ctx, userId, featureFlagsChunk);
            }
            updateGlobalsWithNewFeatureFlags();
        } catch (e) {
            if (!Errors.isNetworkError(e as Error)) {
                logger.warn(`${e}`);
            }
        }
    }
}

export function updateGlobalsWithNewFeatureFlags(): void {
    updateErrorContext();
    updateDefaultMetadata();
}

function* chunks<T>(array: Iterable<T>, chunkSize: number): Generator<T[]> {
    let nextChunk: T[] = [];
    for (const item of array) {
        nextChunk.push(item);
        if (nextChunk.length >= chunkSize) {
            yield nextChunk;
            nextChunk = [];
        }
    }
    if (nextChunk.length > 0) {
        yield nextChunk;
    }
}

/**
 * Snapshot feature flags from server and store in NUserSettings
 *
 * @param features List of feature flags to fetch. If not passed, will fetch all.
 */
async function syncFeatureFlags(
    ctx: AsyncContext,
    userId: string | undefined,
    features: readonly FeatureFlagName[] = allFeatureFlags,
): Promise<void> {
    // API has a limit of 10 right now
    if (features.length > 10) {
        throw new DescriptError(
            'Must have 10 or fewer feature flags',
            ErrorCategory.AppArchitecture,
        );
    }
    if (!ApiClient.isLoggedIn()) {
        return;
    }
    if (userId === undefined) {
        // TODO: support anonymous feature flags
        return;
    }
    const newSettings = await FeatureFlagsClient.fetchFeatureFlags(ctx, {
        features,
    });

    const oldSettings = NUserSettings.Application.featureFlags.getForAccount(userId) || {};
    const updatedSettings = produce(oldSettings, (draft) => {
        for (const [name, value] of entries(newSettings)) {
            // Rely on immer to ignore noop assignments
            draft[name] = value;
        }
    });
    const didChange = updatedSettings !== oldSettings;
    if (didChange) {
        NUserSettings.Application.featureFlags.setForAccount(userId, updatedSettings);
    }

    logger.debug(`Feature flags synced ${didChange ? '(changed)' : '(no change)'}`);
}

/**
 * Get a feature flag from NUserSettings.
 *
 * This will warn but still work if `syncFeatureFlags()` has not been called,
 * because we need this to work offline as well.
 *
 * WARNING: Use `getFeatureFlagMaybe` or `useFeatureFlag` for use in Redux and React
 * or whenever there is access to the Redux store! Otherwise you may end up with a
 * stale value. This function will NOT change when feature flags are updated unless
 * there is a manual call to `syncFeatureFlags()`.
 *
 * @param feature Feature flag to fetch
 */
export function getInitialFeatureFlag<T>(
    feature: FeatureFlagName,
    defaultValue?: FeatureFlagValue,
): T | undefined {
    if (
        PlatformHelpers.buildType === PlatformHelpers.BuildType.testRunner ||
        DescriptFeatures.UI_TEST
    ) {
        return DynamicFlagFixtures.getValue(feature) as T | undefined;
    }

    return (NUserSettings.Application.featureFlags.get()[feature] ?? defaultValue) as
        | T
        | undefined;
}

/**
 * Get all feature flags from NUserSettings
 */
export function getAllFeatureFlags(): Partial<Record<FeatureFlagName, FeatureFlagValue>> {
    if (
        PlatformHelpers.buildType === PlatformHelpers.BuildType.testRunner ||
        DescriptFeatures.UI_TEST
    ) {
        return DynamicFlagFixtures.getAllValues();
    }
    if (!NUserSettings.Application || !NUserSettings.Application.featureFlags) {
        return {};
    }
    if (
        !NUserSettings.Application.featureFlags.isSet() &&
        !NUserSettings.Application.featureFlagOverrides.isSet() &&
        !NUserSettings.Application.publicFeatureFlags.isSet()
    ) {
        return {};
    }
    return {
        ...NUserSettings.Application.publicFeatureFlags.get(),
        ...NUserSettings.Application.featureFlags.get(),
        ...NUserSettings.Application.featureFlagOverrides.get(),
    };
}

const FLAGS_FOR_CONTEXT: Set<FeatureFlagName> = new Set([
    'live-collab-throttle-period-ms',
    'multiwindow',
    'offline-storage-location',
]);
function updateErrorContext(): void {
    const ffVals = getAllFeatureFlags();
    const context: Record<string, string> = {};
    const errMetadata: Partial<GlobalErrorMetadata> = {};
    for (const name of FLAGS_FOR_CONTEXT) {
        const val = ffVals[name];
        if (val && typeof val !== 'object') {
            context[name] = String(val);
            if (errMetadata.contexts === undefined) {
                errMetadata.contexts = { 'Feature Flags': context };
            }
        }
    }
    if (errMetadata.contexts) {
        setGlobalErrorMetadata(errMetadata);
    }
}

const FLAGS_FOR_SPAN_ATTRIBUTES: Set<FeatureFlagName> = new Set([
    'live-collab-throttle-period-ms',
    'multiwindow',
    'edit-in-descript',
]);
function updateDefaultMetadata(): void {
    const ffVals = getAllFeatureFlags();
    const attributes: AssetTracingMetadata = {};
    for (const name of FLAGS_FOR_SPAN_ATTRIBUTES) {
        const val = ffVals[name];
        if (val && typeof val !== 'object') {
            attributes[`app.feature_flag.${name}`] = val;
        }
    }
    setGlobalDefaultMetadata(attributes);
}
