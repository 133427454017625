// Copyright 2024 Descript, Inc

import { trackEvent } from '@descript/analytics';
import {
    RecordingAnalyticsEvents,
    RecordingEventProps,
    WebRecordingSession,
} from '@descript/recorder-base';

function fixedPrecisionNumber(num: number, fractionalDigits: number): number {
    return Number(num.toFixed(fractionalDigits));
}

export function trackRecordingAnalyticsEvent(
    session: WebRecordingSession,
    event: RecordingAnalyticsEvents,
    extraProps?: Record<string, unknown>,
    recorderId?: string,
) {
    let screenMeta, videoMeta, audioMeta;

    if (recorderId) {
        const rec = session.recorders.get(recorderId);
        switch (rec?.mode) {
            case 'screen':
                screenMeta = rec;
                break;
            case 'video':
                videoMeta = rec;
                break;
            case 'audio':
                audioMeta = rec;
                break;
            default:
                break;
        }
    } else if (session?.recorders?.size) {
        for (const rec of session.recorders.values()) {
            switch (rec.mode) {
                case 'screen':
                    screenMeta = rec;
                    break;
                case 'video':
                    videoMeta = rec;
                    break;
                case 'audio':
                    audioMeta = rec;
                    break;
                default:
                    break;
            }
        }
    }

    const kind = screenMeta ? 'screen' : videoMeta ? 'camera' : audioMeta ? 'audio' : 'unknown';
    const sessionProps: RecordingEventProps = {
        session_id: session?.sessionId,
        target: session?.target?.type,
        user_agent: session?.environment?.user_agent,
        app_version: session?.environment?.app_version,
        platform: session?.environment?.platform,
        browser: session?.environment?.browser,
        browser_version: session?.environment?.browser_version,
        os: session?.environment?.os,
        os_version: session?.environment?.os_version,
        arch: session?.environment?.arch,
        kind,
        // Include all of these if not a specific recorder
        has_microphone: recorderId
            ? undefined
            : (videoMeta?.hasAudio ?? audioMeta?.hasAudio ?? false),
        has_camera: recorderId ? undefined : (videoMeta?.hasVideo ?? false),
        has_screen: recorderId ? undefined : (screenMeta?.hasVideo ?? false),
        has_computer_audio: recorderId ? undefined : (screenMeta?.hasAudio ?? false),
        transcription:
            (session?.microphoneTrackSettings?.transcriptionEnabled ||
                session?.computerAudioTrackSettings?.transcriptionEnabled) ??
            false,
        resolution_analytics: {
            configured_resolution: session?.resolutionAnalytics?.configuredResolution,
            actual_resolution: session?.resolutionAnalytics?.actualResolution,
            delta_resolution: session?.resolutionAnalytics?.deltaResolution,
        },
        studio_sound:
            (session?.microphoneTrackSettings?.studioSoundEnabled ||
                session?.computerAudioTrackSettings?.studioSoundEnabled) ??
            false,
        ...(extraProps?.countdown !== undefined ? { countdown: !!extraProps.countdown } : {}),
    };
    const audioProps: Partial<RecordingEventProps> = {
        ...(session?.equipment instanceof Map
            ? { audio_device_label: session?.equipment?.get('audio')?.label }
            : {}),
        has_microphone: sessionProps.has_microphone ?? audioMeta?.hasAudio,
        audio_size_mb: audioMeta ? fixedPrecisionNumber(audioMeta.size_mb ?? 0, 4) : undefined,
        audio_echo_cancellation: audioMeta?.audioSettings?.echoCancellation,
    };

    const videoProps: Partial<RecordingEventProps> = {
        ...(session?.equipment instanceof Map
            ? { camera_device_label: session?.equipment?.get('video')?.label }
            : {}),
        has_camera: sessionProps.has_camera ?? videoMeta?.hasVideo,
        default_camera_resolution: videoMeta
            ? `${videoMeta?.videoSettings?.width}x${videoMeta?.videoSettings?.height}`
            : undefined,
        camera_height: videoMeta?.videoSettings?.height,
        camera_width: videoMeta?.videoSettings?.width,
        video_size_mb: videoMeta ? fixedPrecisionNumber(videoMeta.size_mb ?? 0, 4) : undefined,
        // Audio props
        has_microphone: videoMeta?.hasAudio,
        audio_echo_cancellation: videoMeta?.audioSettings?.echoCancellation,
    };

    const screenProps: Partial<RecordingEventProps> = {
        has_screen: sessionProps.has_screen ?? screenMeta?.hasVideo,
        default_screen_resolution: screenMeta
            ? `${screenMeta?.videoSettings?.width}x${screenMeta?.videoSettings?.height}`
            : undefined,
        screen_height: screenMeta?.videoSettings?.height,
        screen_width: screenMeta?.videoSettings?.width,
        screen_size_mb: screenMeta
            ? fixedPrecisionNumber(screenMeta.size_mb ?? 0, 4)
            : undefined,
        // Audio props
        has_computer_audio: sessionProps.has_computer_audio ?? screenMeta?.hasAudio,
        screen_echo_cancellation: screenMeta?.audioSettings?.echoCancellation,
    };

    const fpsAnalyticsProps: Partial<RecordingEventProps> = {
        fps_analytics: {
            mode: kind === 'screen' ? 'screen' : 'video',
            media_stream: {
                avg_fps: session?.fpsAnalytics?.mediaStream?.avgFPS,
                most_common_range: session?.fpsAnalytics?.mediaStream?.mostCommonRange,
                most_common_count: session?.fpsAnalytics?.mediaStream?.mostCommonCount,
                best_fps: session?.fpsAnalytics?.mediaStream?.bestFPS,
                worst_fps: session?.fpsAnalytics?.mediaStream?.worstFPS,
                total_samples: session?.fpsAnalytics?.mediaStream?.totalSamples,
            },
            demuxer: {
                avg_fps: session?.fpsAnalytics?.demuxer?.avgFPS,
                most_common_range: session?.fpsAnalytics?.demuxer?.mostCommonRange,
                most_common_count: session?.fpsAnalytics?.demuxer?.mostCommonCount,
                best_fps: session?.fpsAnalytics?.demuxer?.bestFPS,
                worst_fps: session?.fpsAnalytics?.demuxer?.worstFPS,
                total_samples: session?.fpsAnalytics?.demuxer?.totalSamples,
            },
            delta_metrics: {
                avg_fps_delta: session?.fpsAnalytics?.deltaMetrics?.avgFPSDelta,
                sample_count_delta: session?.fpsAnalytics?.deltaMetrics?.sampleCountDelta,
                worst_fps_delta: session?.fpsAnalytics?.deltaMetrics?.worstFPSDelta,
                best_fps_delta: session?.fpsAnalytics?.deltaMetrics?.bestFPSDelta,
            },
        },
    };

    const bitrateAnalyticsProps: Partial<RecordingEventProps> = {
        bitrate_analytics: {
            mode: kind === 'screen' ? 'screen' : 'video',
            demuxer: {
                avg_bitrate: session?.bitrateAnalytics?.demuxer?.avgBitrate,
                peak_bitrate: session?.bitrateAnalytics?.demuxer?.peakBitrate,
                min_bitrate: session?.bitrateAnalytics?.demuxer?.minBitrate,
                total_bytes: session?.bitrateAnalytics?.demuxer?.totalBytes,
                total_duration: session?.bitrateAnalytics?.demuxer?.totalDuration,
                sample_count: session?.bitrateAnalytics?.demuxer?.sampleCount,
            },
            media_stream: {
                avg_bitrate: session?.bitrateAnalytics?.mediaStream?.avgBitrate,
                peak_bitrate: session?.bitrateAnalytics?.mediaStream?.peakBitrate,
                min_bitrate: session?.bitrateAnalytics?.mediaStream?.minBitrate,
                total_bytes: session?.bitrateAnalytics?.mediaStream?.totalBytes,
                total_duration: session?.bitrateAnalytics?.mediaStream?.totalDuration,
                sample_count: session?.bitrateAnalytics?.mediaStream?.sampleCount,
            },
            configured_bitrate: session?.bitrateAnalytics?.configuredBitrate,
        },
    };

    const audioAnalyticsProps: Partial<RecordingEventProps> = {
        audio_analytics: {
            mode: kind === 'screen' ? 'screen' : 'video',
            demuxer: {
                sample_rate: session?.audioAnalytics?.demuxer?.sampleRate,
                channel_count: session?.audioAnalytics?.demuxer?.channelCount,
                bit_depth: session?.audioAnalytics?.demuxer?.bitDepth,
                avg_bitrate: session?.audioAnalytics?.demuxer?.avgBitrate,
                peak_bitrate: session?.audioAnalytics?.demuxer?.peakBitrate,
                min_bitrate: session?.audioAnalytics?.demuxer?.minBitrate,
                total_bytes: session?.audioAnalytics?.demuxer?.totalBytes,
                total_duration: session?.audioAnalytics?.demuxer?.totalDuration,
                sample_count: session?.audioAnalytics?.demuxer?.sampleCount,
            },
            media_stream: {
                sample_rate: session?.audioAnalytics?.mediaStream?.sampleRate,
                channel_count: session?.audioAnalytics?.mediaStream?.channelCount,
                bit_depth: session?.audioAnalytics?.mediaStream?.bitDepth,
                avg_bitrate: session?.audioAnalytics?.mediaStream?.avgBitrate,
                peak_bitrate: session?.audioAnalytics?.mediaStream?.peakBitrate,
                min_bitrate: session?.audioAnalytics?.mediaStream?.minBitrate,
                total_bytes: session?.audioAnalytics?.mediaStream?.totalBytes,
                total_duration: session?.audioAnalytics?.mediaStream?.totalDuration,
                sample_count: session?.audioAnalytics?.mediaStream?.sampleCount,
            },
            configured_audio: {
                sample_rate: session?.audioAnalytics?.configuredAudio?.sampleRate,
                channel_count: session?.audioAnalytics?.configuredAudio?.channelCount,
                bit_depth: session?.audioAnalytics?.configuredAudio?.bitDepth,
            },
        },
    };

    const eventProps: RecordingEventProps = {
        ...sessionProps,
        ...audioProps,
        ...videoProps,
        ...screenProps,
        ...fpsAnalyticsProps,
        ...bitrateAnalyticsProps,
        ...audioAnalyticsProps,
        ...(process.env.IS_ROOMS
            ? { build: 'rooms' /* deprecated, prefer context */, context: 'rooms' }
            : { context: 'web' }),
    };

    if (
        ![
            RecordingAnalyticsEvents.recording_started,
            RecordingAnalyticsEvents.recorder_started,
        ].includes(event)
    ) {
        const duration_sec = Math.max(
            audioMeta?.duration_sec ?? 0,
            videoMeta?.duration_sec ?? 0,
            screenMeta?.duration_sec ?? 0,
        );

        if (duration_sec > 0) {
            eventProps.duration_sec = fixedPrecisionNumber(duration_sec, 2);
        }
        eventProps.audio_size_mb = audioMeta
            ? fixedPrecisionNumber(audioMeta.size_mb ?? 0, 4)
            : undefined;
        eventProps.video_size_mb = videoMeta
            ? fixedPrecisionNumber(videoMeta.size_mb ?? 0, 4)
            : undefined;
        eventProps.screen_size_mb = screenMeta
            ? fixedPrecisionNumber(screenMeta.size_mb ?? 0, 4)
            : undefined;
    }

    trackEvent(event, { ...eventProps, ...extraProps });
}
