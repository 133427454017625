// Copyright 2024 Descript, Inc

import { diff_match_patch } from 'diff-match-patch';
import { Delta } from 'jsondiffpatch';
import { DescriptError, ErrorCategory } from '@descript/errors';

const MAGIC_ARRAY_TAG = '_t';
const MAGIN_ARRAY_TAG_VALUE = 'a';
const MAGIC_NUM_DELETE = 0;
const MAGIC_NUM_TEXT = 2;
const MAGIC_NUM_MOVE = 3;

function isArrayDelta(delta: Delta): boolean {
    return delta[MAGIC_ARRAY_TAG] === MAGIN_ARRAY_TAG_VALUE;
}

let diffMatchPatch: diff_match_patch | undefined;
function getDiffMatchPatch(txt: string, patch: string): string {
    if (!diffMatchPatch) {
        diffMatchPatch = new diff_match_patch();
    }
    const results = diffMatchPatch.patch_apply(diffMatchPatch.patch_fromText(patch), txt);
    for (let i = 0; i < results[1].length; i++) {
        if (!results[1][i]) {
            throw new DescriptError('text patch failed', ErrorCategory.LiveCollab);
        }
    }
    return results[0];
}

function numberComparator(a: number, b: number): number {
    return a - b;
}
function indexComparator<T extends { index: number }>(a: T, b: T): number {
    return a.index - b.index;
}

const DELETE = Symbol('delete');

export function applyJdpDeltaPojo<T>(
    left: T | undefined,
    delta: Delta,
    undefinedForDeletion: false,
): T | typeof DELETE;
export function applyJdpDeltaPojo<T>(left: T | undefined, delta: Delta): T;
export function applyJdpDeltaPojo<T>(
    left: T | undefined,
    delta: Delta,
    undefinedForDeletion: boolean = true,
): T | typeof DELETE {
    if (Array.isArray(delta)) {
        if (delta.length === 1) {
            // Added: a value was added, i.e. it was undefined and now has a value.
            return delta[0];
        } else if (delta.length === 2) {
            // Modified: a value was replaced by another value
            return delta[1];
        } else if (delta.length === 3 && delta[2] === MAGIC_NUM_DELETE) {
            // Deleted: a value was deleted, i.e. it had a value and is now undefined
            if (undefinedForDeletion) {
                return undefined as T;
            }
            return DELETE;
        } else if (delta.length === 3 && delta[2] === MAGIC_NUM_TEXT) {
            // Text: use diff-match-patch to apply a text patch
            return getDiffMatchPatch(left as string, delta[0]) as T;
        } else {
            throw new DescriptError('Invalid array delta', ErrorCategory.LiveCollab);
        }
    }

    // Array manipulations
    if (isArrayDelta(delta)) {
        if (!Array.isArray(left)) {
            throw new DescriptError(
                'Cannot apply array delta to non-array',
                ErrorCategory.LiveCollab,
            );
        }
        const newArray = [...left];

        // array handling pulled from https://github.com/benjamine/jsondiffpatch/blob/master/src/filters/arrays.js
        // first, separate removals, insertions and modifications
        const toRemove: number[] = [];
        const toInsert: { index: number; value: unknown }[] = [];
        const toModify: { index: number; delta: Delta }[] = [];
        for (const deltaKey of Object.keys(delta)) {
            if (deltaKey === MAGIC_ARRAY_TAG) {
                continue;
            }
            const deltaValue = delta[deltaKey];
            if (deltaKey[0] === '_') {
                // removed item from original array
                if (deltaValue[2] === MAGIC_NUM_DELETE || deltaValue[2] === MAGIC_NUM_MOVE) {
                    toRemove.push(parseInt(deltaKey.slice(1), 10));
                } else {
                    throw new DescriptError(
                        `only removal or move can be applied at original array indices,` +
                            ` invalid diff type: ${deltaValue[2]}`,
                        ErrorCategory.LiveCollab,
                    );
                }
            } else {
                if (Array.isArray(deltaValue) && deltaValue.length === 1) {
                    // added item at new array
                    toInsert.push({
                        index: parseInt(deltaKey, 10),
                        value: deltaValue[0],
                    });
                } else {
                    // modified item at new array
                    toModify.push({
                        index: parseInt(deltaKey, 10),
                        delta: deltaValue,
                    });
                }
            }
        }

        // remove items, in reverse order to avoid sawing our own floor
        toRemove.sort(numberComparator);
        for (let i = toRemove.length - 1; i >= 0; i--) {
            const indexToRemove = toRemove[i];
            const indexDiff = delta[`_${indexToRemove}`];
            const removedValue = newArray.splice(indexToRemove!, 1)[0];
            if (indexDiff[2] === MAGIC_NUM_MOVE) {
                // reinsert later
                toInsert.push({
                    index: indexDiff[1],
                    value: removedValue,
                });
            }
        }

        // insert items
        toInsert.sort(indexComparator);
        for (const insertion of toInsert) {
            // FIXME: is this supposed to be reversed...?
            newArray.splice(insertion.index, 0, insertion.value);
        }

        // apply modifications
        for (const modification of toModify) {
            newArray[modification.index] = applyJdpDeltaPojo(
                newArray[modification.index],
                modification.delta,
            );
        }
        return newArray as T;
    }

    // Objects
    let newObject: T | undefined = undefined;
    for (const name of Object.keys(delta)) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const oldValue = (left as any)?.[name];
        const newValue = applyJdpDeltaPojo(oldValue, delta[name], false);
        if (oldValue !== newValue) {
            // FIXME: comparing undefined and DELETE?
            if (newObject === undefined) {
                newObject = { ...left } as T;
            }
            if (newValue === DELETE) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                delete (newObject as any)[name];
            } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (newObject as any)[name] = newValue;
            }
        }
    }
    return (newObject ?? left) as T;
}
