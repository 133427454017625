// Copyright 2019 Descript, Inc

import {
    CompositionSummary,
    CreateRevision,
    CreateRevisionJson,
    getRestoreRevisionMessage,
    Revision,
    RevisionContentsJson,
    RevisionJson,
} from './Revision';
import { BaseAssetJson, RevisionAssetsJson } from '@descript/descript-model';
import { UserJson } from './Project';
import { isJsonAssetKey } from './AssetClient';
import { v4 as uuidv4 } from 'uuid';
import { AppConstants } from '../App/Constants';
import { newDate } from './RevisionDate';

export function getRevisionDownloadableAssets(
    rev: Revision,
    includeMediaAssets: boolean,
): RevisionAssetsJson | undefined {
    const assets = rev.contents.assets;
    if (!assets) {
        return undefined;
    }
    const assetsToReturn: RevisionAssetsJson = {};
    Object.keys(assets).forEach((key) => {
        const asset = assets[key]!;
        // Always download metadata assets
        if (isJsonAssetKey(key)) {
            assetsToReturn[key] = asset;
        } else if (includeMediaAssets && asset.is_bounced !== true && !asset.is_global_asset) {
            assetsToReturn[key] = asset;
        }
    });
    return assetsToReturn;
}

function getRevisionAssetsSize(revision: Revision): number | undefined {
    const assets = revision.contents.assets;
    if (!assets) {
        return undefined;
    }
    let size = 0;
    const keys = Object.keys(assets);
    const urlsAccountedFor = new Set<string>();
    for (const assetKey of keys) {
        // TODO: check the AssetQualityManager once we implement MQM UI
        const rootAsset = assets[assetKey]!;
        let asset: BaseAssetJson = rootAsset;
        if (rootAsset.quality) {
            if (rootAsset.quality.preview) {
                asset = rootAsset.quality.preview;
            } else if (rootAsset.quality.original) {
                asset = rootAsset.quality.original;
            }
        }

        const url = asset.url;
        // Multiple entries can point to the same asset url, so make sure we only count them once
        if (!urlsAccountedFor.has(url)) {
            size += asset.size === undefined ? 0 : asset.size;
            urlsAccountedFor.add(url);
        }
    }
    return size;
}

export function getContentUrl(revision: Revision): string {
    return revision.contents.content === undefined
        ? revision.contents.content_url
        : revision.contents.content.url;
}

export function getApproximateContentSize(revision: Revision): number {
    if (revision.contents.content && revision.contents.content.size !== undefined) {
        return revision.contents.content.size;
    }

    // Guess that content.json is as big as the biggest of the alignment files
    let biggestAssetMetadata = 0;
    const assets = revision.contents.assets || {};
    for (const assetName of Object.keys(assets)) {
        if (isJsonAssetKey(assetName)) {
            const size = assets[assetName]!.size ?? 0;
            if (size > biggestAssetMetadata) {
                biggestAssetMetadata = size;
            }
        }
    }
    return biggestAssetMetadata;
}

export function getApproximateContentAndAssetsSize(revision: Revision): number | undefined {
    const contentSize = getApproximateContentSize(revision);
    const otherAssetsSize = getRevisionAssetsSize(revision);

    if (otherAssetsSize !== undefined) {
        return contentSize + otherAssetsSize;
    }

    return undefined;
}

export function makeRevision({
    projectId,
    revisionId = uuidv4(),
    baseRevisionId,
    revisionNumber = 1,
    message,
    bundleVersion = AppConstants.revisionBundleVersion,
    createdBy,
    createdAt = newDate(),
    contents,
    isSignificant = false,
    isNew = true,
    forceSave = false,
    compositionSummaries,
}: {
    projectId: string;
    revisionId?: string;
    baseRevisionId?: string;
    revisionNumber?: number;
    message: string;
    bundleVersion?: string;
    createdBy: UserJson;
    createdAt?: string;
    contents: RevisionContentsJson;
    isSignificant?: boolean;
    isNew?: boolean;
    forceSave?: boolean;
    compositionSummaries?: CompositionSummary[];
}): Revision {
    if (contents) {
        delete contents.cdn_content_url;
        delete contents.cdn_assets;
    }
    return {
        projectId,
        revisionId,
        baseRevisionId,
        revisionNumber,
        message,
        bundleVersion,
        createdBy,
        createdAt,
        contents,
        isSignificant,
        isNew,
        forceSave,
        compositionSummaries,
    };
}

export function makeRevisionFromBaseRevision(
    baseRevision: Revision | undefined,
    revision: {
        projectId: string;
        revisionId?: string;
        message: string;
        createdBy: UserJson;
        bundleVersion?: string;
        createdAt?: string;
        contents: RevisionContentsJson;
        isSignificant?: boolean;
        forceSave?: boolean;
        compositionSummaries?: CompositionSummary[];
    },
): Revision {
    const { revisionId: baseRevisionId = undefined, revisionNumber = 0 } = baseRevision || {};
    return makeRevision({
        ...revision,
        baseRevisionId,
        revisionNumber: revisionNumber + 1,
        isNew: true,
    });
}

export function toCreateRevisionJson({
    revisionId,
    message,
    bundleVersion,
    contents,
    baseRevisionId,
    forceSave,
    compositionSummaries,
    isLateRevision,
}: CreateRevision): CreateRevisionJson {
    return {
        id: revisionId,
        message,
        bundle_version: bundleVersion,
        contents: {
            content_url: contents.content_url,
            content: {
                ...contents.content,
                url: contents.content_url,
            },
            assets: contents.assets,
            json: {},
        },
        default_scene_analysis: {},
        base_revision_id: baseRevisionId,
        force_save: forceSave,
        composition_summaries: compositionSummaries,
        is_late_revision: isLateRevision,
    };
}

export function fromRevisionJson(
    projectId: string,
    {
        id,
        revision_number,
        message,
        bundle_version,
        created_by,
        created_at,
        contents,
        is_significant,
        composition_summaries,
    }: RevisionJson,
): Revision {
    return makeRevision({
        projectId,
        revisionId: id,
        revisionNumber: revision_number,
        message: message,
        bundleVersion: bundle_version,
        createdBy: created_by,
        createdAt: created_at,
        contents: contents,
        isSignificant: is_significant,
        isNew: false,
        compositionSummaries: composition_summaries,
    });
}

export function fromRevisionJsons(projectId: string, jsons: RevisionJson[] = []): Revision[] {
    return jsons.map((revision) => fromRevisionJson(projectId, revision));
}

export function revisionIsSaved(revision: Revision | undefined): boolean {
    return revision !== undefined && revision.revisionNumber > 0;
}

export function makeRestoreRevision(
    projectId: string,
    newRevisionId: string,
    baseRevision: Revision | undefined,
    restoreRevision: Revision,
    createdBy: UserJson,
): Revision {
    return makeRevisionFromBaseRevision(baseRevision, {
        projectId,
        revisionId: newRevisionId,
        createdBy,
        message: getRestoreRevisionMessage(restoreRevision),
        contents: restoreRevision.contents,
        compositionSummaries: restoreRevision.compositionSummaries,
    });
}
