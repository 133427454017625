import { base64Decode, base64Encode } from '@descript/descript-core';
import { invariant, ErrorCategory, DescriptError } from '@descript/errors';
import { Delta } from 'jsondiffpatch';
import { gzip, inflate } from 'pako';
import { applyJdpDeltaImmer } from './applyJdpDeltaImmer';
import { applyJdpDeltaPojo } from './applyJdpDeltaPojo';
import {
    DEPRECATED_FORMAT_NOOP,
    FORMAT_GZIPPED_JSON_DIFF_PATCH_1,
    FORMAT_JSON_DIFF_PATCH_1,
} from './constants';
import { DeltaApplicator } from './DeltaApplicator';
import { DeltaFormat } from './DeltaFormat';
import { LiveCollabUnsupportedDeltaError } from './errors';
import { applyVariantPrefix, parseVariantPrefix } from './stringUtils';
import { DeltaType, FAILURE, Result } from './types';

// TODO, refine this threshold. How are we deciding when it's worth it to compress?
const GZIP_DELTA_SIZE_THRESHOLD = 10_000;

/** Translates between JSON string and some other string representation and vice versa. */
interface DeltaCodec {
    encode(delta: string): string;
    decode(delta: string): string;
}

class GzippedJsonDeltaCodec implements DeltaCodec {
    encode(delta: string): string {
        return base64Encode(gzip(delta));
    }

    decode(delta: string): string {
        const bytes = base64Decode(delta);
        return inflate(bytes, { to: 'string' });
    }
}

class RawJsonDeltaCodec implements DeltaCodec {
    encode(delta: string): string {
        return delta;
    }

    decode(delta: string): string {
        return delta;
    }
}

const GZIP_JSON_CODEC = new GzippedJsonDeltaCodec();
const JSON_CODEC = new RawJsonDeltaCodec();

export interface DeltaDecoder {
    decode(deltaSerializationFormat: string, serializedDelta: string): Result<unknown>;
}

export class MappingDeltaDecoder implements DeltaDecoder {
    /**
     * @param decoderMap - map from delta serialization format to a decoder for that format
     */
    constructor(private readonly decoderMap: Map<string, DeltaDecoder>) {
        // nop
    }

    decode(deltaSerializationFormat: string, serializedDelta: string): Result<unknown> {
        const decoder = this.decoderMap.get(deltaSerializationFormat);
        if (decoder === undefined) {
            throw new LiveCollabUnsupportedDeltaError(
                `Unsupported delta serialization format: ${deltaSerializationFormat}`,
            );
        }
        return decoder.decode(deltaSerializationFormat, serializedDelta);
    }
}

export const GzipJsonDeltaDecoder: DeltaDecoder = {
    decode(deltaSerializationFormat: string, serializedDelta: string): Result<Delta> {
        if (deltaSerializationFormat !== FORMAT_GZIPPED_JSON_DIFF_PATCH_1) {
            return FAILURE;
        }
        return { success: true, result: JSON.parse(GZIP_JSON_CODEC.decode(serializedDelta)) };
    },
};

export const JsonDeltaDecoder: DeltaDecoder = {
    decode(deltaSerializationFormat: string, serializedDelta: string): Result<Delta> {
        if (deltaSerializationFormat !== FORMAT_JSON_DIFF_PATCH_1) {
            return FAILURE;
        }
        return { success: true, result: JSON.parse(JSON_CODEC.decode(serializedDelta)) };
    },
};

export const NoopDeltaDecoder: DeltaDecoder = {
    decode(deltaSerializationFormat: string, serializedDelta: string): Result<undefined> {
        if (deltaSerializationFormat !== DEPRECATED_FORMAT_NOOP) {
            return FAILURE;
        }
        return { success: true, result: undefined };
    },
};

export function parseDelta(delta: DeltaType): {
    serializationFormat: string;
    deltaFormat: DeltaFormat;
    deltaBody: string;
} {
    const { variant: serializationFormat, contents: deltaBody } = parseVariantPrefix(delta);
    invariant(
        serializationFormat !== undefined,
        'serializationFormat is undefined',
        ErrorCategory.LiveCollab,
    );
    const deltaFormat =
        serializationFormat === DEPRECATED_FORMAT_NOOP
            ? DeltaFormat.NoopDelta
            : DeltaFormat.JsonDiffPatch;
    return { serializationFormat, deltaFormat, deltaBody };
}

export const NoopDeltaApplicator: DeltaApplicator = {
    name: 'NoopDeltaApplicator',
    apply: (base, deltaFormat) => {
        if (deltaFormat === DeltaFormat.NoopDelta) {
            if (base === undefined) {
                throw new DescriptError(
                    'base is undefined when applying noop delta',
                    ErrorCategory.LiveCollab,
                );
            }
            return { success: true, result: base };
        }
        return FAILURE;
    },
};

export const JdpImmerDeltaApplicator: DeltaApplicator = {
    name: 'JdpImmerDeltaApplicator',
    apply: (base, deltaFormat, delta) => {
        if (deltaFormat === DeltaFormat.JsonDiffPatch) {
            return { success: true, result: applyJdpDeltaImmer(base, delta as Delta) };
        }
        return FAILURE;
    },
};

export const JdpPojoDeltaApplicator: DeltaApplicator = {
    name: 'JdpPojoDeltaApplicator',
    apply: (base, deltaFormat, delta) => {
        if (deltaFormat === DeltaFormat.JsonDiffPatch) {
            return { success: true, result: applyJdpDeltaPojo(base, delta as Delta) };
        }
        return FAILURE;
    },
};

/** Serializes a delta to the requested format. If no format is supplied,
 * it automatically decides according to the length of the JSON string
 * representation of the delta. */
export function serializeDelta(
    delta: Delta | undefined,
    format?: string,
): DeltaType | undefined {
    if (delta === undefined) {
        return undefined;
    }

    const stringifiedDelta = JSON.stringify(delta);

    if (format !== undefined) {
        switch (format) {
            case FORMAT_JSON_DIFF_PATCH_1:
                return applyVariantPrefix(format, stringifiedDelta);
            case FORMAT_GZIPPED_JSON_DIFF_PATCH_1:
                return applyVariantPrefix(format, GZIP_JSON_CODEC.encode(stringifiedDelta));
            default:
                throw new DescriptError(
                    `unknown delta format ${format}`,
                    ErrorCategory.LiveCollab,
                );
        }
    }

    if (stringifiedDelta.length > GZIP_DELTA_SIZE_THRESHOLD) {
        return applyVariantPrefix(
            FORMAT_GZIPPED_JSON_DIFF_PATCH_1,
            GZIP_JSON_CODEC.encode(stringifiedDelta),
        );
    } else {
        return applyVariantPrefix(FORMAT_JSON_DIFF_PATCH_1, stringifiedDelta);
    }
}
