// Copyright 2024 Descript, Inc
import { ErrorCategory } from '@descript/errors';

// This is for cases when the document fails validation.
export class DocumentInvalidError extends Error {
    category = ErrorCategory.DescriptModel;
    override name = 'DocumentInvalidError';
}

// This is for when the document is a version that the client
// doesn't support. e.g. the Document version is newer than the
// client version.
export class DocumentMismatchError extends Error {
    category = ErrorCategory.DescriptModel;
    override name = 'DocumentMismatchError';
}

// This is used for if the serialized document is in a format that
// the client doesn't support. i.e. the client doesn't support
// deserializing the unencoded Document yet.
export class DocumentFormatError extends Error {
    category = ErrorCategory.DescriptModel;
    override name = 'DocumentFormatError';
}

export class DiscrepancyInTimeTextOrderingError extends Error {
    category = ErrorCategory.DescriptModel;
    override name = 'DiscrepancyInTimeTextOrderingError';
}

// For classic projects that are no longer supported.
export class ClassicProjectError extends Error {
    category = ErrorCategory.DescriptModel;
    override name = 'ClassicProjectError';
    override message = 'Support for classic projects was discontinued on November 19, 2024.';
}
