// Copyright 2024 Descript, Inc

import { invariant, ErrorCategory, DescriptError } from '@descript/errors';
import {
    RecorderVideoTrackRole,
    SpeechPipelineLanguage,
    startWebRecordingWorkflow,
} from '@descript/client/src/Api/RecordingClient';
import { RecorderMetadata } from '@descript/recorder-base';
import { AsyncContext } from '@descript/analytics';
import { getWebRecordingSession } from '../Engine/webRecorderSessions';

function recorderVideoTrackRoleMapping(track: RecorderMetadata) {
    switch (track.mode) {
        case 'screen':
            return RecorderVideoTrackRole.Screen;
        case 'video':
            return RecorderVideoTrackRole.Camera;
        default:
            return undefined;
    }
}

export const startWebWorkflow = async (
    ctx: AsyncContext,
    projectId: string,
    sessionId: string,
    transcriptionAvailable: boolean,
    currentCommitIndex = 0,
    calledByRecovery = false,
): Promise<string> => {
    const session = getWebRecordingSession(sessionId);
    invariant(
        session,
        'Could not start recorder workflow: session not found.',
        ErrorCategory.Recording,
    );
    const earliestRecordingStartTimestamp = Math.min(
        ...Array.from(session.recorders.values()).map((recorderMetadata) => {
            invariant(
                recorderMetadata.recordingStartTimecode !== undefined,
                'Could not start recorder workflow: no "recordingStartTimestamp" found for the current recorder.',
                ErrorCategory.Recording,
            );
            return recorderMetadata.recordingStartTimecode;
        }),
    );
    const payload = {
        projectId: projectId,
        tracks: Array.from(session.recorders.entries()).map(
            ([recorderId, recorderMetadata]) => {
                // invariant fails silently in this map, so logging the error and also throwing to keep functionality and add visibility
                if (!recorderMetadata.assetGuid) {
                    const errMsg = `Could not start recorder workflow: no "assetGuid" found for the current ${recorderMetadata.mode} recorder.`;
                    console.error(errMsg);
                    throw new DescriptError(errMsg, ErrorCategory.Recording);
                }
                if (!recorderMetadata.artifactGuid) {
                    const errMsg = `Could not start recorder workflow: no "artifactGuid" found for the current ${recorderMetadata.mode} recorder.`;
                    console.error(errMsg);
                    throw new DescriptError(errMsg, ErrorCategory.Recording);
                }
                if (recorderMetadata.recordingStartTimecode === undefined) {
                    const errMsg = `Could not start recorder workflow: no "recordingStartTimecode" found for the current ${recorderMetadata.mode} recorder.`;
                    console.error(errMsg);
                    throw new DescriptError(errMsg, ErrorCategory.Recording);
                }
                if (recorderMetadata.hasAudio === undefined) {
                    const errMsg = `Could not start recorder workflow: no "hasAudio" found for the current ${recorderMetadata.mode} recorder.`;
                    console.error(errMsg);
                    throw new DescriptError(errMsg, ErrorCategory.Recording);
                }
                if (recorderMetadata.hasVideo === undefined) {
                    const errMsg = `Could not start recorder workflow: no "hasVideo" found for the current ${recorderMetadata.mode} recorder.`;
                    console.error(errMsg);
                    throw new DescriptError(errMsg, ErrorCategory.Recording);
                }

                const settings = !recorderMetadata.hasAudio
                    ? undefined
                    : recorderMetadata.mode === 'screen'
                      ? session.computerAudioTrackSettings
                      : session.microphoneTrackSettings;

                const runTranscription =
                    settings?.transcriptionEnabled &&
                    recorderMetadata.hasAudio &&
                    transcriptionAvailable;

                let speakerId = settings?.speakerId;
                if (!runTranscription || session.target?.type !== 'script') {
                    speakerId = undefined;
                }

                return {
                    displayName: `${session.userName}'s ${recorderMetadata.mode} recording`,
                    assetId: recorderMetadata.assetGuid,
                    artifactId: recorderMetadata.artifactGuid,
                    // Need to provide the offset in seconds
                    offset:
                        (recorderMetadata.recordingStartTimecode -
                            earliestRecordingStartTimestamp) /
                        1000,
                    hasAudio: recorderMetadata.hasAudio,
                    hasVideo: recorderMetadata.hasVideo,
                    runTranscription,
                    liveTranscriptionAvailable:
                        runTranscription && recorderMetadata.liveTranscribe,
                    studioSound: settings?.studioSoundEnabled
                        ? settings.studioSoundIntensity
                        : undefined,
                    speakerId,
                    expectedFileExtension: recorderMetadata.fileExtension,
                    videoRole: recorderMetadata.hasVideo
                        ? recorderVideoTrackRoleMapping(recorderMetadata)
                        : undefined,
                    // TODO should we use SpeechPipelineLanguage or TranscriptionLanguage?
                    language: runTranscription
                        ? (session.transcriptionLanguage as SpeechPipelineLanguage)
                        : undefined,
                };
            },
        ),
        baseDocumentCommitIndex: currentCommitIndex,
        recovery: calledByRecovery,
        fastAdd: true,
    };
    const response = await startWebRecordingWorkflow(ctx, payload);
    return response.sessionId;
};
