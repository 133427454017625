// Copyright © 2023 Descript, Inc. All rights reserved.

/**
 * Defines in-memory formats for deltas
 * (i.e., independent of serialization formats)
 */
export const enum DeltaFormat {
    JsonDiffPatch = 'jdp',
    NoopDelta = 'noop',
}
