import { BaseLogger } from './BaseLogger';
import { Attributes, type LogEntry, LogLevel, type Loggable, Logger } from './Logger';
import _ from 'lodash';

export type ConsoleLoggerOpts = {
    /** What format to output for logs */
    style: ConsoleFormatter;
    /** The least severe level that should be emitted */
    minLevel: LogLevel;
    /** Attributes to add to every log line */
    attrs: Attributes;
};

const DEFAULT_OPTS: ConsoleLoggerOpts = {
    style: 'minimal',
    minLevel: LogLevel.Info,
    attrs: {},
};

/** A bare bones logger that prints to console/stdout/stderr
 *
 * Works across all runtimes. Pass in any logging attributes you want for all log lines into options.
 */
export class ConsoleLogger extends BaseLogger implements Logger {
    private readonly opts: ConsoleLoggerOpts;
    private readonly logFmt: Formatter;

    constructor(opts: Partial<ConsoleLoggerOpts>) {
        const finalOpts = {
            ...DEFAULT_OPTS,
            ...opts,
        };
        super(finalOpts.minLevel);
        this.opts = finalOpts;
        this.logFmt = FORMATTERS[this.opts.style];
    }

    // eslint-disable no-console
    log(level: string, loggable: Loggable): void {
        switch (level) {
            case 'TRACE':
                this.debug(loggable);
                break;
            case 'DEBUG':
                this.debug(loggable);
                break;
            case 'INFO':
                this.info(loggable);
                break;
            case 'WARN':
                this.warn(loggable);
                break;
            case 'ERROR':
                this.error(loggable);
                break;
            default:
                this.info(loggable);
        }
    }
    // eslint-enable no-console

    logEntry(level: LogLevel, entry: LogEntry): void {
        if (entry.error && this.opts.style !== 'gcp') {
            logFunc[level](
                this.logFmt(level, {
                    ...entry,
                    attrs: {
                        ...this.opts.attrs,
                        ...entry.attrs,
                    },
                }),
                entry.error,
            );
        } else {
            logFunc[level](
                this.logFmt(level, {
                    ...entry,
                    attrs: {
                        ...this.opts.attrs,
                        ...entry.attrs,
                    },
                }),
            );
        }
    }
}

// these 'names' are matched with https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
const logLevelNames = Object.freeze({
    [LogLevel.Debug]: 'DEBUG',
    [LogLevel.Info]: 'INFO',
    [LogLevel.Warning]: 'WARNING',
    [LogLevel.Error]: 'ERROR',
    [LogLevel.Critical]: 'CRITICAL',
}) satisfies Record<LogLevel, string>;

// these 'names' are matched with https://cloud.google.com/logging/docs/reference/v2/rest/v2/LogEntry#LogSeverity
const logFunc = Object.freeze({
    [LogLevel.Debug]: console.debug,
    [LogLevel.Info]: console.info,
    [LogLevel.Warning]: console.warn,
    [LogLevel.Error]: console.error,
    [LogLevel.Critical]: console.error,
}) satisfies Record<LogLevel, typeof console.log>;

type GcpJsonLogLine = {
    severity: string;
    message?: string | undefined;
    'logging.googleapis.com/labels'?: Record<string, string>;
    // Rest of the JSON payload
    [k: string]: unknown;
};

type Formatter = (level: LogLevel, entry: LogEntry) => string;

export type ConsoleFormatter = 'minimal' | 'full' | 'gcp';
/** @internal */
export const FORMATTERS: Record<ConsoleFormatter, Formatter> = {
    /** Very bare-bones log lines */
    minimal(level: LogLevel, entry: LogEntry): string {
        const levelName = logLevelNames[level];
        return `[${entry.group}] ${levelName} - ${entry.message}`;
    },

    /** Very detailed log output */
    full(level: LogLevel, entry: LogEntry): string {
        const levelName = logLevelNames[level];
        const attrLines = [];
        if (entry.attrs) {
            for (const [k, v] of _.sortBy(Object.entries(entry.attrs), (x) => x[0])) {
                attrLines.push(`${k}="${String(v).replaceAll('"', '\\"')}"`);
            }
        }
        return `[${entry.group}] ${levelName} - ${entry.message} ${attrLines.join(' ')}`;
    },

    /** JSON-serialized log lines in schema compatible with GCP structured logging ingestion
     *
     * https://cloud.google.com/logging/docs/structured-logging#special-payload-fields
     */
    gcp(level: LogLevel, entry: LogEntry): string {
        const levelName = logLevelNames[level];
        return JSON.stringify({
            severity: levelName,
            message: entry.message,
            'logging.googleapis.com/labels': { group: entry.group },
            ...entry.attrs,
        } satisfies GcpJsonLogLine);
    },
};
