// Copyright 2024 Descript, Inc
import { AsyncContext, trackEvent } from '@descript/analytics';
import { Microseconds } from '@descript/descript-core';
import { AssetSyncApi } from '../AssetSync/api';
import { DescriptError, ErrorCategory } from '@descript/errors';

const retryWithBackoff = async <T>(
    fn: (attemptNumber: number) => Promise<T>,
    maxRetries = 5,
): Promise<T> => {
    let retries = 0;
    while (retries < maxRetries) {
        try {
            return await fn(retries);
        } catch (err) {
            retries += 1;
            if (retries >= maxRetries) {
                throw err;
            }
            const delayWithJitter = 1000 + Math.random() * 1000 * Math.pow(2, retries);
            await new Promise((resolve) => setTimeout(resolve, delayWithJitter));
        }
    }
    throw new DescriptError(
        'Failed to upload segment: too many retries',
        ErrorCategory.GlobalAssetSync,
    );
};

export async function createAndUploadSegment(
    parentCtx: AsyncContext,
    {
        assetSyncApi,
        projectId,
        assetGuid,
        artifactGuid,
        buffer,
        fileExtension,
        md5,
        sequence,
        isInit,
        startTime,
        duration,
    }: {
        assetSyncApi: AssetSyncApi;
        projectId: string;
        assetGuid: string;
        artifactGuid: string;
        buffer: Buffer | ArrayBuffer;
        fileExtension: string;
        md5: string;
        sequence: number;
        isInit: boolean;
        startTime: Microseconds;
        duration: Microseconds;
    },
) {
    const url = await retryWithBackoff(() =>
        assetSyncApi.getWriteUrlSegment(parentCtx, {
            projectId,
            assetGuid,
            artifactGuid,
            sequence,
            fileExtension,
            size: buffer.byteLength,
            md5,
        }),
    );

    // The md5 must be Base64, or the upload will fail
    const md5Base64 = Buffer.from(md5, 'hex').toString('base64');
    const uploadResponse = await retryWithBackoff((attemptNumber) => {
        const controller = new AbortController();
        // Exponential timeout starting at 30 seconds and up to 8 minutes
        const timeoutMs = 30_000 * Math.pow(2, attemptNumber);
        const timeoutId = setTimeout(() => {
            controller.abort();
            trackEvent('upload-segment-timeout', {
                timeoutMs,
                assetGuid,
                artifactGuid,
                sequence,
            });
        }, timeoutMs);

        return fetch(url, {
            method: 'PUT',
            body: buffer,
            headers: {
                'content-md5': md5Base64,
            },
            signal: controller.signal,
        }).finally(() => clearTimeout(timeoutId));
    });

    if (uploadResponse.status !== 200) {
        throw new DescriptError(
            `Failed to upload segment: ${uploadResponse.status}`,
            ErrorCategory.GlobalAssetSync,
        );
    }

    await retryWithBackoff(() =>
        assetSyncApi.createSegment(parentCtx, {
            projectId,
            assetGuid,
            artifactGuid,
            sequence,
            isInit,
            fileExtension,
            md5,
            startTime,
            duration,
            size: buffer.byteLength,
        }),
    );
}
