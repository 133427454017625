// Copyright 2024 Descript, Inc
import { useFeatureFlag } from '../Browser/FeatureFlags/useFeatureFlag';

/**
 * Gates the quick picker, canvas scene toolbar, and custom layout management options for Layouts 2.1
 */
export function useNewLayoutsDesign() {
    return useFeatureFlag('exp-workflows-layouts-2.1-release') === 'treatment';
}

// if using the new layouts 2.1 design, rename 'template' to 'layout pack'
// should account for pluralization and capitalization
export function useTemplateRename() {
    const isNewLayoutsDesignEnabled = useNewLayoutsDesign();
    const renameFn = (name: string) => {
        if (!isNewLayoutsDesignEnabled) {
            return name;
        }

        return name
            .replace(/\btemplate\b/g, 'layout pack')
            .replace(/\btemplates\b/g, 'layout packs')
            .replace(/\bTemplate\b/g, 'Layout pack')
            .replace(/\bTemplates\b/g, 'Layout packs');
    };

    return { getTemplateCopy: renameFn };
}

export function getTemplateRename(name: string, titleCase = false) {
    return name
        .replace(/\btemplate\b/g, 'layout pack')
        .replace(/\btemplates\b/g, 'layout packs')
        .replace(/\bTemplate\b/g, `Layout ${titleCase ? 'Pack' : 'pack'}`)
        .replace(/\bTemplates\b/g, `Layout ${titleCase ? 'Pack' : 'pack'}`);
}
