// Copyright 2024 Descript, Inc
import { AsyncContext, SpanTag, trackEvent } from '@descript/analytics';
import PQueue from 'p-queue';
import { Assets, newId, MediaMetadata } from '@descript/descript-model';
import { WebRecorder } from '@descript/web-recorder';
import {
    startWebRecordingSpan,
    endWebRecordingSpan,
    SpanStatus,
    endWebRecordingTrace,
    CurrentRecorderContext,
} from '../Span/webRecorderSpanHandlers';
import {
    getWebRecordingSession,
    getWebRecordingSessionsForBackendWorkflow,
    updateWebRecordingSessionRecorderMetadata,
} from './webRecorderSessions';
import { arrayBufferMd5Hex } from '@descript/client/src/Hashing/Hash';
import { createAndUploadSegment } from '@descript/client/src/MediaLibrary/createAndUploadSegment';
import { SpanNames } from '@descript/client/src/Utilities/Tracing';
import { RecordingAssetSyncApi } from '@descript/client/src/Api/RecordingAssetSyncApi';
import { DescriptError, invariant, ErrorCategory } from '@descript/errors';
import {
    debugDump,
    moveRecoveryTempDirectory,
    recoveryArtifactComplete,
    recoverySegmentComplete,
} from '@descript/web-recorder-recovery';
import {
    RecorderMetadata,
    WebRecorderSegmentMetadata,
    WebRecorderStartMetadata,
    WebRecorderStopMetadata,
    WebRecordingSession,
    RecorderContext,
    RecordingAnalyticsEvents,
} from '@descript/recorder-base';
import { RecorderVideoTrackRole, SpeechPipelineLanguage } from '@descript/client';
import * as RecordingClient from '@descript/client/src/Api/RecordingClient';
import { trackError } from '@descript/client/src/Utilities/ErrorTracker';
import { Attributes } from '@opentelemetry/api';
import { createScopedLogger } from '@descript/descript-core';
import { trackRecordingAnalyticsEvent } from '../Analytics/analytics';

const log = createScopedLogger({
    name: 'RECORDER/engine',
    color: 'green',
    // Temporarily enabled logging for all rooms environments
    alwaysEnabled: CurrentRecorderContext === RecorderContext.ROOMS,
});

// ================== Web Recording Queue ==================
/**
 * A serial queue used to handle all operations except for
 * creating & uploading segments.
 */
export const webRecorderMainQueue: PQueue = new PQueue({ concurrency: 1 });
/**
 * A serial queue used to create & upload segments.
 * We use a separate queue so that segment upload does not block things such
 * as reading artifact metadata & reifying the artifact.
 */
export const webRecorderUploadQueue: PQueue = new PQueue({ concurrency: 3 });
const assetSyncApi = new RecordingAssetSyncApi();

// Monitor the main queue for potential lockups since it's concurrency is 1
const QUEUE_LOCK_THRESHOLD = 5;
function monitorQueue(queue: PQueue) {
    const interval = setInterval(() => {
        if (queue.size > QUEUE_LOCK_THRESHOLD && queue.pending === queue.concurrency) {
            trackEvent('recorder-engine-queue-lockup', {
                size: queue.size,
                pending: queue.pending,
            });
            clearInterval(interval);
        }
    }, 5_000);
}

monitorQueue(webRecorderMainQueue);

/**
 * Asynchronously creates a media asset and artifact if they are not already associated with the web recording session.
 *
 * It checks whether an asset and artifact exist for the current web recorder on this web recording session.
 * If these do not exist, the function will create them.
 */
export async function createAssetAndArtifactIfNeeded(
    ctx: AsyncContext,
    webRecordingSession: WebRecordingSession,
    recorderId: string,
    delegateToken?: string,
    forceCreation?: boolean,
) {
    if (delegateToken) {
        assetSyncApi.setDelegateToken(delegateToken);
    }
    const sessionRecorderMetadata = webRecordingSession.recorders.get(recorderId);
    let assetId, artifactId;
    const initialAssetId = sessionRecorderMetadata?.assetGuid;
    const initialArtifactId = sessionRecorderMetadata?.artifactGuid;

    if (!initialAssetId || forceCreation) {
        assetId = await createMediaAsset(ctx, webRecordingSession, recorderId);

        // Save any potential original asset guid for book keeping, should only be used if forceCreation is true (currently only used for recovery)
        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            recoveryOriginalAssetId: initialAssetId,
        });
        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            assetGuid: assetId,
        });
    }

    if (!initialArtifactId || forceCreation) {
        artifactId = await createMediaArtifact(
            ctx,
            webRecordingSession,
            recorderId,
            forceCreation,
        );

        // Save any potential original artifact guid for book keeping,should only be used if forceCreation is true (currently only used for recovery)
        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            recoveryOriginalArtifactId: initialArtifactId,
        });
        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            artifactGuid: artifactId,
        });

        WebRecorder.onRecorderEvent({
            type: 'start_workflow',
            sessionId: webRecordingSession.sessionId,
            waitForRecorders: true,
        });
    }
    const aId = artifactId ?? sessionRecorderMetadata?.artifactGuid;
    if (aId) {
        void moveRecoveryTempDirectory('temp_' + recorderId, aId).catch(async (e) => {
            log.error('Failed to move temporary recovery files', {
                error: e,
                artifactId: aId,
                recorderId,
            });
            trackError(e, 'move-temp-recovery-files-failed', {
                category: ErrorCategory.Recording,
                extra: {
                    artifactId: aId,
                },
                attachment: {
                    filename: 'recovery-debug.txt',
                    data: await debugDump(),
                    contentType: 'text/plain',
                },
            });
        });
    }
    // If we are recovering, we need to move the temp directory from the original asset to the new asset so that
    // the segmented upload can work correctly
    if (initialArtifactId && forceCreation && artifactId && assetId) {
        void moveRecoveryTempDirectory(initialArtifactId, artifactId, assetId);
    }
}

/**
 * This function creates a new media asset. It will throw an error if the project ID does not exist in the
 * web recording session. It will generate a new ID as an hint for the asset, and use it to create the asset
 * using the provided Asset Sync API (or a default one if none was provided). The function also logs information
 * changes throughout the process and updates the web recorder's properties after creating the asset.
 */
export async function createMediaAsset(
    ctx: AsyncContext,
    webRecordingSession: WebRecordingSession,
    recorderId: string,
) {
    const sessionRecorderMetadata = webRecordingSession.recorders.get(recorderId);

    const assetCtx = startWebRecordingSpan(
        SpanNames.WebRecorder_CreateAsset,
        webRecordingSession.sessionId,
        ctx,
    );
    let spanStatus = SpanStatus.COMPLETED;
    let spanError: Error | undefined;

    try {
        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            message: `Creating media asset for ${webRecordingSession.projectId}`,
            context: 'media_asset',
            attributes: webRecordingSession,
        });
        invariant(
            webRecordingSession.projectId,
            'Could not create asset: Project ID not found.',
            ErrorCategory.Recording,
        );
        const assetIdHint = newId();
        const displayName = generateDisplayName(webRecordingSession, sessionRecorderMetadata);
        const res = await assetSyncApi.createAsset(assetCtx, webRecordingSession.projectId, {
            metadata: {
                default_display_name: displayName,
                source: 'web-recorder',
                projectID: webRecordingSession.projectId,
            },
            lookupKey: 'https://assets.descript.com/lookupKey/' + assetIdHint,
            idHint: assetIdHint,
        });
        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            assetGuid: res.asset.guid,
        });
        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            message: `Created ${sessionRecorderMetadata?.mode} media asset for ${webRecordingSession.projectId} - ${res.asset.guid}`,
            context: 'media_asset',
        });
        return res.asset.guid;
    } catch (e) {
        const error = e as Error;
        spanStatus = SpanStatus.ERRORED;
        spanError = error || new DescriptError('Unknown error', ErrorCategory.WebRecorder);
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            error,
            context: 'media_asset',
        });
        throw error;
    } finally {
        endWebRecordingSpan(
            assetCtx,
            SpanNames.WebRecorder_CreateAsset,
            spanStatus,
            undefined,
            spanError,
        );
    }
}

function generateDisplayName(
    webRecordingSession: WebRecordingSession,
    sessionRecorderMetadata: RecorderMetadata | undefined,
    isRecovery?: boolean,
): string {
    const recoveryExtra = isRecovery ? ' (Recovered)' : '';
    return `${webRecordingSession.userName}${sessionRecorderMetadata?.mode === 'screen' ? "'s screen" : ''}${recoveryExtra}`;
}

/**
 * This function creates a media artifact for a given web recording session using WebAssetSyncApi.
 */
export async function createMediaArtifact(
    ctx: AsyncContext,
    webRecordingSession: WebRecordingSession,
    recorderId: string,
    isRecovery?: boolean,
) {
    const sessionRecorderMetadata = webRecordingSession.recorders.get(recorderId);

    const artifactCtx = startWebRecordingSpan(
        SpanNames.WebRecorder_CreateArtifact,
        webRecordingSession.sessionId,
        ctx,
    );
    let spanStatus = SpanStatus.COMPLETED;
    let spanError: Error | undefined;

    try {
        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            message: `Creating placeholder artifact for ${webRecordingSession.projectId}`,
            attributes: webRecordingSession,
            context: 'media_artifact',
        });
        invariant(
            webRecordingSession.projectId,
            'Could not create artifact: Project ID not found.',
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.assetGuid,
            'Could not create artifact: Asset ID not found.',
            ErrorCategory.Recording,
        );
        const createResponse = await assetSyncApi.createPlaceholderArtifact(
            artifactCtx,
            webRecordingSession.projectId,
            sessionRecorderMetadata.assetGuid,
            {
                lookupKey: newId(),
                isSegmented: true,
                metadata: Assets.encodeArtifactMetadata({
                    // TODO: https://linear.app/descript/issue/MEDIA-1000/define-what-metadata-to-set-on-segmented-and-stitched-recording
                    displayName: generateDisplayName(
                        webRecordingSession,
                        sessionRecorderMetadata,
                        isRecovery,
                    ),
                    type: 'web',
                    metadata: {},
                }),
                // TODO: Change this to undefined instead of empty string
                // https://linear.app/descript/issue/MEDIA-966/change-artifacts-fileextension-type-to-be-optional
                fileExtension: sessionRecorderMetadata.fileExtension ?? '',
                transformation: undefined,
                contentType: undefined,
                quality: undefined,
            },
        );
        const artifactGuid = createResponse.placeholderArtifact.guid;

        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            artifactGuid,
        });

        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            message: `Created placeholder artifact for ${webRecordingSession.projectId} - ${artifactGuid}`,
            context: 'media_artifact',
        });
        return artifactGuid;
    } catch (e) {
        const error = e as Error;
        spanStatus = SpanStatus.ERRORED;
        spanError = error || new DescriptError('Unknown error', ErrorCategory.WebRecorder);
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            error: error,
            context: 'media_artifact',
        });
        throw error;
    } finally {
        endWebRecordingSpan(
            artifactCtx,
            SpanNames.WebRecorder_CreateArtifact,
            spanStatus,
            undefined,
            spanError,
        );
    }
}

export async function signalTrackIfNeeded(
    ctx: AsyncContext,
    recordingSessionId: string,
    recorderId: string,
    trackSkew?: number,
    delegateToken?: string,
    transcriptionLanguage?: SpeechPipelineLanguage,
) {
    const webRecordingSession = getWebRecordingSession(recordingSessionId);
    const sessionRecorderMetadata = webRecordingSession?.recorders.get(recorderId);

    invariant(
        webRecordingSession,
        'Could not signal track: Web recording session not found.',
        ErrorCategory.Recording,
    );

    if (
        !webRecordingSession?.backendWorkflowId?.includes('collab') ||
        sessionRecorderMetadata?.track_created
    ) {
        // No need to do anything if the backend workflow is not collaborative or we have made the track already
        return;
    }

    const trackCtx = startWebRecordingSpan(
        SpanNames.WebRecorder_SignalTrack,
        webRecordingSession.sessionId,
        ctx,
    );
    let spanStatus = SpanStatus.ERRORED;
    const spanAttributes: Attributes = {};

    try {
        invariant(
            sessionRecorderMetadata?.assetGuid,
            `Could not signal track: no "assetGuid" found for the current ${sessionRecorderMetadata?.mode} recorder.`,
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.artifactGuid,
            `Could not signal track: no "artifactGuid" found for the current ${sessionRecorderMetadata.mode} recorder.`,
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.recordingStartTimecode !== undefined,
            `Could not signal track: no "recordingStartTimecode" found for the current ${sessionRecorderMetadata.mode} recorder.`,
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.hasAudio !== undefined,
            `Could not signal track: no "hasAudio" found for the current ${sessionRecorderMetadata.mode} recorder.`,
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.hasVideo !== undefined,
            `Could not signal track: no "hasVideo" found for the current ${sessionRecorderMetadata.mode} recorder.`,
            ErrorCategory.Recording,
        );
        const settings = !sessionRecorderMetadata.hasAudio
            ? undefined
            : sessionRecorderMetadata.mode === 'screen'
              ? webRecordingSession.computerAudioTrackSettings
              : webRecordingSession.microphoneTrackSettings;

        // ToDo normally we check transcription minutes to gate this but we don't have the info here
        const runTranscription =
            settings?.transcriptionEnabled && sessionRecorderMetadata.hasAudio;

        let speakerId = settings?.speakerId;
        if (!runTranscription || webRecordingSession.target?.type !== 'script') {
            speakerId = undefined;
        }

        // One recording will have a session per track so we need to find all sessions for this recording
        const allSessionsForRecording = getWebRecordingSessionsForBackendWorkflow(
            webRecordingSession.backendWorkflowId,
        );
        const allRecorders = allSessionsForRecording
            .map((session) => Array.from(session.recorders.values()))
            .flat();

        const validStartTimecodes = allRecorders
            .map((recorderMetadata) => recorderMetadata.recordingStartTimecode)
            .filter((timecode) => timecode !== undefined);

        const earliestLocalStart =
            validStartTimecodes.length > 0 ? Math.min(...validStartTimecodes) : undefined;

        if (
            !earliestLocalStart ||
            !webRecordingSession.backendWorkflowStartTime ||
            !webRecordingSession.localStartTime
        ) {
            // If we are missing any of these, we can't accurately calculate the offset
            spanAttributes.missingCriticalTimestamp = true;
            return;
        }

        const globalStart = webRecordingSession.backendWorkflowStartTime.getTime();
        const localRecordingStart = webRecordingSession.localStartTime.getTime();
        const globalOffset = localRecordingStart - globalStart;

        // recordingStartTimecode time is not accurate but it is consistent and precise, so avoid using it for global offsets
        // however it's fine to use to compare to one session's recordingStartTimecode to another's
        const localOffset = sessionRecorderMetadata.recordingStartTimecode - earliestLocalStart;

        spanAttributes.globalOffset = globalOffset;
        spanAttributes.localOffset = localOffset;
        spanAttributes.trackSkew = trackSkew;

        const offsetSec = (globalOffset + localOffset + (trackSkew ?? 0)) / 1000;

        const track = {
            displayName: generateDisplayName(
                webRecordingSession,
                sessionRecorderMetadata,
                !!sessionRecorderMetadata.recoveryOriginalAssetId,
            ),
            assetId: sessionRecorderMetadata.assetGuid,
            artifactId: sessionRecorderMetadata.artifactGuid,
            // Need to provide the offset in seconds
            offset: offsetSec,
            hasAudio: sessionRecorderMetadata.hasAudio,
            hasVideo: sessionRecorderMetadata.hasVideo,
            runTranscription,
            liveTranscriptionAvailable:
                runTranscription && sessionRecorderMetadata.liveTranscribe,
            studioSound: settings?.studioSoundEnabled
                ? settings.studioSoundIntensity
                : undefined,
            language: transcriptionLanguage,
            speakerId,
            expectedFileExtension: sessionRecorderMetadata.fileExtension,
            videoRole: sessionRecorderMetadata.hasVideo
                ? recorderVideoTrackRoleMapping(sessionRecorderMetadata)
                : undefined,
            userName: webRecordingSession.userName,
            recoveryOriginalAssetId: sessionRecorderMetadata.recoveryOriginalAssetId,
            recoveryOriginalArtifactId: sessionRecorderMetadata.recoveryOriginalArtifactId,
        };
        await RecordingClient.signalAddTrack(
            ctx,
            webRecordingSession.backendWorkflowId,
            track,
            delegateToken,
        );
        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            track_created: true,
        });
        spanStatus = SpanStatus.COMPLETED;
    } finally {
        endWebRecordingSpan(
            trackCtx,
            SpanNames.WebRecorder_SignalTrack,
            spanStatus,
            spanAttributes,
        );
    }
}

function recorderVideoTrackRoleMapping(track: RecorderMetadata) {
    switch (track.mode) {
        case 'screen':
            return RecorderVideoTrackRole.Screen;
        case 'video':
            return RecorderVideoTrackRole.Camera;
        default:
            return undefined;
    }
}

/**
 * Async function to reify placeholder if needed.
 * It bails when the recording is not finished or artifact is already reified.
 * Finally, it invokes WebAssetSyncApi's `reifySegmentedPlaceholder` method for the given params and updates the recorder props.
 */
export async function reifyPlaceholderIfNeeded(
    webRecordingSession: WebRecordingSession,
    recorderId: string,
) {
    const projectId = webRecordingSession.projectId;
    const sessionRecorderMetadata = webRecordingSession.recorders.get(recorderId);

    const ctx = startWebRecordingSpan(
        SpanNames.WebRecorder_ReifyArtifact,
        webRecordingSession.sessionId,
    );

    let spanStatus = SpanStatus.COMPLETED;
    let spanError: Error | undefined;

    try {
        invariant(
            sessionRecorderMetadata,
            'Could not reify placeholder: session recorder metadata is undefined',
            ErrorCategory.Recording,
        );

        if (
            sessionRecorderMetadata.segmentedArtifactState === 'reified' ||
            sessionRecorderMetadata.segmentedArtifactState === 'committed'
        ) {
            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: sessionRecorderMetadata.mode ?? 'N/A',
                message: `reifyPlaceholderIfNeeded, bailing, already reified`,
                attributes: sessionRecorderMetadata,
                context: 'media_artifact',
            });
            return;
        }

        invariant(
            sessionRecorderMetadata.assetGuid !== undefined,
            'Asset ID must be defined before reifying artifact',
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata.artifactGuid !== undefined,
            'Artifact ID must be defined before reifying artifact',
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata.segmentCount,
            'segments length must be defined before reifying artifact',
            ErrorCategory.Recording,
        );
        invariant(
            projectId,
            'Project ID must be defined before reifying artifact',
            ErrorCategory.Recording,
        );

        let mediaMetadata: MediaMetadata | undefined;
        if (
            sessionRecorderMetadata.mode === 'screen' ||
            sessionRecorderMetadata.mode === 'video'
        ) {
            mediaMetadata = {
                type: 'video',
                duration: sessionRecorderMetadata.duration_sec ?? 0,
                videoStreams: [
                    {
                        duration: sessionRecorderMetadata.duration_sec ?? 0,
                        streamIndex: 0,
                        codec: '',
                        size: {
                            width: sessionRecorderMetadata.videoSettings?.width ?? 0,
                            height: sessionRecorderMetadata.videoSettings?.height ?? 0,
                        },
                        pixelFormat: 'yuv420p',
                        frameCount: 0,
                        averageFrameRate: {
                            framerateNumerator:
                                sessionRecorderMetadata.videoSettings?.frameRate ?? 0,
                            framerateDenominator: 1,
                        },
                    },
                ],
            };
            if (sessionRecorderMetadata.hasAudio) {
                mediaMetadata.audioStreams = [
                    {
                        duration: sessionRecorderMetadata.duration_sec ?? 0,
                        streamIndex: 1,
                        codec: '',
                        sampleRate: sessionRecorderMetadata.audioSettings?.sampleRate ?? 0,
                        channelCount: sessionRecorderMetadata.audioSettings?.channelCount ?? 0,
                    },
                ];
            }
        } else if (sessionRecorderMetadata.mode === 'audio') {
            mediaMetadata = {
                type: 'audio',
                duration: sessionRecorderMetadata.duration_sec ?? 0,
                audioStreams: [
                    {
                        duration: sessionRecorderMetadata.duration_sec ?? 0,
                        streamIndex: 0,
                        codec: '',
                        sampleRate: sessionRecorderMetadata.audioSettings?.sampleRate ?? 0,
                        channelCount: sessionRecorderMetadata.audioSettings?.channelCount ?? 0,
                    },
                ],
            };
        }

        await assetSyncApi.reifySegmentedPlaceholder(ctx, {
            projectId,
            assetGuid: sessionRecorderMetadata.assetGuid,
            artifactGuid: sessionRecorderMetadata.artifactGuid,
            segmentCount: sessionRecorderMetadata.segmentCount,
            mediaMetadata,
        });

        updateWebRecordingSessionRecorderMetadata(webRecordingSession.sessionId, recorderId, {
            segmentedArtifactState: 'reified',
        });
        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: sessionRecorderMetadata.mode ?? 'N/A',
            message: `reifyPlaceholderIfNeeded - reified!`,
            attributes: sessionRecorderMetadata,
            context: 'media_artifact',
        });
    } catch (e) {
        const error = e as Error;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if ((error as any).status === 'not_found') {
            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: sessionRecorderMetadata?.mode ?? 'N/A',
                message: 'Placeholder already reified.',
                context: 'media_artifact',
            });
            return;
        }
        spanStatus = SpanStatus.ERRORED;
        spanError = error || new DescriptError('Unknown error', ErrorCategory.Recording);
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            error,
            context: 'media_artifact',
        });
        throw error;
    } finally {
        endWebRecordingSpan(ctx, SpanNames.WebRecorder_Setup, spanStatus, undefined, spanError);
    }
}

/**
 * Asynchronously creates a Web Recorder segment from the Web Recording API Blob Event.
 * Contains operations such as:
 * - Creating a buffer from blobEvent,
 * - Calculating the md5 hash of the buffer,
 * - Building up the parameters required for segment upload,
 * - Triggering an info level event regarding the created segment,
 * - Uploading the segment using the 'createAndUploadSegment' function.
 */
export async function createWebRecorderSegmentFromBlob(
    ctx: AsyncContext,
    webRecordingSession: WebRecordingSession,
    recorderId: string,
    blob: Blob,
    metadata: WebRecorderSegmentMetadata,
) {
    try {
        const sessionRecorderMetadata = webRecordingSession.recorders.get(recorderId);
        invariant(
            sessionRecorderMetadata?.assetGuid,
            'Asset ID must be defined before creating segment',
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.artifactGuid,
            'Artifact ID must be defined before creating segment',
            ErrorCategory.Recording,
        );
        invariant(
            webRecordingSession.projectId,
            'Project ID must be defined before creating segment',
            ErrorCategory.Recording,
        );

        const buffer = await blob.arrayBuffer();
        const md5 = arrayBufferMd5Hex(buffer);

        const uploadSegmentParams = {
            projectId: webRecordingSession.projectId,
            assetSyncApi,
            assetGuid: sessionRecorderMetadata.assetGuid,
            artifactGuid: sessionRecorderMetadata.artifactGuid,
            buffer,
            fileExtension: sessionRecorderMetadata.fileExtension ?? '',
            md5,
            sequence: metadata.chunkNumber,
            isInit: metadata.isInit,
            duration: metadata.chunkDuration,
            startTime: metadata.chunkStartOffset,
        };

        log.debug(`Uploading segment: ${metadata.chunkNumber}`, {
            params: JSON.stringify(uploadSegmentParams),
        });
        const res = await createAndUploadSegment(ctx, uploadSegmentParams);
        if (metadata.chunkNumber > 1) {
            recoverySegmentComplete(
                sessionRecorderMetadata.artifactGuid,
                metadata.chunkNumber,
            ).catch(async (error) => {
                trackError(error, 'recovery-segment-complete-failed', {
                    category: ErrorCategory.Recording,
                    attachment: {
                        filename: 'recovery-debug.txt',
                        data: await debugDump(),
                        contentType: 'text/plain',
                    },
                });
            });
        }

        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: metadata.mode,
            message: `Created segment for ${metadata.mode} - ${metadata.chunkNumber}`,
            attributes: {
                uploadSegmentParams,
                sessionRecorderMetadata,
            },
            context: 'media_upload',
        });

        return res;
    } catch (e) {
        const error = e as Error;

        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: metadata.mode,
            error,
            context: 'media_upload',
        });
        throw error;
    }
}

/**
 * Commits the final artifact for the web recording. Lets GAT
 * know that all segments have been uploaded and are ready for stitching.
 * Throws an error if Asset ID, Artifact ID or Project ID are not found.
 */
export async function commitFinalArtifact(
    webRecordingSession: WebRecordingSession,
    recorderId: string,
    recovery = false,
) {
    const sessionRecorderMetadata = webRecordingSession.recorders.get(recorderId);
    const ctx = startWebRecordingSpan(
        SpanNames.WebRecorder_CommitArtifact,
        webRecordingSession.sessionId,
    );
    let spanStatus = SpanStatus.COMPLETED;
    let spanError: Error | undefined;

    try {
        invariant(
            sessionRecorderMetadata?.assetGuid,
            'Could not create segment: Asset ID not found.',
            ErrorCategory.Recording,
        );
        invariant(
            sessionRecorderMetadata?.artifactGuid,
            'Could not create segment: Artifact ID not found.',
            ErrorCategory.Recording,
        );
        invariant(
            webRecordingSession.projectId,
            'Could not create segment: Project ID not found.',
            ErrorCategory.Recording,
        );
        if (sessionRecorderMetadata.segmentedArtifactState === 'committed') {
            WebRecorder.onRecorderEvent({
                type: 'info',
                recorder: sessionRecorderMetadata.mode ?? 'N/A',
                context: 'media_artifact',
                message: `commitFinalArtifact - bailing, already committed`,
            });
            return;
        }
        const { projectId, sessionId } = webRecordingSession;
        const { assetGuid, artifactGuid } = sessionRecorderMetadata;

        invariant(
            assetGuid !== undefined,
            'Asset ID must be defined before committing artifact',
            ErrorCategory.Recording,
        );
        invariant(
            artifactGuid !== undefined,
            'Artifact ID must be defined before committing artifact',
            ErrorCategory.Recording,
        );
        await assetSyncApi.commitUpload(ctx, {
            projectId,
            assetGuid,
            artifactGuid,
        });

        updateWebRecordingSessionRecorderMetadata(sessionId, recorderId, {
            segmentedArtifactState: 'committed',
        });

        log.debug(`Completing recovery artifact: ${artifactGuid}`);
        recoveryArtifactComplete(artifactGuid).catch(async (error) => {
            trackError(error, 'recovery-artifact-complete-failed', {
                category: ErrorCategory.Recording,
                attachment: {
                    filename: 'recovery-debug.txt',
                    data: await debugDump(),
                    contentType: 'text/plain',
                },
            });
        });

        WebRecorder.onRecorderEvent({
            type: 'info',
            recorder: sessionRecorderMetadata.mode ?? 'N/A',
            context: 'media_artifact',
            message: `commitFinalArtifact - committed!`,
            attributes: {
                sessionRecorderMetadata,
                projectId,
                sessionId,
                assetGuid,
                artifactGuid,
            },
        });
    } catch (e) {
        const error = e as Error;

        spanStatus = SpanStatus.ERRORED;
        spanError = error || new DescriptError('Unknown error', ErrorCategory.Recording);
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: sessionRecorderMetadata?.mode ?? 'N/A',
            error,
            context: 'media_artifact',
            recovery,
        });
        throw error;
    } finally {
        endWebRecordingSpan(
            ctx,
            SpanNames.WebRecorder_CommitArtifact,
            spanStatus,
            undefined,
            spanError,
        );
    }
}

// ================== Web Recorder LifeCycle Events & Handlers ==================
export async function handleRecordingStarted(
    metadata: WebRecorderStartMetadata,
    delegateToken?: string,
) {
    try {
        await webRecorderMainQueue.add(async () => {
            const recordingSessionId = metadata.recordingSessionId;
            invariant(
                recordingSessionId,
                'Handle Recording Started: No recording session id found',
                ErrorCategory.Recording,
            );
            const webRecordingSession = getWebRecordingSession(recordingSessionId);
            invariant(
                webRecordingSession,
                `Handle Recording Started: No recording session found for id: ${recordingSessionId}`,
                ErrorCategory.Recording,
            );
            invariant(
                metadata.recorderId,
                'Handle Recording Started: No recorder id found',
                ErrorCategory.Recording,
            );

            updateWebRecordingSessionRecorderMetadata(recordingSessionId, metadata.recorderId, {
                mode: metadata.mode,
                fileExtension: metadata.fileExtension,
                hasAudio: metadata.hasAudio,
                hasVideo: metadata.hasVideo,
                audioSettings: metadata.audioSettings,
                videoSettings: metadata.videoSettings,
                liveTranscribe: metadata.liveTranscribe,
            });
            trackRecordingAnalyticsEvent(
                webRecordingSession,
                RecordingAnalyticsEvents.recorder_started,
                {},
                metadata.recorderId,
            );
            const ctx = startWebRecordingSpan(SpanNames.WebRecorder_Setup, recordingSessionId);
            await createAssetAndArtifactIfNeeded(
                ctx,
                webRecordingSession,
                metadata.recorderId,
                delegateToken,
            ).catch((error) => {
                endWebRecordingSpan(
                    ctx,
                    SpanNames.WebRecorder_Setup,
                    SpanStatus.ERRORED,
                    undefined,
                    error,
                );

                throw error;
            });
            endWebRecordingSpan(ctx, SpanNames.WebRecorder_Setup, SpanStatus.COMPLETED);
        });
    } catch (e) {
        const error = e as Error;

        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: metadata.mode,
            error,
            context: 'recorder_start',
        });
    }
}

export async function handleOnDataAvailable(
    blob: Blob,
    metadata: WebRecorderSegmentMetadata,
    trackSkew?: number,
    onUploadComplete?: () => void,
    delegateToken?: string,
    transcriptionLanguage?: SpeechPipelineLanguage,
) {
    try {
        const recordingSessionId = metadata.recordingSessionId;
        invariant(
            recordingSessionId,
            'Handle On Data Available: No recording session id found',
            ErrorCategory.Recording,
        );
        const recorder = WebRecorder.getAllRecorders().find(
            (r) => r.id === metadata.recorderId,
        );
        if (recorder?.terminated) {
            return;
        }
        await webRecorderMainQueue.add(async () => {
            let spanStatus = SpanStatus.COMPLETED;
            let spanError: Error | undefined;

            // Ensure the required entities are created before continuing
            const dataAvailableSetupSpan = startWebRecordingSpan(
                SpanNames.WebRecorder_DataAvailableSetup,
                recordingSessionId,
            );

            try {
                const webRecordingSession = getWebRecordingSession(recordingSessionId);
                invariant(
                    webRecordingSession?.projectId,
                    'Handle On Data Available: No project id found',
                    ErrorCategory.Recording,
                );

                const sessionRecorderMetadata = webRecordingSession.recorders.get(
                    metadata.recorderId,
                );

                const segmentCount = (sessionRecorderMetadata?.segmentCount ?? 0) + 1;
                const duration_sec =
                    (sessionRecorderMetadata?.duration_sec ?? 0) + metadata.chunkDuration / 1e6;
                const size_mb =
                    (sessionRecorderMetadata?.size_mb ?? 0) + blob.size / 1024 / 1024;

                // Increase the segment count for the recorder
                updateWebRecordingSessionRecorderMetadata(
                    recordingSessionId,
                    metadata.recorderId,
                    {
                        segmentCount,
                        duration_sec,
                        size_mb,
                    },
                );

                if (
                    sessionRecorderMetadata?.recordingStartTimecode === undefined &&
                    metadata.recordingStartTimecode !== undefined
                ) {
                    updateWebRecordingSessionRecorderMetadata(
                        recordingSessionId,
                        metadata.recorderId,
                        {
                            recordingStartTimecode: metadata.recordingStartTimecode,
                        },
                    );
                }
                await createAssetAndArtifactIfNeeded(
                    dataAvailableSetupSpan,
                    webRecordingSession,
                    metadata.recorderId,
                    delegateToken,
                );

                await signalTrackIfNeeded(
                    dataAvailableSetupSpan,
                    webRecordingSession.sessionId,
                    metadata.recorderId,
                    trackSkew,
                    delegateToken,
                    transcriptionLanguage,
                ).catch((error) => {
                    trackError(error, 'signal-track-failed', {
                        category: ErrorCategory.Recording,
                    });
                });
            } catch (error) {
                spanStatus = SpanStatus.ERRORED;
                spanError = error as Error;
                throw error;
            } finally {
                endWebRecordingSpan(
                    dataAvailableSetupSpan,
                    SpanNames.WebRecorder_DataAvailableSetup,
                    spanStatus,
                    undefined,
                    spanError,
                );
            }
        });
        await webRecorderUploadQueue.add(async () => {
            await webRecorderMainQueue.onIdle();

            let spanStatus = SpanStatus.COMPLETED;
            let spanError: Error | undefined;

            // Add a new pending segment to the web recording session and recorder objects.
            const dataAvailableUploadSpan = startWebRecordingSpan(
                SpanNames.WebRecorder_DataAvailableUpload,
                recordingSessionId,
            );

            try {
                const webRecordingSession = getWebRecordingSession(recordingSessionId);
                invariant(
                    webRecordingSession?.projectId,
                    'Handle On Data Available: No project id found',
                    ErrorCategory.Recording,
                );

                await createWebRecorderSegmentFromBlob(
                    dataAvailableUploadSpan,
                    webRecordingSession,
                    metadata.recorderId,
                    blob,
                    metadata,
                );

                if (onUploadComplete) {
                    onUploadComplete();
                }
            } catch (error) {
                spanStatus = SpanStatus.ERRORED;
                spanError = error as Error;
                throw error;
            } finally {
                endWebRecordingSpan(
                    dataAvailableUploadSpan,
                    SpanNames.WebRecorder_DataAvailableUpload,
                    spanStatus,
                    undefined,
                    spanError,
                );
            }
        });
    } catch (e) {
        const error = e as Error;

        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: metadata.mode,
            error,
            context: 'media_upload',
        });
    }
}

export async function handleRecordingStopped(
    metadata: WebRecorderStopMetadata,
    userId: string,
    attributes?: Attributes,
    onArtifactCommitted?: (recorderId: string) => void,
) {
    let webRecordingSession: WebRecordingSession | undefined;
    const recordingSessionId = metadata.recordingSessionId;
    invariant(
        recordingSessionId,
        'Handle Recording Stopped: No recording session id found',
        ErrorCategory.Recording,
    );

    try {
        if (metadata.terminated) {
            webRecordingSession = getWebRecordingSession(recordingSessionId);
            if (webRecordingSession) {
                trackRecordingAnalyticsEvent(
                    webRecordingSession,
                    RecordingAnalyticsEvents.recorder_terminated,
                    {},
                    metadata.recorderId,
                );
            }
            endWebRecordingTrace(recordingSessionId, undefined, {
                [SpanTag.appRecorderTerminated]: true,
            });
            return;
        }
        await webRecorderMainQueue.add(async () => {
            webRecordingSession = getWebRecordingSession(recordingSessionId);
            invariant(
                webRecordingSession,
                'Handle Recording Stopped: No web recording session found!',
                ErrorCategory.Recording,
            );
            invariant(
                webRecordingSession?.projectId,
                'Handle Recording Stopped: No project id found!',
                ErrorCategory.Recording,
            );
            trackRecordingAnalyticsEvent(
                webRecordingSession,
                RecordingAnalyticsEvents.recorder_stopped,
                {},
                metadata.recorderId,
            );
            await reifyPlaceholderIfNeeded(webRecordingSession, metadata.recorderId);
        });

        // Wait for those operations to finish
        await Promise.all([webRecorderMainQueue.onIdle(), webRecorderUploadQueue.onIdle()]);

        webRecordingSession = getWebRecordingSession(recordingSessionId);
        invariant(
            webRecordingSession,
            'Handle Recording Stopped: No web recording session found!',
            ErrorCategory.Recording,
        );

        await commitFinalArtifact(webRecordingSession, metadata.recorderId);
        trackRecordingAnalyticsEvent(
            webRecordingSession,
            RecordingAnalyticsEvents.recorder_completed,
            {},
            metadata.recorderId,
        );

        if (onArtifactCommitted) {
            onArtifactCommitted(metadata.recorderId);
        }

        if (!WebRecorder.isAnyRecorderActive()) {
            // To track the time taken since the recorder stopped, we write the current time to the attribute and correct it here to be the difference
            if (
                attributes?.[SpanTag.appRecorderUploadAfterStop] &&
                typeof attributes?.[SpanTag.appRecorderUploadAfterStop] === 'number'
            ) {
                attributes[SpanTag.appRecorderUploadAfterStop] =
                    Date.now() - attributes[SpanTag.appRecorderUploadAfterStop];
            }
            endWebRecordingTrace(recordingSessionId, undefined, attributes);
        }
    } catch (e) {
        const error = e as Error;

        WebRecorder.onRecorderEvent({
            type: 'run_recovery',
            props: {
                recoveryReason: 'error-on-stop',
                userId,
                singleSessionId: webRecordingSession?.sessionId,
            },
        });
        WebRecorder.onRecorderEvent({
            type: 'error',
            recorder: metadata.mode,
            error,
            context: 'recorder_stop',
        });
    }
}

export async function handleRecordingPaused(isPaused: boolean) {
    await WebRecorder.pauseAllRecorders(isPaused);
}
