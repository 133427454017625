/** Used to define the upper bound of delta size for Live Collab. */
export const MAX_POST_COMMIT_SIZE_BYTES = 9 * 2 ** 20; // 9MB

export const FORMAT_JSON_DIFF_PATCH_1 = 'jdp1';

export const FORMAT_GZIPPED_JSON_DIFF_PATCH_1 = 'gzjdp1';

export const HASH_V1_SHA256 = 'v1';

export const DEPRECATED_FORMAT_NOOP = 'noop';
