// Copyright 2024 Descript, Inc

import { ErrorCategory } from '@descript/errors';

export class LiveCollabUnavailableError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'LiveCollabUnavailableError';
}

export class LiveCollabHistoricalVersionLoadError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'LiveCollabHistoricalVersionLoadError';
    constructor(message: string) {
        super(message);
    }
}

export class LiveCollabDocumentResolutionError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'LiveCollabDocumentResolutionError';
    constructor(
        message: string,
        readonly projectId: string,
        readonly earliestUnsyncedCommitTime: string | undefined,
    ) {
        super(message);
    }
}

export type LiveCollabDeltaTooLargeSubtype = 'duplicate-to-storyboard' | 'edit';

// exported so that we can check types when the error is emitted from the worker
export const LiveCollabDeltaTooLargeErrorName = 'LiveCollabDeltaTooLargeError';

export class LiveCollabDeltaTooLargeError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = LiveCollabDeltaTooLargeErrorName;
    constructor(
        message: string,
        readonly subtype: LiveCollabDeltaTooLargeSubtype = 'edit',
    ) {
        super(message);
    }
}

export class LiveCollabUnsupportedDeltaError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'LiveCollabUnsupportedDeltaError';
    constructor(message: string) {
        super(message);
    }
}

export class LiveCollabMergeHeadsError extends Error {
    category = ErrorCategory.LiveCollab;
    override name = 'LiveCollabMergeHeadsError';
    constructor(message: string) {
        super(message);
    }
}
