// Copyright 2023 Descript, Inc
/**
 * Common tag column names
 *
 * We pre-define some column names here, because we don't want to have too many columns
 * (like we have with product analytics). Often, tag names can be reused based on context,
 * such as `app.span.type` or `app.object.id`. The tag values can be high cardinality,
 * so we don't need to worry as much about treating them more freeform as long as
 * they have consistent types.
 */
import { Span } from '@opentelemetry/api';

export const errorCategorySymbol = Symbol('errorCategory');

export enum SpanTag {
    /*
     * Tracing span tags
     */

    traceId = 'trace.trace_id',
    parentId = 'trace.parent_id',
    spanId = 'trace.span_id',
    serviceName = 'service.name',
    name = 'name',
    durationMs = 'duration_ms',
    // Whether the span should be marked as having failed in some way
    isErrored = 'error',
    // Some generic status categories that are useful for bucketing common results
    spanStatus = 'status',
    spanKind = 'kind',
    // for tracking sample rate before sending to Honeycomb
    sampleRate = 'sampler.sampleRate',
    // for indicating that a span was canceled (does not imply error)
    canceled = 'canceled',

    /**
     * Metric tags
     */
    metricName = 'metric_name',

    /*
     * Span event tag names
     *
     * These can be used for normal spans too, but generally they are used with
     * span (point) events like errors or logging messages.
     */

    annotationType = 'meta.annotation_type',
    message = 'message',
    stacktrace = 'stacktrace',
    // e.g. TypeError
    errorType = 'error.type',
    // Whether error propagated out of the containing span
    errorEscaping = 'error.escaping',
    // Usually some string code, like 'ENOENT', 'NetworkingError', etc.
    errorCode = 'error.code',
    /** Sentry event ID */
    sentryId = 'error.sentry_id',

    /*
     * System tags
     */

    buildName = 'build.name',
    buildType = 'build.type',
    buildVersion = 'build.version',
    buildPrettyVersion = 'build.pretty_version',
    osName = 'system.os.name',
    osVersion = 'system.os.version',
    osTotalMemory = 'system.os.total_memory_bytes',
    osFreeMemory = 'system.os.free_memory_bytes',
    osNumCpus = 'system.os.number_of_cpus',
    nodeVersion = 'system.node_version',
    browserName = 'browser.name',
    /*
     * Messaging and async queues
     */

    // milliseconds
    messagingE2ELatencyMs = 'messaging.e2e_latency_ms',

    /*
     * Network-related tags
     */
    httpScheme = 'http.scheme',
    httpPath = 'http.path',
    httpMethod = 'http.method',
    httpRequestContentLength = 'http.request_content_length',
    httpResponseContentLength = 'http.response_content_length',
    httpStatusCode = 'http.status_code',
    netPeerName = 'net.peer.name',
    netPeerPort = 'net.peer.port',
    netBytesSent = 'net.bytes_sent',

    /* Redux action tags */
    reduxActionType = 'Redux.action.type',
    reduxActionsBatchId = 'Redux.actions.batchId',

    /*
     * Descript app-specific span tags
     *
     * These can be used for app-level concerns, such as
     * common argument annotations, messages, etc.
     */

    // Generic tag for noting any potentially high-cardinality way
    // Since `name` is meant to be low cardinality/static, this tag can be used for
    // any other useful tag type annotation, e.g. to note certain codepaths or variants.
    appSpanType = 'app.span.type',
    // Often operations are doing something to one particular object that has an ID.
    // If there isn't a more specific tag name below like appProjectId, use this one.
    appObjectId = 'app.object.id',
    // For annotating users that may not be the current user (e.g. author of something).
    // The current user is always added on the server, so there is no need to add annotations
    // for the current user.
    appUserId = 'app.user.id',
    appUserLoggedIn = 'app.user.logged_in',
    appProjectId = 'app.project.id',
    appProjectSize = 'app.project.size',
    appRevisionId = 'app.revision.id',
    appBaseRevisionId = 'app.base_revision.id',
    appBaseRevisionNumber = 'app.base_revision.number',
    appCompositionId = 'app.composition.id',
    appMediaRefId = 'app.media_ref.id',
    appAssetId = 'app.asset.id',
    appArtifactId = 'app.artifact.id',
    appArtifactContentType = 'app.artifact.contentType',
    appArtifactQuality = 'app.artifact.quality',
    appAssetUrl = 'app.asset.url',
    appAssetQuality = 'app.asset.quality',
    appFileExtension = 'app.file.extension',
    appImplementation = 'app.implementation',
    appFileCount = 'app.file.count',
    appInvalidFileCount = 'app.file.count.invalid',
    appValidFileCount = 'app.file.count.valid',
    // Size in bytes
    appFileSize = 'app.file.size',
    appMimeType = 'app.file.mimetype',
    appMD5 = 'app.md5',
    appSkippedMD5 = 'app.skipped_md5',
    appBundleVersion = 'app.bundle_version',
    appFeatureFlagValue = 'app.feature_flag.value',
    appObjectStoreBucket = 'app.object_store_bucket',
    appTimeoutLimitMs = 'app.timeout_limit_ms',
    // Some count of a sub-resource, number of iterations, etc.
    appCount = 'app.count',
    appDepth = 'app.depth',
    appResult = 'app.result',
    // Some scope, e.g., export scope
    appScope = 'app.scope',
    appBatch = 'app.batch',
    appTrigger = 'app.trigger',
    appPeriod = 'app.period',
    appInteractive = 'app.interactive',
    appFormat = 'app.format',
    appFormatLong = 'app.format.long',
    appCopy = 'app.copy',
    appCacheResult = 'app.cache.result',
    appCacheNetworkMs = 'app.cache.network_ms',
    appCacheNetworkEnabled = 'app.cache.network.enabled',
    appDataOrigin = 'app.data.origin',
    appQueueLength = 'app.queue.length',
    appQueueTime = 'app.queue.time',
    appIdempotencyKey = 'app.idempotency_key',
    appDownloadMs = 'app.download_ms',
    appRate = 'app.rate',
    // Infra region
    appRegion = 'app.region',
    appOrigin = 'app.origin', // caller origin

    // Drive View
    appDriveCount = 'app.count.drive',
    appProjectCount = 'app.count.project',
    appProjectDownloadedCount = 'app.count.project_downloaded',
    appDriveId = 'app.drive.id',
    appProductGroup = 'app.product_group',
    appDriveHoursIncluded = 'app.drive_hours_included',

    // App source info
    appMediaSource = 'app.media_reference.source',
    appMediaSourceProvider = 'app.media_reference.source_provider',

    // Media engine
    appEncodeMs = 'app.encode_ms',
    appMediaType = 'app.media_type',
    appMediaEngineMode = 'app.media_engine_mode',
    appDataRefType = 'app.data_ref.type',
    appMediaDuration = 'app.media_duration',
    appMediaInterface = 'app.media_interface',
    appMediaEngineType = 'app.media_engine.type',
    appAudioContextState = 'app.audio_context_state',
    appAudioPrefetchMs = 'app.audio.prefetch_ms',
    appAudioPrefetchCount = 'app.audio.prefetch_count',
    appAudioEncodeMs = 'app.audio.encode_ms',
    appAudioSuspendMs = 'app.audio.suspend_ms',
    appAudioSetupGraphMs = 'app.audio.setup_graph_ms',
    appCreateAudioTimelineMs = 'app.create_simple_timeline_ms',
    appCreateVideoTimelineMs = 'app.create_video_timeline_ms',
    appSetAudioTimelineMs = 'app.set_timeline_ms',
    appSetVideoTimelineMs = 'app.set_video_timeline_ms',
    appGenerateAndSetTimelinesMs = 'app.generate_and_set_timelines_ms',
    appAudioResumeMs = 'app.audio.resume_ms',
    appVideoTotalPrefetchMs = 'app.video.prefetch_ms',
    appVideoActualPrefetchMs = 'app.video.actual_prefetch_ms',
    appIsVelocityAwarePrefetch = 'app.prefetch.velocity_aware',
    appVideoPrefetchCount = 'app.video.prefetch_count',
    appGetAudioMediaMap = 'app.get_audio_media_map_ms',
    appGetVideoMediaMap = 'app.get_video_media_map_ms',
    appVideoEncodeMs = 'app.video.encode_ms',
    appNormalizeMs = 'app.normalize_ms',
    appTimelineDuration = 'app.timeline_duration',
    appPlayerKey = 'app.player_key',
    appAudioExportMs = 'app.audio.export_ms',
    appChannels = 'app.channels',
    appAudioBitrate = 'app.audio.bitrate',
    appVideoBitrate = 'app.video.bitrate',
    appVideoPixelFormat = 'app.video.pixel_format',
    appVideoCodec = 'app.video.codec',
    appWebCodecsVideoDecoderCodec = 'app.web_codecs_video_decoder.codec',
    appPlayerPlayMs = 'app.player_play_ms',
    appPlayerSeekMs = 'app.player_seek_ms',
    appPlayerSeekAndPlay = 'app.player_seek_and_play',
    appPlayerSeekScrubbing = 'app.player_seek_scrubbing',
    appSeekMs = 'app.seek_ms',
    appPrefetchMs = 'app.prefetch_ms',
    appFps = 'app.fps',
    appMtsEnabled = 'app.mts.enabled',
    appPrepareMs = 'app.render.prepare_ms',
    appDecodeMs = 'app.render.decode_ms',
    appDrawMs = 'app.render.draw_ms',
    appGetPixelsMs = 'app.render.get_pixels_ms',
    appAssetsChanged = 'app.assets.changed',
    appCreateImageBitmap = 'app.create_image_bitmap_ms',
    appKeyframeIntervalMean = 'app.keyframe_interval.mean',
    appKeyframeIntervalMin = 'app.keyframe_interval.min',
    appKeyframeIntervalMax = 'app.keyframe_interval.max',
    appKeyframeIntervalCount = 'app.keyframe_interval.count',
    appKeyframeIntervalStdDev = 'app.keyframe_interval.stddev',
    appFrameCount = 'app.frames.count',
    appFrameDupes = 'app.frames.duplicate',

    appMediaFetchPlaylistMs = 'app.media.fetch_playlist_ms',
    appMediaCreateDemuxerMs = 'app.media.create_demuxer_ms',
    appMediaCreateDecoderMs = 'app.media.create_decoder_ms',
    appMediaFallback = 'app.media.fallback',
    appMediaFallbackFailed = 'app.media.fallback.failed',
    appMediaFallbackGenerationTimeMs = 'app.media.fallback_generation_time_ms',

    appMediaSegmentFetchMs = 'app.media.segment_fetch_ms',

    appMediaSegmentDemuxMs = 'app.media.segment_demux_ms',
    appMediaFindSementMs = 'app.media.find_segment_ms',
    appMediaSeekMs = 'app.media.seek_ms',

    appMediaIsPrefetch = 'app.media.is_prefetch',
    appMediaPrefetchCacheState = 'app.media.prefetch_cache_state',
    appMediaPrefetchCacheHitAgeMs = 'app.media.prefetch_cache_age_ms',
    appMediaSegmentCount = 'app.media.segment_count',
    appMediaOldUnusedCount = 'app.media.old_unused_count',

    appMediaFileConversion = 'app.media.file_conversion',

    // Editor
    appPublish = 'app.publish',
    appEditor = 'app.editor',
    appClipCount = 'app.count.clip',
    appTrackCount = 'app.count.track',
    appSpeakerCount = 'app.count.speaker',
    appPinCount = 'app.count.pin',
    appAssetCount = 'app.count.asset',
    appSceneCount = 'app.count.scene', // storyboard scenes
    appTemplate = 'app.template',
    appAISpeech = 'app.overdub',
    appLipSync = 'app.lip_sync',
    appStudioSound = 'app.studio_sound',
    appStudioSoundIntensity = 'app.studio_sound_intensity',
    appEyeContact = 'app.eye_contact',
    appBackgroundRemoval = 'app.background_removal',
    appFontCustom = 'app.font.custom',
    appFontBuiltin = 'app.font.builtin',
    appWatermark = 'app.watermark',
    appHeight = 'app.height',
    appWidth = 'app.width',
    appStoryboard = 'app.storyboard',
    appStoryboardRemastered = 'app.storyboard_remastered',
    appDesktop2 = 'app.desktop2',
    appLiveCollab = 'app.live_collab',
    appWordlessMediaEllipses = 'app.wordless_media_ellipses',
    appTimelineRedesign = 'app.timeline_redesign',
    appAbsolutePlayhead = 'app.absolute_playhead',
    appFlattenTracksFilter = 'app.flatten_tracks_filter',

    // AI Speech
    appAISpeechRequestType = 'app.overdub.request_type',
    appAISpeechVoiceId = 'app.overdub.voice_id',
    appAISpeechVoiceTrainingComplete = 'app.overdub.voice_training_complete',
    appAISpeechError = 'app.overdub.error',

    // Templates
    appTemplateProjectId = 'app.template.project_id',
    appTemplateCompositionId = 'app.template.composition_id',
    appTemplateDriveId = 'app.template.drive_id',
    appTemplateVersionId = 'app.template.version_id',
    appTemplateCardId = 'app.template.card_id',
    appTemplatePreviewLandingPage = 'app.template.preview.landing_page',
    appTemplatePreviewState = 'app.template.preview.state',
    appTemplatePreviewTotalTimeToFetchUrlMs = 'app.template.preview.total_time_to_fetch_url_ms',
    appTemplatePreviewTotalTimeToLoadImageMs = 'app.template.preview.total_time_to_load_image_ms',
    appTemplatePreviewTotalTimeToLoadVideoMs = 'app.template.preview.total_time_to_load_video_ms',
    appTemplatePreviewImageAvailable = 'app.template.preview.image_available',
    appTemplatePreviewVideoAvailable = 'app.template.preview.video_available',
    appTemplatePreviewImageLoadFailed = 'app.template.preview.image_load_failed',
    appTemplatePreviewVideoLoadFailed = 'app.template.preview.video_load_failed',
    appTemplateCopySourceContentsMs = 'app.template.copy_source_contents_ms',
    appTemplateMakeSelectionMs = 'app.template.make_selection_ms',
    appTemplatePasteContentsMs = 'app.template.paste_contents_ms',
    appTemplateTrackSceneAddedMs = 'app.template.track_scene_added_ms',

    // Save card to template
    appTemplateSaveCardNewTemplate = 'app.template.save_scene.new_template',
    appTemplateSaveCardGetTemplateProjectMs = 'app.template.save_scene.get_template_project_ms',
    appTemplateSaveCardCopyPasteMs = 'app.template.save_scene.copy_paste_ms',
    appTemplateSaveCardSaveNewRevisionMs = 'app.template.save_scene.save_new_revision_ms',

    // Publish
    appPublishPublishedProjectId = 'app.publish.published_project_id',
    appPublishType = 'app.publish.type',
    appPublishPermission = 'app.publish.permission',
    appPublishQuickEdit = 'app.publish.quick_edit',
    appPublishDestination = 'app.publish.destination',
    appPublishCheckAlignmentJobsMs = 'app.publish.check_alignment_jobs_ms',
    appPublishUploadBounceFilesMs = 'app.publish.upload_bounce_files_ms',
    appPublishCreateAndUploadPublishedProjectMs = 'app.publish.create_and_upload_published_project_ms',
    appPublishTotalTimeMs = 'app.publish.total_time_ms',
    appPublishResolution = 'app.publish.resolution',
    appPublishWidth = 'app.publish.width',
    appPublishHeight = 'app.publish.height',

    // Publish YouTube
    appPublishYoutubeRequestSuccess = 'app.publish.youtube.request_success',
    appPublishYoutubeErrorType = 'app.publish.youtube.error_type',
    appPublishYoutubeErrorMessage = 'app.publish.youtube.error_message',
    appPublishGoogleDriveRequestSuccess = 'app.publish.googledrive.request_success',
    appPublishGoogleDriveErrorType = 'app.publish.googledrive.error_type',
    appPublishGoogleDriveErrorMessage = 'app.publish.googledrive.error_message',

    // Recorder
    appEditorRecordingStartedTimestampDelta = 'app.editor.recording_started_timestamp',
    appEditorRecordingUserStartedRecording = 'app.editor.recording.user_started_recording',
    appEditorRecorderVersion = 'app.Editor.recorder_version',
    appEditorRecordingStoppedTimestampDelta = 'app.editor.recording_stopped_timestamp',
    appEditorRecordingCancelledTimestampDelta = 'app.editor.recording_cancelled_timestamp',
    appEditorRecordingForceKillTimestampDelta = 'app.editor.recording_force_kill_timestamp',
    appEditorRecordingCurrentConfiguration = 'app.editor.recording.current_configuration',
    appEditorRecordingCurrentState = 'app.editor.recording.current_state',
    appEditorRecordingDuration = 'app.editor.recording_duration',
    appEditorRecordingCameraLoadStatus = 'app.editor.recording.camera_load_status',
    appEditorRecordingCurrentMessage = 'app.editor.recording.current_message',
    appEditorRecordingId = 'app.editor.recording_id',
    appEditorRecordingSuccess = 'app.editor.recording_success',
    appEditorRecordingNativeSuccess = 'app.editor.recording_native_success',
    appEditorRecordingCompositionSuccess = 'app.editor.recording_composition_success',
    appEditorRecordingExternalError = 'app.editor.recording_external_error',
    appEditorRecordingErrorTypeMessage = 'app.editor.recording_error_type_message',
    appEditorRecordingErrorType = 'app.editor.recording_error_type',
    appEditorRecordingErrorMessage = 'app.editor.recording_error_message',
    appEditorRecordingMode = 'app.editor.recording_mode',
    appEditorBackupType = 'app.editor.backup_type',
    appEditorRecordingAllowsScreenCaptureKit = 'app.editor.recording_allows_screen_capture_kit',
    appEditorRecordingComputerAudioSet = 'app.editor.recording.computer_audio_set',
    appEditorRecordingMicrophoneAudioSet = 'app.editor.recording.microphone_audio_set',
    appEditorRecordingScreenRecordingSet = 'app.editor.recording.screen_recording_set',
    appEditorRecordingCameraRecordingSet = 'app.editor.recording.camera_recording_set',
    appEditorAudioRecordingStartedMessage = 'app.editor.audio_recording_starting_message',
    appEditorAudioRecordingStoppedMessage = 'app.editor.audio_recording_stopped_message',
    appEditorRecordingProcessingFilesMessage = 'app.editor.recording.processing_files_message',
    appEditorRecorderDocumentAddSuccess = 'app.editor.recording.document_add_success',
    appEditorRecorderDocumentAddMessage = 'app.editor.recording.document_add_message',
    appEditorRecorderMonitorRecorderMessage = 'app.editor.recording.monitor_message',
    appEditorRecorderMonitorRecorderError = 'app.editor.recording.monitor_error_type',

    // Recorder V2
    appRecorderContext = 'app.recorder.context',
    appRecorderWorkflowId = 'app.recorder.workflow_id',
    appRecorderSessionId = 'app.recorder.session_id',
    appRecorderRecordingId = 'app.recorder.recording_id',
    appRecorderOperatingSystem = 'app.recorder.os',
    appRecorderOperatingSystemVersion = 'app.recorder.os_version',
    appRecorderBrowser = 'app.recorder.browser',
    appRecorderBrowserVersion = 'app.recorder.browser_version',
    appRecorderConfiguration = 'app.recorder.configuration',
    appRecorderConfigCamera = 'app.recorder.configuration.camera',
    appRecorderConfigMic = 'app.recorder.configuration.mic',
    appRecorderConfigCompAudio = 'app.recorder.configuration.computer_audio',
    appRecorderConfigScreen = 'app.recorder.configuration.screen',
    appRecorderEquipmentMicrophone = 'app.recorder.equipment.microphone',
    appRecorderEquipmentCamera = 'app.recorder.equipment.camera',
    appRecorderErrorCategory = 'app.recorder.error_category',
    appRecorderErrorMessage = 'app.recorder.error_message',
    appRecorderErrorExtra = 'app.recorder.error_extra',
    appRecorderSetupErrorPhase = 'app.recorder.setup.error_phase',
    appRecorderSetupTimeToLaunch = 'app.recorder.setup.time_to_launch',
    appRecorderRecordingDuration = 'app.recorder.recording.duration',
    appRecorderRecordingFailureType = 'app.recorder.recording.failure_type',
    appRecorderRecordingProgUploadEnabled = 'app.recorder.recording.prog_upload_enabled',
    appRecorderImportFailureType = 'app.recorder.import.failure_type',
    appRecorderImportUpsertDidChangeDoc = 'app.recorder.import.upsert_did_change_doc',
    appRecorderImportDidAttemptRecovery = 'app.recorder.import.did_attempt_recovery',
    appRecorderImportDidAddMediaAsSequence = 'app.recorder.import.did_add_media_as_sequence',
    appRecorderImportTarget = 'app.recorder.import.target',
    appRecorderImportNumberOfFiles = 'app.recorder.import.number_of_files',
    appRecorderRecordingLocationDidGetDeleted = 'app.recorder.recording_did_get_deleted',
    appRecorderUploadQueueSize = 'app.recorder.upload.queue_size',
    appRecorderSuccess = 'app.recorder.success',
    appRecorderUploadAfterStop = 'app.recorder.upload_time_after_stop_ms',
    appRecorderTerminated = 'app.recorder.terminated',

    // Web Recorder FPS Analytics
    appRecorderMediaStreamAvgFPS = 'app.recorder.media_stream.avg_fps',
    appRecorderMediaStreamBestFPS = 'app.recorder.media_stream.best_fps',
    appRecorderMediaStreamWorstFPS = 'app.recorder.media_stream.worst_fps',
    appRecorderMediaStreamTotalSamples = 'app.recorder.media_stream.total_samples',
    appRecorderDemuxerAvgFPS = 'app.recorder.demuxer.avg_fps',
    appRecorderDemuxerBestFPS = 'app.recorder.demuxer.best_fps',
    appRecorderDemuxerWorstFPS = 'app.recorder.demuxer.worst_fps',
    appRecorderDemuxerTotalSamples = 'app.recorder.demuxer.total_samples',
    appRecorderFPSMode = 'app.recorder.fps.mode',
    appRecorderFPSAvgDelta = 'app.recorder.fps.avg_delta',
    appRecorderFPSSampleDelta = 'app.recorder.fps.sample_delta',
    appRecorderFPSWorstDelta = 'app.recorder.fps.worst_delta',
    appRecorderFPSBestDelta = 'app.recorder.fps.best_delta',

    // Web Recorder Bitrate Analytics
    appRecorderMediaStreamAvgBitrate = 'app.recorder.media_stream.avg_bitrate',
    appRecorderMediaStreamPeakBitrate = 'app.recorder.media_stream.peak_bitrate',
    appRecorderMediaStreamMinBitrate = 'app.recorder.media_stream.min_bitrate',
    appRecorderMediaStreamTotalBytes = 'app.recorder.media_stream.total_bytes',
    appRecorderMediaStreamTotalDuration = 'app.recorder.media_stream.total_duration',
    appRecorderMediaStreamSampleCount = 'app.recorder.media_stream.sample_count',
    appRecorderDemuxerAvgBitrate = 'app.recorder.demuxer.avg_bitrate',
    appRecorderDemuxerPeakBitrate = 'app.recorder.demuxer.peak_bitrate',
    appRecorderDemuxerMinBitrate = 'app.recorder.demuxer.min_bitrate',
    appRecorderDemuxerTotalBytes = 'app.recorder.demuxer.total_bytes',
    appRecorderDemuxerTotalDuration = 'app.recorder.demuxer.total_duration',
    appRecorderDemuxerSampleCount = 'app.recorder.demuxer.sample_count',

    // Web Recorder Resolution Analytics
    appRecorderResolutionConfiguredWidth = 'app.recorder.resolution.configured.width',
    appRecorderResolutionConfiguredHeight = 'app.recorder.resolution.configured.height',
    appRecorderResolutionActualWidth = 'app.recorder.resolution.actual.width',
    appRecorderResolutionActualHeight = 'app.recorder.resolution.actual.height',
    appRecorderResolutionDeltaWidth = 'app.recorder.resolution.delta.width',
    appRecorderResolutionDeltaHeight = 'app.recorder.resolution.delta.height',

    // Web Recorder Audio Analytics
    appRecorderAudioConfiguredSampleRate = 'app.recorder.audio.configured.sample_rate',
    appRecorderAudioConfiguredChannelCount = 'app.recorder.audio.configured.channel_count',
    appRecorderAudioConfiguredBitDepth = 'app.recorder.audio.configured.bit_depth',

    appRecorderAudioMediaStreamSampleRate = 'app.recorder.audio.media_stream.sample_rate',
    appRecorderAudioMediaStreamChannelCount = 'app.recorder.audio.media_stream.channel_count',
    appRecorderAudioMediaStreamBitDepth = 'app.recorder.audio.media_stream.bit_depth',
    appRecorderAudioMediaStreamAvgBitrate = 'app.recorder.audio.media_stream.avg_bitrate',
    appRecorderAudioMediaStreamPeakBitrate = 'app.recorder.audio.media_stream.peak_bitrate',
    appRecorderAudioMediaStreamMinBitrate = 'app.recorder.audio.media_stream.min_bitrate',
    appRecorderAudioMediaStreamTotalBytes = 'app.recorder.audio.media_stream.total_bytes',
    appRecorderAudioMediaStreamTotalDuration = 'app.recorder.audio.media_stream.total_duration',
    appRecorderAudioMediaStreamSampleCount = 'app.recorder.audio.media_stream.sample_count',
    appRecorderAudioMediaStreamSegmentSize = 'app.recorder.audio.media_stream.segment_size',
    appRecorderAudioMediaStreamDuration = 'app.recorder.audio.media_stream.duration',

    appRecorderAudioDemuxerSampleRate = 'app.recorder.audio.demuxer.sample_rate',
    appRecorderAudioDemuxerChannelCount = 'app.recorder.audio.demuxer.channel_count',
    appRecorderAudioDemuxerBitDepth = 'app.recorder.audio.demuxer.bit_depth',
    appRecorderAudioDemuxerAvgBitrate = 'app.recorder.audio.demuxer.avg_bitrate',
    appRecorderAudioDemuxerPeakBitrate = 'app.recorder.audio.demuxer.peak_bitrate',
    appRecorderAudioDemuxerMinBitrate = 'app.recorder.audio.demuxer.min_bitrate',
    appRecorderAudioDemuxerTotalBytes = 'app.recorder.audio.demuxer.total_bytes',
    appRecorderAudioDemuxerTotalDuration = 'app.recorder.audio.demuxer.total_duration',
    appRecorderAudioDemuxerSampleCount = 'app.recorder.audio.demuxer.sample_count',
    appRecorderAudioDemuxerSegmentSize = 'app.recorder.audio.demuxer.segment_size',
    appRecorderAudioDemuxerDuration = 'app.recorder.audio.demuxer.duration',

    // Recorder screen recording
    appRecorderScreenSelectSourceType = 'app.recorder.screen.select_source_type',
    appRecorderScreenNumberOfScreenSources = 'app.recorder.screen.number_of_screen_sources',
    appRecorderScreenNumberOfWindowSources = 'app.recorder.screen.number_of_window_sources',
    appRecorderScreenTimeToLoadSources = 'app.recorder.screen.time_to_load_sources',

    // Recorder Recovery
    appRecorderRecoverySuccess = 'app.recorder.recovery_success',
    appRecorderRecoverySourceDirectory = 'app.recorder.recovery.recording_directory',
    appRecorderRecoveryDestDirectory = 'app.recorder.recovery.recording_destination',
    appRecorderRecoveryMessage = 'app.recorder.recovery.recording_message',
    appRecorderRecoveryType = 'app.recorder.recovery.type',

    // Transcription
    appLanguage = 'app.language',
    appTranscriptionRequestedService = 'app.transcription.requested_service',
    appTranscriptionActualService = 'app.transcription.actual_service',

    // Live Transcription
    appLiveTranscriptionResult = 'app.live_transcription.result',
    appLiveTranscriptionReceiptId = 'app.live_transcription.receipt_id',
    appLiveTranscriptionMessage = 'app.live_transcription.message',

    // Recorder Offsets
    appEditorRecorderCameraOffset = 'app.editor.recording.offset_camera',
    appEditorRecorderMicrophoneOffset = 'app.editor.recording.offset_microphone',
    appEditorRecorderScreenOffset = 'app.editor.recording.offset_screen',
    appEditorRecorderSystemAudioOffset = 'app.editor.recording.offset_systemAudio',
    appEditorRecorderSystemError = 'app.editor.recording.error',
    appEditorRecorderSErrorOffset = 'app.editor.recording.offset_error',

    // Rooms
    appRoomIsOwner = 'app.room.is_owner',
    appRoomAvgRTT = 'app.room.stats.avg_rtt',
    appRoomAudioRecvLoss = 'app.room.stats.audio_recv_loss',
    appRoomAudioSendLoss = 'app.room.stats.audio_send_loss',
    appRoomVideoRecvLoss = 'app.room.stats.video_recv_loss',
    appRoomVideoSendLoss = 'app.room.stats.video_send_loss',
    appRoomAudioRecvJitter = 'app.room.stats.audio_recv_jitter',
    appRoomAudioSendJitter = 'app.room.stats.audio_send_jitter',
    appRoomVideoRecvJitter = 'app.room.stats.video_recv_jitter',
    appRoomVideoSendJitter = 'app.room.stats.video_send_jitter',
    appRoomTimeVeryLow = 'app.room.stats.time_very_low_seconds',
    appRoomTimeLow = 'app.room.stats.time_low_seconds',
    appRoomTimeGood = 'app.room.stats.time_good_seconds',
    appRoomNetworkWasLow = 'app.room.stats.network_was_low',
    appRoomNetworkWasVeryLow = 'app.room.stats.network_was_very_low',
    appRoomUploadsWerePaused = 'app.room.stats.uploads_were_paused',

    // Video player rendering
    appVideoPlayerRenderTime = 'app.media.videoplayer.render_time',
    appVideoPlayerAvgRenderTime = 'app.media.videoplayer.avg_render_time',
    appVideoPlayerFramesRendered = 'app.media.videoplayer.frames_rendered',
    appVideoPlayerFramesDropped = 'app.media.videoplayer.frames_dropped',
    appVideoPlayerFramesDuped = 'app.media.videoplayer.frames_duped',
    appVideoPlayerFramesWasted = 'app.media.videoplayer.frames_wasted',
    appVideoPlayerFps = 'app.media.videoplayer.fps',
    appVideoPlayerMaxFps = 'app.media.videoplayer.max_fps',
    appVideoPlayerUtilization = 'app.media.videoplayer.utilization',
    appVideoPlayerAvgTimeBetweenDroppedFrames = 'app.media.videoplayer.avg_time_between_drops',
    appVideoPlayerMaxTimeBetweenDroppedFrames = 'app.media.videoplayer.max_time_between_drops',
    appVideoPlayerFileTypes = 'app.media.videoplayer.file_types',
    appVideoPlayerDecoderCount = 'app.media.videoplayer.decoder_count',
    appVideoPlayerDecoderTypesRoot = 'app.media.videoplayer.decoder_type',

    // Live collab
    appLiveCollabCommitRef = 'app.live_collab.commit_ref',
    appLiveCollabMigrationStatus = 'app.live_collab.migration_status',
    appLiveCollabLastCommitIndex = 'app.live_collab.last_commit_index',
    appLiveCollabRemoteReadReady = 'app.live_collab.remote_read_ready',
    appLiveCollabLocalReadReady = 'app.live_collab.local_read_ready',

    // Plans and payments
    appPaymentsPaymentMethodType = 'app.payments.payment_method_type',
    appPaymentsStripeElementType = 'app.payments.stripe_element_type',
    appPaymentsSubmitEndReason = 'app.payments.submit.end_reason',
    appPaymentsSubmitErrorMessage = 'app.payments.submit.error_message',
    appPaymentsStripePaymentElementLoadError = 'app.payments.stripe_payment_element_load_error',

    appPaymentsUpgradeOrigin = 'app.payments.upgrade_origin',
    appPaymentsUpgradeOriginContext = 'app.payments.upgrade_origin_context',

    appPaymentsIncompleteSubscriptionAction = 'app.payments.incomplete_subscription_action',
    appPaymentsHasPendingActionRedirectURL = 'app.payments.has_pending_action_redirect_url',

    appPaymentsRequestTaxPercentagesStatus = 'app.payments.request_tax_percentages_status',
    appPaymentsRequestTaxPercentagesFailureReason = 'app.payments.request_tax_percentages_failure_reason',

    appAvailablePlansLoadError = 'app.available_plans.load_error',

    // System

    // GPU Preference
    appSystemGPUPreference = 'app.system.gpu_preference',

    // There can be a max of two gpus in the system
    // GPU - 0
    // Windows:
    appSystemGPUId0 = 'app.system.gpu_id_0',
    appSystemGPUVendorId0 = 'app.system.gpu_vendorid_0',
    appSystemGPURevision0 = 'app.system.gpu_revision_0',
    appSystemGPUStatus0 = 'app.system.gpu_status_0',
    appSystemGPUAvailability0 = 'app.system.gpu_availability_0',
    appSystemGPULastErrorCode0 = 'app.system.gpu_lastErrorCode_0',
    appSystemGPUDriverVersion0 = 'app.system.gpu_driverVersion_0',
    appSystemGPUDriverDate0 = 'app.system.gpu_driverDate_0',
    appSystemGPUConfigManagerErrorCode0 = 'app.system.gpu_configManagerErrorCode_0',

    //Mac:
    appSystemGPUName0 = 'app.system.gpu_name_0',
    appSystemGPURecommendedMaxWorkingSetSize0 = 'app.system.gpu_recommended_max_working_set_0',
    appSystemGPUHeadless0 = 'app.system.gpu_headless_0',
    appSystemGPUType0 = 'app.system.gpu_type_0',
    appSystemGPUMaxTransferRate0 = 'app.system.gpu_max_transfer_rate_0',
    appSystemGPUHasUnifiedMemory0 = 'app.system.gpu_has_unified_memory_0',
    appSystemGPULocation0 = 'app.system.gpu_location_0',

    // GPU - 1
    // Windows:
    appSystemGPUId1 = 'app.system.gpu_id_1',
    appSystemGPUVendorId1 = 'app.system.gpu_vendorid_1',
    appSystemGPURevision1 = 'app.system.gpu_revision_1',
    appSystemGPUStatus1 = 'app.system.gpu_status_1',
    appSystemGPUAvailability1 = 'app.system.gpu_availability_1',
    appSystemGPULastErrorCode1 = 'app.system.gpu_lastErrorCode_1',
    appSystemGPUDriverVersion1 = 'app.system.gpu_driverVersion_1',
    appSystemGPUDriverDate1 = 'app.system.gpu_driverDate_1',
    appSystemGPUConfigManagerErrorCode1 = 'app.system.gpu_configManagerErrorCode_1',

    // Mac:
    appSystemGPUName1 = 'app.system.gpu_name_1',
    appSystemGPURecommendedMaxWorkingSetSize1 = 'app.system.gpu_recommended_max_working_set_1',
    appSystemGPUHeadless1 = 'app.system.gpu_headless_1',
    appSystemGPUType1 = 'app.system.gpu_type_1',
    appSystemGPUMaxTransferRate1 = 'app.system.gpu_max_transfer_rate_1',
    appSystemGPUHasUnifiedMemory1 = 'app.system.gpu_has_unified_memory_1',
    appSystemGPULocation1 = 'app.system.gpu_location_1',

    // CPU
    // Both:
    appSystemCPUName = 'app.system.cpu_name',
    appSystemCPUFamily = 'app.system.cpu_family',
    // Windows:
    appSystemCPUArchitecture = 'app.system.cpu_arch',
    appSystemCPUManufacturer = 'app.system.cpu_manufacturer',
    appSystemCPUStatus = 'app.system.cpu_status',
    appSystemCPUAvailability = 'app.system.cpu_availability',
    appSystemCPULastErrorCode = 'app.system.cpu_lastErrorCode',
    // Mac:
    appSystemCPUType = 'app.system.cpu_type',
    appSystemCPUSubtype = 'app.system.cpu_subtype',
    appSystemCPUSubfamily = 'app.system.cpu_subfamily',
    appSystemCPUMachine = 'app.system.cpu_machine',
    appSystemCPUModel = 'app.system.cpu_model',
    appSystemCPUKernelVersion = 'app.system.cpu_kernel_version',
    appSystemCPUTotalMemory = 'app.system.cpu_total_memory',
    appSystemCPUUsedMemory = 'app.system.cpu_used_memory',
    appSystemCPUAvailableMemory = 'app.system.cpu_available_memory',

    // Document complexity statistics
    appDocTauCount = 'app.doc.tau_count',
    appTrackTauCount = 'app.doc.track.tau_count',
    appDocTrackCount = 'app.doc.track_count',
    appDocTotalWordCount = 'app.doc.word_count',
    appDocMediaRefMaxWordCount = 'app.doc.media_ref.max_word_count',
    appDocMediaRefCount = 'app.doc.media_ref_count',
    appDocMediaRefTotalDuration = 'app.doc.media_ref.total_duration',
    appDocSceneCount = 'app.doc.scene_count',
    appTrackSceneCount = 'app.doc.track.scene_count',
    appDocSceneLayerCount = 'app.doc.scene_layer_count',
    appTrackSceneLayerCount = 'app.doc.track.scene_layer_count',
    appTrackDuration = 'app.doc.track.duration',
    appDocPinnedTrackCount = 'app.doc.pinned_track_count',
    appTrackPinnedTrackCount = 'app.doc.track.pinned_track_count',
    appDocTausInSequencesCount = 'app.doc.taus_in_sequences_count',
    appDocSequenceCount = 'app.doc.sequence_count',
    appDocJsonSizeBytes = 'app.doc.json_size_bytes',
    appDocJsonTooLargeToStringify = 'app.doc.json_too_large_to_stringify',

    // Zoom imports
    appZoomImportSuccess = 'app.zoom_import.success',
    appZoomImportDownloadDuration = 'app.zoom_import.download_duration',
    appZoomImportTotalDuration = 'app.zoom_import.total_duration',
    appZoomImportAccountLinkSuccess = 'app.zoom_import.zoom_link_success',
}

export type RecorderWorkflowSpan = {
    span: Span;
    metadata: {
        startingTimestamp: number;
        stoppingTimestamp: number;
        errorMessage: string[];
    };
};

export enum EditorRecordingAnalyticsState {
    initialized = 'initialized',
    initializing = 'initializing',
    configure = 'configure',
    starting = 'starting',
    started = 'started',
    stopped = 'stopped',
    force_kill = 'force_kill',
    failure = 'failure',
    canceled = 'canceled',
    stopping = 'stopping',
}

export enum SpanStatus {
    aborted = 'aborted',
    alreadyExists = 'already_exists',
    cancelled = 'cancelled',
    dataLoss = 'data_loss',
    deadlineExceeded = 'deadline_exceeded',
    failedPrecondition = 'failed_precondition',
    internal = 'internal',
    invalidArgument = 'invalid_argument',
    notFound = 'not_found',
    ok = 'ok',
    outOfRange = 'out_of_range',
    permissionDenied = 'permission_denied',
    resourceExhausted = 'resource_exhausted',
    unauthenticated = 'unauthenticated',
    unavailable = 'unavailable',
    unimplemented = 'unimplemented',
    unknownError = 'unknown_error',
}

export enum TemplatePreviewLoadState {
    url_fetch_failure = 'url_fetch_failure',
    media_load_failure = 'media_load_failure',
    media_loaded = 'media_loaded',
}

export type CompleteTagData = {
    [SpanTag.traceId]: string;
    [SpanTag.parentId]: string;
    [SpanTag.spanId]: string;
    [SpanTag.serviceName]: string;
    [SpanTag.name]: string;
    [SpanTag.durationMs]: number;
    [SpanTag.isErrored]: boolean;
    [SpanTag.spanStatus]: SpanStatus;
    [SpanTag.spanKind]: 'server' | 'client' | 'internal' | 'publisher' | 'consumer';
    [SpanTag.sampleRate]: number;

    [SpanTag.message]: string;
    [SpanTag.stacktrace]: string;
    [SpanTag.annotationType]: 'span_event' | 'link';
    [SpanTag.errorType]: string;
    [SpanTag.errorEscaping]: boolean;
    [SpanTag.errorCode]: string;
    [SpanTag.sentryId]: string;

    [SpanTag.buildName]: string;
    [SpanTag.buildType]: string;
    [SpanTag.buildVersion]: string;
    [SpanTag.buildPrettyVersion]: string;
    [SpanTag.osName]: string;
    [SpanTag.osVersion]: string;
    [SpanTag.osTotalMemory]: number;
    [SpanTag.osFreeMemory]: number;
    [SpanTag.osNumCpus]: number;
    [SpanTag.nodeVersion]: string;

    [SpanTag.messagingE2ELatencyMs]: number;

    [SpanTag.httpMethod]: 'get' | 'post' | 'put' | 'delete' | 'head' | 'patch';
    [SpanTag.httpPath]: string;
    [SpanTag.httpRequestContentLength]: number;
    [SpanTag.httpResponseContentLength]: number;
    [SpanTag.httpStatusCode]: number;
    [SpanTag.netPeerName]: string;
    [SpanTag.netPeerPort]: string; // URL.port is a string
    [SpanTag.httpScheme]: string;
    [SpanTag.netBytesSent]: number;

    [SpanTag.appSpanType]: string;
    [SpanTag.appObjectId]: string;
    [SpanTag.appObjectStoreBucket]: string;
    [SpanTag.appUserId]: string;
    [SpanTag.appUserLoggedIn]: boolean;
    [SpanTag.appProjectId]: string;
    [SpanTag.appRevisionId]: string;
    [SpanTag.appBaseRevisionId]: string;
    [SpanTag.appBaseRevisionNumber]: number;
    [SpanTag.appCompositionId]: string;
    [SpanTag.appAssetId]: string;
    [SpanTag.appAssetUrl]: string;
    [SpanTag.appAssetQuality]: 'original' | 'preview';
    [SpanTag.appArtifactId]: string;
    [SpanTag.appArtifactContentType]: string;
    [SpanTag.appArtifactQuality]: string;
    [SpanTag.appFileExtension]: string;
    [SpanTag.appFileSize]: number;
    [SpanTag.appMimeType]: string;
    [SpanTag.appMD5]: string;
    [SpanTag.appSkippedMD5]: boolean;
    [SpanTag.appBundleVersion]: string;
    [SpanTag.appFeatureFlagValue]: string;
    [SpanTag.appTimeoutLimitMs]: number;
    [SpanTag.appCount]: number;
    [SpanTag.appResult]: string;
    [SpanTag.appScope]: string;
    [SpanTag.appImplementation]: string;
    [SpanTag.appBatch]: boolean;
    [SpanTag.appTrigger]: string;
    [SpanTag.appPeriod]: string;
    [SpanTag.appInteractive]: boolean;
    [SpanTag.appFormat]: string;
    [SpanTag.appRate]: number;
    [SpanTag.appRegion]: string;
    [SpanTag.appCacheResult]: 'hit' | 'miss' | 'inconsistent' | 'stale';
    [SpanTag.appDataOrigin]: 'network' | 'disk' | 'memory';
    [SpanTag.appQueueLength]: number;
    [SpanTag.appIdempotencyKey]: string;
    [SpanTag.appDownloadMs]: number;

    [SpanTag.appDriveCount]: number;
    [SpanTag.appProjectCount]: number;
    [SpanTag.appProjectDownloadedCount]: number;
    [SpanTag.appDriveId]: string;
    [SpanTag.appProductGroup]: string;

    [SpanTag.appEncodeMs]: number;
    [SpanTag.appMediaType]: 'audio' | 'video' | 'image';
    [SpanTag.appDataRefType]:
        | 'url'
        | 'blob'
        | 'local-file'
        | 'missing'
        | 'artifact'
        | 'segmented-artifact';
    [SpanTag.appMediaDuration]: number;
    [SpanTag.appMediaInterface]: string;
    [SpanTag.appMediaEngineType]: string;
    [SpanTag.appMediaFileConversion]: string;
    [SpanTag.appAudioPrefetchMs]: number;
    [SpanTag.appAudioEncodeMs]: number;
    [SpanTag.appVideoTotalPrefetchMs]: number;
    [SpanTag.appVideoEncodeMs]: number;
    [SpanTag.appTimelineDuration]: number;
    [SpanTag.appPlayerKey]: string;
    [SpanTag.appChannels]: number;
    [SpanTag.appAudioBitrate]: number;
    [SpanTag.appVideoBitrate]: number;
    [SpanTag.appVideoPixelFormat]: string;
    [SpanTag.appVideoCodec]: string;
    [SpanTag.appWebCodecsVideoDecoderCodec]: string;
    [SpanTag.appFps]: number;

    [SpanTag.appPublish]: boolean;
    [SpanTag.appEditor]: 'quick' | 'full';
    [SpanTag.appClipCount]: number;
    [SpanTag.appTrackCount]: number;
    [SpanTag.appSpeakerCount]: number;
    [SpanTag.appPinCount]: number;
    [SpanTag.appAssetCount]: number;
    [SpanTag.appSceneCount]: number;
    [SpanTag.appTemplate]: string;
    [SpanTag.appAISpeech]: boolean;
    [SpanTag.appStudioSound]: boolean;
    [SpanTag.appFontCustom]: boolean;
    [SpanTag.appFontBuiltin]: boolean;
    [SpanTag.appWatermark]: boolean;
    [SpanTag.appStoryboard]: boolean;
    [SpanTag.appStoryboardRemastered]: boolean;
    [SpanTag.appDesktop2]: boolean;
    [SpanTag.appFlattenTracksFilter]: 'script' | 'all';

    /** Templates */
    [SpanTag.appTemplateProjectId]: string;
    [SpanTag.appTemplateCompositionId]: string;
    [SpanTag.appTemplateDriveId]: string;
    [SpanTag.appTemplateVersionId]: string;
    [SpanTag.appTemplateCardId]: string;
    [SpanTag.appTemplatePreviewLandingPage]: string;
    [SpanTag.appTemplatePreviewState]: TemplatePreviewLoadState;
    [SpanTag.appTemplatePreviewTotalTimeToFetchUrlMs]: number;
    [SpanTag.appTemplatePreviewTotalTimeToLoadImageMs]: number;
    [SpanTag.appTemplatePreviewTotalTimeToLoadVideoMs]: number;
    [SpanTag.appTemplatePreviewImageAvailable]: boolean;
    [SpanTag.appTemplatePreviewVideoAvailable]: boolean;
    [SpanTag.appTemplatePreviewImageLoadFailed]: boolean;
    [SpanTag.appTemplatePreviewVideoLoadFailed]: boolean;
    [SpanTag.appTemplateCopySourceContentsMs]: number;
    [SpanTag.appTemplateMakeSelectionMs]: number;
    [SpanTag.appTemplatePasteContentsMs]: number;
    [SpanTag.appTemplateTrackSceneAddedMs]: number;

    /** Save scene to template */
    [SpanTag.appTemplateSaveCardNewTemplate]: boolean;
    [SpanTag.appTemplateSaveCardGetTemplateProjectMs]: number;
    [SpanTag.appTemplateSaveCardCopyPasteMs]: number;
    [SpanTag.appTemplateSaveCardSaveNewRevisionMs]: number;

    /** Published Projects */
    [SpanTag.appPublishPublishedProjectId]: string;
    [SpanTag.appPublishType]: 'video' | 'audio' | 'audiogram';
    [SpanTag.appPublishPermission]: 'unlisted' | 'private' | 'public';
    [SpanTag.appPublishQuickEdit]: boolean;
    [SpanTag.appPublishDestination]: string;
    [SpanTag.appPublishCheckAlignmentJobsMs]: number;
    [SpanTag.appPublishUploadBounceFilesMs]: number;
    [SpanTag.appPublishCreateAndUploadPublishedProjectMs]: number;
    [SpanTag.appPublishTotalTimeMs]: number;
    [SpanTag.appPublishResolution]: string;
    [SpanTag.appPublishWidth]: number;
    [SpanTag.appPublishHeight]: number;

    /** Doc complexity stats */
    [SpanTag.appDocTauCount]: number;
    [SpanTag.appTrackTauCount]: number;
    [SpanTag.appDocTrackCount]: number;
    [SpanTag.appDocTotalWordCount]: number;
    [SpanTag.appDocMediaRefMaxWordCount]: number;
    [SpanTag.appDocMediaRefCount]: number;
    [SpanTag.appDocSceneCount]: number;
    [SpanTag.appTrackSceneCount]: number;
    [SpanTag.appDocSceneLayerCount]: number;
    [SpanTag.appTrackSceneLayerCount]: number;
    [SpanTag.appTrackDuration]: number;
    [SpanTag.appDocPinnedTrackCount]: number;
    [SpanTag.appTrackPinnedTrackCount]: number;
    [SpanTag.appDocTausInSequencesCount]: number;
    [SpanTag.appDocSequenceCount]: number;
    [SpanTag.appDocJsonSizeBytes]: number;
    [SpanTag.appDocJsonTooLargeToStringify]: boolean;

    /** Screen recording */
    [SpanTag.appRecorderScreenSelectSourceType]: 'screen' | 'window';
    [SpanTag.appRecorderScreenNumberOfScreenSources]: number;
    [SpanTag.appRecorderScreenNumberOfWindowSources]: number;
    [SpanTag.appRecorderScreenTimeToLoadSources]: number;

    [SpanTag.appZoomImportTotalDuration]: number;
    [SpanTag.appZoomImportDownloadDuration]: number;
    [SpanTag.appZoomImportSuccess]: boolean;
    [SpanTag.appZoomImportAccountLinkSuccess]: boolean;

    [key: string]: number | string | boolean;
};

export function featureFlagSpanTag(name: string): string {
    return `app.feature_flag.${name}`;
}
