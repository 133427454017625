// Copyright 2022 Descript, Inc

import { CompositionSummary, compositionSummaryIsEqual } from './Revision';

export type DocumentMetadataJson = {
    composition_summaries: CompositionSummary[];
    composition_count: number;
    total_project_size_bytes: number;
};

export type DocumentMetadata = {
    compositionSummaries: CompositionSummary[];
    compositionCount: number;
    totalProjectSizeBytes: number;
};

export function documentMetadataFromJSON(
    json?: DocumentMetadataJson,
): DocumentMetadata | undefined {
    if (
        json === undefined ||
        json.composition_summaries === undefined ||
        json.composition_count === undefined ||
        json.total_project_size_bytes === undefined
    ) {
        return undefined;
    }
    return {
        compositionSummaries: json.composition_summaries,
        compositionCount: json.composition_count,
        totalProjectSizeBytes: json.total_project_size_bytes,
    };
}

export function documentMetadataToJSON(
    documentMetadata?: DocumentMetadata,
): DocumentMetadataJson | undefined {
    if (
        documentMetadata === undefined ||
        documentMetadata.compositionSummaries === undefined ||
        documentMetadata.compositionCount === undefined ||
        documentMetadata.totalProjectSizeBytes === undefined
    ) {
        return undefined;
    }
    return {
        composition_summaries: documentMetadata.compositionSummaries,
        composition_count: documentMetadata.compositionCount,
        total_project_size_bytes: documentMetadata.totalProjectSizeBytes,
    };
}

export function recentMetadataIsEqual(
    a: DocumentMetadata | undefined,
    b: DocumentMetadata | undefined,
): boolean {
    if (a === undefined || b === undefined) {
        return a === undefined && b === undefined;
    }
    if (a.compositionCount !== b.compositionCount) {
        return false;
    }
    if (a.totalProjectSizeBytes !== b.totalProjectSizeBytes) {
        return false;
    }

    if (a.compositionSummaries.length !== b.compositionSummaries.length) {
        return false;
    }
    return a.compositionSummaries.every((aComp, index) =>
        compositionSummaryIsEqual(aComp, b.compositionSummaries[index]!),
    );
}
